import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import PersonalTab from './PersonalTab/PersonalTab';
import SecurityTab from './SecurityTab/SecurityTab';
import CommunicationTab from './CommunicationTab/CommunicationTab';
import { getUserProfileApi, getCountry } from './service';
import { images } from '../../config/images';
import "./UserProfile.css"
import { useSelector, useDispatch } from 'react-redux';
import { setUserDetails } from '../Login/slice/userSlice';


const UserProfile = () => {

    const [value, setValue] = React.useState('1');
    const [userProfile, setUserProfile] = useState("");
    const [mobielNo, setMobileNo] = useState("");
    const [mobileCountryCode, setMobileCountryCode] = useState("");


    const userState = useSelector(state => state.userState);
    const dispatch = useDispatch();
   

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getUserProfile = async () => {
        const resp = await getUserProfileApi();
        if (resp.data.success == true) {
            setUserProfile(resp.data.payload);
            setMobileNo(resp.data.payload.mobile_number % 10000000000 ? resp.data.payload.mobile_number % 10000000000 : '')
            setMobileCountryCode(Math.floor(resp.data.payload.mobile_number / 10000000000));
            dispatch(setUserDetails({ ...userState, firstName: resp.data.payload.first_name, lastName: resp.data.payload.last_name, company_logo: resp.data.payload.company_logo }))
        }
    }

    useEffect(() => {
        getUserProfile();
    }, [value])

    return (
        <>
            <div className='containerWrap'>
                <Typography variant="h5" gutterBottom style={{ paddingTop: '30px', paddingLeft: '65px', color: '#1C407B', fontWeight: '600' }}>
                    USER PROFILE
                </Typography>
                {/* <SectionTitle title={"USER PROFILE"}/> */}
                <Grid container>
                    <Grid item xs={12} pt='20px' px='60px'>
                        <Card className="userpofileCard">
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, border: 'none' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example"
                                            sx={{
                                                "& .MuiTab-root": {
                                                    fontWeight: 600,
                                                    textTransform: "none",
                                                    borderRadius: "0",
                                                    color:"#AEBCD2",
                                                    "&.Mui-selected": {
                                                        color: "#001323",
                                                    },
                                                },
                                            }}>
                                            <Tab label={
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    {/* <PersonIcon style={{ marginRight: "8px" }} /> */}
                                                    {value == "1" ? <img src={require("../../assets/images/user_dark.png")} alt="" style={{ marginRight: "8px" }} /> : <img src={require("../../assets/images/user_light.png")} alt="" style={{ marginRight: "8px" }} />}
                                                    Personal
                                                </div>} value="1" style={{ fontSize: '20px', fontWeight: '500' }} />
                                            <Tab label={
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    {value == "2" ? <img src={require("../../assets/images/security_dark.png")} alt="" style={{ marginRight: "8px" }} /> : <img src={require("../../assets/images/security_light.png")} alt="" style={{ marginRight: "8px" }} />}
                                                    Security
                                                </div>} value="2" style={{ fontSize: '20px', fontWeight: '500' }} />
                                            {/* <Tab label="Communication Settings" value="3" style={{ fontSize: '20px', fontWeight: '500' }} />
                                        <img src={images.communicationImg} alt="" className='' /> */}
                                            <Tab label={
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    {/* <SecurityIcon style={{ marginRight: "8px" }} /> */}
                                                    {value == "3" ? <img src={images.communicationImg} alt="" style={{ marginRight: "8px" }} /> : <img src={require("../../assets/images/communication_light.png")} alt="" style={{ marginRight: "8px" }}  />}
                                                    Communication Settings
                                                </div>} value="3" style={{ fontSize: '20px', fontWeight: '500' }} />
                                        </TabList>
                                    </Box>
                                    {/* =============== Personal =============== */}
                                    <PersonalTab userProfile={userProfile} setUserProfile={setUserProfile} value={value} getUserProfile={getUserProfile} setValue={setValue} mobielNo={mobielNo} mobileCountryCode={mobileCountryCode} setMobileNo={setMobileNo} setMobileCountryCode={setMobileCountryCode} />
                                    {/* =============== Security =============== */}
                                    {/* =============== Security =============== */}
                                    <SecurityTab userProfile={userProfile} setUserProfile={setUserProfile} getUserProfile={getUserProfile} setValue={setValue} mobielNo={mobielNo} setMobileNo={setMobileNo} setMobileCountryCode={setMobileCountryCode} mobileCountryCode={mobileCountryCode} />
                                    {/* =============== Communication Setting =============== */}
                                    <CommunicationTab userProfile={userProfile} setUserProfile={setUserProfile} getUserProfile={getUserProfile} setValue={setValue} />
                                </TabContext>
                            </Box>
                        </Card>
                        {/* <FooterText /> */}
                    </Grid>

                </Grid>
            </div>
        </>
    )
}

export default UserProfile;