import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "../Source/Source.css";
import ConsumptionSlider from "./ConsumptionSlider";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import { getDeviceSourceAPI, getTagID } from "../Source/services";
import { getDeviceConf } from "./service";
import { useEffect } from "react";
import { LineChart } from "./LineChart";
import HighchartsGraph from "./LineChart2";
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import { useCubeQuery } from "@cubejs-client/react";
import CubeQueryComponent from "./ConsumptionGraphData";
import CubeQueryComponent1 from "./ConsumptionGraphData1";
import CubeQueryComponent2 from "./ConsumptionGraphData2";
import CubeQueryComponent3 from "./ConsumptionGraphData3";
import ConsumptionThresholdValue from "./ConsumptionThresholdValue";
import { setProcessDashboarData } from "./slice/processDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";

const Consumption = () => {
  const [deviceData, setDeviceData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [unitSearchQuery, setUnitSearchQuery] = useState("");
  const [selectTimeRange, setSelectTimeRange] = useState("live");

  const { deviceIdRe, deviceUnit, deviceColor } = useSelector(state => state.processDashboardState);
  const dispatch = useDispatch()
  console.log("deviceColor", deviceColor);

  const handleUnitSearchChange = (event) => {
    setUnitSearchQuery(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const [deviceId, setDeviceId] = useState([]);
  const [unitName, setUnitName] = useState([]);
  const [getDevice, setGetDevice] = useState([]);
  const [minValue, setMinValue] = useState([]);
  const [maxValue, setMaxValue] = useState([]);
  const [chartCubeData, setChartCubeData] = useState([]);
  const [average, setAverage] = useState(false);
  const [confData, setConfData] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [chartLineData, setChartLineData] = useState([]);
  const [minMax, setMinMax] = useState([])
  const [realTimevalue, setRealTimevalue] = useState([]);
  const [chartLineData1, setChartLineData1] = useState([]);
  const [minMax1, setMinMax1] = useState([])
  const [realTimevalue1, setRealTimevalue1] = useState([]);
  const [chartLineData2, setChartLineData2] = useState([]);
  const [minMax2, setMinMax2] = useState([])
  const [realTimevalue2, setRealTimevalue2] = useState([]);
  const [chartLineData3, setChartLineData3] = useState([]);
  const [minMax3, setMinMax3] = useState([])
  const [realTimevalue3, setRealTimevalue3] = useState([]);
  const [combineChartData, setCombineChartData] = useState([]);
  const [combineRealTimeData, setCombinerealTimeData] = useState([])
  const [combineMinMax, setCombineminMax] = useState([]);
  const [percentageValue, setPercentageValue] = useState([0, 0, 0, 0]);
  const [cubeThresholdValue, setCubeThresoldValue] = useState(0);
  const [cubeTagUnitSTKVA, setCubeTagUnitSTKVA] = useState(0);
  const [thresholdValue, setThresholdValue] = useState(0)



  // Line-1 Chart data 
  const handelCubeChartLineData = (value, minmax) => {
    setChartLineData((prev) => [...prev, value])
    setRealTimevalue([value])
    setMinMax([minmax])
  }

  // Line-2 Chart data 
  const handelCubeChartLineData1 = (value, minmax) => {
    setChartLineData1((prev) => [...prev, value])
    setRealTimevalue1([value])
    setMinMax1([minmax])
  }

  // Line-3 Chart data 
  const handelCubeChartLineData2 = (value, minmax) => {
    setChartLineData2((prev) => [...prev, value])
    setRealTimevalue2([value])
    setMinMax2([minmax])
  }

  // Line-4 Chart data 
  const handelCubeChartLineData3 = (value, minmax) => {
    setChartLineData3((prev) => [...prev, value])
    setRealTimevalue3([value])
    setMinMax3([minmax])
  }

  useEffect(() => {
    if (chartLineData.length || chartLineData1.length) {
      setCombineChartData([chartLineData, chartLineData1, chartLineData2, chartLineData3]);
      setCombinerealTimeData([realTimevalue, realTimevalue1, realTimevalue2, realTimevalue3]);
      setCombineminMax([minMax, minMax1, minMax2, minMax3]);
    }
  }, [chartLineData, chartLineData1, chartLineData2, chartLineData3, realTimevalue, realTimevalue1, realTimevalue2, realTimevalue3, minMax, minMax1, minMax2, minMax3])


  // device percentage value
  useEffect(() => {
    if (combineChartData.length) {
      const resultArray = combineChartData.map((subArray) => {
        if (subArray.length >= 2) {
          const lastValue = subArray[subArray.length - 1].tagValue;
          const secondLastValue = subArray[subArray.length - 2].tagValue;
          const percentage = ((lastValue - secondLastValue) / secondLastValue) * 100;
          console.log("lastValue", lastValue);
          console.log("secondLastValue", secondLastValue);
          return parseFloat(percentage) ? parseFloat(percentage) : 0 ;
        } else {
          return 0;
        }
      });

      setPercentageValue(resultArray)
      console.log("resultArrayresultArray", resultArray);
    }
  }, [combineChartData])


  // threshold value in st_kva

  useEffect(() => {
    if (deviceUnit[0] == "ST_kVA" && cubeThresholdValue && cubeTagUnitSTKVA) {
      const kvaThreshold = (cubeThresholdValue.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / cubeTagUnitSTKVA) * 100;

      setThresholdValue(kvaThreshold.toFixed(2))
      // console.log("kvaThreshold", kvaThreshold);
    }
  }, [deviceUnit, cubeThresholdValue, cubeTagUnitSTKVA ])

  const switchColors = [
    "#FEBB22",
    "#075571",
    "#002B2A",
    "#C99C00",
    "#69927A",
    "#872B81",
    "#F06292",
    "#29445A",
    "#CD4D4A",
    "#0C4562",
    "#544632", 
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#001A30",
    "#0B648D",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#123782",
    "#C60BC8",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#EB9E79",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#7D2264",
    "#0EB520",
    "#0E3504",
    "#1D733F",
    "#6E02B1"
  ];


  const navigate = useNavigate();

  const param = useParams();

  useEffect(() => {
    getDeviceData();
  }, []);

  // deviceData.forEach((device, index) => {
  //   console.log("Device at index", index, ":", device.device);
  // });

  const getDeviceData = async () => {
    const params = {
      device_type: "Consumption",
      plant_id: Number(param.id),
    };
    try {
      const resp = await getDeviceSourceAPI(params);
      if (resp.status == 200) {
        setDeviceData(resp.data.payload.device);
        setTagData(resp.data.payload.tag);
      }
    } catch (error) {
      console.log("error", error);
   }
  };
  const handleSearchData = () => { };
  useEffect(() => {
    const fetchData = async () => {
      const newConfData = await Promise.all(
        deviceData.map(async (device) => {
          const unit = await getDeviceConfData(device.device);
          return unit;
        })
      );
      setConfData(newConfData);
    };

    fetchData();
  }, [deviceData]);

  const getDeviceConfData = async (deviceId) => {
    try {
      const param1 = {
        plant_id: param.id,
        company_id: param.companyId,
        device_type: "Consumption",
        device_id: deviceId,
      };

      const resp = await getDeviceConf(param1);
      if (resp.status === 200 || resp.status === 201) {
        return resp.data.payload.length ? resp.data.payload[0].tag_assigned : [];
      }
    } catch (error) {
      console.error("Error fetching device configuration:", error);
    }
  };

  const isCheckboxDisabled = (id) => {
    return deviceIdRe.length >= 1 && !deviceIdRe.includes(id);
  };


  const handleSelectDeviceId = (id, index, event, row) => {

    const existingIndex = deviceColor.findIndex(
        (color) => color.deviceId === row.device
      );

      if (existingIndex !== -1) {
        const updatedColors = [...deviceColor];
        updatedColors.splice(existingIndex, 1);
         dispatch(setProcessDashboarData({
          deviceColor: updatedColors
    }));
      } else {
        // Add the object to the state array if it doesn't exist
         dispatch(setProcessDashboarData({
          deviceColor: [
            ...deviceColor,
            { deviceId: row.device, dvName: row.device__device_name, color: event.target.name },
          ]
        }));
      }


    if (!Array.isArray(deviceIdRe)) {
      setDeviceId([]);
      dispatch(setProcessDashboarData({ deviceIdRe: [] }))
    }
    if (deviceIdRe.includes(id)) {
      // setDeviceId(deviceIdRe.filter((element) => element !== id));
      dispatch(setProcessDashboarData({ deviceIdRe: deviceIdRe.filter((element) => element !== id) }))
    } else {
      // setDeviceId([...deviceIdRe, id]);
      dispatch(setProcessDashboarData({ deviceIdRe: [...deviceIdRe, id] }))
    }
  };

  const handleSelectTagUnit = (id) => {
    if (!Array.isArray(deviceUnit)) {
      // setUnitName([]);
      dispatch(setProcessDashboarData({ deviceUnit: []}))
    }
    if (deviceUnit.includes(id)) {
      // setUnitName(unitName.filter((element) => element !== id));
      dispatch(setProcessDashboarData({ deviceUnit: deviceUnit.filter((element) => element !== id) }))
    } else {
      // setUnitName([id]);
      dispatch(setProcessDashboarData({ deviceUnit: [id] }))
    }
  };

  const getTagIDApi = async () => {
    const param = {
      device_id: deviceIdRe,
      tag_unit: deviceUnit[0],
    };

    const resp = await getTagID(param);

    if (resp.status == 200) {
      setGetDevice(resp.data.payload);
    }
  };

  useEffect(() => {
    if (deviceIdRe.length && deviceUnit.length) {
      setChartLineData([]);
      setChartLineData1([]);
      setChartLineData2([]);
      setChartLineData3([]);
      setMinMax([]);
      setMinMax1([]);
      setMinMax2([]);
      setMinMax3([]);
      setRealTimevalue([]);
      setRealTimevalue1([]);
      setRealTimevalue2([]);
      setRealTimevalue3([]);
      setCombineChartData([])
      setCombineminMax([]);
      setCombinerealTimeData([])
      setGetDevice([])
      getTagIDApi();
    } else {
      setChartLineData([]);
      setChartLineData1([]);
      setChartLineData2([]);
      setChartLineData3([]);
      setMinMax([]);
      setMinMax1([]);
      setMinMax2([]);
      setMinMax3([]);
      setRealTimevalue([]);
      setRealTimevalue1([]);
      setRealTimevalue2([]);
      setRealTimevalue3([]);
      setCombineChartData([])
      setCombineminMax([]);
      setCombinerealTimeData([])
      setGetDevice([]);
    }
  }, [deviceIdRe, deviceUnit, selectTimeRange]);


  const getTagValueOfSTKVA = useCubeQuery({
    "dimensions": [
      "DeviceConfigurationTagconfiguration.tagUnit",
      "DeviceConfigurationTagconfiguration.latestValue"
    ],
    "order": {
      "DeviceConfigurationTagconfiguration.tagUnit": "asc"
    },
    "filters": [
      {
        "member": "DeviceConfigurationTagconfiguration.tagUnit",
        "operator": "contains",
        "values": [
          "ST_kVA"
        ]
      }
    ]
  }, { subscribe: deviceUnit[0] ? deviceUnit[0].toLowerCase()  == "st_kva" ? true : false : false });


  useMemo(() => {
    if (getTagValueOfSTKVA.resultSet) {
      const tagValue = getTagValueOfSTKVA.resultSet.tablePivot().length ? parseFloat(getTagValueOfSTKVA.resultSet.tablePivot()[0]["DeviceConfigurationTagconfiguration.latestValue"]) : 0
      
      setCubeTagUnitSTKVA(tagValue)
    }
  }, [getTagValueOfSTKVA.resultSet])


  console.log("getTagValueOfSTKVA", getTagValueOfSTKVA);





  return (
    <>
      {getDevice.length ? getDevice[0] ? <CubeQueryComponent getDevice={getDevice} handelCubeChartLineData={handelCubeChartLineData} deviceId={deviceId} selectTimeRange={selectTimeRange} /> : null : null}
      {getDevice.length ? getDevice[1] ? <CubeQueryComponent1 getDevice={getDevice} deviceId={deviceId} selectTimeRange={selectTimeRange} handelCubeChartLineData1={handelCubeChartLineData1} /> : null : null}
      {getDevice.length ? getDevice[2] ? <CubeQueryComponent2 getDevice={getDevice} deviceId={deviceId} selectTimeRange={selectTimeRange} handelCubeChartLineData2={handelCubeChartLineData2} /> : null : null}
      {getDevice.length ? getDevice[3] ? <CubeQueryComponent3 getDevice={getDevice} deviceId={deviceId} selectTimeRange={selectTimeRange} handelCubeChartLineData3={handelCubeChartLineData3} /> : null : null}
      {getDevice.length ? <ConsumptionThresholdValue getDevice={getDevice} setCubeThresoldValue={setCubeThresoldValue} /> : null}
      <div className="page-wraper">
        <div className="page-header">
          <div
            onClick={() =>
              navigate(
                `/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`
              )
            }
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Consumptions</span>
          </div>
        </div>

        <div className="pt-3">
          <ConsumptionSlider deviceData={deviceData} confData={confData} getDevice={getDevice}/>
        </div>
        <div className="pt-2 consumption-grid-card">
          <Grid item xs={12} sm={12} md={3}>
            <Box className="source-box">
              <Box className="consumer-text-disc mt-2">
                <h6 className="Title">Title</h6>
                <h6
                  className="Title-desc"
                  style={{ marginBottom: "0 !important" }}
                >
                   Energy Meter Trends - Real Time
                </h6>
              </Box>

              {/* <Box className="consumer-text-disc mt-2">
                <h6 className="Title">Description</h6>
                <h6 className="Title-desc">Energy Meter Trends - Real Time</h6>
              </Box> */}

              <TextField
                variant="outlined"
                className="search-textfield"
                placeholder="Search Device here"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />
              <div className="furnace-grid">
                <Box className="Grid-text mt-2">
                  {deviceData.length
                    ? deviceData
                      .filter((e) =>
                        e.device__device_name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )
                      .map((e, index) => (
                        <Grid item xs={12} key={e.device__device_name}>
                          <Grid
                            container
                            alignItems="center"
                            style={{
                              background: "#efeffd",
                              width: "100%",
                              marginBottom: "7px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              style={{
                                padding: "7px 12px",
                                borderLeft: `4px solid ${switchColors[index % switchColors.length]
                                  }`,
                              }}
                            >
                              <h6 className="mt-2">
                                {e.device__device_name}
                              </h6>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              textAlign="right"
                              className="grid-switch-unit"
                            >
                              <Switch
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  handleSelectDeviceId(e.device, index, event, e)
                                }
                                name={switchColors[index]}
                                disabled={isCheckboxDisabled(e.device)}
                                checked={deviceIdRe.includes(e.device)}
                                sx={{
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#828282",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#D9D9E5 !important",
                                  },
                                  "& .Mui-checked": {
                                    "& .MuiSwitch-thumb": {
                                      bgcolor:
                                        switchColors[
                                        index % switchColors.length
                                        ],
                                    },
                                    "& .MuiSwitch-track": {
                                      bgcolor: "#E2D9F2 !important",
                                    },
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    : []}
                </Box>
              </div>
              <Box className="consumer-text-disc mt-2 ">
                {/* <h6 className="Title">Description</h6>
                <h6 className="Title-desc">Units</h6> */}
                {/* <div class="horizontal-line"></div> */}
              </Box>

              <TextField
                variant="outlined"
                className="search-textfield"
                placeholder="Search Unit here"
                value={unitSearchQuery}
                onChange={handleUnitSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />

              <div className="device-unit">
                <Box className="Grid-text1 mt-2">
                  {[...new Set(
                    tagData
                      .filter(
                        (e) =>
                          e.tag_unit &&
                          e.tag_unit.toLowerCase().includes(unitSearchQuery.toLowerCase())
                      )
                      .map((e) => e.tag_unit)
                  )].map((tagUnit) => (
                    <Grid item xs={12} key={tagUnit}>
                      {tagUnit && (
                        <Grid
                          container
                          alignItems="center"
                          style={{
                            background: "#efeffd",
                            width: "100%",
                            marginBottom: "7px",
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            className="mt-2"
                            style={{ padding: "7px 12px" }}
                          >
                            <h6>{tagUnit}</h6>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            textAlign="right"
                            className="grid-switch-unit"
                          >
                            <Switch
                              checked={deviceUnit.includes(tagUnit)}
                              style={{ float: "right" }}
                              onClick={() => handleSelectTagUnit(tagUnit)}
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  bgcolor: "#828282",
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#D9D9E5 !important",
                                },
                                "& .Mui-checked": {
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#50C878",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#CEE6E1 !important",
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  ))}

                </Box>
              </div>

              <div>
                <button className="export-btn">EXPORT ENERGY DATA</button>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9} className="consumption-table-box">
            <Box className="source-table-box">
              <div style={{ paddingTop: "20px" }}>
                <div className="d-flex justify-content-end">
                  {["kwh", "kvah", "kvarh"].includes(deviceUnit[0] ? deviceUnit[0].toLowerCase() : "") &&
                    <Box className="source-boxes">
                      <h6 style={{ color: "#50C878" }}>Cum. : {percentageValue.length ? percentageValue.reduce((total, value) => total + value, 0).toFixed(2) : 0}%</h6>
                    </Box>}

                  {["st_kva"].includes(deviceUnit[0] ? deviceUnit[0].toLowerCase() : "") &&
                  <Box className="source-boxes" style={{ marginRight: "22px" }}>
                    <h6>
                        Threshold:<b> { thresholdValue}%</b>
                    </h6>
                  </Box>}
                  <p className="average-toggle">Average</p>
                  <Switch
                    checked={average}
                    onChange={(e) => setAverage(e.target.checked)}
                    className="average-toggle-switch"
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: "#828282",
                      },
                      "& .MuiSwitch-track": {
                        bgcolor: "#D9D9E5 !important",
                      },
                      "& .Mui-checked": {
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#50C878",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#CEE6E1 !important",
                        },
                      },
                    }}
                  />
                  {/* <FormControlLabel
                    value="start"
                    control={<Switch color="#45BF6D" />}
                    label="Average"
                    labelPlacement="start"
                  /> */}
                  <div className="d-flex ">
                    <h6 className="time-desc">Interval</h6>
                    <Box
                      sx={{ minWidth: 200 }}
                      style={{ float: "right", marginRight: "20px" }}
                    >
                      <select
                        className="form-input-class"
                        style={{ padding: "6px 8px", borderRadius: "4px" }}
                        id="selectedClass"
                        // defaultValue="all"
                        value={selectTimeRange}
                        onChange={(event) =>
                          setSelectTimeRange(event.target.value)
                        }
                        required
                      >
                        <option value="live" selected>
                          Live
                        </option>
                        <option value="1min">1 Min</option>
                        <option value="5min">5 Min</option>
                        <option value="15min">15 Min</option>
                      </select>
                    </Box>
                  </div>
                </div>
              </div>
              <div style={{ height:"421px", width: "100%", padding: "20px" }}>

                {/* <LineChart
                  selectedColors={deviceColor}
                  unitName={deviceUnit[0]}
                  chartCubeData={chartCubeData}
                  getDevice={getDevice}
                  average={average}
                  chartLineData={chartLineData}
                  combineChartData={combineChartData}
                // Pass the selected switch color to the LineChart component
                /> */}

                <HighchartsGraph
                  selectedColors={deviceColor}
                  unitName={deviceUnit[0]}
                  chartCubeData={chartCubeData}
                  getDevice={getDevice}
                  average={average}
                  chartLineData={chartLineData}
                  combineChartData={combineChartData}
                  selectTimeRange={selectTimeRange}
                />

              </div>
              <div>
                <Table className="source-table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "600",
                          width: "18%",
                          fontSize: "18px",
                          fontWeight: "800",
                        }}
                      >
                        {deviceUnit[0]}
                      </TableCell>

                      {getDevice.length
                        ? getDevice.map((e, index) => {                          
                          const findColor = deviceColor.find((f) => f.deviceId == e.device)
                          return (
                            <TableCell>
                              <div className="d-flex justify-content-between">
                                <div>{e.device__device_name}</div>
                                <div>
                                  {percentageValue[index] > 0 ? <NorthIcon style={{ color: findColor ? findColor.color : "" }} /> : percentageValue[index] == 0 ? '' : <SouthIcon style={{ color: findColor ? findColor.color : "" }} />}{" "}
                                  {/* <SouthIcon style={{ color: findColor ? findColor.color : ""}} />{" "} */}
                                  {percentageValue[index] == 0 ? '-' : percentageValue[index].toFixed(2)} %
                                </div>
                              </div>
                            </TableCell>
                          );
                        })
                        : []}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ fontWeight: "500" }}>
                        Real Time
                      </TableCell>
                      {/* {combineChartData.length ? combineChartData.map((e) =>{}) } */}
                      {getDevice && getDevice.length
                        ? getDevice.map((e, i) => {
                          const tagValue = combineRealTimeData[i]?.[0]?.tagValue;
                          return <TableCell>{tagValue}</TableCell>;
                        })
                        : []
                      }

                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "500" }}>
                        All Time Min.
                      </TableCell>
                      {getDevice && getDevice.length
                        ? getDevice.map((e, i) => {
                          const tagValue = combineMinMax[i]?.[0]?.minValue;
                          return <TableCell>{tagValue}</TableCell>;
                        })
                        : []
                      }
                      {/* {minMax.length
                        ? minMax.map((e) => {
                          return <TableCell>{e.minValue}</TableCell>;
                        })
                        : []} */}
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "500" }}>
                        All Time Max.
                      </TableCell>
                      {getDevice && getDevice.length
                        ? getDevice.map((e, i) => {
                          const tagValue = combineMinMax[i]?.[0]?.maxValue;
                          return <TableCell>{tagValue}</TableCell>;
                        })
                        : []
                      }
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default Consumption;
