import React from 'react';
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { images } from "../../../../config/images";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

const AlarmManagementUserList = () => {
    const navigate = useNavigate()
    const label = { inputProps: { "aria-label": "Checkbox demo" } };
    
    return (
        <>
            <Grid container style={{ paddingTop: "10px" }}>
                <Grid item xs={12}>
                    <Card className="manageDepartment-card">
                        <Grid
                            container
                            style={{
                                paddingLeft: "30px",
                                paddingRight: "30px",
                                paddingTop: "30px",
                                paddingBottom: "30px",
                            }}
                        >
                            <Grid item sm={12} md={12} lg={4} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>Select Department</b>
                                </Typography>
                                <div className="manageDepartment-wrap">
                                    <div className="manageDepartment-wrap">
                                        <div className="alarmMangement-UserList-fieldWrap">
                                            <img
                                                src={images.activeDeviceimg}
                                                alt=""
                                                className="activeDeviceimg"
                                            />
                                            <div className="manageDepartment-field">
                                                <Typography variant="body2" gutterBottom>
                                                    Instrument
                                                </Typography>
                                                {/* <div className="manageDepartment-fieldIcon">
                                                <img src={images.editImg} alt="" style={{ width: "25x", cursor: "pointer", }} />
                                                <img src={images.deleteImg} alt="" style={{
                                                    width: "25px",
                                                    cursor: "pointer",
                                                }} />
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="manageDepartment-wrap">
                                        <div className="alarmMangement-UserList-fieldWrap">
                                            <div className="manageDepartment-field">
                                                <Typography variant="body2" gutterBottom>
                                                    Engineering
                                                </Typography>
                                                {/* <div className="manageDepartment-fieldIcon">
                                                <img src={images.editImg} alt="" style={{ width: "25x", cursor: "pointer", }} />
                                                <img src={images.deleteImg} alt="" style={{
                                                    width: "25px",
                                                    cursor: "pointer",
                                                }} />
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="manageDepartment-wrap">
                                        <div className="alarmMangement-UserList-fieldWrap">
                                            <div className="manageDepartment-field">
                                                <Typography variant="body2" gutterBottom>
                                                    Maintenance
                                                </Typography>
                                                {/* <div className="manageDepartment-fieldIcon">
                                                <img src={images.editImg} alt="" style={{ width: "25x", cursor: "pointer", }} />
                                                <img src={images.deleteImg} alt="" style={{
                                                    width: "25px",
                                                    cursor: "pointer",
                                                }} />
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={12} md={12} lg={4} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>Roles</b>
                                </Typography>
                                <div className="alarmMangementCard-useListCard1">
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked={false}
                                                style={{
                                                    color: "#1C407B",
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Engineer
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked={false}
                                                style={{
                                                    color: "#1C407B",
                                                }} />

                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Technician
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked
                                                style={{
                                                    color: "#1C407B",
                                                }} />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Supervisor
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked={false}
                                                style={{
                                                    color: "#1C407B",
                                                }} />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Admin
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked={false}
                                                style={{
                                                    color: "#1C407B",
                                                }} />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Manager
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={12} md={12} lg={4} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>User Details</b>
                                </Typography>
                                <div className="alarmMangementCard-useListCard">
                                    <div className='alarmaManagementCard-UserDetailsWrap'>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                User Name
                                            </Typography>
                                        </div>
                                        <div className='alarmaManagementCard-alarmPriorityWrap'>
                                            <div className='px-1'>
                                                <Typography variant="body2" gutterBottom className='m-0'>
                                                    Alarm Priority
                                                </Typography>
                                            </div>
                                            <div className='alarmaManagementCard-checkboxWrap'>
                                                <div>
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#E31E24",
                                                            padding: '0px'
                                                        }} />

                                                </div>
                                                <Typography variant="body2" gutterBottom className='m-0'>
                                                    H
                                                </Typography>
                                            </div>
                                            <div className='alarmaManagementCard-checkboxWrap'>
                                                <div>
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#FFA500",
                                                            // color:'#FFFF00',
                                                            padding: '0px'
                                                        }} />
                                                </div>
                                                <Typography variant="body2" gutterBottom className='m-0'>
                                                    M
                                                </Typography>
                                            </div>
                                            <div className='alarmaManagementCard-checkboxWrap'>
                                                <div>
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked={false}
                                                        style={{
                                                            color: "#1C407B",
                                                            padding: '0px'
                                                        }} />
                                                </div>
                                                <Typography variant="body2" gutterBottom className='m-0'>
                                                    L
                                                </Typography>
                                            </div>
                                            <div className='alarmaManagementCard-checkboxWrap'>
                                                <div>
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked={false}
                                                        style={{
                                                            color: "#1C407B",
                                                            padding: '0px'
                                                        }} />
                                                </div>
                                                <Typography variant="body2" gutterBottom className='m-0'>
                                                    All
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked
                                                style={{
                                                    color: "#1C407B",
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Shreekar Yadav
                                            </Typography>
                                        </div>
                                    </div> */}
                                    {/* <div className="alarmManagementCard-UserDetail-ContentWrap"> */}
                                    <div>
                                        <div className='alarmManagementCard-UserDetail-innerWrap'>
                                            <div className="alarmManagementCard-UserDetail-ContentWrap">
                                                <div className='alarmManagementCard-UserDetail-Content-inner-Wrap'>
                                                    <div>
                                                        <Checkbox
                                                            {...label}
                                                            defaultChecked
                                                            style={{
                                                                color: "#1C407B",
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography variant="body2" gutterBottom className='m-0'>
                                                            Shreekar Yadav
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#E31E24",
                                                        }}
                                                    />
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#FFA500",
                                                        }}
                                                    />
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#FFFF00",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='alarmManagementCard-UserDetail-innerWrap'>
                                            <div className="alarmManagementCard-UserDetail-ContentWrap">
                                                <div className='alarmManagementCard-UserDetail-Content-inner-Wrap'>
                                                    <div>
                                                        <Checkbox
                                                            {...label}
                                                            defaultChecked
                                                            style={{
                                                                color: "#1C407B",
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography variant="body2" gutterBottom className='m-0'>
                                                            Shreekar Yadav
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#E31E24",
                                                        }}
                                                    />
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#FFA500",
                                                        }}
                                                    />
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#FFFF00",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='alarmManagementCard-UserDetail-innerWrap'>
                                            <div className="alarmManagementCard-UserDetail-ContentWrap">
                                                <div className='alarmManagementCard-UserDetail-Content-inner-Wrap'>
                                                    <div>
                                                        <Checkbox
                                                            {...label}
                                                            defaultChecked
                                                            style={{
                                                                color: "#1C407B",
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography variant="body2" gutterBottom className='m-0'>
                                                            Shreekar Yadav
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#E31E24",
                                                        }}
                                                    />
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#FFA500",
                                                        }}
                                                    />
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked={false}
                                                        style={{
                                                            color: "#1C407B",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='alarmManagementCard-UserDetail-innerWrap'>
                                            <div className="alarmManagementCard-UserDetail-ContentWrap">
                                                <div className='alarmManagementCard-UserDetail-Content-inner-Wrap'>
                                                    <div>
                                                        <Checkbox
                                                            {...label}
                                                            defaultChecked
                                                            style={{
                                                                color: "#1C407B",
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography variant="body2" gutterBottom className='m-0'>
                                                            Shreekar Yadav
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#E31E24",
                                                        }}
                                                    />
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked
                                                        style={{
                                                            color: "#FFA500",
                                                        }}
                                                    />
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked={false}
                                                        style={{
                                                            color: "#1C407B",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='alarmManagementCard-UserDetail-innerWrap'>
                                            <div className="alarmManagementCard-UserDetail-ContentWrap">
                                                <div className='alarmManagementCard-UserDetail-Content-inner-Wrap'>
                                                    <div>
                                                        <Checkbox
                                                            {...label}
                                                            defaultChecked={false}
                                                            style={{
                                                                color: "#1C407B",
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography variant="body2" gutterBottom className='m-0'>
                                                            Shreekar Yadav
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked={false}
                                                        style={{
                                                            color: "#1C407B",
                                                        }}
                                                    />
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked={false}
                                                        style={{
                                                            color: "#1C407B",
                                                        }}
                                                    />
                                                    <Checkbox
                                                        {...label}
                                                        defaultChecked={false}
                                                        style={{
                                                            color: "#1C407B",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </div>
                                <button className="Plantmanagemet submit" onClick={() => navigate('/alarmmanagement')}>Submit</button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid></Grid>
            </Grid>
        </>
    )
}
export default AlarmManagementUserList;