import React, { useEffect, useState } from "react";
import {
  getOverViewAnalyticsData,
  getOverviewReportGraphData,
} from "./services";

export const OverViewAnalyticsReportApiData = ({
  showTimeReport,
  setFisrtLineChartReportData,
  setSecondLineChartReportData,
  selectFirstUnit,
  selectAggReport,
  selectIntervalReport,
  deviceIdReportRedux,
  selectSecondUnit,
  startTimeFormatRep,
  endTimeFormatRep,
  formatStartDateRep,
  formatEndDateRep,
  setLoadingReport,
}) => {
  const handleAnalyticsChartLineData1 = async () => {
    if (
      (selectFirstUnit != "none",
      deviceIdReportRedux.length,
      formatStartDateRep,
      formatEndDateRep)
    ) {
    }
    try {
      setLoadingReport(true);
      const params = {
        device_id: deviceIdReportRedux,
        tag_unit: selectFirstUnit,
        start_date: formatStartDateRep,
        end_date: formatEndDateRep,
        // aggregate: Number(selectAggReport),
      };

      if (showTimeReport) {
        params["start_time"] = startTimeFormatRep;
        params["end_time"] = endTimeFormatRep;
      }

      if (["kwh", "kvah", "kvarh "].includes(selectFirstUnit.toLowerCase())) {
        params["aggregate"] = Number(selectAggReport);
      } else {
        params["interval"] = Number(selectIntervalReport);
      }

      const resp = await getOverviewReportGraphData(params);
      if (resp.data.success === true) {
        setLoadingReport(false);
        console.log("kp", resp.data);
        setFisrtLineChartReportData(resp.data.payload);
        // setAnalyticsData(resp.data.payload);
      }
    } catch (error) {
      setLoadingReport(false);
      console.log("handleAnalyticsData", error);
    }
  };

  const handleAnalyticsChartLineData2 = async () => {
    if (
      (selectSecondUnit != "none",
      deviceIdReportRedux.length,
      formatStartDateRep,
      formatEndDateRep)
    ) {
    }
    try {
      setLoadingReport(true);
      const params = {
        device_id: deviceIdReportRedux,
        tag_unit: selectSecondUnit,
        start_date: formatStartDateRep,
        end_date: formatEndDateRep,
        // aggregate: Number(selectAggReport),
      };

      if (showTimeReport) {
        params["start_time"] = startTimeFormatRep;
        params["end_time"] = endTimeFormatRep;
      }

      if (["kwh", "kvah", "kvah"].includes(selectSecondUnit.toLowerCase())) {
        params["aggregate"] = Number(selectAggReport);
      } else {
        params["interval"] = Number(selectIntervalReport);
      }
      const resp = await getOverviewReportGraphData(params);
      if (resp.data.success === true) {
        setLoadingReport(false);
        console.log("kp", resp.data);
        setSecondLineChartReportData(resp.data.payload);
        // setAnalyticsData(resp.data.payload);
      }
    } catch (error) {
      setLoadingReport(false);
      console.log("handleAnalyticsData", error);
    }
  };

  useEffect(() => {
    if (
      selectFirstUnit != "none" &&
      deviceIdReportRedux.length &&
      formatStartDateRep &&
      formatEndDateRep
    ) {
      handleAnalyticsChartLineData1();
    }
  }, [
    selectFirstUnit,
    deviceIdReportRedux,
    selectAggReport,
    startTimeFormatRep,
    endTimeFormatRep,
    formatStartDateRep,
    formatEndDateRep,
  ]);

  useEffect(() => {
    if (
      selectSecondUnit != "none" &&
      deviceIdReportRedux.length &&
      formatStartDateRep &&
      formatEndDateRep
    ) {
      handleAnalyticsChartLineData2();
    }
  }, [
    selectSecondUnit,
    deviceIdReportRedux,
    selectAggReport,
    startTimeFormatRep,
    endTimeFormatRep,
    formatStartDateRep,
    formatEndDateRep,
  ]);

  return <div>{false ? "" : ""}</div>;
};
