import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate, useParams } from "react-router-dom";
import { images } from '../../../config/images';
import { getUser, getUserDepartment, adduserDepartment, updateUserDepartment, deleteUserDepartment, searchUserDataList, getCompanyUserManage } from '../service';
import './UserManagement.css'
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CompanyByUserManagement = () => {

    const navigate = useNavigate();
    const [openUpdateUserManagement, setUpdateUserManagement] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [userData, setUserData] = useState([]);
    const [addUserModal, setAddUserModal] = useState(false);
    const [department, setDepartment] = useState([]);
    const [selectDepartment, setSelectDepartment] = useState("none");
    const [selectRole, setSelectRole] = useState("none")
    const [addUserDepartment, setaddUserDepartment] = useState({ first_name: "", last_name: "", email: "" });
    const [updateUser, setupdateUser] = useState({ department: "", role_name: "", first_name: "", last_name: "", email: "" });
    const [userRowId, setUserRowId] = useState("")
    const [selectedRows, setSelectedRows] = useState([]);


    const param = useParams()

    const [searchUserDataList, setSearchUserList] = useState({
        first_name: '', last_name: '', department__department_name: '', user_role__name: '', email: ''
    })

    const { companyId } = useSelector(state => state.userState)

    const notify = (message) =>
        toast.error(message, {
            theme: "colored",
            position: toast.POSITION.TOP_RIGHT,
            toastClassName: "custom-toast",
        });

    //Add user
    const handleAddUser = () => {
        setAddUserModal(!addUserModal);
    }

    //Update user
    const handleUserUpdate = (e) => {
        // setupdateUser({department: , user_role: "none", first_name: "", last_name: "", email: ""})
        setupdateUser({ department_name: e.department_name, role_name: e.role_name, first_name: e.first_name, last_name: e.last_name, email: e.email })
        setUpdateUserManagement(!openUpdateUserManagement);
        setOpenDeleteModal(false)
        setUserRowId(e.id);
    }

    //Delete user modal
    const handleConfirmationModal = (id) => {
        setUserRowId(id)
        setOpenDeleteModal(!openDeleteModal);
        setUpdateUserManagement(false)
    }

    // get userList
    const getUserList = async () => {
        const params = {
            company: param.id
        }
        const response = await getCompanyUserManage(params);
        if (response.status == 200) {
            setUserData(response.data.payload);
        }
    }

    //get user department list
    const getUserDepartmentList = async () => {
        const response = await getUserDepartment();
        setDepartment(response.data.payload);
    }

    //Add user Department
    const handleChangeuserManagement = (e) => {
        setaddUserDepartment({ ...addUserDepartment, [e.target.name]: e.target.value });
    };

    // handleUpdateUser
    const handelChangeUpdateUser = (e) => {
        setupdateUser({ ...updateUser, [e.target.name]: e.target.value })
    }

    const handleSearchUserData = (e) => {
        setSearchUserList({ ...searchUserDataList, [e.target.name]: e.target.value })
    }

    //get Device
    const searchUserList = async () => {
        const response = await searchUserDataList(searchUserDataList);
        if (response.status == 200) {
            setSearchUserList(response.data.payload)
        }
    }


    const submitUserDepartment = async () => {
        if (selectDepartment === "none") {
          notify("Please Select Department");
          return;
        }
      
        if (selectRole === "none") {    
          notify("Please Select Role");
          return;   
        }
      
        if (addUserDepartment.first_name === "") {
          notify("Please Enter First Name");
          return;
        }
      
        if (addUserDepartment.last_name === "") {
          notify("Please Enter Last Name");
          return;
        }
      
        if (addUserDepartment.email === "") {
          notify("Please Enter Email");
          return;
        }
      
        const params = {
          company_name: param.id,
          department: Number(selectDepartment),
          role: Number(selectRole),
          first_name: addUserDepartment.first_name,
          last_name: addUserDepartment.last_name,
          email: addUserDepartment.email,
        };
      
        try {
          const response = await adduserDepartment(params);
          if (response.status === 201) {
            getUserList();
            setAddUserModal(!addUserModal);
          }
        } catch (error) {
          console.log("err", error);
          notify(error.response.data.email[0]);
        }
      };
      
    useEffect(() => {
        getUserDepartmentList();
    }, [])

    useEffect(() => {
        getUserList();
    }, [searchUserDataList]);


    const handelUpdateUser = async () => {
        
          if (updateUser.first_name === "") {
            notify("Please Enter First Name");
            return;
          }
        
          if (updateUser.last_name === "") {
            notify("Please Enter Last Name");
            return;
          }
        
          if (updateUser.email === "") {
            notify("Please Enter Email");
            return;
          }
        const param = {
            // "department": 1,
            // "user_role": 1,
            // "first_name": "test",
            // "last_name": "test",
            // "email": "test13@gmail.com"
            id: userRowId,
            department_name: updateUser.department_name,
            role_name: updateUser.role_name,
            first_name: updateUser.first_name,
            last_name: updateUser.last_name,
            email: updateUser.email
        }
        try {
            const resp = await updateUserDepartment(userRowId, param);
            if (resp.status == 200) {
                getUserList();
                setUpdateUserManagement(false);
            }
        } catch (error) {
            notify(error.response.data.email[0]);
        }
    }

    const handelDeleteUser = async () => {
        try{
            const resp = await deleteUserDepartment(selectedRows);
        if (resp.status == 200 || resp.status == 201) {
            setOpenDeleteModal(!openDeleteModal);
            getUserList();
        }
        }
        catch(error){
            toast.error("please select user id", {
                theme: "colored",
                position: toast.POSITION.TOP_RIGHT,
                toastClassName: "custom-toast",
              });
        }
      
    }


    const handleSelectRow = (rowId) => {
        const selectedIndex = selectedRows.indexOf(rowId);
        let newSelectedRows = [];

        if (selectedIndex === -1) {
            newSelectedRows = [...selectedRows, rowId];
        } else {
            newSelectedRows = selectedRows.filter((id) => id !== rowId);
        }

        setSelectedRows(newSelectedRows);
    };

    return (
        <>
            <div>
                <ToastContainer autoClose={4000}></ToastContainer>
            </div>
            <div className='containerWrap'>
                <Container maxWidth="xxl">
                    <div className="deviceManagementHeader-wrap configure">
                        <div className='deviceManagementTitle-wrap'>
                            <KeyboardBackspaceIcon onClick={() => navigate('/superadmindashboard')} className='backArrow' /> <Typography variant="h5" className="deviceManagementTitle" gutterBottom style={{ margin: '0' }} onClick={() => navigate('/superadmindashboard')} >
                            {userData.length > 0 ? `${userData[0].company_name} - User Management` : 'User Management'}
                            </Typography>
                        </div>
                        <div className='addDevice-buttonWrap'>
                            <button className='manageDepartment-button' onClick={() => navigate('/managedepartment')}>Access Management</button>
                            {/* <img src={images.addImage} alt="" className='cloudActiveImg' onClick={handleUserUpdate} /> */}
                            <img src={images.addImage} alt="" className='cloudActiveImg' onClick={handleAddUser} />
                            <img src={images.deleteIcon} alt="" className='cloudActiveImg' onClick={handleConfirmationModal} />
                            <img src={images.excelLogo} alt="" className='cloudActiveImg' />
                        </div>
                    </div>
                    {/* =============== User Add Modal Start =============== */}
                    {
                        addUserModal && <Card className='inviteUserModal'>
                            <Box>
                                <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 34 }}>
                                    <Typography gutterBottom style={{ display: 'none' }}>
                                        ..
                                    </Typography>
                                    <img src={images.closeIcon} alt="" style={{ width: '30px', cursor: 'pointer' }} onClick={handleAddUser} />
                                </Stack>
                                <Typography gutterBottom style={{ color: '#1C407B', fontWeight: '600', fontSize: '19px', textAlign: 'center' }}>
                                    User Management
                                </Typography>
                            </Box>
                            <Box>
                                <div className='useInviteWrap'>
                                    <label for="exampleInputEmail1" class="form-label userInviteLabel"><b>Department</b> <span style={{ color: '#E31E24' }}>*</span></label>
                                    <select class="form-select form-select-lg updateUserDropdown"
                                        value={selectDepartment} onChange={(e) => setSelectDepartment(e.target.value)} aria-label=".form-select-lg example">
                                        <option value="none" selected disabled hidden>Select Department</option>
                                        {/* <option value="1">Engineer</option>
                                        <option value="2">Admin</option> */}
                                        {
                                            department.length ? department.map((e) => {
                                                return (
                                                    <option value={e.id}>{e.department_name}</option>
                                                )
                                            }) : []
                                        }
                                    </select>
                                </div>
                                <div className='useInviteWrap'>
                                    <label for="exampleInputEmail1" class="form-label userInviteLabel"><b>Role</b><span style={{ color: '#E31E24' }}>*</span></label>
                                    <select class="form-select form-select-lg updateUserDropdown"
                                        value={selectRole} onChange={(e) => setSelectRole(e.target.value)}
                                        aria-label=".form-select-lg example">
                                        <option value="none" selected disabled hidden>Select Role</option>
                                        {/* <option value="1">Engineer</option>
                                        <option value="2">Admin</option> */}
                                        {
                                            department.length ? department.map((e) => {
                                                if (selectDepartment == e.id) {
                                                    return e.company_roles.map((e1) => {
                                                        return (
                                                            <option value={e1.id}>{e1.role_name}</option>
                                                        )
                                                    })
                                                }

                                            }) : []
                                        }
                                    </select>
                                </div>
                                <div className='useInviteWrap'>
                                    <label for="exampleInputEmail1" class="form-label userInviteLabel"><b>First Name</b><span style={{ color: '#E31E24' }}>*</span></label>
                                    <input type="text" name='first_name' class="form-control userInviteLabelplaceHolder" id="exampleInputEmail1" placeholder='Enter First Name' onChange={handleChangeuserManagement} />
                                </div>
                                <div className='useInviteWrap'>
                                    <label for="exampleInputEmail1" class="form-label userInviteLabel"><b>Last Name</b><span style={{ color: '#E31E24' }}>*</span></label>
                                    <input type="text" name='last_name' class="form-control userInviteLabelplaceHolder" id="exampleInputEmail1" placeholder='Enter Last Name' onChange={handleChangeuserManagement} />
                                </div>
                                <div className='useInviteWrap'>
                                    <label for="exampleInputEmail1" class="form-label userInviteLabel"><b>Email Id</b><span style={{ color: '#E31E24' }}>*</span></label>
                                    <input type="email" name='email' class="form-control userInviteLabelplaceHolder" id="exampleInputEmail1" placeholder='Enter Email Id' onChange={handleChangeuserManagement} />
                                </div>
                            </Box>
                            <Box className=''>
                                <button className='ClientManagement InviteUser-Button' onClick={submitUserDepartment}>Add User</button>
                            </Box>
                        </Card>
                    }
                    {/* =============== User Add Modal End =============== */}
                    {/* =============== User Upadte Modal Start =============== */}
                    {openUpdateUserManagement && <Card className='inviteUserModal'>
                        <Box>
                            <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 34 }}>
                                <Typography gutterBottom style={{ display: 'none' }}>
                                    ..
                                </Typography>
                                <img src={images.closeIcon} alt="" style={{ width: '30px', cursor: 'pointer' }} onClick={handleUserUpdate} />
                            </Stack>
                            <Typography gutterBottom style={{ color: '#1C407B', fontWeight: '600', fontSize: '19px', textAlign: 'center' }}>
                                User Management
                            </Typography>
                        </Box>
                        <Box>
                            <div className='useInviteWrap'>
                                <label for="exampleInputEmail1" class="form-label userInviteLabel"><b>Department</b> <span style={{ color: '#E31E24' }}>*</span></label>
                                <select class="form-select form-select-lg updateUserDropdown"
                                    value={updateUser.department} name='department' onChange={(e) => handelChangeUpdateUser(e)} aria-label=".form-select-lg example">
                                    <option value="none" selected disabled hidden>Select Department</option>
                                    {/* <option value="1">Engineer</option>
                                        <option value="2">Admin</option> */}
                                    {
                                        department.length ? department.map((e) => {
                                            return (
                                                <option value={e.id}>{e.department_name}</option>
                                            )
                                        }) : []
                                    }
                                </select>
                            </div>
                            <div className='useInviteWrap'>
                                <label for="exampleInputEmail1" class="form-label userInviteLabel"><b>Role</b><span style={{ color: '#E31E24' }}>*</span></label>
                                <select class="form-select form-select-lg updateUserDropdown"
                                    value={updateUser.role_name} name='role_name' onChange={(e) => handelChangeUpdateUser(e)}
                                    aria-label=".form-select-lg example">
                                    <option value="none" selected disabled hidden>Select Role</option>
                                    {/* <option value="1">Engineer</option>
                                        <option value="2">Admin</option> */}
                                    {
                                        department.length ? department.map((e) => {
                                            if (updateUser.department == e.id) {
                                                return e.company_roles.map((e1) => {
                                                    return (
                                                        <option value={e1.id}>{e1.role_name}</option>
                                                    )
                                                })
                                            }

                                        }) : []
                                    }
                                </select>
                            </div>
                            <div className='useInviteWrap'>
                                <label for="exampleInputEmail1" class="form-label userInviteLabel"><b>First Name</b><span style={{ color: '#E31E24' }}>*</span></label>
                                <input type="text" name='first_name' class="form-control userInviteLabelplaceHolder" id="exampleInputEmail1" placeholder='Enter First Name' value={updateUser.first_name} onChange={(e) => handelChangeUpdateUser(e)} />
                            </div>
                            <div className='useInviteWrap'>
                                <label for="exampleInputEmail1" class="form-label userInviteLabel"><b>Last Name</b><span style={{ color: '#E31E24' }}>*</span></label>
                                <input type="text" name='last_name' class="form-control userInviteLabelplaceHolder" id="exampleInputEmail1" placeholder='Enter Last Name' value={updateUser.last_name} onChange={(e) => handelChangeUpdateUser(e)} />
                            </div>
                            <div className='useInviteWrap'>
                                <label for="exampleInputEmail1" class="form-label userInviteLabel"><b>Email Id</b><span style={{ color: '#E31E24' }}>*</span></label>
                                <input type="email" name='email' class="form-control userInviteLabelplaceHolder" id="exampleInputEmail1" placeholder='Enter Email Id' value={updateUser.email} onChange={(e) => handelChangeUpdateUser(e)} />
                            </div>
                        </Box>
                        <Box className=''>
                            {/* <button className='ClientManagement InviteUser-Button userManagement' onClick={() => handelUpdateUser()} >Update</button> */}
                            <button className='ClientManagement InviteUser-Button userManagement' onClick={handelUpdateUser} >Update</button>
                        </Box>
                    </Card>}
                    {/* =============== User Upadte Modal End =============== */}
                    {/* =============== Delete user Modal Start =============== */}
                    {openDeleteModal && <Card className='deleteConfirmation-Modal'>
                        <Typography variant="h6" gutterBottom className='deleteConfirmation-title'>
                            Are You Sure<br></br> You Want To Delete?
                        </Typography>
                        <Box className='deleteConfirmation-btnWrap'>
                            <button className='deleteConfirmation-Yesbtn' onClick={handelDeleteUser}>Yes</button>
                            <button className='deleteConfirmation-Nobtn' onClick={handleConfirmationModal}>No</button>
                        </Box>
                    </Card>}
                    {/* =============== Delete user Modal End =============== */}
                    {/* =============== User management table Start =============== */}
                    <div className="userManagementTable">
                        <Table aria-label="" className={`deviceManagementTable ${addUserModal ? 'disabledRow' : ''}`}>
                            <TableHead>
                                <TableRow className="deviceManagementTable-title">
                                    <TableCell colSpan={2}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} />
                                            </FormGroup> First Name
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        Last Name
                                    </TableCell>
                                    <TableCell>
                                        Department
                                    </TableCell>
                                    <TableCell>
                                        Role
                                    </TableCell>
                                    <TableCell>
                                        Email Id
                                    </TableCell>
                                    <TableCell>
                                        Active
                                    </TableCell>
                                    <TableCell>
                                        Action
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input type="search" placeholder="First Name" name="first_name" value={searchUserDataList.first_name} onChange={handleSearchUserData} />
                                    </TableCell>
                                    <TableCell className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input type="search" placeholder="Last Name" name="last_name" value={searchUserDataList.last_name} onChange={handleSearchUserData} />
                                    </TableCell>
                                    <TableCell className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input type="search" placeholder="Department" name="department__department_name" value={searchUserDataList.department__department_name} onChange={handleSearchUserData} />
                                    </TableCell>
                                    <TableCell className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input type="search" placeholder="Role" name="user_role__name" value={searchUserDataList.user_role__name} onChange={handleSearchUserData} />
                                    </TableCell>
                                    <TableCell className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input type="search" placeholder="Email Id" name="email" value={userData.email} onChange={handleSearchUserData} />
                                    </TableCell>
                                    <TableCell className="search-table-row">
                                    </TableCell>
                                    <TableCell className="search-table-row">
                                    </TableCell>
                                </TableRow>
                                {/* <TableRow className='deviceManagementTable-data'>
                                    <TableCell >
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox defaultChecked />} />
                                        </FormGroup>
                                    </TableCell>
                                    <TableCell>
                                        Parth
                                    </TableCell>
                                    <TableCell>
                                        Sarthi
                                    </TableCell>
                                    <TableCell>
                                        Instrument
                                    </TableCell>
                                    <TableCell>
                                        Engineer
                                    </TableCell>
                                    <TableCell>
                                        abc@gmail.com
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" className='deviceManagementTable-icon'>
                                            <Switch defaultChecked size="small" color='success' />
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" className='deviceManagementTable-icon'>
                                            <img src={images.editImg} alt="" className='cloudActiveImg' onClick={handleUserUpdate}/>
                                            <img src={images.deleteImg} alt="" className='cloudActiveImg'  onClick={handleConfirmationModal}/>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                                <TableRow className='deviceManagementTable-data'>
                                    <TableCell >
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox />} />
                                        </FormGroup>
                                    </TableCell>
                                    <TableCell>
                                        Shreekar
                                    </TableCell>
                                    <TableCell>
                                        Yadav
                                    </TableCell>
                                    <TableCell>
                                        Maintanance
                                    </TableCell>
                                    <TableCell>
                                        Admin
                                    </TableCell>
                                    <TableCell>
                                        abc@gmail.com
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" className='deviceManagementTable-icon'>
                                            <Switch defaultChecked size="small" color='success' />
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" className='deviceManagementTable-icon'>
                                            <img src={images.editImg} alt="" className='cloudActiveImg' onClick={handleUserUpdate} />
                                            <img src={images.deleteImg} alt="" className='cloudActiveImg' onClick={handleConfirmationModal} />
                                        </Stack>
                                    </TableCell>
                                </TableRow> */}
                                {userData.map((e) =>
                                    <TableRow className='deviceManagementTable-data'>
                                        <TableCell >
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox  checked={selectedRows.includes(e.id)}
                                                        onChange={() => handleSelectRow(e.id)}/>} />
                                            </FormGroup>
                                        </TableCell>
                                        <TableCell>
                                            {e.first_name}
                                        </TableCell>
                                        <TableCell>
                                            {e.last_name}
                                        </TableCell>
                                        <TableCell>
                                            {e.department}
                                        </TableCell>
                                        <TableCell>
                                            {e.user_role}
                                        </TableCell>
                                        <TableCell>
                                            {e.email}
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" className='deviceManagementTable-icon'>
                                                <Switch checked={e.is_login} size="small" color='success' />
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" className='deviceManagementTable-icon'>
                                                <img src={images.editImg} alt="" className='cloudActiveImg' onClick={() => handleUserUpdate(e)} />
                                                <img src={images.deleteImg} alt="" className='cloudActiveImg' onClick={() => handleConfirmationModal(e.id)} />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableHead>
                            <TableBody>
                            </TableBody>
                        </Table>
                    </div>
                    {/* =============== User management table Ends =============== */}
                </Container >
            </div>

        </>
    )
}
export default CompanyByUserManagement;