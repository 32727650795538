import * as React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
// import FooterText from '../../components/FooterText/FooterText';
import Typography from "@mui/material/Typography";
import { images } from "../../../../config/images";
import { useNavigate } from "react-router-dom";


const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const VmsPlantDashboard = () => {
    const navigate = useNavigate()

const handleProcessDashboard =()=>{
    navigate("/VmsMachine")
}
  return (  
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <Card style={{ marginTop: "15px" }} className="dasboardCard">
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{
                    backgroundColor: "#DCE1EA",
                    width: "2px",
                    height: "49px",
                    marginTop: "20px",
                  }}
                />
              }
              // spacing={{ sm: 14, md: 10, lg: 28 }}
              className="dashboardItem"
            >
              <Item className="dashboardCard-item">
                <img
                  src={images.energyMeterImg}
                  alt=""
                  className="dashboardCard-img"
                />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                  MACHINES
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                  108
                  </Typography>
                </Stack>
              </Item>
              <Item className="dashboardCard-item">
                <img src={images.clientManagementPlantS} alt="" className="dashboardCard-img" />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                  ACTIVE
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                  08
                  </Typography>
                </Stack>
              </Item>
              <Item className="dashboardCard-item">
                <img
                  src={images.activeImg}
                  alt=""
                  className="dashboardCard-img"
                />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                  INACTIVE
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                  06
                  </Typography>
                </Stack>
              </Item>
              <Item className="dashboardCard-item">
                <img
                  src={images.serviceCall}
                  alt=""
                  className="dashboardCard-img"
                />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                  WORK ORDER
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                  01
                  </Typography>
                </Stack>
              </Item>
            </Stack>
          </Card>
          <Grid container spacing={4} mt={"0px"}>
      
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Card
                    className="dashboardCard-plant"
                    onClick={() => handleProcessDashboard()}
                  >
                    <Stack direction="column">
                      <Typography
                        gutterBottom
                        className="dashboardCard-plantTitle"
                      >
                      Plant - 1
                      </Typography>
                      <Typography
                        gutterBottom
                        className="dashboardCard-plantsubTitle"
                      >
                   Lorem Ipsum is simply dummy
                      </Typography>
                      <Box className="dashboardCard-plantContent">
                        <Typography
                          gutterBottom
                          className="dashboardCard-plantText"
                        >
                          Machine:
                        </Typography>
                        <Stack direction="column">
                          
                          <Typography
                            variant="body1"
                            className="dashboardCard-plantNumber1"
                          >
                          03
                          </Typography>
                          <Typography
                            variant="caption"
                            className="dashboardCard-plantSmalltext"
                          >
                            Active
                          </Typography>
                        </Stack>
                        <Box
                          style={{
                            borderRight: "2px solid #C9CFE7",
                            height: "30px",
                          }}
                          paddingRight={5}
                        >
                          <Stack
                            direction="column"
                            style={{ marginTop: "-7px" }}
                          >
                            <Typography
                              variant="body1"
                              className="dashboardCard-plantNumber2"
                            >
                           02
                            </Typography>
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Inactive
                            </Typography>
                          </Stack>
                        </Box>
                      
        
                        <Stack direction="column" spacing={1}>
                          <img
                            src={images.notificationactiveImg}
                            alt=""
                            className="dashboardCard-plantImg"
                          />
                          <Typography
                            variant="caption"
                            className="dashboardCard-plantSmalltext"
                          >
                            Notification
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
          
          </Grid>
        
        </Container>
      </div>
    </>
  );
};
export default VmsPlantDashboard;
