import React, { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "./Slider";
import {
  Box,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "../Source/Source.css";
import CardSlider from "./Slider";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import ConfigrationCardSlider from "./ConfigrationSlider";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import { getDeviceConfigData, getDeviceSourceAPI } from "./services";

const ConfigrationSource = () => {
  const [value, setValue] = React.useState("1");
  const [deviceId, setDeviceId] = useState("");
  const [deviceData, setDeviceData] = useState([]);
  const [deviceConfig, setDeviceConfig] = useState([]);
  const [device,setDevice] = useState("")


  const navigate = useNavigate();
  const handleSearchData = (event, newValue) => {};
  const param = useParams();
  const location = useLocation();
  const sourceData =
    location.pathname.split("/")[2].charAt(0).toUpperCase() +
    location.pathname.split("/")[2].slice(1);

  useEffect(() => {
    handleDevice();
  }, []);

  useEffect(()=>{
    handleGetConfigDevice()
  },[])

  const handleDevice = async () => {
    const params = {
      device_type: sourceData,
      plant_id: param.id,
    };
    const resp = await getDeviceSourceAPI(params);

    if (resp.data.success === true) {
      setDeviceData(resp.data.payload.device);
      // setDeviceId(resp.data.payload[0].device);
    }
  };

  const handleGetConfigDevice = async () => {
    const params = {
      plant_id: param.id,
      company_id: param.companyId,
      device_type: sourceData,
    };
    try {
      const resp = await getDeviceConfigData(params);
      if (resp.data.success === true) {
        setDeviceConfig(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  return (
    <>
      <div className="page-wraper">
        <div className="page-header">
          <div
            onClick={() =>
              navigate(`/source/company/${param.companyId}/plant/${param.id}`)
            }
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Source</span>
          </div>
        </div>

        <div className="pt-3">
          <ConfigrationCardSlider />
        </div>
        <div className="pt-2 consumption-grid-card">
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Box className="source-box">
              <Box className="consumer-text-disc">
                <h6 className="Title">Title</h6>
                <h6 className="Title-desc">Energy Meter Charts</h6>
              </Box>

              <Box className="consumer-text-disc mt-2">
                <h6 className="Title">Description</h6>
                <h6 className="Title-desc">Energy Meters</h6>
              </Box>

              <TextField
                variant="outlined"
                className="search-textfield"
                placeholder="Search here"
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />

              <div className="furnace">
                <Box className="Grid-text mt-2 d-flex justify-content-between">
                  {deviceData.length ? deviceData.map((e) => (
                    <>
                      {e.is_configured ? (
                        <>
                          <h6 className="mt-2">{e.device__device_name}</h6>
                          <Switch
                            onChange={handleSearchData}
                            defaultChecked
                            sx={{
                              "& .MuiSwitch-thumb": {
                                bgcolor: "#9B64B7",
                              },
                              "& .MuiSwitch-track": {
                                bgcolor: "#E2D9F2 !important",
                              },
                              "& .Mui-checked": {
                                "& .MuiSwitch-thumb": {
                                  bgcolor: "#9B64B7",
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#E2D9F2 !important",
                                },
                              },
                            }}
                          />
                        </>
                      ) : (
                        []
                      )}
                    </>
                  )) : []}
                </Box>
              </div>
              <Box className="consumer-text-disc mt-2">
                <h6 className="Title">Description</h6>
                <h6 className="Title-desc">Units</h6>
              </Box>

              <TextField
                variant="outlined"
                className="search-textfield"
                placeholder="Search here"
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />

              <div className="device-units-name">
                <Box className="Grid-text1 mt-2 d-flex justify-content-between">
                {deviceData.map((e) => {
                  return(
                   
                 <>
                 {e.tag_unit ?
                 <>
                  <h6 className=" mt-2">{e.tag_unit}</h6>
                  <Switch
                     onChange={handleSearchData}
                     defaultChecked
                     sx={{
                       "& .MuiSwitch-thumb": {
                         bgcolor: "#00AB66",
                       },
                       "& .MuiSwitch-track": {
                         bgcolor: "#B2E6D1 !important",
                       },
                       "& .Mui-checked": {
                         "& .MuiSwitch-thumb": {
                           bgcolor: "#00AB66",
                         },
                         "& .MuiSwitch-track": {
                           bgcolor: "#B2E6D1 !important",
                         },
                       },
                     }}
                   /> 
                   </>
                 
                 : ""}
                     
                    
                      </>
                   
                  )})}
            
                
                </Box>
             
              </div>
        

              <div className="mt-3">
                <button className="export-btn">EXPORT ENERGY DATA</button>
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={9}
            className="consumption-table-box"
          >
            <Box className="source-table-box">
              <div style={{ height: "431px", paddingTop: "20px" }}>
                <div className="d-flex justify-content-end">
                  <Box className="source-boxes">
                    <h6 style={{ color: "#50C878" }}>Cum. : 100%</h6>
                  </Box>

                  <Box className="source-boxes" style={{ marginRight: "22px" }}>
                    <h6>Threshold: 70%</h6>
                  </Box>
                  <p className="average-toggle">Average</p>
                  <Switch
                    onChange={handleSearchData}
                    className="average-toggle-switch"
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: "#828282",
                      },
                      "& .MuiSwitch-track": {
                        bgcolor: "#D9D9E5 !important",
                      },
                      "& .Mui-checked": {
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#50C878",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#CEE6E1 !important",
                        },
                      },
                    }}
                  />
                  {/* <FormControlLabel
                    value="start"
                    control={<Switch color="#45BF6D" />}
                    label="Average"
                    labelPlacement="start"
                  /> */}
                  <div className="d-flex ">
                    <h6 className="time-desc">Interval</h6>
                    <Box
                      sx={{ minWidth: 200 }}
                      style={{ float: "right", marginRight: "20px" }}
                    >
                      <select
                        className="form-input-class"
                        style={{ padding: "6px 8px", borderRadius: "4px" }}
                        id="selectedClass"
                        // defaultValue="all"
                        // value={selectYear}
                        // onChange={(event) => setSelectYear(event.target.value)}
                        required
                      >
                        <option value="none" selected disabled hidden>
                          15 Min
                        </option>
                        <option value="1">15 Min</option>
                        <option value="2">30 Min</option>
                        <option value="3">Hourly</option>
                        <option value="4">Daily</option>
                        <option value="5">Weekly</option>
                      </select>
                    </Box>
                  </div>
                </div>
              </div>
              <div>
                <Table className="source-table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "600",
                          width: "18%",
                          fontSize: "18px",
                          fontWeight: "800",
                        }}
                      >
                        kW
                      </TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-between">
                          <div>Grid - 1</div>
                          <div>
                            <NorthIcon style={{ color: "#9A64B2" }} /> 12%
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-between">
                          <div>Grid - 2</div>
                          <div>
                            <SouthIcon style={{ color: "#EF75A0" }} /> 12%
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-between">
                          <div>Grid - 3</div>
                          <div>
                            <NorthIcon style={{ color: "#9A64B2" }} /> 12%
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-between">
                          <div>Grid - 4</div>
                          <div>
                            <SouthIcon style={{ color: "#EF75A0" }} /> 12%
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ fontWeight: "500" }}>
                        Real Time
                      </TableCell>
                      <TableCell>
                        <b>5632</b>
                      </TableCell>
                      <TableCell>5632</TableCell>
                      <TableCell>5632</TableCell>
                      <TableCell>5632</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "500" }}>
                        All Time Min.
                      </TableCell>
                      <TableCell>5632</TableCell>
                      <TableCell>5632</TableCell>
                      <TableCell>5632</TableCell>
                      <TableCell>5632</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "500" }}>
                        All Time Max.
                      </TableCell>
                      <TableCell>5632</TableCell>
                      <TableCell>5632</TableCell>
                      <TableCell>5632</TableCell>
                      <TableCell>5632</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default ConfigrationSource;
