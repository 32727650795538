import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const HikemPopUP = () => {
    const navigate = useNavigate()
  return (
    <>
      <div>
        <Box className="popup-box">
        <div className="pt-5 text-center">
            <h3>Please Select</h3>
        </div>
        <div className="text-center pt-4">
            <button className="hikemm-btn" style={{marginRight:"15px"}} onClick={()=> navigate("/")}>Hikemm</button>
            <button className="vms-btn" onClick={()=>navigate("/vms")}>VMS</button>
            </div>
        </Box>
      </div>
    </>
  );
};
export default HikemPopUP;
