// export const urls ={
//     apiBaseUrl: "https://hikemm.hikar.cloud"
// }

export const urls = {
    apiBaseUrl: "https://hikemm.hikartech.in"
}

// export const urls = {
//     apiBaseUrl: "http://192.168.29.157:7000/"
// }