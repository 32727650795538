// import cubejs from "@cubejs-client/core";
// import { CubeProvider } from "@cubejs-client/react";
// import WebSocketTransport from "@cubejs-client/ws-transport";
// import React from "react";

// let apiTokenPromise;

// export const cubejsApi = cubejs(
//   () => {
//     if (!apiTokenPromise) {
//       apiTokenPromise = fetch('https://cube.hikemm.hikar.cloud/auth/cubejs-token')
//         .then((res) => res.json())
//         .then((r) => r.token);
//     }
//     return apiTokenPromise;
//   },
//   {
//     apiUrl: 'https://cube.hikemm.hikar.cloud/cubejs-api/v1/',
//   }
// );
// // branch

// function CubeJsApiWrapper(props) {
//     return (
//         <CubeProvider cubejsApi={cubejsApi}>
//           {props.children}
//         </CubeProvider>
//        )
// }

// export default CubeJsApiWrapper;


import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import WebSocketTransport from "@cubejs-client/ws-transport";
import React from "react";

let apiTokenPromise;

export const cubejsApi = cubejs(
  () => {
    if (!apiTokenPromise) {
      apiTokenPromise = fetch('https://hikemm.hikartech.in/cube/auth/cubejs-token')
        .then((res) => res.json())
        .then((r) => r.token);
    }
    return apiTokenPromise;
  },
  {
    apiUrl: 'https://hikemm.hikartech.in/cube/cubejs-api/v1/',
  }
);
// branch

function CubeJsApiWrapper(props) {
  return (
    <CubeProvider cubejsApi={cubejsApi}>
      {props.children}
    </CubeProvider>
  )
}

export default CubeJsApiWrapper;

