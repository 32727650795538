import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-drag-and-drop';
import Container from '@mui/material/Container';
import { Grid } from "@mui/material";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from "react-router-dom";
import { images } from '../../../../../config/images';
import { getDeviceAndHicloudTag, createBridgeDeviceAndHicloudTag } from '../../../service';
import { ToastContainer, toast } from 'react-toastify';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import 'react-toastify/dist/ReactToastify.css';
import './Bridge.css'
import { useEffect } from 'react';
import Loader from '../../../../../components/Loader/Loader';

function Bridge() {
    const navigate = useNavigate();
    const [blankAssignDevice, setblankAssignDevice] = useState([])
    const [hiCloudTagData, setHiCloudTagData] = useState([]);
    const [deviceTag, setDeviceTag] = useState([]);
    const [isLoading, setLoading] = useState(false) 
    const [deviceDetail, setDeviceDetail] = useState({
        uid: "",
        device_name: "",
        device_description: "",

     })
    const [lockAndUnlock, setLockAndUnlock] = useState({});

    const location = useParams();

    console.log("hiCloudTagData", hiCloudTagData);
    const notifyError = (message) => {
        toast.error(message, {
            theme: "colored",
            position: toast.POSITION.TOP_RIGHT,
            toastClassName: "custom-toast",
        });
    }

    const notifySuccess = (message) => {
        toast.success(message, {
            theme: "colored",
            position: toast.POSITION.TOP_RIGHT,
            toastClassName: "custom-toast",
        });
    }

    const handelChangeUnlock = (id) => {
        setLockAndUnlock((prev) => ({ ...prev, [id]: !prev[id] }));
    }

    const handelChangeSetUnlockData = (value, index) => {
        if (deviceTag.includes(value)) {
            // If value already exists in deviceTag, remove it
            const updatedDeviceTag = deviceTag.filter((tag) => tag !== value);
            setDeviceTag(updatedDeviceTag);
        } else {
            // If value doesn't exist in deviceTag, add it
            setDeviceTag([...deviceTag, value]);
        }
        
        if (blankAssignDevice && index >= 0 && index < blankAssignDevice.length) {
            const updatedBlankAssignDevice = [...blankAssignDevice];
            updatedBlankAssignDevice[index] = null;
            setblankAssignDevice(updatedBlankAssignDevice);
            setLockAndUnlock((prev) => ({ ...prev, [value]: !prev[value] }));
        }
    };

    const handleDrop = (data, event) => {
        // This method runs when the data drops
        const filterData = deviceTag.filter((e, i) => e !== data.e.foo);
        setDeviceTag(filterData)
        // console.log(kp[data.kp]);
        setblankAssignDevice((prevBlankAssignDevice) => {
            const updatedBlankAssignDevice = [...prevBlankAssignDevice];
            updatedBlankAssignDevice[data.i] = data.e.foo;
            return updatedBlankAssignDevice;
        });
        
    }

    useEffect(() => {
        submitCreateBridge()
    }, [deviceTag])


    const getDeviceAndHicloudTagData = async () => {
        setLoading(true)
        const param = {
          device: location.id
        };
        try {
          const resp = await getDeviceAndHicloudTag(param);
            if (resp.status == 200) {
                setLoading(false)
                const blankAssignDeviceEmpaty = [];
                if (resp.data.payload) {
                    resp.data.payload.hicloud_tag.forEach(element => {
                        blankAssignDeviceEmpaty.push(element.device_tag);
                    });
                 
                }
                setblankAssignDevice(blankAssignDeviceEmpaty);
                setHiCloudTagData(resp.data.payload.hicloud_tag);
                setDeviceTag(resp.data.payload.device_tag);
                setDeviceDetail({
                    uid: resp.data.payload.device_detail.uid,
                    device_name: resp.data.payload.device_detail.device_name,
                    device_description: resp.data.payload.device_detail.device_description

                })
          }
        } catch (error) {
          console.log(error);
        //   notifyError(error.response.data.message);
        }
      };
      
      useEffect(() => {
        getDeviceAndHicloudTagData();
      }, []);
      


    const submitCreateBridge = async () => {
        const tagBridge = hiCloudTagData.reduce((acc, val, i) => {
            // if (blankAssignDevice[i] !== null) {
                acc[val.tag_name] = blankAssignDevice[i];
            // }
            return acc;
        }, {});

        const param = {
            device_id: location.id,
            tag: tagBridge
        }
        const resp = await createBridgeDeviceAndHicloudTag(param);
        if (resp.status == 200) {
            // notifySuccess("successfull create bridge");
            // getDeviceAndHicloudTagData();
            setLockAndUnlock({});
        }
    }

    const submitRedirect = () => {
        navigate(`/deviceManagement/configure/device/${location.id}`)
    }

    return (
        <>
            <div>
                <ToastContainer autoClose={4000}></ToastContainer>
            </div>
            <div className='containerWrap'>
                <Container maxWidth="xxl">
                    <div className="deviceManagementHeader-wrap configure">
                        <div className='deviceManagementTitle-wrap'>
                            <KeyboardBackspaceIcon onClick={() => navigate(`/deviceManagement/configure/device/${location.id}`)} className='backArrow' /> <Typography variant="h5" gutterBottom className='deviceManagementTitle'>
                                {deviceDetail.device_name}
                            </Typography>
                        </div>
                    </div>
                    <Grid container>
                        <Grid item xs={12} pt='20px'>
                            <Card className="bridgeDevice-headerContent">
                                <Box className='bridgeDevice-detailsWrap'>
                                    <Box className='bridgeDevice-details'>
                                        <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }}>
                                            {deviceDetail.device_name}
                                        </Typography>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {deviceDetail.device_description}
                                        </Typography>
                                    </Box>
                                    <Box className='bridgeDevice-uidDetailswrap'>
                                        <Box className='bridgeDevice-uidDetails'>
                                            <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }}>
                                                UID:
                                            </Typography>
                                            <Typography variant="caption" gutterBottom>
                                                {deviceDetail.uid}
                                            </Typography>
                                        </Box>
                                        <Box className='bridgeDevice-tagDetails'>
                                            <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }}>
                                                Active Tags:
                                            </Typography>
                                            <Typography variant="caption" gutterBottom>
                                                {deviceTag.length}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container>
                        {/* =============== Device Tag Start =============== */}
                        <Grid item sm={4} md={4} lg={3} xl={3} pt='20px' pr='10px'>
                            <Card className='devicetagWrap'>
                                <div className='deviceWrap'>
                                    <div className='deviceTitleWrap'>
                                        <Typography variant="h6" gutterBottom className='deviceTagtitle'>
                                            Device Tag<img src={images.informationIcon} alt="" style={{ paddingLeft: '10px', width: '25px' }} />
                                        </Typography>
                                    </div>
                                    {deviceTag.length ? deviceTag.map((e, i) => {
                                        return (
                                            <Draggable type="foo" data={`${e}`}>
                                                <Box className='devicetagBox'>
                                                    <Typography variant="body2" gutterBottom className='devicetagBox-text' >
                                                        {e}
                                                    </Typography>
                                                    <DragIndicatorIcon className='dragIcon bridge' />
                                                </Box>
                                            </Draggable>
                                        )
                                    }) : []}
                                </div>
                            </Card>
                        </Grid>
                        {/* =============== Device Tag End =============== */}
                        {/* ===============  HiCloud Tag Start =============== */}
                        <Grid item sm={8} md={8} lg={9} xl={9} pt='20px'>
                            <Card className='hiCloudtagWrap'>
                                <div className='hiCloudWrap'>
                                    <div className='hiCloudTitleWrap'>
                                        <Typography variant="h6" gutterBottom className='hiCloudTitle'>
                                            HiCloud<sup style={{ marginTop: '0.2em' }} className='hiCloud-text'>R</sup>Tag <img src={images.informationIcon} alt="" style={{ paddingLeft: '10px', width: '25px' }} />
                                        </Typography>
                                    </div>
                                    <Grid container>
                                        <Grid item sm={6} md={6} lg={4} xl={4}>
                                            {hiCloudTagData.length ? hiCloudTagData.map((e) => {
                                                return (
                                                    <Box className='hiCloudtagBox'>
                                                        <Typography variant="body2" gutterBottom className='hiCloudtagBox-text'>
                                                            {e.tag_name}
                                                        </Typography>
                                                    </Box>
                                                )
                                            }) : []}
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={4} xl={4}>
                                            {blankAssignDevice.length ? blankAssignDevice.map((e, i) => {
                                                console.log("e",e);
                                                return (
                                                    <Droppable types={['foo']} onDrop={(e) => handleDrop({ e, i })}>
                                                        <Box className='hiCloudtagBox-drag' style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography variant="body2" gutterBottom className='hiCloudtagBox-text'>
                                                                {e}
                                                            </Typography>
                                                            {e != null ?  lockAndUnlock[e] ?
                                                                <img src={images.lockOpenImg} alt="" style={{ width: '16px', marginLeft: 'auto', marginRight: '10px' }} onClick={() => handelChangeSetUnlockData(e, i)}/> :
                                                                <img src={images.lockImg} alt="" style={{ width: '16px', marginLeft: 'auto', marginRight: '10px' }} onClick={() => handelChangeUnlock(e)} /> : ""} 
                                                        </Box>
                                                    </Droppable>
                                                )
                                            }) : []}

                                        </Grid>
                                    </Grid>
                                </div>
                                <Box className='configuration-submitBtnWrap'>
                                    <button className='Bridge submit' onClick={submitRedirect}>Submit</button>
                                </Box>
                            </Card>
                        </Grid>
                        {/* ===============  HiCloud Tag End =============== */}
                    </Grid>
                </Container>
                {isLoading && <Loader />}
            </div>
        </>
    )
}

export default Bridge