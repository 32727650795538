import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  FormControlLabel,
  FormGroup,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@mui/material/Switch";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import {
  getDeviceOverview,
  getDeviceUnit,
  getOverViewAnalyticsData,
  getOverViewPieChartData,
  getOverviewDeviceData,
  getOverviewReportData,
  getOverviewReportDeviceData,
} from "./services";
import { OverViewAnalyticsReportApiData } from "./OverViewAnalyticsReportApiData";

import { useDispatch, useSelector } from "react-redux";
import { setOverviewData } from "./Slice/OverViewAnalyticsSlice";
import { ParetoChart } from "./ParetoChart";
import dayjs, { Dayjs } from "dayjs";

import { images } from "../../../config/images";
import { MultiAxisLineChart } from "./MultiAxisReportLineChart";
import { PopDonutChart } from "./PopPieChart";
import { DonutChart } from "./PieChart";
import { PopgroupBarChart } from "./PopgroupBarChart";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      padding: "8px 10px", // set the desired padding here
    },
  },
});

const OverViewAnalytics = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [active, setActive] = useState("Consumer");
  const [value, setValue] = React.useState("1");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDatePOP, setStartDatePOP] = useState(null);
  const [endDatePOP, setEndDatePOP] = useState(null);
  const [startDatePOP1, setStartDatePOP1] = useState(null);
  const [endDatePOP1, setEndDatePOP1] = useState(null);

  const [deviceData, setDeviceData] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [reportOverViewData, setReportOverViewData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [deviceUnitData, setDeviceUnitData] = useState([]);
  const [unitSearchQuery, setUnitSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectTimeRange, setSelectTimeRange] = useState("1");
  const [selectPOPTimeRange, setSelectPOPTimeRange] = useState("1");
  const [analyticsData, setAnalyticsData] = useState([]);
  const [analyticsPOPData, setAnalyticsPOPData] = useState([]);
  const [analyticsPOP1Data, setAnalyticsPOP1Data] = useState([]);
  const [analyticsPieChartData, setAnalyticsPieChartData] = useState([]);
  const [analyticsPopPieChart, setAnalyticsPopPieChart] = useState([]);
  const [analyticsPop1PieChart, setAnalyticsPop1PieChart] = useState([]);
  const [donutPOPChartSum, setDonutPOPChartSum] = useState([]);
  const [donutPOP1ChartSum, setDonut1POPChartSum] = useState([]);
  const [donutPOPUnit, setDonutPOPUnit] = useState([]);
  const [donutChartSum, setDonutChartSum] = useState([]);
  const [donutUnit, setDonutUnit] = useState([]);
  const [showTime, setShowTime] = useState(false);

  const {
    deviceIdRedux,
    deviceUnitRedux,
    deviceColors,
    deviceIdReportRedux,
    deviceUnitReportRedux,
    deviceColorsReportRedux,
    unitColorsReportRedux,
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    devicePOPColors,
    startDateRe,
    endDateRe,
    startDatePopRe,
    endDatePopRe,
    startDatePopRe1,
    endDatePopRe1,
  } = useSelector((state) => state.overViewState);

  const startDateReFormat = startDateRe ? dayjs(startDateRe) : null;
  const endDateReFormat = endDateRe ? dayjs(endDateRe) : null;

  const startDatePOPReFormat = startDatePopRe ? dayjs(startDatePopRe) : null;
  const endDatePOPReFormat = endDatePopRe ? dayjs(endDatePopRe) : null;

  const startDatePOP1ReFormat = startDatePopRe1 ? dayjs(startDatePopRe1) : null;
  const endDatePOP1ReFormat = endDatePopRe1 ? dayjs(endDatePopRe1) : null;

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const startTimeFormat = startDateRe
    ? dayjs(startDateRe).format(timeFormat)
    : "";
  const endTimeFormat = endDateRe ? dayjs(endDateRe).format(timeFormat) : "";

  const startPOPTimeFormat = startDatePopRe
    ? dayjs(startDatePopRe).format(timeFormat)
    : "";
  const endPOPTimeFormat = endDatePopRe
    ? dayjs(endDatePopRe).format(timeFormat)
    : "";
  const startPOP1TimeFormat = startDatePopRe1
    ? dayjs(startDatePopRe1).format(timeFormat)
    : "";
  const endPOP1TimeFormat = endDatePopRe1
    ? dayjs(endDatePopRe1).format(timeFormat)
    : "";
  const formatStartDate = startDateRe
    ? dayjs(startDateRe).format(dateFormat)
    : "";
  const formatEndDate = endDateRe ? dayjs(endDateRe).format(dateFormat) : "";

  const formatPOPStartDate = startDatePopRe
    ? dayjs(startDatePopRe).format(dateFormat)
    : "";
  const formatPOPEndDate = endDatePopRe
    ? dayjs(endDatePopRe).format(dateFormat)
    : "";
  const formatPOP1StartDate = startDatePopRe1
    ? dayjs(startDatePopRe1).format(dateFormat)
    : "";
  const formatPOP1EndDate = endDatePopRe1
    ? dayjs(endDatePopRe1).format(dateFormat)
    : "";

  // Report Overview Analysis
  const [deviceReport, setDeviceReport] = useState([]);
  const [unitReport, setUnitReport] = useState([]);
  const [showTimeReport, setShowTimeReport] = useState(false);
  const [selectAggReport, setSelectAggReport] = useState("15");
  const [selectIntervalReport, setSelectIntervalReport] = useState("1");
  const [selectFirstUnit, setSelectFirstUnit] = useState("none");
  const [selectSecondUnit, setSelectSecondUnit] = useState("none");
  const [startDateRep, setStartDateRep] = useState(null);
  const [endDateRep, setEndDateRep] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoading1, setLoading1] = useState(false);
  const [isLoadingPop, setLoadingPop] = useState(false);
  const [isLoadingPop1, setLoadingPop1] = useState(false);
  const [isLoadingReport,setLoadingReport] = useState(false)

  const startTimeFormatRep = startDateRep
    ? dayjs(startDateRep).format(timeFormat)
    : "";
  const endTimeFormatRep = endDateRep
    ? dayjs(endDateRep).format(timeFormat)
    : "";
  const formatStartDateRep = startDateRep
    ? dayjs(startDateRep).format(dateFormat)
    : "";
  const formatEndDateRep = endDateRep
    ? dayjs(endDateRep).format(dateFormat)
    : "";

  // chartData Report
  const [fisrtLineChartReportData, setFisrtLineChartReportData] = useState([]);
  const [secondeLineChartReportData, setSecondLineChartReportData] = useState(
    []
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleToggleTime = () => {
    setShowTime((prevShowTime) => !prevShowTime);
  };

  const switchColors = [
    "#FEBB22",
    "#075571",
    "#002B2A",
    "#C99C00",
    "#69927A",
    "#872B81",
    "#F06292",
    "#29445A",
    "#CD4D4A",
    "#0C4562",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#001A30",
    "#0B648D",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#123782",
    "#C60BC8",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#EB9E79",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#7D2264",
    "#0EB520",
    "#0E3504",
    "#1D733F",
    "#6E02B1",
  ];

  const classes = useStyles();

  const handleSearchData = () => {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const param = useParams();
  const theme = createTheme();

  useEffect(() => {
    handlegetDevice();
  }, []);

  const handlegetDevice = async () => {
    const params = {
      plant_id: param.id,
      // company_id: param.companyId,
    };
    try {
      const resp = await getOverviewDeviceData(params);
      if (resp.data.success == true) {
        setDeviceData(resp.data.payload.device);
        setDeviceUnitData(resp.data.payload.tag);
        // if (resp.data.payload.length) {
        //   setDeviceId(resp.data.payload[0].device);
        // }
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  // get device In Report
  const handlegetDeviceReport = async () => {
    const params = {
      plant_id: param.id,
    };
    try {
      const resp = await getOverviewReportDeviceData(params);
      if (resp.data.success == true) {
        console.log("resres", resp.data);
        setDeviceReport(resp.data.payload.device);
        setUnitReport(resp.data.payload.tag);
        // if (resp.data.payload.length) {
        //   setDeviceId(resp.data.payload[0].device);
        // }
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    handlegetDeviceReport();
  }, []);

  const handleSelectDeviceId = (id, index, event, row) => {
    const existingIndex = deviceColors.findIndex(
      (color) => color.deviceId === row.device
    );

    if (existingIndex !== -1) {
      const updatedColors = [...deviceColors];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setOverviewData({
          deviceColors: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setOverviewData({
          deviceColors: [
            ...deviceColors,
            {
              deviceId: row.device,
              dvName: row.device__device_name,
              color: event.target.name,
            },
          ],
        })
      );
    }

    if (!Array.isArray(deviceIdRedux)) {
      setDeviceId([]);
      dispatch(setOverviewData({ deviceIdRedux: [] }));
    }
    if (deviceIdRedux.includes(id)) {
      // setDeviceId(deviceIdRedux.filter((element) => element !== id));
      dispatch(
        setOverviewData({
          deviceIdRedux: deviceIdRedux.filter((element) => element !== id),
        })
      );
    } else {
      // setDeviceId([...deviceIdRedux, id]);
      dispatch(setOverviewData({ deviceIdRedux: [...deviceIdRedux, id] }));
    }
  };

  const handlePOPSelectDeviceId = (id, index, event, row) => {
    const existingIndex = devicePOPColors.findIndex(
      (color) => color.deviceId === row.device
    );

    if (existingIndex !== -1) {
      // If the selected device already exists, remove it
      const updatedColors = [...devicePOPColors];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setOverviewData({
          devicePOPColors: updatedColors,
        })
      );

      // Unselect the device
      dispatch(setOverviewData({ deviceIdPOPRedux: [] }));
    } else {
      // Remove any previously selected devices
      dispatch(setOverviewData({ devicePOPColors: [] }));

      // Add the selected device to the state array
      dispatch(
        setOverviewData({
          devicePOPColors: [
            {
              deviceId: row.device,
              dvName: row.device__device_name,
              color: event.target.name,
            },
          ],
        })
      );

      // Set the selected device as the only device in the array
      dispatch(setOverviewData({ deviceIdPOPRedux: [id] }));
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleUnitSearchChange = (event) => {
    setUnitSearchQuery(event.target.value);
  };

  const handleSelectTagUnit = (id) => {
    if (!Array.isArray(deviceUnitRedux)) {
      // setUnitName([]);
      dispatch(setOverviewData({ deviceUnitRedux: [] }));
    }
    if (deviceUnitRedux.includes(id)) {
      // setUnitName(unitName.filter((element) => element !== id));
      dispatch(
        setOverviewData({
          deviceUnitRedux: deviceUnitRedux.filter((element) => element !== id),
        })
      );
    } else {
      // setUnitName([id]);
      dispatch(setOverviewData({ deviceUnitRedux: [id] }));
    }
  };

  const handlePOPSelectTagUnit = (id) => {
    if (!Array.isArray(deviceUnitPOPRedux)) {
      // setUnitName([]);
      dispatch(setOverviewData({ deviceUnitPOPRedux: [] }));
    }
    if (deviceUnitPOPRedux.includes(id)) {
      // setUnitName(unitName.filter((element) => element !== id));
      dispatch(
        setOverviewData({
          deviceUnitPOPRedux: deviceUnitPOPRedux.filter(
            (element) => element !== id
          ),
        })
      );
    } else {
      // setUnitName([id]);
      dispatch(setOverviewData({ deviceUnitPOPRedux: [id] }));
    }
  };

  const handleSelectDeviceIdReport = (id, index, event, row) => {
    const existingIndex = deviceColorsReportRedux.findIndex(
      (color) => color.deviceId === row.device
    );

    if (existingIndex !== -1) {
      const updatedColors = [...deviceColorsReportRedux];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setOverviewData({
          deviceColorsReportRedux: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setOverviewData({
          deviceColorsReportRedux: [
            ...deviceColorsReportRedux,
            {
              deviceId: row.device,
              dvName: row.device__device_name,
              color: event.target.name,
            },
          ],
        })
      );
    }

    if (!Array.isArray(deviceIdReportRedux)) {
      setDeviceId([]);
      dispatch(setOverviewData({ deviceIdReportRedux: [] }));
    }
    if (deviceIdReportRedux.includes(id)) {
      // setDeviceId(deviceIdReportRedux.filter((element) => element !== id));
      dispatch(
        setOverviewData({
          deviceIdReportRedux: deviceIdReportRedux.filter(
            (element) => element !== id
          ),
        })
      );
    } else {
      // setDeviceId([...deviceIdReportRedux, id]);
      dispatch(setOverviewData({ deviceIdReportRedux: [id] }));
    }
  };
  const handleSelectTagUnitReport = (id, event, row) => {
    const existingIndex = unitColorsReportRedux.findIndex(
      (color) => color.tagUnit === row.tag_unit
    );

    if (existingIndex !== -1) {
      const updatedColors = [...unitColorsReportRedux];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setOverviewData({
          unitColorsReportRedux: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setOverviewData({
          unitColorsReportRedux: [
            ...unitColorsReportRedux,
            {
              tagUnit: row.tag_unit,
              color: event.target.name,
            },
          ],
        })
      );
    }

    if (!Array.isArray(deviceUnitReportRedux)) {
      // setUnitName([]);
      dispatch(setOverviewData({ deviceUnitReportRedux: [] }));
    }
    if (deviceUnitReportRedux.includes(id)) {
      // setUnitName(unitName.filter((element) => element !== id));
      dispatch(
        setOverviewData({
          deviceUnitReportRedux: deviceUnitReportRedux.filter(
            (element) => element !== id
          ),
        })
      );
    } else {
      // setUnitName([id]);
      dispatch(
        setOverviewData({
          deviceUnitReportRedux: [...deviceUnitReportRedux, id],
        })
      );
    }

    if (!deviceUnitReportRedux.length) {
      setSelectFirstUnit("none");
      setSelectSecondUnit("none");
    }
  };

  useEffect(() => {
    handleAnalyticsData();
  }, [
    deviceIdRedux,
    deviceUnitRedux,
    formatStartDate,
    formatEndDate,
    selectTimeRange,
    startTimeFormat,
    endTimeFormat,
  ]);

  useEffect(() => {
    handleAnalyticsPieChartData();
  }, [deviceIdRedux, deviceUnitRedux, formatStartDate, formatEndDate]);

  const handleAnalyticsData = async () => {
    if (
      deviceIdRedux &&
      deviceUnitRedux[0] &&
      formatStartDate &&
      formatEndDate &&
      selectTimeRange
    ) {
      const params = {
        device_id: deviceIdRedux,
        tag_unit: deviceUnitRedux[0],
        start_date: formatStartDate,
        end_date: formatEndDate,
        // start_time: startTimeFormat !== "00:00:00" ? startTimeFormat : "",
        // end_time: endTimeFormat !== "00:00:00" ? endTimeFormat : "",
        aggregate: Number(selectTimeRange),
      };

      if (startTimeFormat !== "00:00:00" && endTimeFormat !== "00:00:00") {
        params["start_time"] = startTimeFormat;
        params["end_time"] = endTimeFormat;
      }
      try {
        setLoading(true);
        const resp = await getOverViewAnalyticsData(params);
        if (resp.data.success === true) {
          setLoading(false);
          setAnalyticsData(resp.data.payload);
        }
      } catch (error) {
        setLoading(false);
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    handleAnalyticsPOPBarChart();
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux[0],
    formatPOPStartDate,
    formatPOPEndDate,
    selectPOPTimeRange,
  ]);

  const handleAnalyticsPOPBarChart = async () => {
    if (
      deviceIdPOPRedux &&
      deviceUnitPOPRedux[0] &&
      formatPOPStartDate &&
      formatPOPEndDate &&
      selectPOPTimeRange
    ) {
      const params = {
        device_id: deviceIdPOPRedux,
        tag_unit: deviceUnitPOPRedux[0],
        start_date: formatPOPStartDate,
        end_date: formatPOPEndDate,
        aggregate: Number(selectPOPTimeRange),
      };

      if (
        startPOPTimeFormat !== "00:00:00" &&
        endPOPTimeFormat !== "00:00:00"
      ) {
        params["start_time"] = startPOPTimeFormat;
        params["end_time"] = endPOPTimeFormat;
      }
      try {
        setLoadingPop(true)
        const resp = await getOverViewAnalyticsData(params);
        if (resp.data.success === true) {
          setLoadingPop(false)
          setAnalyticsPOPData(resp.data.payload);
        }
      } catch (error) {
        setLoadingPop(false)
        console.log("handleAnalyticsData", error);
      }
    }
  };
  useEffect(() => {
    handleAnalyticsPOPBarChart2();
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux[0],
    formatPOP1StartDate,
    formatPOP1EndDate,
    selectPOPTimeRange,
  ]);

  const handleAnalyticsPOPBarChart2 = async () => {
    if (
      deviceIdPOPRedux &&
      deviceUnitPOPRedux[0] &&
      formatPOP1StartDate &&
      formatPOP1EndDate &&
      selectPOPTimeRange
    ) {
      const params = {
        device_id: deviceIdPOPRedux,
        tag_unit: deviceUnitPOPRedux[0],
        start_date: formatPOP1StartDate,
        end_date: formatPOP1EndDate,
        aggregate: Number(selectPOPTimeRange),
      };

      if (
        startPOP1TimeFormat !== "00:00:00" &&
        endPOP1TimeFormat !== "00:00:00"
      ) {
        params["start_time"] = startPOP1TimeFormat;
        params["end_time"] = endPOP1TimeFormat;
      }
      try {
        const resp = await getOverViewAnalyticsData(params);
        if (resp.data.success === true) {
          setAnalyticsPOP1Data(resp.data.payload);
        }
      } catch (error) {
        console.log("handleAnalyticsData", error);
      }
    }
  };
  const handleAnalyticsPieChartData = async () => {
    if (
      deviceIdRedux &&
      deviceUnitRedux[0] &&
      formatStartDate &&
      formatEndDate
    ) {
      const params = {
        device_id: deviceIdRedux,
        tag_unit: deviceUnitRedux[0],
        start_date: formatStartDate,
        end_date: formatEndDate,
      };
      try {
        setLoading1(true);
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setLoading1(false);
          setAnalyticsPieChartData(resp.data.payload);
          setDonutChartSum(resp.data.tag_value_sum);
          setDonutUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        setLoading1(false);
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    handleAnalyticsPOPpieChartData();
  }, [deviceIdRedux, deviceUnitRedux[0], formatPOPStartDate, formatPOPEndDate]);

  const handleAnalyticsPOPpieChartData = async () => {
    if (
      deviceIdPOPRedux &&
      deviceUnitPOPRedux[0] &&
      formatPOPStartDate &&
      formatPOPEndDate
    ) {
      const params = {
        device_id: deviceIdPOPRedux,
        tag_unit: deviceUnitPOPRedux[0],
        start_date: formatPOPStartDate,
        end_date: formatPOPEndDate,
      };
      try {
        setLoadingPop1(true)
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setLoadingPop1(false)
          setAnalyticsPopPieChart(resp.data.payload);
          setDonutPOPChartSum(resp.data.tag_value_sum);
          setDonutPOPUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        setLoadingPop1(false)
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    handleAnalyticsPOP1pieChartData();
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux[0],
    formatPOP1StartDate,
    formatPOP1EndDate,
  ]);

  const handleAnalyticsPOP1pieChartData = async () => {
    if (
      deviceIdPOPRedux &&
      deviceUnitPOPRedux[0] &&
      formatPOP1StartDate &&
      formatPOP1EndDate
    ) {
      const params = {
        device_id: deviceIdPOPRedux,
        tag_unit: deviceUnitPOPRedux[0],
        start_date: formatPOP1StartDate,
        end_date: formatPOP1EndDate,
      };
      try {
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setAnalyticsPop1PieChart(resp.data.payload);
          setDonut1POPChartSum(resp.data.tag_value_sum);
          setDonutUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        console.log("handleSubmit", error);
      }
    }
  };

  // report Table data
  const getOverviewReportApi = async () => {
    if (
      deviceIdReportRedux.length &&
      deviceUnitReportRedux.length &&
      formatStartDateRep &&
      formatEndDateRep
    ) {
      const paramRequset = {
        device_id: deviceIdReportRedux[0],
        tag_units: deviceUnitReportRedux,
        start_date: formatStartDateRep,
        end_date: formatEndDateRep,
      };
      try {
        const resp = await getOverviewReportData(paramRequset);

        if (resp.status == 200) {
          setReportOverViewData(resp.data.payload);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    getOverviewReportApi();
  }, [formatStartDateRep, formatEndDateRep]);

  return (
    <>
      <OverViewAnalyticsReportApiData
        showTimeReport={showTimeReport}
        setFisrtLineChartReportData={setFisrtLineChartReportData}
        setSecondLineChartReportData={setSecondLineChartReportData}
        selectFirstUnit={selectFirstUnit}
        selectAggReport={selectAggReport}
        selectIntervalReport={selectIntervalReport}
        deviceIdReportRedux={deviceIdReportRedux}
        selectSecondUnit={selectSecondUnit}
        startTimeFormatRep={startTimeFormatRep}
        endTimeFormatRep={endTimeFormatRep}
        formatStartDateRep={formatStartDateRep}
        formatEndDateRep={formatEndDateRep}
        setLoadingReport={setLoadingReport}
      />
      <div className="page-wraper">
        <div className="page-header page-header-analytics">
          {activeClass == "Analytics" ? (
            <div
              onClick={() =>
                navigate(
                  `/overView/company/${param.companyId}/plant/${param.id}`
                )
              }
              className="page-back-btn"
            >
              <ArrowBackIcon />
              <span>Overview - Analytics</span>
            </div>
          ) : (
            <div
              onClick={() =>
                navigate(
                  `/overView/company/${param.companyId}/plant/${param.id}`
                )
              }
              className="page-back-btn"
            >
              <ArrowBackIcon />
              <span>Overview - Report</span>
            </div>
          )}
          <div className="d-flex">
            <button
              className={`${
                activeClass == "Analytics" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => setActiveClass("Analytics")}
            >
              Analytics
            </button>
            <button
              className={`${
                activeClass == "Reports" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => setActiveClass("Reports")}
            >
              Reports
            </button>
          </div>
        </div>
        {activeClass == "Analytics" ? (
          <>
            <div className="analysis-tabs-btn pt-3">
              <div className="analytics-tabs-btn">
                <div className="d-flex">
                  <button
                    className={`${
                      active == "Consumer"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() => setActive("Consumer")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Consumer over Consumer
                  </button>
                  <button
                    className={`${
                      active == "Period"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() => setActive("Period")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Period over Period
                  </button>
                </div>
              </div>
              {active == "Consumer" ? (
                <div
                  className="analytics-aggregation analytics-datepicker"
                  style={{ justifyContent: "flex-end" }}
                >
                  <h6 className="AGG-dropdown">AGG.</h6>
                  <Box
                    sx={{ minWidth: 200 }}
                    style={{ float: "right", marginRight: "24px" }}
                  >
                    <select
                      className="form-input-class"
                      style={{ padding: "16px 8px", borderRadius: "4px" }}
                      id="selectedClass"
                      value={selectTimeRange}
                      onChange={(event) =>
                        setSelectTimeRange(event.target.value)
                      }
                      // defaultValue="all"
                      // value={selectYear}
                      // onChange={(event) => setSelectYear(event.target.value)}
                      required
                    >
                      <option value="15" selected disabled hidden>
                        15 Min
                      </option>
                      <option value="15">15 Min</option>
                      <option value="30">30 Min</option>
                      <option value="60">Hourly</option>
                      <option value="1">Daily</option>
                      <option value="7">Weekly</option>
                    </select>
                  </Box>

                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={<Switch color="primary" />}
                      label="Time"
                      labelPlacement="top"
                      onChange={handleToggleTime}
                    />
                  </FormGroup>

                  {showTime ? (
                    <>
                      <Box style={{ width: "51%" }} className="box-datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="Start Date"
                            value={startDateReFormat}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ startDateRe: newValue })
                              )
                            }
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className="header-btn-month box-end-datepicker"
                        style={{ width: "51%" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="End Date"
                            value={endDateReFormat}
                            onChange={(newValue) =>
                              dispatch(setOverviewData({ endDateRe: newValue }))
                            }
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box style={{ width: "51%" }} className="box-datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="date-picker-production"
                            label="Start Date"
                            value={startDateReFormat}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ startDateRe: newValue })
                              )
                            }
                            inputFormat="DD/MM/YYYY"
                            views={["day"]}
                            // onChange={(newValue) => {
                            //   setStartDate(newValue);
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className="header-btn-month"
                        style={{ width: "51%" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="date-picker-production"
                            label="End Date"
                            value={endDateReFormat}
                            inputFormat="DD/MM/YYYY"
                            views={["day"]}
                            onChange={(newValue) =>
                              dispatch(setOverviewData({ endDateRe: newValue }))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  )}

                  {/* <Box className="header-btn-date" style={{ width: "31%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box className="header-btn-month" style={{ width: "31%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                      />
                    </LocalizationProvider>
                  </Box> */}
                </div>
              ) : (
                <div
                  className="analytics-aggregation analytics-datepicker"
                  style={{ justifyContent: "flex-end" }}
                >
                  <h6 className="AGG-dropdown" style={{ marginLeft: "20px" }}>
                    AGG.
                  </h6>
                  <Box
                    sx={{ minWidth: 200 }}
                    style={{ float: "right", marginRight: "45px" }}
                  >
                    <select
                      className="form-input-class"
                      style={{ padding: "16px 8px", borderRadius: "4px" }}
                      id="selectedClass"
                      value={selectPOPTimeRange}
                      onChange={(event) =>
                        setSelectPOPTimeRange(event.target.value)
                      }
                      required
                    >
                      <option value="15" selected disabled hidden>
                        15 Min
                      </option>
                      <option value="15">15 Min</option>
                      <option value="30">30 Min</option>
                      <option value="60">Hourly</option>
                      <option value="1">Daily</option>
                      <option value="7">Weekly</option>
                    </select>
                  </Box>

                  {/* <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={<Switch color="primary" />}
                      label="Time"
                      labelPlacement="top"
                    />
                  </FormGroup> */}

                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={<Switch color="primary" />}
                      label="Time"
                      labelPlacement="top"
                      onChange={handleToggleTime}
                    />
                  </FormGroup>

                  {showTime ? (
                    <>
                      <Box style={{ width: "51%" }} className="box-datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="Start Date"
                            className="date-picker-production datepicker-color"
                            value={startDatePOPReFormat}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ startDatePopRe: newValue })
                              )
                            }
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className="header-btn-month"
                        style={{ width: "51%" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="End Date"
                            className="date-picker-production datepicker-color"
                            value={endDatePOPReFormat}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ endDatePopRe: newValue })
                              )
                            }
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box style={{ width: "51%" }} className="box-datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="Start Date"
                            className="date-picker-production second-datepicker-color"
                            value={startDatePOP1ReFormat}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ startDatePopRe1: newValue })
                              )
                            }
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className="header-btn-month"
                        style={{ width: "51%" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="End Date"
                            className="date-picker-production second-datepicker-color"
                            value={endDatePOP1ReFormat}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ endDatePopRe1: newValue })
                              )
                            }
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box style={{ width: "51%" }} className="box-datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="date-picker-production datepicker-color"
                            label="Start Date"
                            value={startDatePOPReFormat}
                            inputFormat="DD/MM/YYYY"
                            views={["day"]}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ startDatePopRe: newValue })
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                                InputLabelProps={{
                                  style: {
                                    color: "#C0255F",
                                  },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className="header-btn-month"
                        style={{ width: "51%" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="date-picker-production datepicker-color"
                            label="End Date"
                            value={endDatePOPReFormat}
                            inputFormat="DD/MM/YYYY"
                            views={["day"]}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ endDatePopRe: newValue })
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>

                      <Box style={{ width: "51%" }} className="box-datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="date-picker-production second-datepicker-color"
                            label="Start Date"
                            value={startDatePOP1ReFormat}
                            inputFormat="DD/MM/YYYY"
                            views={["day"]}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ startDatePopRe1: newValue })
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className="header-btn-month"
                        style={{ width: "51%" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="date-picker-production second-datepicker-color"
                            label="End Date"
                            value={endDatePOP1ReFormat}
                            inputFormat="DD/MM/YYYY"
                            views={["day"]}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ endDatePopRe1: newValue })
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="pt-2 consumption-grid-card">
              {active == "Consumer" ? (
                <>
                  <Grid item xs={12} sm={12} md={3}>
                    <Box className="consumerData-box">
                      <Box className="consumer-text-disc">
                        <h6 className="Title">Title</h6>
                        <h6>Energy Meter Charts</h6>
                      </Box>

                      {/* <Box className="consumer-text-disc mt-2">
                    <h6 className="Title">Description</h6>
                    <h6>Energy Meters</h6>
                  </Box> */}

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search here"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />
                      <div className="furnace-grid">
                        <Box className="Grid-text mt-2">
                          {deviceData.length
                            ? deviceData
                                .filter((e) =>
                                  e.device__device_name
                                    ?.toLowerCase()
                                    ?.includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    key={e.device__device_name}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.device__device_name}
                                        </h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={(event) =>
                                            handleSelectDeviceId(
                                              e.device,
                                              index,
                                              event,
                                              e
                                            )
                                          }
                                          name={switchColors[index]}
                                          checked={deviceIdRedux.includes(
                                            e.device
                                          )}
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))
                            : []}
                        </Box>
                      </div>
                      <Box className="consumer-text-disc mt-2">
                        {/* <h6 className="Title">Description</h6>
                        <h6>Units</h6> */}
                      </Box>

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search here"
                        value={unitSearchQuery}
                        onChange={handleUnitSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />

                      <div className="device-unit">
                        <Box className="Grid-text1 mt-2">
                          {deviceUnitData
                            .filter(
                              (e) =>
                                e.tag_unit &&
                                e.tag_unit
                                  .toLowerCase()
                                  .includes(unitSearchQuery.toLowerCase())
                            )
                            .map((e) => (
                              <Grid item xs={12} key={e.tag_unit}>
                                {e.tag_unit && (
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      className="mt-2"
                                      style={{ padding: "7px 12px" }}
                                    >
                                      <h6>{e.tag_unit}</h6>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={deviceUnitRedux.includes(
                                          e.tag_unit
                                        )}
                                        style={{ float: "right" }}
                                        onClick={() =>
                                          handleSelectTagUnit(e.tag_unit)
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#50C878",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#CEE6E1 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            ))}
                        </Box>
                      </div>

                      <div className="mt-3">
                        <button
                          className="export-btn"
                          style={{ marginTop: "46px" }}
                        >
                          EXPORT ENERGY DATA
                        </button>
                      </div>
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={3}>
                    <Box className="consumerData-box">
                      <Box className="consumer-text-disc">
                        <h6 className="Title">Title</h6>
                        <h6>Energy Meter Charts</h6>
                      </Box>

                      {/* <Box className="consumer-text-disc mt-2">
                    <h6 className="Title">Description</h6>
                    <h6>Energy Meters</h6>
                  </Box> */}

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search here"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />
                      <div className="furnace-grid">
                        <Box className="Grid-text mt-2">
                          {deviceData.length
                            ? deviceData
                                .filter((e) =>
                                  e.device__device_name
                                    ?.toLowerCase()
                                    ?.includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    key={e.device__device_name}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.device__device_name}
                                        </h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={(event) =>
                                            handlePOPSelectDeviceId(
                                              e.device,
                                              index,
                                              event,
                                              e
                                            )
                                          }
                                          name={switchColors[index]}
                                          checked={deviceIdPOPRedux.includes(
                                            e.device
                                          )}
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))
                            : []}
                        </Box>
                      </div>
                      <Box className="consumer-text-disc mt-2">
                        {/* <h6 className="Title">Description</h6>
                        <h6>Units</h6> */}
                      </Box>

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search here"
                        value={unitSearchQuery}
                        onChange={handleUnitSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />

                      <div className="device-unit">
                        <Box className="Grid-text1 mt-2">
                          {deviceUnitData
                            .filter(
                              (e) =>
                                e.tag_unit &&
                                e.tag_unit
                                  .toLowerCase()
                                  .includes(unitSearchQuery.toLowerCase())
                            )
                            .map((e) => (
                              <Grid item xs={12} key={e.tag_unit}>
                                {e.tag_unit && (
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      className="mt-2"
                                      style={{ padding: "7px 12px" }}
                                    >
                                      <h6>{e.tag_unit}</h6>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={deviceUnitPOPRedux.includes(
                                          e.tag_unit
                                        )}
                                        style={{ float: "right" }}
                                        onClick={() =>
                                          handlePOPSelectTagUnit(e.tag_unit)
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#50C878",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#CEE6E1 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            ))}
                        </Box>
                      </div>

                      <div className="mt-3">
                        <button
                          className="export-btn"
                          style={{ marginTop: "46px" }}
                        >
                          EXPORT ENERGY DATA
                        </button>
                      </div>
                    </Box>
                  </Grid>
                </>
              )}

              {active == "Consumer" ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  className="consumption-table-box"
                >
                  <Box className="consumerData-box">
                    <div style={{ minHeight: "322px" }}>
                      <div className="d-flex justify-content-between">
                        <h6>
                          <b>Pareto Chart - Consumer over Consumer</b>
                        </h6>
                        <div
                          onClick={() =>
                            navigate(
                              `/overviewanalytics/energybarchart/company/${param.companyId}/plant/${param.id}`
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img src={images.zoomIn} />
                        </div>
                      </div>
                      <div className="spinner-container">
                        {isLoading && (
                          <div className="spinner">
                            <img src={images.spinner} alt="Spinner" />
                          </div>
                        )}
                        <ParetoChart analyticsData={analyticsData} />
                      </div>
                    </div>
                    <div className="mt-2">
                      <h6>
                        <b>Pie Chart - Consumer over Consumer</b>
                      </h6>
                      <div className="spinner-container">
                        {isLoading1 && (
                          <div className="spinner">
                            <img src={images.spinner} alt="Spinner" />
                          </div>
                        )}
                        <DonutChart
                          analyticsPieChartData={analyticsPieChartData}
                          selectedColors={deviceColors}
                          donutChartSum={donutChartSum}
                          donutUnit={donutUnit}
                        />
                      </div>
                    </div>
                  </Box>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  className="consumption-table-box"
                >
                  <Box className="consumerData-box">
                    <div style={{ minHeight: "356px" }}>
                      <div className="d-flex justify-content-between">
                        <h6>
                          <b>Bar Chart - Period over Period</b>
                        </h6>
                        <div
                          onClick={() =>
                            navigate(
                              `/overviewanalytics/energybarchartpop/company/${param.companyId}/plant/${param.id}`
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img src={images.zoomIn} />
                        </div>
                      </div>
                      <div className="spinner-container">
                        {isLoadingPop && (
                          <div className="spinner">
                            <img src={images.spinner} alt="Spinner" />
                          </div>
                        )}
                         <PopgroupBarChart
                        analyticsPOPData={analyticsPOPData}
                        analyticsPOP1Data={analyticsPOP1Data}
                        selectedColors={devicePOPColors}
                      />
                      </div>
                    
                    </div>
                    <div>
                      <h6>Pie Chart - Period over Period</h6>
                      <div className="spinner-container">
                        {isLoadingPop1 && (
                          <div className="spinner">
                            <img src={images.spinner} alt="Spinner" />
                          </div>
                        )}
                         <PopDonutChart
                        analyticsPopPieChart={analyticsPopPieChart}
                        analyticsPop1PieChart={analyticsPop1PieChart}
                        selectedColors={devicePOPColors}
                        donutPOPChartSum={donutPOPChartSum}
                        donutPOPUnit={donutPOPUnit}
                        donutPOP1ChartSum={donutPOP1ChartSum}
                      />
                      </div>
                     
                    </div>
                  </Box>
                </Grid>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-end pt-3">
              {/* <div className="analytics-tabs-btn">
                <div className="d-flex">
                  <button
                    className={`${
                      active == "Consumer"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() => setActive("Consumer")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Consumer over Consumer
                  </button>
                  <button
                    className={`${
                      active == "Period"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() => setActive("Period")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Period over Period
                  </button>
                </div>
              </div> */}
              <div
                className="analytics-aggregation analytics-datepicker"
                style={{ justifyContent: "flex-end" }}
              >
                <h6 className="AGG-dropdown">Interval</h6>
                <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                  <select
                    className="form-input-class"
                    style={{
                      padding: "14px 8px",
                      borderRadius: "4px",
                      marginRight: "20px",
                    }}
                    id="selectedClass"
                    value={selectIntervalReport}
                    // onChange={(e) => setSelectAggReport(e.target.value)}
                    onChange={(e) => setSelectIntervalReport(e.target.value)}
                    required
                  >
                    <option value="1" selected disabled hidden>
                      1 min
                    </option>
                    <option value="1">1 Min</option>
                    <option value="15">15 Min</option>
                    <option value="30">30 Min</option>
                    <option value="60">Hourly</option>
                    <option value="24">Day</option>
                    <option value="7">Weekly</option>
                  </select>
                </Box>

                <h6 className="AGG-dropdown">AGG.</h6>
                <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                  <select
                    className="form-input-class"
                    style={{ padding: "14px 8px", borderRadius: "4px" }}
                    id="selectedClass"
                    value={selectAggReport}
                    onChange={(e) => setSelectAggReport(e.target.value)}
                    // onChange={(e) => ["kwh", "kvah", "kvarh"].includes(selectFirstUnit.toLocaleLowerCase()) || ["kwh", "kvah", "kvarh"].includes(selectSecondUnit.toLocaleLowerCase()) ? null : setSelectAggReport(e.target.value) }
                    required
                  >
                    <option value="15" selected disabled hidden>
                      15 Min
                    </option>
                    <option value="15">15 Min</option>
                    <option value="30">30 Min</option>
                    <option value="60">Hourly</option>
                    <option value="1">Day</option>
                    <option value="7">Weekly</option>
                  </select>
                </Box>

                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="top"
                    control={<Switch color="primary" />}
                    label="Time"
                    labelPlacement="top"
                    onChange={() => setShowTimeReport(!showTimeReport)}
                  />
                </FormGroup>

                {showTimeReport ? (
                  <>
                    <Box style={{ width: "32%" }} className="box-datepicker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Start Date"
                          value={startDateRep}
                          onChange={(newValue) => setStartDateRep(newValue)}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box className="header-btn-month" style={{ width: "30%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="End Date"
                          value={endDateRep}
                          onChange={(newValue) => setEndDateRep(newValue)}
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box style={{ width: "32%" }} className="box-datepicker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="Start Date"
                          value={startDateRep}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            setStartDateRep(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box className="header-btn-month" style={{ width: "30%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="End Date"
                          value={endDateRep}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            setEndDateRep(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                )}
              </div>
            </div>

            <div className="pt-2 consumption-grid-card">
              <Grid item xs={12} sm={12} md={3}>
                <Box
                  className="consumerData-box"
                  style={{ minHeight: "761px" }}
                >
                  <Box className="consumer-text-disc">
                    <h6 className="Title">Title</h6>
                    <h6>Energy Meter Charts</h6>
                  </Box>

                  {/* <Box className="consumer-text-disc mt-2">
                    <h6 className="Title">Description</h6>
                    <h6>Energy Meters</h6>
                  </Box> */}

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search here"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />
                  <div className="furnace-grid">
                    <Box className="Grid-text mt-2">
                      {deviceReport.length
                        ? deviceReport
                            .filter((e) =>
                              e.device__device_name
                                ?.toLowerCase()
                                ?.includes(searchQuery.toLowerCase())
                            )
                            .map((e, index) => (
                              <Grid item xs={12} key={e.device__device_name}>
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    background: "#efeffd",
                                    width: "100%",
                                    marginBottom: "7px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    style={{
                                      padding: "7px 12px",
                                      borderLeft: `4px solid ${
                                        switchColors[
                                          index % switchColors.length
                                        ]
                                      }`,
                                    }}
                                  >
                                    <h6 className="mt-2">
                                      {e.device__device_name}
                                    </h6>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      style={{ float: "right" }}
                                      onClick={(event) =>
                                        handleSelectDeviceIdReport(
                                          e.device,
                                          index,
                                          event,
                                          e
                                        )
                                      }
                                      name={switchColors[index]}
                                      checked={deviceIdReportRedux.includes(
                                        e.device
                                      )}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor:
                                              switchColors[
                                                index % switchColors.length
                                              ],
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#E2D9F2 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))
                        : []}
                    </Box>
                  </div>
                  <Box className="consumer-text-disc mt-2">
                    {/* <h6 className="Title">Description</h6>
                    <h6>Units</h6> */}
                  </Box>

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search here"
                    value={unitSearchQuery}
                    onChange={handleUnitSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />

                  <div className="device-unit">
                    <Box className="Grid-text1 mt-2">
                      {unitReport
                        .filter(
                          (e) =>
                            e.tag_unit &&
                            e.tag_unit
                              .toLowerCase()
                              .includes(unitSearchQuery.toLowerCase())
                        )
                        .map((e, index) => (
                          <Grid item xs={12} key={e.tag_unit}>
                            {e.tag_unit && (
                              <Grid
                                container
                                alignItems="center"
                                style={{
                                  background: "#efeffd",
                                  width: "100%",
                                  marginBottom: "7px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={8}
                                  className="mt-2"
                                  style={{ padding: "7px 12px" }}
                                >
                                  <h6>{e.tag_unit}</h6>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  textAlign="right"
                                  className="grid-switch-unit"
                                >
                                  <Switch
                                    checked={deviceUnitReportRedux.includes(
                                      e.tag_unit
                                    )}
                                    name={switchColors[index]}
                                    style={{ float: "right" }}
                                    onClick={(event) =>
                                      handleSelectTagUnitReport(
                                        e.tag_unit,
                                        event,
                                        e
                                      )
                                    }
                                    sx={{
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#828282",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#D9D9E5 !important",
                                      },
                                      "& .Mui-checked": {
                                        "& .MuiSwitch-thumb": {
                                          bgcolor:
                                            switchColors[
                                              index % switchColors.length
                                            ],
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#E2D9F2 !important",
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                    </Box>
                  </div>

                  <div className="mt-3">
                    <button
                      className="export-btn"
                      style={{ marginTop: "117px" }}
                    >
                      EXPORT ENERGY DATA
                    </button>
                  </div>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                className="consumption-table-box"
              >
                <Box className="consumerData-box" style={{ padding: "0px" }}>
                  <div style={{ minHeight: "330px" }}>
                  <div className="spinner-container">
                        {isLoadingReport && (
                          <div className="spinner">
                            <img src={images.spinner} alt="Spinner" />
                          </div>
                        )}
                       <MultiAxisLineChart
                      fisrtLineChartReportData={fisrtLineChartReportData}
                      secondeLineChartReportData={secondeLineChartReportData}
                      selectFirstUnit={selectFirstUnit}
                      selectSecondUnit={selectSecondUnit}
                    />
                      </div>
                   
                  </div>
                  <div className="d-flex justify-content-between m-3">
                    <select
                      className="form-input-class"
                      style={{
                        padding: "0px 14px",
                        borderRadius: "4px",
                        height: "40px",
                      }}
                      id="selectedClass"
                      value={selectFirstUnit}
                      onChange={(e) => setSelectFirstUnit(e.target.value)}
                      required
                    >
                      <option value="none" selected disabled hidden>
                        select unit
                      </option>
                      {deviceUnitReportRedux
                        ? deviceUnitReportRedux.map((e) => (
                            <option value={e}>{e}</option>
                          ))
                        : []}
                    </select>

                    <select
                      className="form-input-class"
                      style={{
                        padding: "0px 14px",
                        borderRadius: "4px",
                        height: "40px",
                      }}
                      id="selectedClass"
                      value={selectSecondUnit}
                      onChange={(e) => setSelectSecondUnit(e.target.value)}
                      required
                    >
                      <option value="none" selected disabled hidden>
                        select unit
                      </option>
                      {deviceUnitReportRedux
                        ? deviceUnitReportRedux.map((e) => (
                            <option value={e}>{e}</option>
                          ))
                        : []}
                    </select>
                  </div>
                  <div>
                    <Table className="source-table">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>

                          {reportOverViewData.length
                            ? reportOverViewData.map((row) => {
                              const formattedValue = row.tag_unit.toFixed(2);
                              return <TableCell>{formattedValue}</TableCell>
                              })
                            : []}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Min</TableCell>
                          {reportOverViewData.length
                            ? reportOverViewData.map((row) => {
                              const formattedValue1 = row.min_value.toFixed(2);
                              return <TableCell>{formattedValue1}</TableCell>
                             
                              })
                            : []}
                        </TableRow>
                        <TableRow>
                          <TableCell>Max</TableCell>
                          {reportOverViewData.length
                            ? reportOverViewData.map((row) => {
                              const formattedValue2 = row.max_value.toFixed(2);
                              return <TableCell>{formattedValue2}</TableCell>
                    
                              })
                            : []}
                        </TableRow>
                        <TableRow>
                          <TableCell>Mean</TableCell>
                          {reportOverViewData.length
                            ? reportOverViewData.map((row) => {
                              const formattedValue3 = row.mean_value.toFixed(2);
                              return <TableCell>{formattedValue3}</TableCell>
                               
                              })
                            : []}
                        </TableRow>
                        <TableRow>
                          <TableCell>Std. Dev</TableCell>
                          {reportOverViewData.length
                            ? reportOverViewData.map((row) => {
                              const formattedValue4 = row.std_dev_value.toFixed(2);
                              return <TableCell>{formattedValue4}</TableCell>
                              
                              })
                            : []}
                        </TableRow>
                        <TableRow>
                          <TableCell>All Time Min</TableCell>
                          {reportOverViewData.length
                            ? reportOverViewData.map((row) => {
                              const formattedValue5 = row.all_time_min_value.toFixed(2);
                              return <TableCell>{formattedValue5}</TableCell>
                               
                              })
                            : []}
                        </TableRow>
                        <TableRow>
                          <TableCell>All Time Max</TableCell>
                          {reportOverViewData.length
                            ? reportOverViewData.map((row) => {
                              const formattedValue6 = row.all_time_max_value.toFixed(2);
                              return <TableCell>{formattedValue6}</TableCell>
                               
                              })
                            : []}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>{" "}
                </Box>
              </Grid>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default OverViewAnalytics;
