import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(CategoryScale, LinearScale, BarElement, annotationPlugin, Title, Tooltip, Legend);

export function BarChartPOP({average, zoomChartData ,startTimeFormat, formatEndDate,zoomChartData1,devicePOPColors,startTimeFormat1,formatEndDate1}) {

  const backColors = zoomChartData.length &&devicePOPColors.length&&  devicePOPColors.find((e)=>e.deviceId ==  zoomChartData[0].device_id
  )
  // Calculate the average tag_value
  const averageTagValue = zoomChartData.length && zoomChartData1.length
    ? (zoomChartData[0].tag_value + zoomChartData1[0].tag_value) / 2
    : 0;
  
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations:average ? [
          {
            type: "line",
            mode: "horizontal",
            scaleID: "y",
            value: averageTagValue,
            borderColor: "green", // Change the borderColor to the desired color for the average line
            borderWidth: 2,
            borderDash: [5, 5],
            label: {
              content: "Average",
              enabled: true,
              position: "right",
            },
          },
        ] : []
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    elements: {
      bar: {
        barPercentage: 1,
        categoryPercentage: 1,
      },
    },
  };
  

  const labels = [`${startTimeFormat}  -  ${formatEndDate} ${startTimeFormat1}  -  ${formatEndDate1} `];

  const data = {
    labels,
    datasets: [
      {
        label: zoomChartData.length ? zoomChartData[0].device_name : '',
        data: [zoomChartData.length ? zoomChartData[0].tag_value : 0],
        backgroundColor: '#C0255F',
        stack: 'Stack 0',
        barPercentage: 1
       
      },
      {
        label: zoomChartData1.length ? zoomChartData1[0].device_name : '',
        data: [zoomChartData1.length ? zoomChartData1[0].tag_value : 0],
        backgroundColor: '#E66595',
        stack: 'Stack 1',
        barPercentage: 1
      
      },
    ],
  };

  return (
    <div style={{ width: '100%', height: '300px' }}>
      <Bar options={options} data={data} />
    </div>
  );
}
