import React, { useEffect } from 'react';
import { connect } from 'mqtt';

function Mqqtdata({ setMqttTagData, topics, overViewTagData }) {
    console.log("topics", topics);

    useEffect(() => {
        const mqttBrokerPort = 8883;
        const mqttBrokerAddress = 'mqtt.hikartech.in';
        const mqttUsername = 'hikar'; // Replace 'your-username' with the actual username
        const mqttPassword = 'Secure@098'; // Replace 'your-password' with the actual password

        const tagTime = new Date().toLocaleTimeString('en-US', {
            timeZone: 'Asia/Kolkata',
            hour12: false,
        });

        const mqttClient = connect(`wss://${mqttBrokerAddress}/mqtt`, {
            username: mqttUsername,
            password: mqttPassword,
            rejectUnauthorized: false
        });

        mqttClient.on('connect', () => {
            console.log('Connected to MQTT broker');
        });

        mqttClient.on('message', (topic, message) => {
            try {
                const messageString = message.toString();
                console.log("messageString", messageString);
                const tags = topic.split("/").slice(-2).join("/");
                const data = JSON.parse(`[${messageString.replace(/}{/g, '},{')}]`);

                const formattedMessage = data.map(obj => {
                    const device_tag = Object.keys(obj)[0];
                    const tagUnit = overViewTagData ? overViewTagData.find((f) => f.device__uid == tags && device_tag == f.device_tag) : "";
                    const tagValue = obj[device_tag];
                    return {
                        tagValue: parseFloat(tagValue),
                        device__uid: tags,
                        device_tag,
                        [device_tag]: tagValue,
                        tagUnit: tagUnit ? tagUnit.tag_unit : "",
                        tagTime
                    };
                });

                setMqttTagData(prevData => ({
                    ...prevData,
                    [tags]: formattedMessage
                }));

                console.log("formattedMessage", formattedMessage);
                // Handle the received message here
            } catch (error) {
                console.log("Error handling message: ", error);
            }
        });

        mqttClient.on('error', (error) => {
            console.log("MQTT error: ", error);
        });

        const intervalId = setInterval(() => {
            // Code to execute every 3 seconds
            mqttClient.subscribe(topics);
        }, 3000);

        return () => {
            clearInterval(intervalId); // Clear the interval when the component is unmounted
            mqttClient.end(); // Disconnect the MQTT client
        };
    }, [topics]);

    return (
        <div>
            {/* Your component JSX */}
        </div>
    );
}

export default Mqqtdata;
