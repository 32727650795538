import React, { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ToastContainer, toast } from "react-toastify";
import { MuiFileInput } from "mui-file-input";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { images } from "../../../../config/images";
import CheckIcon from "@mui/icons-material/Check";
import {
  getTagConfiguration,
  getAlarmConfiguration,
  exportCSVConfiguration,
  importCSVConfiguration,
  addConfigurationApi,
  updateConfigurationApi,
  getDeviceById,
} from "../../service";
import TabConfigure1 from "./ConfigureExpand/TabConfigure1";
import "./Configure.css";
import { async } from "q";

function Configure() {
  const [value, setValue] = React.useState("1");
  const [alarmConfData, setAlarmConfData] = useState([]);
  const [tagConfData, setTagConfData] = useState([]);
  const [openImportCSVModal, setOpenImportCSVModal] = useState("");
  const [openTagConfigurationModal, setTagConfigurationModal] = useState("");
  const [openAlarmConfigurationModal, setAlarmConfigurationMOdal] =
    useState("");
  const [file, setFile] = React.useState(null);
  const [isDisable, setDisable] = useState(false)

  console.log("isDisable", isDisable);
  const navigate = useNavigate();
  const location = useParams();

  console.log("tagConfData", tagConfData);

  const initialData = {
    tag_name: "",
    tag_comment: "",
    device_tag: "",
    tag_unit: "",
    tag_read_write_type: "",
    signal_type: "",
    low_range: "",
    high_range: "",
    alert: "",
    alarm_mail: "",
    alert_priority: "",
    alert_message_1: "",
    alert_message_0: "",
    setpoint_hi: "",
    setpoint_lo: "",
    setpoint_hh: "",
    setpoint_ll: "",
    hys: "",
    almsup: "",
    device: location.id,
  };

  const [addConfiguration, setAddConfiguration] = useState(initialData);
  const [editIcone, setEditIcone] = useState({});
  const [tagConfId, setTagConfId] = useState("");
  const [editIconAlert, setEditIconAlewrt] = useState({});
  const [alarmConfId, setAlarmConfId] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [lockStatus, setLoackStatus] = useState(true);

  const containerRef = useRef(null);

  const handleEditRow = (id) => {
    setTagConfId(id);
    if (!lockStatus) {
      setEditIcone((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };

  const handleEditAlarmRow = (id) => {
    setAlarmConfId(id);
    if (!lockStatus) {
      setEditIconAlewrt((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };

  const handleInputChange = (id, field, value) => {
    setTagConfData((prev) => {
      const index = prev.findIndex((e) => e.id === id);
      const updatedRow = { ...prev[index], [field]: value };
      const updatedData = [...prev];
      updatedData[index] = updatedRow;
      return updatedData;
    });
  };

  const handleAlarmInputChange = (id, field, value) => {
    setAlarmConfData((prev) => {
      const index = prev.findIndex((e) => e.id === id);
      const updatedRow = { ...prev[index], [field]: value };
      const updatedData = [...prev];
      updatedData[index] = updatedRow;
      return updatedData;
    });
  };

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value == "2") {
      scrollRight();
    }
  }, [value]);

  const downloadCSV = (data) => {
    fetch(data)
      .then((response) => response.blob())
      .then((blob) => {
        const csvData = new Blob([blob], { type: "text/csv" });
        const csvUrl = URL.createObjectURL(csvData);
        window.open(csvUrl);
      });
  };

  const getDeviceName = async () => {
    const res = await getDeviceById(location.id);
    if (res.status == 200) {
      setDeviceName(res.data.device_name);
    }
    console.log("res", res.data);
  };

  useEffect(() => {
    getDeviceName();
  }, []);

  const getAlarmConfigurationData = async () => {
    const params = {
      device: location.id,
    };
    try {
      const resp = await getAlarmConfiguration(params);
      console.log("resp", resp);
      if (resp.status == 200) {
        setAlarmConfData(resp.data.payload);
      }
    } catch (error) {
      console.log("error", error.response.data.message);
      // notifyError(error.response.data.message);
    }
  };

  const getTagConfigurationData = async () => {
    const params = {
      device: location.id,
    };
    try {
      const resp = await getTagConfiguration(params);
      if (resp.status == 200) {
        setTagConfData(resp.data.payload);
      }
    } catch (error) {
      console.log("error", error.response.data.message);
      // notifyError(error.response.data.message);
    }
  };

  useEffect(() => {
    if (value == "1") {
      getTagConfigurationData();
    } else {
      getAlarmConfigurationData();
    }
  }, [value]);

  const submitAddConfiguration = async () => {
    const resp = await addConfigurationApi(addConfiguration);
    if (resp.status == 200) {
      notifySuccess(resp.data.message);
      // handleOpenTagConfiguration();
      getTagConfigurationData();
      getAlarmConfigurationData();
    }
  };

  const handleExportCSVConfiguration = async () => {
    const param = {
      device: location.id,
    };

    const resp = await exportCSVConfiguration(param);

    if (resp.status == 200) {
      console.log("resp", resp);

      const url = resp.data.payload.filename;
      const filename = url.split("/").pop();

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      notifySuccess(resp.data.message);
    }
  };
  const handleChangeFile = (newFile) => {
    setFile(newFile);
  };

  //Import CSV Modal
  const handleImportCSV = () => {
    if (lockStatus) {
      return true;
    }

    setOpenImportCSVModal(!openImportCSVModal);
    setFile(null);
  };

  //TagConfiguration Modal
  // const handleOpenTagConfiguration = () => {
  //     setTagConfigurationModal(!openTagConfigurationModal);
  //     setAddConfiguration(initialData)
  // }
  //AlarmConfiguration Modal
  const handleOpenAlarmConfiguration = () => {
    setAlarmConfigurationMOdal(!openAlarmConfigurationModal);
  };

  //Add configuration
  const handleChangeAddConfiguration = (e) => {
    setAddConfiguration({
      ...addConfiguration,
      [e.target.name]: e.target.value,
    });
  };

  function isCSVFile(fileName) {
    if (fileName.endsWith(".csv")) {
      return false;
    } else {
      notifyError("Please Add CSV file");
      return true;
    }
  }

  const handleImportCSVConfiguration = async () => {
    if (file == null) {
      notifyError("Please Add CSV file");
      return true;
    }

    // check CSV file or Not
    isCSVFile(file.name);

    try {
      const formData = new FormData();
      formData.append("device", location.id);
      formData.append("excel_file", file);
      const resp = await importCSVConfiguration(formData);

      if (resp.status == 200) {
        notifySuccess("Import successfully");
        handleImportCSV();
        setFile(null);
        getTagConfigurationData();
        getAlarmConfigurationData();
      }
    } catch (error) {
      console.log("error", error);
      notifyError(error.response.data.message);
    }
  };

  const submitupdateTagConfiguration = async (tagId) => {
    const param = tagConfData.find((f) => f.id == tagId);

    const resp = await updateConfigurationApi(tagId, param);

    if (resp.status == 200) {
      setEditIcone((prev) => ({ ...prev, [tagId]: !prev[tagId] }));
      notifySuccess("update Successfully");
    }
  };

  const submitupdateAlarmConfiguration = async (tagId) => {
    const param = alarmConfData.find((f) => f.id == alarmConfId);
    const resp = await updateConfigurationApi(tagId, param);
    if (resp.status == 200) {
      setEditIconAlewrt((prev) => ({ ...prev, [tagId]: !prev[tagId] }));
      notifySuccess("update Successfully");
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      let scrollAmount = 1010; // Default scroll amount

      // Adjust the scroll amount based on the viewport width
      if (window.matchMedia("(max-width: 1024px)").matches) {
        scrollAmount = 940; // Adjusted scroll amount for tablet screens
      }

      containerRef.current.scrollLeft += scrollAmount;
    }
  };


  const calculateDisableState = () => {
    let isDisabled = false;

    for (const e of tagConfData) {
      if (!(e.tag_name && e.tag_name.length <= 18)) {
        isDisabled = true;
        break;
      }

      if (!(e.tag_comment && e.tag_comment.length <= 24)) {
        isDisabled = true;
        break;
      }

      if (!(e.tag_unit && e.tag_unit.length <= 10)) {
        isDisabled = true;
        break;
      }

      if (!(e.tag_read_write_type && ["RD", "RW"].includes(e.tag_read_write_type))) {
        isDisabled = true;
        break;
      }

      if (!(e.signal_type && ["AI", "AO", "DI", "DO"].includes(e.signal_type))) {
        isDisabled = true;
        break;
      }

      if (!(["AI", "AO"].includes(e.signal_type) ? e.low_range && Number(e.low_range) < Number(e.high_range) : true)) {
        isDisabled = true;
        break;
      }

      if (!((["AI", "AO"].includes(e.signal_type)) ? e.high_range && Number(e.low_range) < Number(e.high_range) : true)) {
        isDisabled = true;
        break;
      }

      if (!(e.alert && ["YES", "NO"].includes(e.alert))) {
        isDisabled = true;
        break;
      }

      if (!(e.alert && e.alert != "NO" ? ["YES", "NO"].includes(e.alarm_mail) : true)) {
        isDisabled = true;
        break;
      }

      if (!(e.alert && e.alert != "NO" ? ["HIGH", "MEDIUM", "LOW"].includes(e.alert_priority) : true)) {
        isDisabled = true;
        break;
      }

      if (!(e.alert && e.alert != "NO" ? e.alert_message_1 && e.alert_message_1.length <= 24 : true)) {
        isDisabled = true;
        break;
      }

      if (!(e.alert && e.alert != "NO" ? e.alert_message_0 && e.alert_message_0.length <= 24 : true)) {
        isDisabled = true;
        break;
      }

      if (!(e.alert && e.alert != "NO" ? e.signal_type == "AI" && e.setpoint_hh && Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range) : true)) {
        isDisabled = true;
        break;
      }

      if (!(e.alert && e.alert != "NO" ? e.signal_type == "AI" && e.setpoint_hi && Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range) : true)) {
        isDisabled = true;
        break;
      }

      if (!(e.alert && e.alert != "NO" ? e.signal_type == "AI" && e.setpoint_lo && Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range) : true)) {
        isDisabled = true;
        break;
      }

      if (!(e.alert && e.alert != "NO" ? e.signal_type == "AI" && e.setpoint_ll && Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range) : true)) {
        isDisabled = true;
        break;
      }

      if (!(e.alert && e.alert != "NO" ?  e.signal_type == "AI" && e.hys && 1 <= Number(e.hys) && Number(e.hys) <= 10 : true )) {
        isDisabled = true;
        break;
      }

      if (!(e.alert && e.alert != "NO" ? ["TRUE", "FALSE"].includes(e.almsup) : true)) {
        isDisabled = true;
        break;
      }
    }

    setDisable(isDisabled);
  };

  useEffect(() => {
    calculateDisableState();
  }, [tagConfData]);


  return (
    <>
      <div>
        <ToastContainer autoClose={4000}></ToastContainer>
      </div>
      <div className="containerWrap">
        {/* <div>Configure</div> */}
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap configure">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => navigate("/deviceManagement")}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                className="deviceManagementTitle"
                onClick={() => navigate("/deviceManagement")}
                gutterBottom
                style={{ margin: "0" }}
              >
                {deviceName}
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              {/* <button className='addDevice-button' onClick={handleOpenTagConfiguration}>Add Configuration</button> */}
              {/* {value == 2 && <button className='addDevice-button bridge' onClick={handleOpenAlarmConfiguration}>Add Alarm</button>} */}
              <button
                className={
                  isDisable
                    ? "addDevice-button-disable" : "addDevice-button bridge"

                }
                onClick={() =>
                  !isDisable
                    ? navigate(
                      `/deviceManagement/configure/device/bridge/${location.id}`
                    )
                    : null
                }
              >
                Bridge
                <img src={images.bridgeImg} alt="" />
              </button>
              {/* <button className='addDevice-button bridge' onClick={handleExportCSVConfiguration}><a href={exportCSV} download style={{ color: "white", textDecoration: "none" }}>Export CSV<img src={images.csvImg} alt="" /></a></button> */}
              <button
                className="addDevice-button bridge"
                onClick={handleExportCSVConfiguration}
              >
                Export CSV
                <img src={images.csvImg} alt="" />
              </button>
              <button
                className="addDevice-button bridge import"
                onClick={handleImportCSV}
              >
                Import CSV
                <img src={images.csvImg} alt="" />
              </button>
              {/* <img src={images.excelLogo} alt="" className='cloudActiveImg' /> */}
            </div>
          </div>

          {/* =============== Import CSV Modal Start =============== */}
          {openImportCSVModal && (
            <Card className="importCSVModal">
              <Typography
                variant="h6"
                gutterBottom
                className="importCSVModal-text"
              >
                Import CSV
              </Typography>
              <Box className="ImportCSV Input">
                <MuiFileInput
                  value={file}
                  onChange={handleChangeFile}
                  placeholder="Insert a file"
                />
              </Box>
              {/* <input class="form-control" type="file" id="formFile" style={{marginBottom:'20px'}}/> */}
              <Box className="ImportCSV-BtnWrap">
                <button
                  className="ImportCSV-AddBtn"
                  onClick={handleImportCSVConfiguration}
                >
                  Add
                </button>
                <button
                  className="ImportCSV-CancelBtn"
                  onClick={handleImportCSV}
                >
                  Cancel
                </button>
              </Box>
            </Card>
          )}
          {/* =============== Import CSV Modal End =============== */}
          {/* =============== Tag configuration Modal Start =============== */}
          {/* =============== Alarm configuration Modal End =============== */}
          <TabContext value={value}>
            <div className="device-management tagWrap">
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    bgcolor: "#f2f2f2",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    display: "inline-flex",
                    gap: "0px",
                    width: "fit-content",
                  }}
                >
                  <Tab
                    label="Tag Configuration"
                    value="1"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#1C407B",
                        color: "#fff",
                        textTransform: "capitalize",
                        fontSize: "18px",
                      },
                      "&:not(.Mui-selected)": {
                        backgroundColor: "#fff",
                        color: "#001323",
                        textTransform: "capitalize",
                        fontSize: "18px",
                      },
                    }}
                  />
                  <Tab
                    label="Alarm Configuration"
                    value="2"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#1C407B",
                        color: "#fff",
                        textTransform: "capitalize",
                        fontSize: "18px",
                      },
                      "&:not(.Mui-selected)": {
                        backgroundColor: "#fff",
                        color: "#001323",
                        textTransform: "capitalize",
                        fontSize: "18px",
                      },
                    }}
                  />
                </TabList>
              </Box>
              <div className="device-managemnet-Btnwrap">
                <div
                  className="device-managemnegt btnwrap"
                  onClick={() => setLoackStatus(!lockStatus)}
                >
                  <button
                    className={`device-managemnet ${lockStatus ? "unlock-button" : "unlock-btn"
                      }`}
                  >
                    Unlock
                    {lockStatus ? (
                      <img
                        src={images.deviceManagementUnlockDark}
                        alt=""
                        className="device-mangement unlockImg"
                      />
                    ) : (
                      <img
                        src={images.deviceManagementUnlockWhite}
                        alt=""
                        className="device-mangement unlockImg"
                      />
                    )}
                  </button>
                </div>
                <div
                  className="device-managemnegt btnwrap"
                  onClick={() => setLoackStatus(!lockStatus)}
                >
                  <button
                    className={`device-management ${!lockStatus ? "lock-btn" : "lock-button"
                      }`}
                  >
                    Lock
                    {!lockStatus ? (
                      <img
                        src={images.deviceManagementLockDark}
                        alt=""
                        className="device-mangement unlockImg"
                      />
                    ) : (
                      <img
                        src={images.deviceManagementLockWhite}
                        alt=""
                        className="device-mangement unlockImg"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
            {/* =============== TAG CONFIGURATION TABLE START =============== */}
            <TabPanel value="1" className="confi-tabs">
              <div className="tableResponsive d-flex confi-table-group">
                {/* =============== confi-table-40 Table start =============== */}
                <div className="confi-table-40">
                  <Table aria-label="" className="deviceManagementTable">
                    <TableHead>
                      <TableRow className="deviceManagementTable-title">
                        <TableCell className="sticky-header">
                          <div className="">Edit</div>
                        </TableCell>
                        <TableCell>
                          <div>Tag Name</div>
                        </TableCell>
                        <TableCell>
                          <div>Tag Comment</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* =============== confi-table-40 Table Body start =============== */}
                    <TableBody>
                      {tagConfData.length ? (
                        tagConfData.map((e) => {
                          return (
                            <TableRow className="deviceManagementTable-data">
                              <TableCell
                                style={{ backgroundColor: "#fff" }}
                                className="edit-column"
                              >
                                {editIcone[e.id] ? (
                                  <CheckIcon
                                    style={{ width: "20px" }}
                                    onClick={() =>
                                      submitupdateTagConfiguration(e.id)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={images.editImg}
                                    alt=""
                                    style={{ width: "20px" }}
                                    onClick={() => handleEditRow(e.id)}
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                className={
                                  e.tag_name
                                    ? e.tag_name.length <= 18
                                      ? ""
                                      : "bgred"
                                    : "bgred"
                                }
                              >
                                <input
                                  type="text"
                                  id="fname"
                                  className="configure-input"
                                  disabled={!editIcone[e.id]}
                                  value={e.tag_name}
                                  onChange={(event) =>
                                    handleInputChange(
                                      e.id,
                                      "tag_name",
                                      event.target.value
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell
                                className={
                                  e.tag_comment
                                    ? e.tag_comment.length <= 24
                                      ? ""
                                      : "bgred"
                                    : "bgred"
                                }
                              >
                                <input
                                  type="text"
                                  className="configure-input"
                                  value={e.tag_comment}
                                  onChange={(event) =>
                                    handleInputChange(
                                      e.id,
                                      "tag_comment",
                                      event.target.value
                                    )
                                  }
                                  disabled={!editIcone[e.id]}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <>
                          <TableRow className="deviceManagementTable-data">
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow className="deviceManagementTable-data">
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                    {/* =============== confi-table-40 Table Body end =============== */}
                  </Table>
                </div>
                {/* =============== confi-table-40 Table end =============== */}
                {/* =============== confi-table-60 Table start =============== */}
                <div className="confi-table-60 tab">
                  <Table aria-label="" className="deviceManagementTable ">
                    <TableHead>
                      <TableRow className="deviceManagementTable-title">
                        <TableCell>
                          <div>Tag Unit</div>
                        </TableCell>
                        <TableCell>
                          <div>Tag Read Write Type</div>
                        </TableCell>
                        <TableCell>
                          <div>Signal Type</div>
                        </TableCell>
                        <TableCell>
                          <div>Low Range</div>
                        </TableCell>
                        <TableCell>
                          <div>High Range</div>
                        </TableCell>
                        <TableCell>
                          <div>Alert</div>
                        </TableCell>
                        <TableCell>
                          <div>Alarm Email</div>
                        </TableCell>
                        <TableCell>
                          <div>Alert Priority</div>
                        </TableCell>
                        <TableCell>
                          <div>Alertmessage1</div>
                        </TableCell>
                        <TableCell>
                          <div>Alertmessage0</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_HH</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_HI</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_LO</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_LL</div>
                        </TableCell>
                        <TableCell>
                          <div>HYS</div>
                        </TableCell>
                        <TableCell>
                          <div>ALMSUP</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* =============== confi-table-60 Table Body start =============== */}
                    <TableBody>
                      {tagConfData.length ? (
                        tagConfData.map((e) => {
                          return (
                            <>
                              <TableRow className="deviceManagementTable-data">
                                <TableCell
                                  className={
                                    e.tag_unit
                                      ? e.tag_unit.length <= 10
                                        ? ""
                                        : "bgred"
                                      : "bgred"
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.tag_unit}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "tag_unit",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    ["RD", "RW"].includes(e.tag_read_write_type)
                                      ? ""
                                      : "bgred"
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.tag_read_write_type}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "tag_read_write_type",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    ["AI", "AO", "DI", "DO"].includes(
                                      e.signal_type
                                    )
                                      ? ""
                                      : "bgred"
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.signal_type}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "signal_type",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell className={["AI", "AO"].includes(e.signal_type) ? e.low_range ? Number(e.low_range) < Number(e.high_range) ? "" : "bgred" : "bgred" : ""}>
                                {/* <TableCell className={e.low_range ? Number(e.low_range) < Number(e.high_range) ? "" : "bgred" : "bgred"}> */}
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.low_range}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "low_range",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell className={["AI", "AO"].includes(e.signal_type) ? e.high_range ? Number(e.low_range) < Number(e.high_range) ? "" : "bgred" : "bgred" : ""}>
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.high_range}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "high_range",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    ["YES", "NO"].includes(e.alert)
                                      ? ""
                                      : "bgred"
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.alert}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "alert",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? ["YES", "NO"].includes(e.alarm_mail) ? "" : "bgred" : ""
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.alarm_mail}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "alarm_mail",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? ["HIGH", "MEDIUM", "LOW"].includes(e.alert_priority) ? "" : "bgred" : ""
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.alert_priority}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "alert_priority",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ?
                                        e.alert_message_1 ?
                                          e.alert_message_1.length <= 24 ?
                                            "" : "bgred" : "bgred" : ""
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.alert_message_1}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "alert_message_1",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ?
                                        e.alert_message_0 ?
                                          e.alert_message_0.length <= 24 ?
                                            "" : "bgred" : "bgred" : ""
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.alert_message_0}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "alert_message_0",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? e.signal_type == "AI" ? (e.setpoint_hh ?
                                        (Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range)
                                          ? "" : "bgred")
                                        : "bgred") : "bgred" : "" : ""
                                  }
                                >
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.setpoint_hh}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "setpoint_hh",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? e.signal_type == "AI" ? e.setpoint_hi ?
                                        (Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range)
                                          ? "" : "bgred")
                                        : "bgred" : "bgred" : "" : ""
                                  }
                                >
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.setpoint_hi}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "setpoint_hi",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? e.signal_type == "AI" ? e.setpoint_lo ?
                                        (Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range)
                                          ? "" : "bgred")
                                        : "bgred" : "bgred" : "" : ""
                                  }
                                >
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.setpoint_lo}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "setpoint_lo",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? e.signal_type == "AI" ? e.setpoint_ll ?
                                        (Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range)
                                          ? "" : "bgred")
                                        : "bgred" : "bgred" : "" : ""
                                  }
                                >
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.setpoint_ll}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "setpoint_ll",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? e.signal_type == "AI" ? (e.hys ? 1 <= Number(e.hys) && Number(e.hys) <= 10 ? "" : "bgred" : "bgred") : "bgred" : "" : ""
                                  }
                                >
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.hys}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "hys",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? ["TRUE", "FALSE"].includes(e.almsup) ? "" : "bgred" : ""
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.almsup}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "almsup",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <TableRow className="deviceManagementTable-data">
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>

                          <TableRow className="deviceManagementTable-data">
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                    {/* =============== confi-table-60 Table Body start =============== */}
                  </Table>
                </div>
                {/* =============== confi-table-60 Table end =============== */}
              </div>
            </TabPanel>
            {/* =============== TAG CONFIGURATION TABLE END =============== */}

            {/* =============== ALARM CONFIGURATION TABLE START =============== */}
            <TabPanel value="2" className="confi-tabs">
              <div
                className="tableResponsive d-flex confi-table-group"
                ref={containerRef}
              >
                {/* =============== confi-table-40 Table start =============== */}
                <div className="confi-table-40">
                  <Table aria-label="" className="deviceManagementTable">
                    <TableHead>
                      <TableRow className="deviceManagementTable-title">
                        <TableCell className="sticky-header">
                          <div className="">Edit</div>
                        </TableCell>
                        <TableCell>
                          <div>Tag Name</div>
                        </TableCell>
                        <TableCell>
                          <div>Tag Comment</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* =============== confi-table-40 Table Body start =============== */}
                    <TableBody>
                      {tagConfData.length ? (
                        tagConfData.map((e) => {
                          return (
                            <TableRow className="deviceManagementTable-data">
                              <TableCell
                                style={{ backgroundColor: "#fff" }}
                                className="edit-column"
                              >
                                {editIcone[e.id] ? (
                                  <CheckIcon
                                    style={{ width: "20px" }}
                                    onClick={() =>
                                      submitupdateTagConfiguration(e.id)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={images.editImg}
                                    alt=""
                                    style={{ width: "20px" }}
                                    onClick={() => handleEditRow(e.id)}
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                className={
                                  e.tag_name
                                    ? e.tag_name.length <= 18
                                      ? ""
                                      : "bgred"
                                    : "bgred"
                                }
                              >
                                <input
                                  type="text"
                                  id="fname"
                                  className="configure-input"
                                  disabled={!editIcone[e.id]}
                                  value={e.tag_name}
                                  onChange={(event) =>
                                    handleInputChange(
                                      e.id,
                                      "tag_name",
                                      event.target.value
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell
                                className={
                                  e.tag_comment
                                    ? e.tag_comment.length <= 24
                                      ? ""
                                      : "bgred"
                                    : "bgred"
                                }
                              >
                                <input
                                  type="text"
                                  className="configure-input"
                                  value={e.tag_comment}
                                  onChange={(event) =>
                                    handleInputChange(
                                      e.id,
                                      "tag_comment",
                                      event.target.value
                                    )
                                  }
                                  disabled={!editIcone[e.id]}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <>
                          <TableRow className="deviceManagementTable-data">
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow className="deviceManagementTable-data">
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                    {/* =============== confi-table-40 Table Body end =============== */}
                  </Table>
                </div>
                {/* =============== confi-table-40 Table end =============== */}
                {/* =============== confi-table-60 Table start =============== */}
                <div className="confi-table-60 tab">
                  <Table aria-label="" className="deviceManagementTable ">
                    <TableHead>
                      <TableRow className="deviceManagementTable-title">
                        <TableCell>
                          <div>Tag Unit</div>
                        </TableCell>
                        <TableCell>
                          <div>Tag Read Write Type</div>
                        </TableCell>
                        <TableCell>
                          <div>Signal Type</div>
                        </TableCell>
                        <TableCell>
                          <div>Low Range</div>
                        </TableCell>
                        <TableCell>
                          <div>High Range</div>
                        </TableCell>
                        <TableCell>
                          <div>Alert</div>
                        </TableCell>
                        <TableCell>
                          <div>Alarm Email</div>
                        </TableCell>
                        <TableCell>
                          <div>Alert Priority</div>
                        </TableCell>
                        <TableCell>
                          <div>Alertmessage1</div>
                        </TableCell>
                        <TableCell>
                          <div>Alertmessage0</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_HH</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_HI</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_LO</div>
                        </TableCell>
                        <TableCell>
                          <div>Setpoint_LL</div>
                        </TableCell>
                        <TableCell>
                          <div>HYS</div>
                        </TableCell>
                        <TableCell>
                          <div>ALMSUP</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* =============== confi-table-60 Table Body start =============== */}
                    <TableBody>
                      {tagConfData.length ? (
                        tagConfData.map((e) => {
                          return (
                            <>
                              <TableRow className="deviceManagementTable-data">
                                <TableCell
                                  className={
                                    e.tag_unit
                                      ? e.tag_unit.length <= 10
                                        ? ""
                                        : "bgred"
                                      : "bgred"
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.tag_unit}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "tag_unit",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    ["RD", "RW"].includes(e.tag_read_write_type)
                                      ? ""
                                      : "bgred"
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.tag_read_write_type}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "tag_read_write_type",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    ["AI", "AO", "DI", "DO"].includes(
                                      e.signal_type
                                    )
                                      ? ""
                                      : "bgred"
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.signal_type}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "signal_type",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell className={e.low_range ? Number(e.low_range) < Number(e.high_range) ? "" : "bgred" : "bgred"}>
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.low_range}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "low_range",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell className={e.high_range ? Number(e.low_range) < Number(e.high_range) ? "" : "bgred" : "bgred"}>
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.high_range}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "high_range",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    ["YES", "NO"].includes(e.alert)
                                      ? ""
                                      : "bgred"
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.alert}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "alert",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? ["YES", "NO"].includes(e.alarm_mail) ? "" : "bgred" : ""
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.alarm_mail}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "alarm_mail",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? ["HIGH", "MEDIUM", "LOW"].includes(e.alert_priority) ? "" : "bgred" : ""
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.alert_priority}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "alert_priority",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ?
                                        e.alert_message_1 ?
                                          e.alert_message_1.length <= 24 ?
                                            "" : "bgred" : "bgred" : ""
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.alert_message_1}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "alert_message_1",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ?
                                        e.alert_message_0 ?
                                          e.alert_message_0.length <= 24 ?
                                            "" : "bgred" : "bgred" : ""
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.alert_message_0}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "alert_message_0",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? e.signal_type == "AI" ? (e.setpoint_hh ?
                                        (Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range)
                                          ? "" : "bgred")
                                        : "bgred") : "bgred" : "" : ""
                                  }
                                >
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.setpoint_hh}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "setpoint_hh",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? e.signal_type == "AI" ? e.setpoint_hi ?
                                        (Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range)
                                          ? "" : "bgred")
                                        : "bgred" : "bgred" : "" : ""
                                  }
                                >
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.setpoint_hi}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "setpoint_hi",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? e.signal_type == "AI" ? e.setpoint_lo ?
                                        (Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range)
                                          ? "" : "bgred")
                                        : "bgred" : "bgred" : "" : ""
                                  }
                                >
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.setpoint_lo}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "setpoint_lo",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? e.signal_type == "AI" ? e.setpoint_ll ?
                                        (Number(e.high_range) > Number(e.setpoint_hh) && Number(e.setpoint_hh) > Number(e.setpoint_hi) && Number(e.setpoint_hi) > Number(e.setpoint_lo) && Number(e.setpoint_lo) > Number(e.setpoint_ll) && Number(e.setpoint_ll) > Number(e.low_range)
                                          ? "" : "bgred")
                                        : "bgred" : "bgred" : "" : ""
                                  }
                                >
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.setpoint_ll}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "setpoint_ll",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? e.signal_type == "AI" ? (e.hys ? 1 <= Number(e.hys) && Number(e.hys) <= 10 ? "" : "bgred" : "bgred") : "bgred" : "" : ""
                                  }
                                >
                                  <input
                                    type="number"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.hys}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "hys",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    e.alert ?
                                      e.alert != "NO" ? ["TRUE", "FALSE"].includes(e.almsup) ? "" : "bgred" : ""
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    className="configure-input"
                                    disabled={!editIcone[e.id]}
                                    value={e.almsup}
                                    onChange={(event) =>
                                      handleInputChange(
                                        e.id,
                                        "almsup",
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <TableRow className="deviceManagementTable-data">
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>

                          <TableRow className="deviceManagementTable-data">
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                    {/* =============== confi-table-60 Table Body start =============== */}
                  </Table>
                </div>
                {/* =============== confi-table-60 Table end =============== */}
              </div>
            </TabPanel>
            {/* =============== ALARM CONFIGURATION TABLE END =============== */}
          </TabContext>
        </Container>
      </div>
    </>
  );
}

export default Configure;
