import React, { useEffect } from "react";
import { Routes, Route, useParams, useNavigate, useLocation } from "react-router-dom";
// import { BrowserRouter as Router } from "react-router-dom";
import Dashboard1 from "../container/Dashboard1/Dashboard1";
import Dashboard from "../container/Dashboard/Dashboard";
import UserProfile from "../container/userProfile/UserProfile";
import SuperAdminDashboard from "../container/SuperAdminDashboard/SuperAdminDashboard";
import SubscriptionManagement from "../container/SuperAdminDashboard/SubscriptionManagement/SubscriptionManagement";
import HikemmServices from "../container/SuperAdminDashboard/SubscriptionManagement/HikemmServices.js/HikemmServices";
import DeviceManagement from "../container/SuperAdminDashboard/DeviceManagement/DeviceManagement";
import Configure from "../container/SuperAdminDashboard/DeviceManagement/Configure/Configure";
import Bridge from "../container/SuperAdminDashboard/DeviceManagement/Configure/Bridge/Bridge";
import UserManagement from "../container/SuperAdminDashboard/UserManagement/UserManagement";
import AccessManagement from "../container/SuperAdminDashboard/Access Management/AccessManagement";
import AddAccesssManagementByID from "../container/SuperAdminDashboard/Access Management/AddAccessManagement/AddAccesssManagementByID";
import AddAccessManagement from "../container/SuperAdminDashboard/Access Management/AddAccessManagement/AddAccessManagement";
import ManageDepartment from "../container/SuperAdminDashboard/Access Management/ManageDepartment/ManageDepartment";
import PlantManagement from "../container/SuperAdminDashboard/PlantManagement/PlantManagement";
import CompanyByPlantmanagement from "../container/SuperAdminDashboard/PlantManagement/CompanyByPlantmanagement";
import AlarmManagement from "../container/SuperAdminDashboard/AlarmManagement/AlarmManagement";
import AlarmManagementConfiguration from "../container/SuperAdminDashboard/AlarmManagement/AlarmMangementConfiguration/AlarmManagementConfiguration";
import ReportManagement from "../container/SuperAdminDashboard/ReportManagement/ReportManagement";
import ReportManagementConfiguration from "../container/SuperAdminDashboard/ReportManagement/ReportManagementConfiguration/ReportManagementConfiguration";
import ProcessDashboard from "../container/ProcessDashboard/ProcessDashboard";
import OverView from "../container/ProcessDashboard/OverView/OverView";
import OverViewAnalytics from "../container/ProcessDashboard/OverView/OverViewAnalytics";
import Source from "../container/ProcessDashboard/Source/Source";
import Consumption from "../container/ProcessDashboard/Consumption/Consumption";
// import Analytics from "../container/ProcessDashboard/AdvanceAnalytics.js/Analytics";
import ClientManagement from "../container/SuperAdminDashboard/ClientManagement/ClientManagement";
import Analytics from "../container/ProcessDashboard/AdvanceAnalytics/Analytics";
import AnalyticsProduction from "../container/ProcessDashboard/AdvanceAnalytics/Production";
import Plan from "../container/ProcessDashboard/AdvanceAnalytics/PlanVsActual";
import EnergyCost from "../container/ProcessDashboard/AdvanceAnalytics/EnergyCost";
import CO2 from "../container/ProcessDashboard/AdvanceAnalytics/CO2";
import Alarm from "../container/ProcessDashboard/Alarm/Alarm";
import Configration from "../container/ProcessDashboard/Configration/Configration";
import ConfigrationOverView from "../container/ProcessDashboard/ConfigrationOverView/ConfigrationOverView";
import ServiceCall from "../container/ProcessDashboard/ServiceCall/ServiceCall";
import ConfigrationAnalytics from "../container/ProcessDashboard/AdvanceAnalytics/ConfigrationAnalytics";
import { useDispatch } from "react-redux";
import { clearUserDetails } from "../container/Login/slice/userSlice";
import ConfigrationSource from "../container/ProcessDashboard/Source/ConfigrationSource";
import ConfigrationConsumption from "../container/ProcessDashboard/Consumption/ConfigrationConsumption";
import SourceAlarm from "../container/ProcessDashboard/Alarm/SourceAlarm";
import ConsumptionAlarm from "../container/ProcessDashboard/Alarm/ConsumptionAlarm";
import OverviewAlarm from "../container/ProcessDashboard/Alarm/OverviewAlarm";
import CubeJsApiWrapper from "../container/Cubejs/CubejsApiWrapper";
import { clearProcessDashboarData } from "../container/ProcessDashboard/Consumption/slice/processDashboardSlice";
import { clearProcessDashboarDataSet } from "../container/ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import { clearOverviewData } from "../container/ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";
import ZoomInChart from "../container/ProcessDashboard/OverView/ZoomInEnergyBarChart";
import ZoomInPOPChart from "../container/ProcessDashboard/OverView/ZoomInEnergyBarChartPOP";
import CompanyByUserManagement from "../container/SuperAdminDashboard/UserManagement/CompanyByUserManagement";
import HikemPopUP from "../container/HikemmPopup";
import VmsCompanyDashboard from "../container/VMS/VmsCompanyDashboard/VmsCompanyDashboard";
import VmsPlantDashboard from "../container/VMS/VmsCompanyDashboard/VmsPlantDashboard/VmsPlantDashboard";
import VmsMachine from "../container/VMS/VmsCompanyDashboard/VmsMachine/VmsMachine";


const AuthenticationRoutes = () => {

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const end = location.pathname.indexOf("/", 1);
        const dynamicPart = location.pathname.substring(0, end);
        if (dynamicPart == "/redirect") {
            console.log("redirect");
            // dispatch(clearUserDetails());
            dispatch(clearProcessDashboarData());
            dispatch(clearProcessDashboarDataSet());
            dispatch(clearOverviewData());
        }

        if (dynamicPart == "/create-password") {
            console.log("redirect43434");
            // dispatch(clearUserDetails());
            dispatch(clearProcessDashboarData());
            dispatch(clearProcessDashboarDataSet());
            dispatch(clearOverviewData());
            window.location.reload(false)
        }

    }, [])

    return (
        <>
            <CubeJsApiWrapper>
                <Routes>
                    <Route exact path="/" element={<Dashboard1 />} />
                    <Route path="/companyDashboard/plant/:id" element={<Dashboard />} />
                    <Route path="/userprofile" element={<UserProfile />} />
                    <Route path="/superadmindashboard" element={<SuperAdminDashboard />} />
                    <Route path="/subscriptionManagement" element={<SubscriptionManagement />} />
                    <Route path="/hikemmservices" element={<HikemmServices />} />
                    <Route path="/deviceManagement" element={<DeviceManagement />} />
                    <Route path="/deviceManagement/configure/device/:id" element={<Configure />} />
                    <Route path="/deviceManagement/configure/device/bridge/:id" element={<Bridge />} />
                    <Route path="/usermanagement" element={<UserManagement />} />
                    <Route path="/accessManagement" element={<AccessManagement />} />
                    <Route path="/accessManagement/:departmentId" element={<AddAccesssManagementByID />} />
                    <Route path="/addaccessmanagement" element={<AddAccessManagement />} />
                    <Route path="/managedepartment" element={<ManageDepartment />} />
                    <Route path="/plantmanagement" element={<PlantManagement />} />
                    <Route path="/company/plantmanagement/:id" element={<CompanyByPlantmanagement />} />
                    <Route path="/company/usermanagement/:id" element={<CompanyByUserManagement/>} />
                    <Route path="/alarmmanagement" element={<AlarmManagement />} />
                    <Route path="/alarmmanagement/configuration" element={<AlarmManagementConfiguration />} />
                    <Route path="/reportmanagement" element={<ReportManagement />} />
                    <Route path="/reportmanagement/configuration" element={<ReportManagementConfiguration />}/>
                    <Route path="/company/:companyId/plant/plantprocessdashboard/:id" element={<ProcessDashboard />} />
                    <Route path="/overView/company/:companyId/plant/:id" element={<OverView />} />
                    <Route path="/overView-analytics/company/:companyId/plant/:id" element={<OverViewAnalytics />} />
                    <Route path="/source/company/:companyId/plant/:id" element={<Source />} />
                    <Route path="/consumption/company/:companyId/plant/:id" element={<Consumption />} />
                    <Route path="/analytics/company/:companyId/plant/:id" element={<Analytics />} />
                    <Route path="/clientmanagement" element={<ClientManagement />} />
                    <Route path="/analyticsproduction/company/:companyId/plant/:id" element={<AnalyticsProduction />} />
                    <Route path="/energycost/company/:companyId/plant/:id" element={<EnergyCost />} />
                    <Route path="/CO2/company/:companyId/plant/:id" element={<CO2 />} />
                    <Route path="/alarm/company/:companyId/plant/:id" element={<Alarm />} />
                    <Route path="/source-alarm/company/:companyId/plant/:id" element={<SourceAlarm />} />
                    <Route path="/consumption-alarm/company/:companyId/plant/:id" element={<ConsumptionAlarm />} />
                    <Route path="/Company/:companyId/plant/configration/:id" element={<Configration />} />
                    <Route path="/overview-configration/company/:companyId/plant/:id" element={<ConfigrationOverView />} />
                    <Route path="/servicecall/company/:companyId/plant/:id" element={<ServiceCall />} />
                    <Route path="/analytics-configration/company/:companyId/plant/:id" element={<ConfigrationAnalytics />} />
                    <Route path="/configrationsource/source/company/:companyId/plant/:id" element={<ConfigrationSource />} />
                    <Route path="/consumption-configration/company/:companyId/plant/:id" element={<ConfigrationConsumption />} />
                    <Route path="/overview-alarm/company/:companyId/plant/:id" element={<OverviewAlarm />} />
                    <Route path="/overviewanalytics/energybarchart/company/:companyId/plant/:id" element={<ZoomInChart />}/>
                    <Route path = "/overviewanalytics/energybarchartpop/company/:companyId/plant/:id" element={<ZoomInPOPChart/>}/>
                    <Route path = "/select" element={<HikemPopUP />}/>

                ====================================================VMS============================================================
                    <Route path = "/vms" element={<VmsCompanyDashboard />}/>
                    <Route path="/vms-plants" element={<VmsPlantDashboard />}/>
                    <Route path="/VmsMachine" element={<VmsMachine />}/>
                </Routes>
            </CubeJsApiWrapper>
        </>
    )
}

export default AuthenticationRoutes;    