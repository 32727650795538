import React, { useEffect, useState } from 'react';
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import { useCubeQuery } from '@cubejs-client/react';

const ConsumptionThresholdValue = ({ getDevice, setCubeThresoldValue, setCubeTagUnitSTKVA }) => {


    useEffect(() => {
        // Function to execute the cube query
        const executeCubeQuery = async () => {
            try {
                var currentTime = new Date();
                currentTime.setHours(currentTime.getHours() - 24);

                var previousTime = currentTime.toLocaleString('en-US', {
                    timeZone: 'UTC',
                    hour12: false,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/, '$3-$1-$2 $4:$5:$6');

                console.log("previousTime", getDevice);


                const cubeData = await Promise.all(
                    getDevice.map(async (array, i) => {
                        console.log("arrayarray", array);
                    const cubeDataValue = await cubejsApi.load({
                        "dimensions": [
                            "DashboardTaghistory.tagValue",
                            "DashboardTaghistory.createdAt"
                        ],
                        "filters": [
                            {
                                "member": "DashboardTaghistory.tag",
                                "operator": "equals",
                                "values": [array.id.toString()]
                            },
                            {
                                "member": "DashboardTaghistory.createdAt",
                                "operator": "inDateRange",
                                "values": [previousTime]
                            }
                        ],
                        "limit": 1
                    })
                        console.log("thresoldValuecubeDataValue", cubeDataValue);
                      const rawValue = cubeDataValue.rawData().length ? cubeDataValue.rawData()[0]["DashboardTaghistory.tagValue"] : 0;
                      return parseFloat(rawValue);
                     })
                );

                setCubeThresoldValue(cubeData)

            } catch (error) {
                // Handle any errors that occur during the cube query
                console.error('Cube query error:', error);
            }
        };

        // Execute the cube query immediately
        executeCubeQuery();

        // Set interval to execute the cube query every 1 hours
        var interval = setInterval(() => {
            executeCubeQuery();
        }, 60000 * 60);


        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(interval);
        };
    }, [getDevice]);
};

export default ConsumptionThresholdValue;
