import React, { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module

const HighchartsGraph = ({
    unitName,
    chartCubeData,
    getDevice,
    average,
    selectedColors,
    chartLineData,
    combineChartData,
    selectTimeRange
}) => {
    const categories = [unitName].concat(
        chartLineData?.map((e) => e.tagTime) || []
    );

    // Define custom colors for the lines
    const lineColors = ["#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#FFFF00"];

    const options = {
        chart: {
            type: "spline",
            scrollablePlotArea: {
                minWidth: 700,
                scrollPositionX: categories.length - 1, // Set scroll position to the rightmost end
            },
        },
        title: {
            text: null, // Remove the title
        },
        xAxis: {
            categories: categories,
            scrollbar: {
                enabled: true,
            },
        },
        yAxis: {
            title: {
                text: unitName,
            },
        },
        series: getDevice.length
            ? getDevice.map((e, index) => {
                const findDeviceId = selectedColors.find(
                    (f) => f.deviceId === e.device
                );
                return {
                    name: e.device__device_name,
                    data:
                        (combineChartData[index] &&
                            combineChartData[index].map((e) => e.tagValue)) ||
                        [],
                    color: findDeviceId ? findDeviceId.color : "",
                };
            })
            : [],
        colors: selectedColors.length ? selectedColors.map((e) => e.color) : [],
        exporting: {
            enabled: true, // Disable exporting options
        },
        credits: {
            enabled: false, // Disable credits
        },
    };

    useEffect(() => {
        options["chart"] = {
            type: "spline",
            scrollablePlotArea: {
                minWidth: 700,
                scrollPositionX: categories.length - 1, // Set scroll position to the rightmost end
            }
        }
    }, [getDevice, selectTimeRange])

    return (
        <div style={{ height: "500px" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default HighchartsGraph;
