import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "@mui/material";
import "../Source/Source.css";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { useCubeQuery } from "@cubejs-client/react";
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import Mqqtdata from "../Mqqtdata";

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function ConsumptionSlider({ deviceData, confData, getDevice }) {
  const [tag, setTag] = useState("");
  const [tagValue, setTagValue] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mqttTagData, setMqttTagData] = useState({});
  

  console.log("mqttTagData", mqttTagData);

  const tagName = confData.map((array) => array.map((obj) => obj.tag_name));
  const flatTagName = tagName.flat();


 
  // const sliderCubeData = useCubeQuery({
  //   "dimensions": [
  //     "DeviceConfigurationTagconfiguration.latestValue",
  //     "SuperadminDevice.deviceName",
  //     "DeviceConfigurationTagconfiguration.tagUnit"
  //   ],
  //   "order": {
  //     "DeviceConfigurationTagconfiguration.latestValue": "asc"
  //   },
  //   "filters": [
  //     {
  //       "member": "DeviceConfigurationTagconfiguration.tagName",
  //       "operator": "contains",
  //       "values": flatTagName.length ? flatTagName : [""]
  //     }
  //   ]
  // }, {subscribe: true})

  // console.log("sliderCubeData",sliderCubeData);

  // useEffect(() => {
  //   if (sliderCubeData.resultSet) {
  //     const tableData = sliderCubeData.resultSet.tablePivot().map((raw) => {
  //       return ({
  //         tagUnit: raw["DeviceConfigurationTagconfiguration.tagUnit"],
  //         deviceName: raw["SuperadminDevice.deviceName"],
  //         tagValue: raw["DeviceConfigurationTagconfiguration.latestValue"]
  //       })
  //     })

  //     setTagValue(tableData)
  //     console.log("tableData", tableData);
  //   }
  // }, [sliderCubeData.resultSet])

  console.log("confDataconfData", confData);
  
  useEffect(() => {
    if (deviceData.length) {
      const topicArray = deviceData.map((uid) => 'Hikar/DeviceUID/' + uid.device__uid);
      setTopics(topicArray)
    }
  }, [deviceData]);


  // useEffect(() => {
  //   if (deviceData.length && mqttTagData) {
      
  //     const tagName = mqttTagData[dashboard[0].position_2_name.device_uid]?.find(
  //       f => f[dashboard[0].position_2_name.tag_name]
  //     )?.[dashboard[0].position_2_name.tag_name];

  //     console.log("kpkpkpkpkpkpkpk", tagName);
  //   }
  // }, [mqttTagData, dashboard]);

  return (
    <>
      {deviceData.length && <Mqqtdata setMqttTagData={setMqttTagData} topics={topics} />}
    <Slider {...sliderSettings} className="my-slider-class">
      {deviceData
        ? deviceData.map((card, index) =>{

          const tagValuefirst = mqttTagData[card.device__uid] && confData.length ?
            mqttTagData[card.device__uid].find((f) => f && f[confData[index] && confData[index][0] && confData[index][0].tag_name])
            : {};

          const tagValue1 = tagValuefirst && Object.values(tagValuefirst)[0] ? Object.values(tagValuefirst)[0] : 0;
    




          const tagValuesecond = mqttTagData[card.device__uid] && confData.length ?
            mqttTagData[card.device__uid].find((f) => f && f[confData[index] && confData[index][1] && confData[index][1].tag_name])
            : {};

          const tagValue2 = tagValuesecond && Object.values(tagValuesecond)[0] ? Object.values(tagValuesecond)[0] : 0;
    


          const tagValueThird = mqttTagData[card.device__uid] && confData.length ?
            mqttTagData[card.device__uid].find((f) => f && f[confData[index] && confData[index][2] && confData[index][2].tag_name])
            : {};

          const tagValue3 = tagValueThird  && Object.values(tagValueThird)[0] ? Object.values(tagValueThird)[0] : 0;
        

          // console.log("tagValue1", tagValue1);

          // const tagValue3 = mqttTagData[card.device__uid] && confData.length ?
          //   mqttTagData[card.device__uid]?.find((f) => {
          //     const tagName = confData[index]?.[2]?.tag_name;
          //     return f && f[tagName] === tagName;
          //   })?.[confData[index]?.[2]?.tag_name]
          //   : 0;

          // console.log("tagValue1", tagValue1);
         

          // const findTagValue1 = tagValue.length && confData.length ?
          //   tagValue.find((f) =>
          //     f.tagUnit == confData[index]?.[0]?.tag_unit &&
          //     f.deviceName == card?.device__device_name
          //   )
          //   : 0;

          // const findTagValue2 = tagValue.length && confData.length ?
          //   tagValue.find((f) =>
          //     f.tagUnit == confData[index]?.[1]?.tag_unit &&
          //     f.deviceName == card?.device__device_name
          //   )
          //   : 0;

          // const findTagValue3 = tagValue.length && confData.length ?
          //   tagValue.find((f) =>
          //     f.tagUnit == confData[index]?.[2]?.tag_unit &&
          //     f.deviceName == card?.device__device_name
          //   )
          //   : 0;


          // const findTagValue2 = tagValue.length && confData.length ? tagValue.find((f) => f.tagUnit == confData[index][1].tag_unit && f.deviceName == card.device__device_name) : 0;

          // const findTagValue3 = tagValue.length && confData.length  ? tagValue.find((f) => f.tagUnit == confData[index][2].tag_unit && f.deviceName == card.device__device_name) : 0 ;
         
        return (
          <div>
          <Card
            style={{
              width: "96%",
            }}
            // onClick={() => handleOpenPopup(card.device)}
          >
            <h5
              className="cardTitle"
              style={{
                opacity: "0.8",
              }}
            >
              {card.device__device_name}
            </h5>
            <Table className="card-slider-table">
              <TableBody>
                <TableRow>
                  <TableCell>
                  {confData[index] && confData[index].length > 0
                    ? (confData[index][0] && confData[index][0].tag_unit) || "-"
                    : "-"
                  }
                  </TableCell>
                  <TableCell>:</TableCell>
                <TableCell>
                  {/* {tagValue[index] && tagValue[index].length > 0
                    ? tagValue[index][0] : 0
                  } */}
                      {/* {findTagValue1 ? findTagValue1.tagValue : 0} */}
                      {tagValue1 ? tagValue1 : 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                  {confData[index] && confData[index].length > 0
                    ? (confData[index][1] && confData[index][1].tag_unit) || "-"
                    : "-"
                  }
                  </TableCell>
                  <TableCell>:</TableCell>
                <TableCell>
                  {/* {tagValue[index] && tagValue[index].length > 0
                    ? tagValue[index][1] : 0
                  } */}
                      {/* {findTagValue2 ? findTagValue2.tagValue : 0} */}
                      {tagValue2 ? tagValue2 : 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                  {confData[index] && confData[index].length > 0
                    ? (confData[index][2] && confData[index][2].tag_unit) || "-"
                    : "-"
                  }
                  </TableCell>
                  <TableCell>:</TableCell>
                <TableCell>
                  {/* {tagValue[index] && tagValue[index].length > 0
                  ? tagValue[index][2] : 0
                  } */}
                      {/* {findTagValue3 ? findTagValue3.tagValue : 0} */}
                      {tagValue3 ? tagValue3 : 0}
                </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </div>
          )})
        : []}
      </Slider>
    </>
  );
}

export default ConsumptionSlider;
