import React, { useEffect, useState } from "react";
import logo from "../../../assets/hikar.png";
import { Grid } from "@mui/material";
import { Button } from "../../../components/Button/button";
import '../OTP/Otp.css';
import OTPInput, { ResendOTP } from "otp-input-react";
import { SliderAuth } from "../../../components/SliderAuth/SliderAuth";
import { loginMfaApi } from "../service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch } from "react-redux";
import { setUserDetails } from "../slice/userSlice";
import { images } from "../../../config/images";
import Loader from "../../../components/Loader/Loader";

export default function MfaVerification({ email, mfaUserId, onCancelClick  }) {

    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(60);
    const [OTP, setOTP] = useState("");
    const [visibleOTP, setvisibleOTP] = useState(false);
    const[isLoading,setLoading] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const notify = (message) => toast.error(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    const notifySuccess = (message) => toast.success(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    const submitMfaVerification = async () => {
      
        const param = {
            mfa_user_id: mfaUserId,
            email: email,
            mfa_token: OTP
        }

        if (OTP.length != 6) {
            notify("Please Enter valid OTP.")
            return true;
        }

        try {
            setLoading(true)
            const response = await loginMfaApi(param);

            if (response.data.message == "No user with Id: ba007454-e12d-4ba0-bece-ab304a1aa457 found") {
                notify(response.data.message);
                return true;
            }

            if (response.data.message == "No user with Id: ba007454-e12d-4ba0-bece-ab304a1aa457 found") {
                notify(response.data.message)
                return true;
            }

            if (response.data.message == "OTP token is valid, Login successfully") {
                setLoading(false)
                dispatch(setUserDetails({ firstName: response.data.payload.first_name, lastName: response.data.payload.last_name, email: response.data.payload.email, token: response.data.payload.token, id: response.data.payload.id, MFA_UserId: response.data.payload.mfa_user_id, company_logo: response.data.payload.company_logo, companyId: response.data.payload.company_id, is_super_admin: response.data.payload.is_super_admin, is_admin: response.data.payload.is_admin, companyName: response.data.payload.company_name}));
            }
        }
        catch (error) {
            setLoading(false)
            notify(error.response.data.message)
        }
    }




    return (
        <>
            <div>
                <ToastContainer autoClose={8000}></ToastContainer>
            </div>
            <Grid container className="main-container">
                <SliderAuth />
                <Grid item xs={12} md={5} lg={4} className="signIn-form-wrap">
                    <div className="pb-4">
                        <img src={logo} alt="" className="signIn-logo" />
                    </div>
                    <h1 className="forgotPassword text-center p-5 pb-3">MFA Verification</h1>
                    <div className="forgotPassword-lines pb-4">
                        <h5 style={{ color: "#001323", fontWeight: 400, letterSpacing: "0.48px" }}>Please Enter Google Authenticator Code</h5>
                    </div>
                    <div className="otp-input wrap">
                        <OTPInput
                            value={OTP}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                            secure={!visibleOTP}
                            className='OTP pb-3'
                            inputStyle='xyz'
                        />

                        {visibleOTP ? <img src={images.viewIcon} alt="" className="view icon OTP" onClick={() => setvisibleOTP(false)} /> :
                            <img src={images.eyecloseIcon} alt="" className="view icon OTP" onClick={() => setvisibleOTP(true)} />}

                    </div>

                    {/* <div className="countdown-text d-flex justify-content-center">
                        {seconds > 0 ? (
                            <>
                                <p className="d-flex" style={{ marginLeft: "50px" }}>
                                    Please wait for  {seconds < 10 ? `0${seconds}` : seconds} seconds  <div style={{ cursor: "pointer", marginLeft: "30px" }}><a style={{ textDecorationLine: "underline", fontWeight: 500 }}>Resend</a></div>
                                </p>

                            </>
                        ) : (
                            <p>Didn't recieve code?</p>
                        )}

                    </div> */}

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='pb-5'>
                        <Grid item xs={6}>
                            <div onClick={() => onCancelClick()}>
                                <Button title='cancel' classname={"border-btn"} />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div onClick={() => submitMfaVerification()}>
                                <Button title='Submit' classname={"custom-button"} />
                            </div>
                        </Grid>
                    </Grid>
                    {/* <div className="SingIn-unlock d-flex flex-column pb-0 pb-sm-5 pb-md-3 pb-lg-4">
                        <div>Unlock account?</div>
                        <div>Help</div>
                    </div> */}
                    <div className="SingIn-unlock d-flex flex-column">
                        <div onClick={() => window.location.assign("https://hikartech.com/unlock-account/")} style={{ cursor: "pointer" }}>Unlock account?</div>
                        <div>Help</div>
                    </div>
                    <div className="signIn-footer d-flex justify-content-between">
                        <div>
                            <h6>Terms & Conditions</h6>
                        </div>
                        <div>
                            <h6>Privacy</h6>
                        </div>
                        <div>
                            <h6>Cookies</h6>
                        </div>
                        <div>
                            <h6>Trademarks</h6>
                        </div>
                    </div>
                    <div className="signIn-footer-lines">
                        <div>
                            <h6>Powered by</h6>
                        </div>
                        <div>
                            <h6><span>Hikar®Technomation</span> Private Limited © All Rights Reserved</h6>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {isLoading && <Loader/>}
        </>
    );
}