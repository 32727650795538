/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
// import { ValidatorForm } from "react-material-ui-form-validator";
import logo from "../../../assets/hikar.png";
// import viewIcon from "../../../assets/images/viewIcon.png";
import { Grid } from "@mui/material";
// import { InputField } from "../../../components/InputField/InputField";
import { Button } from "../../../components/Button/button";
import { InputAdornment } from '@material-ui/core';
// import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import './Sign.css';
import { SliderAuth } from "../../../components/SliderAuth/SliderAuth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { signInApi } from "../service";
import { setUserDetails } from "../slice/userSlice";
import Checkbox from '@mui/material/Checkbox';
import { images } from '../../../config/images';
import Cookies from "universal-cookie";
import Loader from "../../../components/Loader/Loader";
 

export default function SignIn({ onNextClick, setMobileNo, setEmail, setMfaUserId }) {


    const navigate = useNavigate();

    const dispatch = useDispatch();

    const cookies = new Cookies();

    const notify = (message) => toast.error(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    const [formvalues, setFormvalue] = React.useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);
    const[isLoading,setLoading] = useState(false)
    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };


    useEffect(() => {
        setFormvalue({
            email: cookies.get("username"),
            password: cookies.get("password"),
        });
    }, []);

    useMemo(() => {
        if (cookies.get("username") && cookies.get("password")) {
            setIsChecked(true);
        }
    }, [formvalues]);

    const handleSubmit = async () => {
       
        if (!formvalues.email) {
            notify("Please Enter Email id or password.");
            return true;
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formvalues.email)) {
            notify("Please Enter valid Email.");
            return true
        }


        if (!formvalues.password) {
            notify("Please Enter valid password.");
            return true;
        }

        // const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,16}$/;

        // if (!passwordPattern.test(formvalues.password)) {
        //     notify("Password must contain at least one uppercase letter, at least one lowercase, at least one Alphabet, up to 7 to 16 charactor Long password!");
        //     return true;
        // }
        const params = {
            email: formvalues.email,
            password: formvalues.password,
        }
        try {
            setLoading(true)
            const response = await signInApi(params);
            setLoading(false)
            if (response.data.message == "Your 2-Step verification in enable, Please verify for login") {
                setMobileNo(response.data.payload.mobile_number)
                onNextClick("verify2Step");
                return true;
            }

            if (response.data.message == "Your MFA verification in enable, Please verify for login") {
                setEmail(response.data.payload.email);
                setMfaUserId(response.data.payload.mfa_user_id)
                onNextClick("verifyMFA");
                return true;
            }

            if (response.data.message == "You login successfully") {
                if (isChecked) {
                    cookies.set("username", formvalues.email);
                    cookies.set("password", formvalues.password);
                } else {
                    cookies.set("username", "");
                    cookies.set("password", "");
                }
                // console.log("response", response.data);
                dispatch(setUserDetails({ firstName: response.data.payload.first_name, lastName: response.data.payload.last_name, email: response.data.payload.email, token: response.data.payload.token, id: response.data.payload.id, MFA_UserId: response.data.payload.mfa_user_id, company_logo: response.data.payload.company_logo, companyId: response.data.payload.company_id, is_super_admin: response.data.payload.is_super_admin, is_admin: response.data.payload.is_admin,companyName : response.data.payload.company_name,companyDescription: response.data.payload.company_description }));
            }

        } catch (error) {
            console.log("error", error);
            setLoading(false)
            if (error.response.data.message) {
                notify(error.response.data.message);
            } else {
                notify("Please Enter valid email id.");
            }

        }
    }

    const handleChange = (e) => {
        setFormvalue({ ...formvalues, [e.target.name]: e.target.value });
    }

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


    return (
        <>
            <div>
                <ToastContainer autoClose={4000}></ToastContainer>
            </div>
            <Grid container className="main-container">
                <SliderAuth />
                <Grid item xs={12} md={5} lg={4} className="signIn-form-wrap">
                    <div className="pb-4">
                        <img src={logo} alt="" className="signIn-logo" />
                    </div>
                    <h3 className="text-center singIn-title">Welcome</h3>
                    <TextField id="filled-basic" name="email" label="Email ID" value={formvalues.email}
                        onChange={handleChange} variant="filled" style={{ width: '100%' }} InputLabelProps={{
                            style: { color: '#011627' }
                        }} />
                    <TextField id="filled-basic" name="password" type={showPassword ? 'text' : 'password'} label="Password" variant="filled" value={formvalues.password}
                        onChange={handleChange} style={{ width: '100%', marginTop: '20px' }} InputLabelProps={{
                            style: { color: '#011627' },
                        }} InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" onClick={handleTogglePasswordVisibility} style={{cursor:"pointer"}}>
                                    {showPassword ? <img src={images.viewIcon} alt="" /> : <img src={images.eyecloseIcon} alt="" />} 
                                </InputAdornment>
                            ),
                        }} />
                    <div className="SignIn-keepLogin-wrap">
                        <div className="keepLogin-wrap">
                            {/* <input
                                type="checkbox"
                                id="keepMeLogin"
                                className="signIncheckBox"
                                name="keepMeLogin"
                                value={isChecked}
                                checked={isChecked}
                                // onClick={handleCheckBox}
                            /> */}
                            <Checkbox className='signIncheckBox' checked={isChecked}  onClick={() => setIsChecked(!isChecked)}/>
                            {/* <input type="checkbox" id="keepMeLogin" name="keepMeLogin" value="keepMeLogin" class="keepmeLogin-checkBox" /> */}
                            <label for="keepMeLogin" className="keepLogin-label login">Keep me login</label>
                        </div>
                        <div className="forgotPassword-link" style={{ cursor: "pointer" }} onClick={() => navigate('/forgot-password')}>Forgot Password?</div>
                    </div>
                    <div>
                        <Button title='Login' onClick={handleSubmit} classname={"custom-button"} />
                    </div>
                    <div className="SingIn-unlock d-flex flex-column">
                        <div onClick={() => window.location.assign("https://hikartech.com/unlock-account/")} style={{cursor: "pointer"}}>Unlock account?</div>
                        <div>Help</div>
                    </div>
                    <div className="signIn-Footer">
                        <div>
                            <h6>Terms & Conditions</h6>
                        </div>
                        <div>
                            <h6>Privacy</h6>
                        </div>
                        <div>
                            <h6>Cookies</h6>
                        </div>
                        <div>
                            <h6>Trademarks</h6>
                        </div>
                    </div>
                    <div className="signIn-footer-lines">
                        <div>
                            <h6>Powered by</h6>
                        </div>
                        <div>
                            <h6><b>Hikar®Technomation</b> Private Limited © All Rights Reserved</h6>
                        </div>
                    </div>
                    {/* </ValidatorForm> */}
          
                </Grid>
            </Grid>
        {isLoading && <Loader />}
        </>
    );
}