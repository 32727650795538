import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/ElectrothermLogo-sm.png";
import { Grid } from "@mui/material";
import { Button } from "../../../components/Button/button";
import '../OTP/Otp.css';
import OTPInput, { ResendOTP } from "otp-input-react";
import { useNavigate } from "react-router-dom";
import { SliderAuth } from "../../../components/SliderAuth/SliderAuth";
import { ToastContainer, toast } from 'react-toastify';
import { accOTPVerifyApi, createPassworOtpdApi } from "../service";
import 'react-toastify/dist/ReactToastify.css'
import { images } from "../../../config/images";

export default function GetOtp({ userDetail, email, OTP, setOTP, onNextClick, onCancelClick }) {

    const navigate = useNavigate();
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(60);
    const [visibleOTP, setvisibleOTP] = useState(false);
const[isLoading,setLoading] = useState(false)
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const notify = (message) => toast.error(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    const notifySucces = (message) => toast.success(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    const submitVerifyOtp = async () => {
        const param = {
            email: email,
            otp: OTP
        }

        if (OTP.length != 6) {
            notify("Please Enter valid OTP.")
            return true;
        }
try{
    setLoading(true)
    const resp = await accOTPVerifyApi(param);

    if (resp.data.success == false) {
        notify(resp.data.message);
        return true;
    }
    
    if (resp.status == 200) {
        setLoading(false)
        onNextClick("ReEnterPassword")
    }

}
catch(error){
    setLoading(false)
    console.log("handleSubmit",error)
}
     
    }

    const handleResendOTP = async () => {
        setLoading(true)
        const param = {
            email: email
        }
        try{
            const resp = await createPassworOtpdApi(param);
            if (resp.status == 200) {
                setLoading(false)
                notifySucces("Resend OTP succesfully");
                setSeconds(60)
            }
        }
        catch(error){
            setLoading(false)
            console.log("handleSubmit",error)
        }
       
    }
    
    return (
        <>
            <div>
                <ToastContainer autoClose={8000}></ToastContainer>
            </div>
            <Grid container className="main-container">
                <SliderAuth />
                <Grid item xs={12} md={5} lg={4} className="signIn-form-wrap">
                    <div className="pb-4">
                        <img src={userDetail ? userDetail.company_logo ? userDetail.company_logo : require('../../../assets/hikar.png') : require('../../../assets/hikar.png')} alt="" className={"logo-deafult"} style={{ width: "100px" }} />
                    </div>
                    <h1 className="forgotPassword text-center p-5 pb-3">Create Password?</h1>
                    <div className="forgotPassword-lines pb-4">
                        <h5 style={{ color: "#001323", fontWeight: 400, letterSpacing: "0.48px" }}>Please Enter OTP Sent to {userDetail.email}</h5>
                    </div>
                    <div className="otp-input wrap">
                    <OTPInput
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                        secure={!visibleOTP}
                        className='OTP pb-3'
                        inputStyle='xyz'
                    />

                    {visibleOTP ? <img src={images.viewIcon} alt="" className="view icon OTP" onClick={() => setvisibleOTP(false)} /> :
                            <img src={images.eyecloseIcon} alt="" className="view icon OTP" onClick={() => setvisibleOTP(true)} />}
                        
                        </div>
                    <div className="countdown-text d-flex justify-content-center">
                        {seconds > 0 ? (
                            <>
                                <p className="d-flex" style={{ marginLeft: "50px" }}>
                                    Please wait for  {seconds < 10 ? `0${seconds}` : seconds} seconds
                                </p>

                            </>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="abc">
                                <div>
                                    Didn't recieve code?
                                </div>
                                <div>
                                        <a style={{ cursor: "pointer", textDecorationLine: "underline", fontWeight: 500 }} onClick={handleResendOTP}>Resend</a>
                                </div>
                            </div>
                        )}

                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='pb-5'>
                        <Grid item xs={6}>
                            <div onClick={() => onCancelClick("")}>
                                <button className="createPassword-button" >Cancel</button>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <button className="createPassword-button getOTP" onClick={() => submitVerifyOtp()}>Submit</button>
                            </div>
                        </Grid>
                    </Grid>
                    <div className="signIn-footer-lines">
                        <div>
                            <h6>Powered by</h6>
                        </div>
                        <div>
                            <h6><span>Hikar®Technomation</span> Private Limited © All Rights Reserved</h6>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}