import React, { useMemo, useState } from "react";
import { Box, Link } from "@material-ui/core";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { images } from "../../config/images";
import "../ProcessDashboard/ProcessDashboard.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FooterText from "../../components/FooterText/FooterText";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { setHeaderDetails } from "../Header/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import { getPlantDashboard } from "./Configration/services";
import { useEffect } from "react";
import { useCubeQuery } from "@cubejs-client/react";
import dayjs from "dayjs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { cubejsApi } from "../Cubejs/CubejsApiWrapper";
import DonutChart from "./DonutChart";
import BarChart from "./BarChart";
import { setProcessDashboarDateSet } from "./sliceProcessDashboard/dateSliceprocessDashboard";
import Mqqtdata from "./Mqqtdata";

const dateFormat = "YYYY-MM-DD"; // Replace with your desired date format

const ProcessDashboard = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate6, setStartDate6] = useState(null);
  const [endDate6, setEndDate6] = useState(null);
  const [startDate7, setStartDate7] = useState(null);
  const [endDate7, setEndDate7] = useState(null);

  const [dashboard, setDashboard] = useState([]);
  const [positionValue1, setPositionValue1] = useState(0);
  const [positionValue2, setPositionValue2] = useState(0);
  const [positionValue3, setPositionValue3] = useState(0);
  const [positionValue4, setPositionValue4] = useState(0);
  const [positionValue5, setPositionValue5] = useState("");
  const [positionValue6, setPositionValue6] = useState("");
  const [positionValue7, setPositionValue7] = useState("");
  const [tagData1, setTagData1] = useState("");
  const [tagData2, setTagData2] = useState("");
  const [tagData3, setTagData3] = useState("");
  const [tagData4, setTagData4] = useState("");
  const [tagData5, setTagData5] = useState("");
  const [tagData6, setTagData6] = useState("");
  const [tagData7, setTagData7] = useState("");
  const [activeChart, setActiveChart] = useState("pie");
  const [activeChart1, setActiveChart1] = useState("pie");
  const [activeChart2, setActiveChart2] = useState("pie");
  const [positionValue_sum_5, setPositionValue_sum_5] = useState(0);
  const [positionValue_sum_6, setPositionValue_sum_6] = useState(0);
  const [positionValue_sum_7, setPositionValue_sum_7] = useState(0);
  const [dashboardCubeData, setDashboardCubeData] = useState([])
  const [dashboardTagName, setDashboardTagName] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mqttTagData, setMqttTagData] = useState([]);

  console.log("mqttTagData", mqttTagData);

  useEffect(() => {
    if (dashboard.length) {

      const topicArray = [
        dashboard[0].position_1_name ? 'Hikar/DeviceUID/' + dashboard[0].position_1_name.device_uid : null,
        dashboard[0].position_2_name ? 'Hikar/DeviceUID/' + dashboard[0].position_2_name.device_uid : null,
        dashboard[0].position_3_name ? 'Hikar/DeviceUID/' + dashboard[0].position_3_name.device_uid : null,
        dashboard[0].position_4_name ? 'Hikar/DeviceUID/' + dashboard[0].position_4_name.device_uid : null,
      ].filter(topic => topic !== null);
      setTopics(topicArray)
    }
  },[dashboard])

  const handleChartClick = (chartType) => {
    setActiveChart(chartType);
  };

  const handleChartClick1 = (chartType) => {
    setActiveChart1(chartType);
  };

  const handleChartClick2 = (chartType) => {
    setActiveChart2(chartType);
  };

  const {
    startDateRe,
    endDateRe,
    startDateRe2,
    endDateRe2,
    startDateRe3,
    endDateRe3,
  } = useSelector((state) => state.dateSliceprocessDashboardState);
  const startDateReFormat = dayjs(startDateRe);
  const endDateReFormat = dayjs(endDateRe);
  const startDateReFormat2 = dayjs(startDateRe2);
  const endDateReFormat2 = dayjs(endDateRe2);
  const startDateReFormat3 = dayjs(startDateRe3);
  const endDateReFormat3 = dayjs(endDateRe3);

  // console.log("startDateRe", startDateRe);
  const formatStartDate = startDateReFormat
    ? dayjs(startDateReFormat).format(dateFormat)
    : "";
  const formatEndDate = endDateReFormat
    ? dayjs(endDateReFormat).format(dateFormat)
    : "";
  const formatStartDate6 = startDateRe2
    ? dayjs(startDateRe2).format(dateFormat)
    : "";
  const formatEndDate6 = endDateRe2 ? dayjs(endDateRe2).format(dateFormat) : "";

  const formatStartDate7 = startDateRe3
    ? dayjs(startDateRe3).format(dateFormat)
    : "";
  const formatEndDate7 = endDateRe3 ? dayjs(endDateRe3).format(dateFormat) : "";

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { headerName } = useSelector((state) => state.headerState);
  const dispatch = useDispatch();

  const param = useParams();

  const handleOverViewClick = () => {
    navigate(`/overView/company/${param.companyId}/plant/${param.id}`);
    setActiveClass("overview");
  };

  const handleSourceClick = () => {
    navigate(`/source/company/${param.companyId}/plant/${param.id}`);
    setActiveClass("source");
  };

  const handleConsumption = () => {
    navigate(`/consumption/company/${param.companyId}/plant/${param.id}`);
    setActiveClass("consumption");
  };
  const handleAdvanceAnalytics = () => {
    dispatch(setHeaderDetails({ headerName: "Advance Analytics" }));
    navigate(`/analytics/company/${param.companyId}/plant/${param.id}`);
    setActiveClass("Analytics");
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (dashboard.length) {
      handleCubejsPostion5();
      // handleCube();
    }
  }, [dashboard, formatStartDate, formatEndDate]);

  useEffect(() => {
    if (dashboard.length) {
      handleCubejsPostion6();
      // handleCube();
    }
  }, [dashboard, formatStartDate6, formatEndDate6]);

  useEffect(() => {
    if (dashboard.length) {
      handleCubejsPostion7();
      // handleCube();
    }
  }, [dashboard, formatStartDate7, formatEndDate7]);

  // const handleCube = async () => {
  //   if (!dashboard.length) {
  //     return true;
  //   }

  //   if (dashboard[0].position_1_name) {
  //     const t1 = await cubejsApi.load(
  //       {
  //         dimensions: [
  //           "DeviceConfigurationTagconfiguration.tagComment",
  //           "DeviceConfigurationTagconfiguration.tagUnit",
  //         ],
  //         order: {
  //           "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //         },
  //         filters: [
  //           {
  //             member: "DeviceConfigurationTagconfiguration.tagName",
  //             operator: "equals",
  //             values: [`${dashboard[0].position_1_name}`],
  //           },
  //         ],
  //       },
  //       { subscribe: true }
  //     );


  //     // console.log("t1t1t1t1", t1.rawData());
  //     setTagData1({
  //       comment:
  //         t1.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagComment"
  //         ],
  //       unit: t1.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagUnit"
  //       ],
  //     });
  //   }
  //   if (dashboard[0].position_2_name) {
  //     const t2 = await cubejsApi.load(
  //       {
  //         dimensions: [
  //           "DeviceConfigurationTagconfiguration.tagComment",
  //           "DeviceConfigurationTagconfiguration.tagUnit",
  //         ],
  //         order: {
  //           "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //         },
  //         filters: [
  //           {
  //             member: "DeviceConfigurationTagconfiguration.tagName",
  //             operator: "equals",
  //             values: [`${dashboard[0].position_2_name}`],
  //           },
  //         ],
  //       },
  //       { subscribe: true }
  //     );
  //     setTagData2({
  //       comment:
  //         t2.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagComment"
  //         ],
  //       unit: t2.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagUnit"
  //       ],
  //     });
  //   }
  //   if (dashboard[0].position_3_name) {
  //     const t3 = await cubejsApi.load(
  //       {
  //         dimensions: [
  //           "DeviceConfigurationTagconfiguration.tagComment",
  //           "DeviceConfigurationTagconfiguration.tagUnit",
  //         ],
  //         order: {
  //           "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //         },
  //         filters: [
  //           {
  //             member: "DeviceConfigurationTagconfiguration.tagName",
  //             operator: "equals",
  //             values: [`${dashboard[0].position_3_name}`],
  //           },
  //         ],
  //       },
  //       { subscribe: true }
  //     );
  //     setTagData3({
  //       comment:
  //         t3.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagComment"
  //         ],
  //       unit: t3.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagUnit"
  //       ],
  //     });
  //   }
  //   if (dashboard[0].position_4_name) {
  //     const t4 = await cubejsApi.load(
  //       {
  //         dimensions: [
  //           "DeviceConfigurationTagconfiguration.tagComment",
  //           "DeviceConfigurationTagconfiguration.tagUnit",
  //         ],
  //         order: {
  //           "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //         },
  //         filters: [
  //           {
  //             member: "DeviceConfigurationTagconfiguration.tagName",
  //             operator: "equals",
  //             values: [`${dashboard[0].position_4_name}`],
  //           },
  //         ],
  //       },
  //       { subscribe: true }
  //     );
  //     setTagData4({
  //       comment:
  //         t4.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagComment"
  //         ],
  //       unit: t4.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagUnit"
  //       ],
  //     });
  //   }

  // };

  // const handleCubeTag5 = async () => {
  //   if (startDateReFormat && endDateReFormat) {
  //     if (dashboard[0].position_5.length) {
  //       const values = dashboard[0].position_5_names;

  //       const mapCube = await Promise.all(
  //         values.map(async (e, i) => {
  //           const t5 = await cubejsApi.load({
  //             dimensions: [
  //               "DeviceConfigurationTagconfiguration.tagComment",
  //               "DeviceConfigurationTagconfiguration.tagUnit",
  //               "DeviceConfigurationTagconfiguration.deviceId"
  //             ],
  //             order: {
  //               "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //             },
  //             filters: [
  //               {
  //                 member: "DeviceConfigurationTagconfiguration.tagName",
  //                 operator: "equals",
  //                 values: [e],
  //               },
  //             ],
  //           });

  //           const cubeData = t5.rawData();

  //           const tagData = {
  //             tagUnit: cubeData.length ? cubeData[0]["DeviceConfigurationTagconfiguration.tagUnit"] : '',
  //             tagComment: cubeData.length ? cubeData[0]["DeviceConfigurationTagconfiguration.tagComment"] : '',
  //           }

  //           return tagData
  //         })
  //       );

  //       // console.log("valuesjdkjfhdjf", values);
  //       // const t5 = await cubejsApi.load({
  //       //   dimensions: [
  //       //     "DeviceConfigurationTagconfiguration.tagComment",
  //       //     "DeviceConfigurationTagconfiguration.tagUnit",
  //       //     "DeviceConfigurationTagconfiguration.deviceId"
  //       //   ],
  //       //   order: {
  //       //     "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //       //   },
  //       //   filters: [
  //       //     {
  //       //       member: "DeviceConfigurationTagconfiguration.tagName",
  //       //       operator: "equals",
  //       //       values: values,
  //       //     },
  //       //   ],
  //       // });

  //       // const data = t5.rawData();
  //       // console.log("t5t5t5t5t5t5t5t5", data);

  //       // const comment5 = data.map((e) => {
  //       //   return {
  //       //     tagUnit: e["DeviceConfigurationTagconfiguration.tagUnit"],
  //       //     tagComment: e["DeviceConfigurationTagconfiguration.tagComment"],
  //       //     deviceId: e["DeviceConfigurationTagconfiguration.deviceId"]
  //       //   };
  //       // });
  //       setTagData5(mapCube);
  //     }
  //   }
  // }

  // const handleCubeTag6 = async () => {
  //   if (startDateReFormat2 && endDateReFormat2) {
  //     if (dashboard[0].position_6.length) {
  //       const values = dashboard[0].position_6_names;

  //       const mapCube = await Promise.all(
  //         values.map(async (e, i) => {
  //           const t6 = await cubejsApi.load({
  //             dimensions: [
  //               "DeviceConfigurationTagconfiguration.tagComment",
  //               "DeviceConfigurationTagconfiguration.tagUnit",
  //               "DeviceConfigurationTagconfiguration.deviceId"
  //             ],
  //             order: {
  //               "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //             },
  //             filters: [
  //               {
  //                 member: "DeviceConfigurationTagconfiguration.tagName",
  //                 operator: "equals",
  //                 values: [e],
  //               },
  //             ],
  //           });

  //           const cubeData = t6.rawData();

  //           const tagData = {
  //             tagUnit: cubeData.length ? cubeData[0]["DeviceConfigurationTagconfiguration.tagUnit"] : '',
  //             tagComment: cubeData.length ? cubeData[0]["DeviceConfigurationTagconfiguration.tagComment"] : '',
  //           }

  //           return tagData
  //         })
  //       );
  //       // const t6 = await cubejsApi.load({
  //       //   dimensions: [
  //       //     "DeviceConfigurationTagconfiguration.tagComment",
  //       //     "DeviceConfigurationTagconfiguration.tagUnit",
  //       //   ],
  //       //   order: {
  //       //     "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //       //   },
  //       //   filters: [
  //       //     {
  //       //       member: "DeviceConfigurationTagconfiguration.tagName",
  //       //       operator: "equals",
  //       //       values: values,
  //       //     },
  //       //   ],
  //       // });

  //       // const data = t6.rawData();
  //       // console.log("data", data);

  //       // const comment6 = data.map((e) => {
  //       //   return {
  //       //     tagUnit: e["DeviceConfigurationTagconfiguration.tagUnit"],
  //       //     tagComment: e["DeviceConfigurationTagconfiguration.tagComment"],
  //       //   };
  //       // });
  //       setTagData6(mapCube);
  //     }
  //   }
  // }

  // const handleCubeTag7 = async () => {
  //   if (startDateReFormat3 && endDateReFormat3) {
  //     if (dashboard[0].position_7.length) {
  //       const values = dashboard[0].position_7_names;

  //       const mapCube = await Promise.all(
  //         values.map(async (e, i) => {
  //           const t7 = await cubejsApi.load({
  //             dimensions: [
  //               "DeviceConfigurationTagconfiguration.tagComment",
  //               "DeviceConfigurationTagconfiguration.tagUnit"
  //             ],
  //             order: {
  //               "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //             },
  //             filters: [
  //               {
  //                 member: "DeviceConfigurationTagconfiguration.tagName",
  //                 operator: "equals",
  //                 values: [e],
  //               },
  //             ],
  //           });

  //           const cubeData = t7.rawData();
  //           console.log("cubeData", cubeData, i);

  //           const tagData = {
  //             tagUnit: cubeData.length ? cubeData[0]["DeviceConfigurationTagconfiguration.tagUnit"] : '',
  //             tagComment: cubeData.length ? cubeData[0]["DeviceConfigurationTagconfiguration.tagComment"] : '',
  //           }

  //           return tagData
  //         })
  //       );

  //       // const t7 = await cubejsApi.load({
  //       //   dimensions: [
  //       //     "DeviceConfigurationTagconfiguration.tagComment",
  //       //     "DeviceConfigurationTagconfiguration.tagUnit",
  //       //   ],
  //       //   order: {
  //       //     "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //       //   },
  //       //   filters: [
  //       //     {
  //       //       member: "DeviceConfigurationTagconfiguration.tagName",
  //       //       operator: "equals",
  //       //       values: values,
  //       //     },
  //       //   ],
  //       // });

  //       // const data = t7.rawData();
  //       // console.log("data", data);

  //       // const comment7 = data.map((e) => {
  //       //   return {
  //       //     tagUnit: e["DeviceConfigurationTagconfiguration.tagUnit"],
  //       //     tagComment: e["DeviceConfigurationTagconfiguration.tagComment"],
  //       //   };
  //       // });
  //       setTagData7(mapCube);
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (dashboard.length) {
  //     handleCubeTag5();
  //   }
  // }, [
  //   dashboard,
  //   formatStartDate,
  //   formatEndDate
  // ]);

  // useEffect(() => {
  //   if (dashboard.length) {
  //     handleCubeTag6();
  //   }
  // }, [
  //   dashboard,
  //   formatStartDate6,
  //   formatEndDate6
  // ]);

  // useEffect(() => {
  //   if (dashboard.length) {
  //     handleCubeTag7();
  //   }
  // }, [
  //   dashboard,
  //   formatStartDate7,
  //   formatEndDate7
  // ]);

  // useEffect(() => {
  //   if (dashboard.length) {
  //     handleCube();
  //   }
  // }, [
  //   dashboard,
  //   // formatStartDate,
  //   // formatEndDate,
  //   // formatStartDate6,
  //   // formatEndDate6,
  //   // formatStartDate7,
  //   // formatEndDate7,
  // ]);


  // useEffect(() => {

  //   if (dashboard.length) {

  //     // Function to execute the cube query
  //     const executeCubeQuery = async () => {
  //       try {
  //         const pos1 = await cubejsApi.load(
  //           {
  //             "dimensions": [
  //               "DeviceConfigurationTagconfiguration.latestValue"
  //             ],
  //             "limit": 1,
  //             "filters": [
  //               {
  //                 "member": "DeviceConfigurationTagconfiguration.tagid",
  //                 operator: "equals",
  //                 values: dashboard.length
  //                   ? dashboard[0].position_1
  //                     ? [`${dashboard[0].position_1}`]
  //                     : ["0"]
  //                   : ["0"],
  //               },
  //             ],
  //           }
  //         )

  //         const pos2 = await cubejsApi.load(
  //           {
  //             "dimensions": [
  //               "DeviceConfigurationTagconfiguration.latestValue"
  //             ],
  //             "limit": 1,
  //             "filters": [
  //               {
  //                 "member": "DeviceConfigurationTagconfiguration.tagid",
  //                 operator: "equals",
  //                 values: dashboard.length
  //                   ? dashboard[0].position_2
  //                     ? [`${dashboard[0].position_2}`]
  //                     : ["0"]
  //                   : ["0"],
  //               },
  //             ],
  //           }
  //         )

  //         const pos3 = await cubejsApi.load(
  //           {
  //             "dimensions": [
  //               "DeviceConfigurationTagconfiguration.latestValue"
  //             ],
  //             "limit": 1,
  //             "filters": [
  //               {
  //                 "member": "DeviceConfigurationTagconfiguration.tagid",
  //                 operator: "equals",
  //                 values: dashboard.length
  //                   ? dashboard[0].position_3
  //                     ? [`${dashboard[0].position_3}`]
  //                     : ["0"]
  //                   : ["0"],
  //               },
  //             ],
  //           }
  //         )

  //         const pos4 = await cubejsApi.load(
  //           {
  //             "dimensions": [
  //               "DeviceConfigurationTagconfiguration.latestValue"
  //             ],
  //             "limit": 1,
  //             "filters": [
  //               {
  //                 "member": "DeviceConfigurationTagconfiguration.tagid",
  //                 operator: "equals",
  //                 values: dashboard.length
  //                   ? dashboard[0].position_4
  //                     ? [`${dashboard[0].position_4}`]
  //                     : ["0"]
  //                   : ["0"],
  //               },
  //             ],
  //           }
  //         )

  //         console.log("pos1", pos1.rawData());

  //         const rawValue1 = pos1.rawData().length ? pos1.rawData()[0]["DeviceConfigurationTagconfiguration.latestValue"] : 0;
  //         const rawValue2 = pos2.rawData().length ? pos2.rawData()[0]["DeviceConfigurationTagconfiguration.latestValue"] : 0;
  //         const rawValue3 = pos3.rawData().length ? pos3.rawData()[0]["DeviceConfigurationTagconfiguration.latestValue"] : 0;
  //         const rawValue4 = pos4.rawData().length ? pos4.rawData()[0]["DeviceConfigurationTagconfiguration.latestValue"] : 0;
  //         console.log("rawValue", rawValue1);
  //         setPositionValue1(rawValue1);
  //         setPositionValue2(rawValue2);
  //         setPositionValue3(rawValue3);
  //         setPositionValue4(rawValue4);

  //       } catch (error) {
  //         // Handle any errors that occur during the cube query
  //         console.error('Cube query error:', error);
  //       }
  //     };


  //     // Execute the cube query immediately
  //     executeCubeQuery();


  //     // Set interval to execute the cube query every minute
  //     setInterval(() => {
  //       executeCubeQuery();
  //     }, 2000);
  //   }

  // }, [dashboard]);

  // const { resultSet, isLoading } = useCubeQuery(
  //   {
  //     "dimensions": [
  //       "DeviceConfigurationTagconfiguration.latestValue",
  //       "DeviceConfigurationTagconfiguration.tagUnit",
  //       "DeviceConfigurationTagconfiguration.tagComment"
  //     ],
  //     "filters": [
  //       {
  //         "member": "DeviceConfigurationTagconfiguration.tagName",
  //         "operator": "contains",
  //         "values": dashboardTagName.length ? dashboardTagName : ["kp"]
  //       }
  //     ]
  //   },
  //   { subscribe: true }
  // );

  // useEffect(() => {
  //   if (resultSet && isLoading == false) {
  //     const tableData  = resultSet.tablePivot().map((raw) => {
  //       return ({
  //         taComment: raw["DeviceConfigurationTagconfiguration.tagComment"],
  //         tagUnit: raw["DeviceConfigurationTagconfiguration.tagUnit"],
  //         tagValue: raw["DeviceConfigurationTagconfiguration.latestValue"]
  //       })
  //     })

  //     // setTableRowData(tableData)
  //     setDashboardCubeData(tableData)
  //     console.log("tableData", tableData);
  //   }
  // }, [
  //   resultSet && resultSet.tablePivot()[0] && resultSet.tablePivot()[0]["DeviceConfigurationTagconfiguration.latestValue"],
  //   resultSet && resultSet.tablePivot()[1] && resultSet.tablePivot()[1]["DeviceConfigurationTagconfiguration.latestValue"],
  //   resultSet && resultSet.tablePivot()[2] && resultSet.tablePivot()[2]["DeviceConfigurationTagconfiguration.latestValue"],
  //   resultSet && resultSet.tablePivot()[3] && resultSet.tablePivot()[3]["DeviceConfigurationTagconfiguration.latestValue"],
  // ])


  // const position_1_cube = useCubeQuery(
  //   {
  //     "dimensions": [
  //       "DeviceConfigurationTagconfiguration.latestValue"
  //     ],
  //     "limit": 1,
  //     "filters": [
  //       {
  //         "member": "DeviceConfigurationTagconfiguration.tagid",
  //         operator: "equals",
  //         values: dashboard.length
  //           ? dashboard[0].position_1
  //             ? [`${dashboard[0].position_1}`]
  //             : ["0"]
  //           : ["0"],
  //       },
  //     ],
  //   },
  //   { subscribe: true }
  // );

  // const position_2_cube = useCubeQuery(
  //   {
  //     "dimensions": [
  //       "DeviceConfigurationTagconfiguration.latestValue"
  //     ],
  //     "limit": 1,
  //     "filters": [
  //       {
  //         "member": "DeviceConfigurationTagconfiguration.tagid",
  //         operator: "equals",
  //         values: dashboard.length
  //           ? dashboard[0].position_2
  //             ? [`${dashboard[0].position_2}`]
  //             : ["0"]
  //           : ["0"],
  //       },
  //     ],
  //   },
  //   { subscribe: true }
  // );

  // const position_3_cube = useCubeQuery(
  //   {
  //     "dimensions": [
  //       "DeviceConfigurationTagconfiguration.latestValue"
  //     ],
  //     "limit": 1,
  //     "filters": [
  //       {
  //         "member": "DeviceConfigurationTagconfiguration.tagid",
  //         operator: "equals",
  //         values: dashboard.length
  //           ? dashboard[0].position_3
  //             ? [`${dashboard[0].position_3}`]
  //             : ["0"]
  //           : ["0"],
  //       },
  //     ],
  //   },
  //   { subscribe: true }
  // );

  // const position_4_cube = useCubeQuery(
  //   {
  //     "dimensions": [
  //       "DeviceConfigurationTagconfiguration.latestValue"
  //     ],
  //     "limit": 1,
  //     "filters": [
  //       {
  //         "member": "DeviceConfigurationTagconfiguration.tagid",
  //         operator: "equals",
  //         values: dashboard.length
  //           ? dashboard[0].position_4
  //             ? [`${dashboard[0].position_4}`]
  //             : ["0"]
  //           : ["0"],
  //       },
  //     ],
  //   },
  //   { subscribe: true }
  // );
  // console.log("position_4_cube", position_4_cube);

  // useEffect(() => {
  //   if (
  //     !position_1_cube.isLoading &&
  //     !position_2_cube.isLoading &&
  //     !position_3_cube.isLoading &&
  //     !position_4_cube.isLoading
  //   ) {
  //     if (
  //       position_1_cube.resultSet ||
  //       position_2_cube.resultSet ||
  //       position_3_cube.resultSet ||
  //       position_4_cube.resultSet
  //     ) {
  //       const pos1 = position_1_cube.resultSet
  //         ? position_1_cube.resultSet.tablePivot().length
  //           ? position_1_cube.resultSet.tablePivot()[0][
  //           "DeviceConfigurationTagconfiguration.latestValue"
  //           ]
  //           : 0
  //         : 0;
  //       const pos2 = position_2_cube.resultSet
  //         ? position_2_cube.resultSet.tablePivot().length
  //           ? position_2_cube.resultSet.tablePivot()[0][
  //           "DeviceConfigurationTagconfiguration.latestValue"
  //           ]
  //           : 0
  //         : 0;
  //       const pos3 = position_3_cube.resultSet
  //         ? position_3_cube.resultSet.tablePivot().length
  //           ? position_3_cube.resultSet.tablePivot()[0][
  //           "DeviceConfigurationTagconfiguration.latestValue"
  //           ]
  //           : 0
  //         : 0;
  //       const pos4 = position_4_cube.resultSet
  //         ? position_4_cube.resultSet.tablePivot().length
  //           ? position_4_cube.resultSet.tablePivot()[0][
  //           "DeviceConfigurationTagconfiguration.latestValue"
  //           ]
  //           : 0
  //         : 0;

  //       console.log("firstDashboard", pos1);
  //       setPositionValue1(pos1);
  //       setPositionValue2(pos2);
  //       setPositionValue3(pos3);
  //       setPositionValue4(pos4);
  //     }
  //   }
  // }, [position_1_cube, position_2_cube, position_3_cube, position_4_cube]);

  const handleCubejsPostion5 = async () => {
    if (!dashboard.length) {
      return true;
    }

    if (startDateReFormat && endDateReFormat) {
      if (dashboard[0].position_5) {
        const values = dashboard[0].position_5
          .filter((value) => value !== undefined)
          .map((e) => e.toString());

        const mapCube = await Promise.all(
          values.map(async (e, i) => {
            const endDateValue = await cubejsApi.load({
              limit: 1,
              dimensions: [
                "DashboardTaghistory.tagValue",
                "DashboardTaghistory.latestTagUnit"
              ],
              order: [["DashboardTaghistory.createdAt", "desc"]],
              filters: [
                {
                  member: "DashboardTaghistory.tag",
                  operator: "equals",
                  values: [e],
                },
              ],
              timeDimensions: [
                {
                  dimension: "DashboardTaghistory.createdAt",
                  granularity: "second",
                  dateRange: [`${formatStartDate}`, `${formatEndDate}`],
                },
              ],
            });

            const startDateValue = await cubejsApi.load({
              limit: 1,
              dimensions: ["DashboardTaghistory.tagValue"],
              order: [["DashboardTaghistory.createdAt", "asc"]],
              filters: [
                {
                  member: "DashboardTaghistory.tag",
                  operator: "equals",
                  values: [e],
                },
              ],
              timeDimensions: [
                {
                  dimension: "DashboardTaghistory.createdAt",
                  granularity: "second",
                  dateRange: [`${formatStartDate}`, `${formatEndDate}`],
                },
              ],
            });

            const endDateData = endDateValue.rawData();
            const startDateData = startDateValue.rawData();

            // return
            return (
              parseFloat(
                endDateData.length
                  ? endDateData[0]["DashboardTaghistory.tagValue"]
                  : 0
              ) -
              parseFloat(
                startDateData.length
                  ? startDateData[0]["DashboardTaghistory.tagValue"]
                  : 0
              )
            );
            // const sum = data.reduce((total, item) => {
            //   const tagValue = parseFloat(item["DashboardTaghistory.tagValue"]);
            //   return total + tagValue;
            // }, 0);

            // return sum;
          })
        );

        console.log("mapCube", mapCube);

        setPositionValue5(mapCube);
      }
    }
  };

  const handleCubejsPostion6 = async () => {
    if (!dashboard.length) {
      return true;
    }

    if (startDateReFormat2 && endDateReFormat2) {
      if (dashboard[0].position_6) {
        const values = dashboard[0].position_6
          .filter((value) => value !== undefined)
          .map((e) => e.toString());

        const mapCube = await Promise.all(
          values.map(async (e, i) => {
            const endDateValue = await cubejsApi.load({
              limit: 1,
              dimensions: ["DashboardTaghistory.tagValue"],
              order: [["DashboardTaghistory.createdAt", "desc"]],
              filters: [
                {
                  member: "DashboardTaghistory.tag",
                  operator: "equals",
                  values: [e],
                },
              ],
              timeDimensions: [
                {
                  dimension: "DashboardTaghistory.createdAt",
                  granularity: "second",
                  dateRange: [`${formatStartDate6}`, `${formatEndDate6}`],
                },
              ],
            });

            const startDateValue = await cubejsApi.load({
              limit: 1,
              dimensions: ["DashboardTaghistory.tagValue"],
              order: [["DashboardTaghistory.createdAt", "asc"]],
              filters: [
                {
                  member: "DashboardTaghistory.tag",
                  operator: "equals",
                  values: [e],
                },
              ],
              timeDimensions: [
                {
                  dimension: "DashboardTaghistory.createdAt",
                  granularity: "second",
                  dateRange: [`${formatStartDate6}`, `${formatEndDate6}`],
                },
              ],
            });

            const endDateData = endDateValue.rawData();
            const startDateData = startDateValue.rawData();

            // return
            return (
              parseFloat(
                endDateData.length
                  ? endDateData[0]["DashboardTaghistory.tagValue"]
                  : 0
              ) -
              parseFloat(
                startDateData.length
                  ? startDateData[0]["DashboardTaghistory.tagValue"]
                  : 0
              )
            );
          })
        );

        setPositionValue6(mapCube);
      }
    }
  };

  const handleCubejsPostion7 = async () => {
    if (!dashboard.length) {
      return true;
    }

    if (startDateReFormat3 && endDateReFormat2) {
      if (dashboard[0].position_7) {
        const values = dashboard[0].position_7
          .filter((value) => value !== undefined)
          .map((e) => e.toString());

        const mapCube = await Promise.all(
          values.map(async (e, i) => {
            const endDateValue = await cubejsApi.load({
              limit: 1,
              dimensions: ["DashboardTaghistory.tagValue"],
              order: [["DashboardTaghistory.createdAt", "desc"]],
              filters: [
                {
                  member: "DashboardTaghistory.tag",
                  operator: "equals",
                  values: [e],
                },
              ],
              timeDimensions: [
                {
                  dimension: "DashboardTaghistory.createdAt",
                  granularity: "second",
                  dateRange: [`${formatStartDate7}`, `${formatEndDate7}`],
                },
              ],
            });

            const startDateValue = await cubejsApi.load({
              limit: 1,
              dimensions: ["DashboardTaghistory.tagValue"],
              order: [["DashboardTaghistory.createdAt", "asc"]],
              filters: [
                {
                  member: "DashboardTaghistory.tag",
                  operator: "equals",
                  values: [e],
                },
              ],
              timeDimensions: [
                {
                  dimension: "DashboardTaghistory.createdAt",
                  granularity: "second",
                  dateRange: [`${formatStartDate7}`, `${formatEndDate7}`],
                },
              ],
            });

            const endDateData = endDateValue.rawData();
            const startDateData = startDateValue.rawData();

            // return
            return (
              parseFloat(
                endDateData.length
                  ? endDateData[0]["DashboardTaghistory.tagValue"]
                  : 0
              ) -
              parseFloat(
                startDateData.length
                  ? startDateData[0]["DashboardTaghistory.tagValue"]
                  : 0
              )
            );
          })
        );

        setPositionValue7(mapCube);
      }
    }
  };


  useEffect(() => {
    handleGetPlantDashboard();
  }, []);

  const handleGetPlantDashboard = async () => {
    const params = {
      plant_id: param.id,
    };
    try {
      const resp = await getPlantDashboard(params);
      // console.log("resprespresp", resp);
      if (resp.data.success == true && resp.data.payload.length) {
        setDashboard(resp.data.payload);

        const tagName = [resp.data.payload[0].position_1_name && resp.data.payload[0].position_1_name.tag_name,
          resp.data.payload[0].position_2_name && resp.data.payload[0].position_2_name.tag_name,
          resp.data.payload[0].position_3_name && resp.data.payload[0].position_3_name.tag_name,
          resp.data.payload[0].position_4_name && resp.data.payload[0].position_4_name.tag_name];
        setDashboardTagName(tagName.filter((f) => f != null))
        console.log("tagName", tagName);
        console.log("resres", resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    dispatch(
      setHeaderDetails({
        ...headerName,
        headerImage: (
          <>
            <div>
              <button
                className="configration-btn"
              // onClick={() => navigate(`/company/31/plant/configration/${location.pathname.substring(location.pathname.lastIndexOf('/') + 1)}`)}
              >
                <img
                  src={images.configration}
                  style={{ height: "18px", width: "18px", marginRight: "8px" }}
                />
                Configration
              </button>
            </div>
            <div
              onClick={() => navigate("/servicecall")}
              style={{ cursor: "pointer" }}
            >
              <img src={images.callImg} />
            </div>
            <button className="alarm-btn" onClick={() => navigate("/alarm")}>
              Alarm
            </button>
          </>
        ),
      })
    );
  }, []);

  const sliderColor = [
    "sliderColor1",
    "sliderColor2",
    "sliderColor3",
    "sliderColor4",
    "sliderColor5",
    "sliderColor6",
  ];

  const sliderData = positionValue5
    ? positionValue5.map((e, i) => {
      return {
        Title: dashboard.length ? dashboard[0].position_5_names.length ? dashboard[0].position_5_names[i].tag_comment : "" : "",
        data: e,
        unit: dashboard.length ? dashboard[0].position_5_names.length ? dashboard[0].position_5_names[i].tag_unit : "" : "",
      };
    })
    : [];

  const sliderData1 = positionValue6
    ? positionValue6.map((e, i) => {
      return {
        Title: dashboard.length ? dashboard[0].position_6_names.length ? dashboard[0].position_6_names[i].tag_comment : "" : "",
        data: e,
        unit: dashboard.length ? dashboard[0].position_6_names.length ? dashboard[0].position_6_names[i].tag_unit : "" : "",
      };
    })
    : [];

  const sliderData2 = positionValue7
    ? positionValue7.map((e, i) => {
      return {
        Title: dashboard.length ? dashboard[0].position_7_names.length ? dashboard[0].position_7_names[i].tag_comment : "" : "",
        data: e,
        unit: dashboard.length ? dashboard[0].position_7_names.length ? dashboard[0].position_7_names[i].tag_unit : "" : "",
      };
    })
    : [];



  // sum Of Donutes Chart

  useEffect(() => {
    if (positionValue5) {
      const sum = positionValue5.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const sumRounded = sum.toFixed(2); // Round the sum to 2 decimal places
      setPositionValue_sum_5(sumRounded);
    }

    if (positionValue6) {
      const sum = positionValue6.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const sumRounded1 = sum.toFixed(2);
      setPositionValue_sum_6(sumRounded1);
    }

    if (positionValue7) {
      const sum = positionValue7.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const sumRounded2 = sum.toFixed(2);
      setPositionValue_sum_7(sumRounded2);
    }
  }, [positionValue5, positionValue6, positionValue7]);

  // useEffect(() => {
  //   if (dashboard.length && mqttTagData) {
  //     // const deviceUid = dashboard[0].position_1_name.device_uid;
  //     // const tagData = mqttTagData[dashboard[0].position_1_name.device_uid]?.find(
  //     //   f => f[dashboard[0].position_1_name.tag_name]
  //     // );
  //     const tagName = mqttTagData[dashboard[0].position_2_name.device_uid]?.find(
  //       f => f[dashboard[0].position_2_name.tag_name]
  //     )?.[dashboard[0].position_2_name.tag_name];

  //     console.log("kpkpkpkpkpkpkpk", tagName);
  //   }
  // }, [mqttTagData, dashboard]);


  return (
    <>
      {dashboard.length && topics.length && <Mqqtdata setMqttTagData={setMqttTagData} topics={topics} />}
      <div className="p-4 pb-5">
        <div className="d-flex gap-3">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                style={{ padding: "11px 19px", borderRadius: "6px" }}
              >
                <div className="d-flex ">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.speedometer} />
                    </div>
                  </Box>
                  <CardContent className="processDashboar-meterCard">
                    <Typography className="device-desc">
                      {/* {tagData1 ? tagData1.comment : "-"} */}
                      {dashboard.length ? dashboard[0].position_1_name ? dashboard[0].position_1_name.tag_comment : "-" : '-'}
                    </Typography>
                    <Typography className="device-number">
                      {dashboard.length && mqttTagData && mqttTagData[dashboard[0].position_1_name?.device_uid]?.find(
                        f => f[dashboard[0].position_1_name.tag_name]
                      )?.[dashboard[0].position_1_name.tag_name] ? 
                        mqttTagData[dashboard[0].position_1_name?.device_uid]?.find(
                          f => f[dashboard[0].position_1_name.tag_name]
                        )?.[dashboard[0].position_1_name.tag_name] : 0
                      }
                      {/* {dashboardCubeData.length && dashboard.length && dashboard[0].position_1_name ? dashboardCubeData.find((f) => f.tagUnit === dashboard[0].position_1_name.tag_unit && f.tagComment == dashboard[0].position_1_name.tag_comment).tagValue ?
                        dashboardCubeData.find((f) => f.tagUnit === dashboard[0].position_1_name.tag_unit && f.tagComment == dashboard[0].position_1_name.tag_comment).tagValue : 0  : 0} */}
                    </Typography>
                    <Typography className="unit">
                      {/* {tagData1 ? tagData1.unit : "-"} */}
                      {dashboard.length ? dashboard[0].position_1_name ? dashboard[0].position_1_name.tag_unit : "-" : '-'}
                    </Typography>
                  </CardContent>

                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                style={{ padding: "11px 19px", borderRadius: "6px" }}
              >
                <div className="d-flex ">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.speedometer} />
                    </div>
                  </Box>
                  <CardContent className="processDashboar-meterCard">
                    <Typography className="device-desc">
                      {/* {tagData2 ? tagData2.comment : "-"} */}
                      {dashboard.length ? dashboard[0].position_2_name ? dashboard[0].position_2_name.tag_comment : "-" : '-'}
                    </Typography>
                    <Typography className="device-number">
                      {/* {dashboardCubeData.length && dashboard.length && dashboard[0].position_2_name ? dashboardCubeData.find((f) => f.tagUnit === dashboard[0].position_2_name.tag_unit && f.tagComment == dashboard[0].position_2_name.tag_comment).tagValue ? 
                        dashboardCubeData.find((f) => f.tagUnit === dashboard[0].position_2_name.tag_unit && f.tagComment == dashboard[0].position_2_name.tag_comment).tagValue : 0 : 0} */}
                      {/* {positionValue2 ? positionValue2 : 0} */}
                      {dashboard.length && mqttTagData && mqttTagData[dashboard[0].position_2_name?.device_uid]?.find(
                        f => f[dashboard[0].position_2_name.tag_name]
                      )?.[dashboard[0].position_2_name.tag_name] ?
                        mqttTagData[dashboard[0].position_2_name?.device_uid]?.find(
                          f => f[dashboard[0].position_2_name.tag_name]
                        )?.[dashboard[0].position_2_name.tag_name] : 0
                      }
                    </Typography>
                    <Typography className="unit">
                      {/* {tagData2 ? tagData2.unit : "-"} */}
                      {dashboard.length ? dashboard[0].position_2_name ? dashboard[0].position_2_name.tag_unit : "-" : '-'}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                style={{ padding: "11px 19px", borderRadius: "6px" }}
              >
                <div className="d-flex">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.speedometer} />
                    </div>
                  </Box>
                  <CardContent className="processDashboar-meterCard">
                    <Typography className="device-desc">
                      {/* {tagData3 ? tagData3.comment : "-"} */}
                      {dashboard.length ? dashboard[0].position_3_name ? dashboard[0].position_3_name.tag_comment : "-" : '-'}
                    </Typography>
                    <Typography className="device-number">
                      {/* {dashboardCubeData.length && dashboard.length && dashboard[0].position_3_name ? dashboardCubeData.find((f) => f.tagUnit === dashboard[0].position_3_name.tag_unit && f.tagComment == dashboard[0].position_3_name.tag_comment).tagValue ? 
                        dashboardCubeData.find((f) => f.tagUnit === dashboard[0].position_3_name.tag_unit && f.tagComment == dashboard[0].position_3_name.tag_comment).tagValue : 0 : 0} */}
                      {/* {positionValue3 ? positionValue3 : 0} */}
                      {dashboard.length && mqttTagData && mqttTagData[dashboard[0].position_3_name?.device_uid]?.find(
                        f => f[dashboard[0].position_3_name.tag_name]
                      )?.[dashboard[0].position_3_name.tag_name] ?
                        mqttTagData[dashboard[0].position_3_name?.device_uid]?.find(
                          f => f[dashboard[0].position_3_name.tag_name]
                        )?.[dashboard[0].position_3_name.tag_name] : 0
                      }
                     
                    </Typography>
                    <Typography className="unit">
                      {/* {tagData3 ? tagData3.unit : "-"} */}
                      {dashboard.length ? dashboard[0].position_3_name ? dashboard[0].position_3_name.tag_unit : "-" : '-'}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                style={{ padding: "11px 19px", borderRadius: "6px" }}
              >
                <div className="d-flex ">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.speedometer} />
                    </div>
                  </Box>
                  <CardContent className="processDashboar-meterCard">
                    <Typography className="device-desc">
                      {/* {tagData4 ? tagData4.comment : "-"} */}
                      {dashboard.length ? dashboard[0].position_4_name ? dashboard[0].position_4_name.tag_comment : "-" : '-'}
                    </Typography>
                    <Typography className="device-number">
                      {/* {dashboardCubeData.length && dashboard.length && dashboard[0].position_4_name ? dashboardCubeData.find((f) => f.tagUnit === dashboard[0].position_4_name.tag_unit && f.tagComment == dashboard[0].position_4_name.tag_comment).tagValue ? 
                        dashboardCubeData.find((f) => f.tagUnit === dashboard[0].position_4_name.tag_unit && f.tagComment == dashboard[0].position_4_name.tag_comment).tagValue : 0 : 0} */}
                      {/* {positionValue4 ? positionValue4 : 0} */}
                      {dashboard && dashboard.length && mqttTagData && mqttTagData[dashboard[0]?.position_4_name?.device_uid]?.find(
                        f => f[dashboard[0]?.position_4_name?.tag_name]
                      )?.[dashboard[0]?.position_4_name?.tag_name] ? mqttTagData[dashboard[0]?.position_4_name?.device_uid]?.find(
                        f => f[dashboard[0]?.position_4_name?.tag_name]
                      )?.[dashboard[0]?.position_4_name?.tag_name] : 0}

                    </Typography>
                    <Typography className="unit" style={{ height: "20px" }}>
                      {/* {tagData4 ? tagData4.unit : "-"} */}
                      {dashboard.length ? dashboard[0].position_4_name ? dashboard[0].position_4_name.tag_unit : "-" : '-'}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div className="pt-3 process-card-chart">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card
                style={{
                  height: "550px",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="graph-box">
                  <div className="d-flex">
                    <img
                      src={
                        activeChart === "pie"
                          ? images.pieChartBgImg
                          : images.pieChart
                      }
                      // height="40px"
                      // width="50px"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleChartClick("pie")}
                    />
                    <img
                      src={
                        activeChart === "bar"
                          ? images.barChartBgImg
                          : images.barChart
                      }
                      // height="40px"
                      // height="50px"
                      // width="50px"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleChartClick("bar")}
                    // className="barChart-background"
                    />
                  </div>
                  <div
                    className="graph-date pt-3"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Box className="process-header-btn-date">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="Start Date"
                          value={startDateReFormat}
                          inputFormat="dd/MM/yyyy" // Set the input format to "dd/MM/yyyy"
                          views={["day"]}
                          onChange={(newValue) => {
                            dispatch(
                              setProcessDashboarDateSet({
                                startDateRe: newValue,
                              })
                            );
                            // setStartDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box
                      className="process-header-btn-month"
                      style={{ marginRight: "11px" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="End Date"
                          value={endDateReFormat}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            // setEndDate(newValue);
                            dispatch(
                              setProcessDashboarDateSet({ endDateRe: newValue })
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
                <p className="processDashboard-chartText">
                  SOURCE OVER CONSUMPTIONS
                </p>
                <div
                  style={
                    {
                      // height: "250px",
                      // width: "100%",
                      // padding: "30px",
                      // marginLeft:"86px",
                    }
                  }
                >
                  {activeChart === "pie" && (
                    <DonutChart
                      tagData={tagData5}
                      dashboard={dashboard.length ? dashboard[0].position_5_names : []}
                      positionValue={positionValue5}
                      sum={positionValue_sum_5}
                    />
                  )}
                </div>
                <div
                  style={
                    {
                      // height: "250px",
                      // width: "100%",
                      // padding: "30px",
                      // marginLeft:"86px",
                    }
                  }
                >
                  {activeChart === "bar" && (
                    <BarChart
                      tagData={tagData5}
                      dashboard={dashboard.length ? dashboard[0].position_5_names : []}
                      positionValue={positionValue5}
                    />
                  )}
                </div>

                <div className="slider-data-div">
                  <Slider
                    key={sliderData.length}
                    {...sliderSettings}
                    className="my-slider-class"
                  >
                    {sliderData.map((e, i) => (
                      <div className={`slider-title ${sliderColor[i]}`}>
                        <p className="silder--title">{e.Title}</p>
                        <p className="silder--data">{e.data.toFixed(2)}</p>{" "}
                        {/* Round e.data to 2 decimal places */}
                        <p className="silder--unit">{e.unit}</p>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card
                style={{
                  height: "550px",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="graph-box">
                  <div className="d-flex">
                    <img
                      src={
                        activeChart1 === "pie"
                          ? images.pieChartBgImg
                          : images.pieChart
                      }
                      // height="50px"
                      // width="50px"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleChartClick1("pie")}
                    />
                    <img
                      src={
                        activeChart1 === "bar"
                          ? images.barChartBgImg
                          : images.barChart
                      }
                      // height="40px"
                      // height="50px"
                      // width="50px"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleChartClick1("bar")}
                    />
                  </div>
                  <div
                    className="graph-date pt-3"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Box className="process-header-btn-date">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="Start Date"
                          value={startDateReFormat2}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            // setStartDate6(newValue);
                            dispatch(
                              setProcessDashboarDateSet({
                                startDateRe2: newValue,
                              })
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box
                      className="process-header-btn-month"
                      style={{ marginRight: "11px" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="End Date"
                          value={endDateReFormat2}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            // setEndDate6(newValue);
                            dispatch(
                              setProcessDashboarDateSet({
                                endDateRe2: newValue,
                              })
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
                <p className="processDashboard-chartText">
                  CONSUMER OVER CONSUMER
                </p>
                <div
                  style={
                    {
                      // height: "250px",
                      // width: "100%",
                      // padding: "30px",
                    }
                  }
                >
                  {activeChart1 === "pie" ? (
                    <DonutChart
                      tagData={tagData6}
                      positionValue={positionValue6}
                      sum={positionValue_sum_6}
                      dashboard={dashboard.length ? dashboard[0].position_6_names : []}
                    />
                  ) : (
                    <BarChart
                      tagData={tagData6}
                        positionValue={positionValue6}
                        dashboard={dashboard.length ? dashboard[0].position_6_names : []}
                    />
                  )}
                </div>
                <div className="slider-data-div">
                  <Slider
                    key={sliderData1.length}
                    {...sliderSettings}
                    className="my-slider-class"
                  >
                    {sliderData1.map((e, i) => (
                      <div className={`slider-title ${sliderColor[i]}`}>
                        <p className="silder--title">{e.Title}</p>
                        <p className="silder--data">{e.data.toFixed(2)}</p>
                        <p className="silder--unit">{e.unit}</p>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card
                style={{
                  height: "550px",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="graph-box">
                  <div className="d-flex">
                    <img
                      src={
                        activeChart2 === "pie"
                          ? images.pieChartBgImg
                          : images.pieChart
                      }
                      // height="50px"
                      // width="50px"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleChartClick2("pie")}
                    />
                    <img
                      src={
                        activeChart2 === "bar"
                          ? images.barChartBgImg
                          : images.barChart
                      }
                      // height="40px"
                      // height="50px"
                      // width="50px"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleChartClick2("bar")}
                    />
                  </div>
                  <div
                    className="graph-date pt-3"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Box className="process-header-btn-date">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="Start Date"
                          value={startDateReFormat3}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            // setStartDate7(newValue);
                            dispatch(
                              setProcessDashboarDateSet({
                                startDateRe3: newValue,
                              })
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box
                      className="process-header-btn-month"
                      style={{ marginRight: "11px" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="End Date"
                          value={endDateReFormat3}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            // setEndDate7(newValue);
                            dispatch(
                              setProcessDashboarDateSet({
                                endDateRe3: newValue,
                              })
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
                <p className="processDashboard-chartText">SOURCE OVER SOURCE</p>
                <div
                  style={
                    {
                      // height: "250px",
                      // width: "100%",
                      // padding: "30px",
                    }
                  }
                >
                  {activeChart2 === "pie" ? (
                    <DonutChart
                      tagData={tagData7}
                      positionValue={positionValue7}
                      sum={positionValue_sum_7}
                      dashboard={dashboard.length ? dashboard[0].position_7_names : []}
                    />
                  ) : (
                    <BarChart
                      tagData={tagData7}
                        positionValue={positionValue7}
                        dashboard={dashboard.length ? dashboard[0].position_7_names : []}
                    />
                  )}
                </div>
                <div className="slider-data-div">
                  <Slider
                    key={sliderData2.length}
                    {...sliderSettings}
                    className="my-slider-class"
                  >
                    {sliderData2.map((e, i) => (
                      <div className={`slider-title ${sliderColor[i]}`}>
                        <p className="silder--title">{e.Title}</p>
                        <p className="silder--data">{e.data.toFixed(2)}</p>
                        <p className="silder--unit">{e.unit}</p>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={1} className="pt-4">
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <button
              // className="Process-dashboard-btn"
              className={`${activeClass == "overview"
                  ? "active-process-dashboard"
                  : "Process-dashboard-btn"
                }`}
              // style={{pointerEvents:"none"}}
              onClick={() => handleOverViewClick()}
            >
              OVERVIEW
            </button>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <button
              className={`${activeClass == "source"
                  ? "active-process-dashboard"
                  : "Process-dashboard-btn"
                }`}
              style={{ pointerEvents: "none" }}
              onClick={() => handleSourceClick()}
            >
              SOURCE
            </button>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <button
              className={`${activeClass == "consumption"
                  ? "active-process-dashboard"
                  : "Process-dashboard-btn"
                }`}
              onClick={() => handleConsumption()}
            >
              CONSUMPTIONS
            </button>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <button
              className={`${activeClass == "Analytics"
                  ? "active-process-dashboard"
                  : "Process-dashboard-btn"
                }`}
              style={{ pointerEvents: "none" }}
            // onClick={() => handleAdvanceAnalytics()}
            >
              ADVANCED ANALYTICS
            </button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default ProcessDashboard;
