/* eslint-disable eqeqeq */
import React from 'react'
import Container from '@mui/material/Container';
import { Grid } from "@mui/material";
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './HikemmServices.css'

function HikemmServices() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(25);
    const [usersValue, setUsersvalue] = React.useState(3);

    const handleChangeDevice = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeUsers = () => {
        setUsersvalue();
    }

    return (
        <>
            <div className='containerWrap'>
                <Container maxWidth="xxl">
                    <div className="subscriptionTitle-wrap">
                        <KeyboardBackspaceIcon onClick={() => navigate('/subscriptionManagement')} className='backArrow' /> <Typography variant="h5" gutterBottom style={{ margin: '0', paddingLeft: '18px', color: '#001323', fontWeight: '600' }}>
                            HIKEMM Services
                        </Typography>
                    </div>
                    <Typography variant="subtitle1" gutterBottom style={{ marginBottom: '20px' }}>
                        Choose the devices & Users for EMS Services!
                    </Typography>
                </Container>
                <Container fluid className='abc'>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 1, xl: 2, xxl: 2 }}>
                        {/* =============== Device =============== */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <Card className='hikemmDeviceModal-card'>
                                <Box className='hikemmDeviceModal-titleWrap'>
                                    <Typography variant="h5" gutterBottom className='hikemmDeviceModal-title'>
                                        Devices
                                    </Typography>
                                    <select class="hikemmDeviceModal-dropDown form-select" aria-label="Default select example">
                                        <option selected>Data Storage 1 Year</option>
                                        <option value="1" className='abc'>Data Storage 3 Year</option>
                                        <option value="2">Data Storage 5 Year</option>
                                    </select>
                                </Box>
                                <Box className='hikemmDeviceModal-innercard'>
                                    <Box className='hikemmDeviceModal-headerWrap'>
                                        <Typography variant="h5" gutterBottom className='hikemmDeviceModal-headerText'>
                                            Don't worry! your device license product starts only<br></br> when you assign license to Energy Devices
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" gutterBottom className='hikemmDeviceModal-innerWrapText'>
                                        How Many Energy Devices<br></br> You Want to Connect
                                    </Typography>
                                    <Box className='hikemmDeviceModal-innerWrap'>
                                        <Stack spacing={2} direction="row" className='hikemmDeviceModal-innerButtonwrap'>
                                            <button className='decrementButton' onClick={() => setValue(25)}>-</button>
                                            <Typography variant="h6" gutterBottom style={{ fontWeight: 400 }}>
                                                {value}
                                            </Typography>
                                            <button className='decrementButton' onClick={() => setValue(100)}>+</button>
                                        </Stack>
                                        <Box>
                                            <Slider aria-label="Volume" value={value} onChange={handleChangeDevice} className='hikemmDeviceModal-range' />
                                            <Box className='hikemmDeviceModal-rangeNumber'>
                                                <Typography variant="h6" gutterBottom>
                                                    0
                                                </Typography>
                                                <Typography variant="h6" gutterBottom>
                                                    5
                                                </Typography>
                                                <Typography variant="h6" gutterBottom>
                                                    10
                                                </Typography>
                                                <Typography variant="h6" gutterBottom>
                                                    25
                                                </Typography>
                                                <Typography variant="h6" gutterBottom>
                                                    50
                                                </Typography>
                                                <Typography variant="h6" gutterBottom>
                                                    100
                                                </Typography>
                                                <Typography variant="h6" gutterBottom>
                                                    More
                                                </Typography>
                                            </Box>
                                            {value == 100 && <Box>
                                                <Typography variant="h5" gutterBottom className='hikemmDeviceModal-innerText'>
                                                    Energy Devices can be used to Monitor and Analyze<br></br> Energy usage your factory equipment with HiCloud.
                                                </Typography>
                                            </Box>}
                                        </Box>
                                        {value == 100 && <Box className='hikemmDeviceModal-billedBoxwrap'>
                                            <Box className='hikemmDeviceModal-billedBox1'>
                                                <Typography variant="body2" gutterBottom className='hikemmDeviceModal-billedBoxTitle1'>
                                                    Billed Monthly
                                                </Typography>
                                                <Box className='hikemmDeviceModal-billedinnerBox1'>
                                                    <Box>
                                                        <Typography variant="h6" gutterBottom>
                                                            ₹12.00
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="body2" gutterBottom>per connection<br className='d-md-none'></br> per month</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className='hikemmDeviceModal-billedinnerBoxtext1'>
                                                    <Typography variant="body2" gutterBottom>
                                                        Total <b>$60.00</b> per month
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box className='hikemmDeviceModal-billedBox2'>
                                                <Typography variant="body2" gutterBottom className='hikemmDeviceModal-billedBoxTitle2'>
                                                    Billed Monthly
                                                </Typography>
                                                <Box className='hikemmDeviceModal-billedinnerBox2'>
                                                    <Box>
                                                        <Typography variant="h6" gutterBottom>
                                                            ₹12.00
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="body2" gutterBottom>per connection<br className='d-md-none'></br> per month</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className='hikemmDeviceModal-billedinnerBoxtext2'>
                                                    <Typography variant="body2" gutterBottom>
                                                        Total <b>$60.00</b> per month
                                                    </Typography>
                                                </Box>
                                                <div className='hikemmDeviceModal-billedBox2Tag'>
                                                    <Typography variant="body2" gutterBottom style={{ margin: '0px' }}>
                                                        Save $60 per your
                                                    </Typography>
                                                </div>
                                            </Box>
                                        </Box>}
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                        {/* =============== Users =============== */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <Card className='hikemmDeviceModal-card custom-class'>
                                <Box className='hikemmDeviceModal-titleWrap'>
                                    <Typography variant="h5" gutterBottom className='hikemmDeviceModal-title'>
                                        Users
                                    </Typography>
                                </Box>
                                <Box className='hikemmDeviceModal-innercard'>
                                    <Box className='hikemmDeviceModal-headerWrap'>
                                        <Typography variant="h5" gutterBottom className='hikemmDeviceModal-headerText'>
                                            Don't pay per user. Pay for<br></br> what your actually use.
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" gutterBottom className='hikemmDeviceModal-innerWrapText'>
                                        How Many Active User Connection<br></br>Your Company Want
                                    </Typography>
                                    <Box className='hikemmDeviceModal-innerWrap'>
                                        <Stack spacing={2} direction="row" className='hikemmDeviceModal-innerButtonwrap'>
                                            <button className='decrementButton' onClick={() => setUsersvalue(3)}>-</button>
                                            <Typography variant="h6" gutterBottom style={{ fontWeight: 400 }}>
                                                {usersValue}
                                            </Typography>
                                            <button className='decrementButton' onClick={() => setUsersvalue(25)}>+</button>
                                        </Stack>
                                        <Box>
                                            <Slider aria-label="Volume" value={usersValue} onChange={handleChangeUsers} className='hikemmDeviceModal-range' />
                                            <Box className='hikemmDeviceModal-rangeNumber'>
                                                <Typography variant="h6" gutterBottom>
                                                    Free
                                                </Typography>
                                                <Typography variant="h6" gutterBottom>
                                                    10
                                                </Typography>
                                                <Typography variant="h6" gutterBottom>
                                                    25
                                                </Typography>
                                                <Typography variant="h6" gutterBottom>
                                                    More
                                                </Typography>
                                            </Box>
                                            {usersValue == 25 && <Box>
                                                <Typography variant="h5" gutterBottom className='hikemmDeviceModal-innerText'>
                                                    Active user connect to their factory by sitting any<br></br> where on the world with HiCloud..
                                                </Typography>
                                            </Box>}
                                        </Box>
                                        {usersValue == 25 && <Box className='hikemmDeviceModal-billedBoxwrap'>
                                            <Box className='hikemmDeviceModal-billedBox1'>
                                                <Typography variant="body2" gutterBottom className='hikemmDeviceModal-billedBoxTitle1'>
                                                    Billed Monthly
                                                </Typography>
                                                <Box className='hikemmDeviceModal-billedinnerBox1'>
                                                    <Box>
                                                        <Typography variant="h6" gutterBottom>
                                                            ₹12.00
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="body2" gutterBottom>per connection<br className='d-md-none'></br> per month</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className='hikemmDeviceModal-billedinnerBoxtext1'>
                                                    <Typography variant="body2" gutterBottom>
                                                        Total <b>$60.00</b> per month
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box className='hikemmDeviceModal-billedBox2'>
                                                <Typography variant="body2" gutterBottom className='hikemmDeviceModal-billedBoxTitle2'>
                                                    Billed Monthly
                                                </Typography>
                                                <Box className='hikemmDeviceModal-billedinnerBox2'>
                                                    <Box>
                                                        <Typography variant="h6" gutterBottom>
                                                            ₹12.00
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="body2" gutterBottom>per connection<br className='d-md-none'></br> per month</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className='hikemmDeviceModal-billedinnerBoxtext2'>
                                                    <Typography variant="body2" gutterBottom>
                                                        Total <b>$60.00</b> per month
                                                    </Typography>
                                                </Box>
                                                <div className='hikemmDeviceModal-billedBox2Tag'>
                                                    <Typography variant="body2" gutterBottom style={{ margin: '0px' }}>
                                                        Save $60 per your
                                                    </Typography>
                                                </div>
                                            </Box>
                                        </Box>}
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
                <Box className='hikemmDeviceModal-footerText'>
                    <Typography variant="h5" gutterBottom>
                        Get a quote from sales@hikartech.com
                    </Typography>
                    <Box className='hikemmDeviceModal-footerBtnwrap'>
                        <button className='contactUs-button'>Contact Us</button>
                        <button className='Back-button'>Back</button>
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default HikemmServices