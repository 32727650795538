import React, { useEffect, useMemo, useState } from 'react';
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import Mqqtdata from '../Mqqtdata';
import { async } from 'q';

const CubeQueryComponent = ({ getDevice, handelCubeChartLineData, handelCubeChartLineData1, selectTimeRange }) => {

    const [selectTime, setSelectTime] = useState();
    const [topics, setTopics] = useState("");
    const [mqttTagData, setMqttTagData] = useState([]);

    console.log("topicstopicstopicstopics", mqttTagData);

    useEffect(() => {
        if (getDevice.length) {
            const topicArray = 'Hikar/DeviceUID/' + getDevice[0].device__uid
            console.log("topicArray", topicArray);
            setTopics(topicArray)
        }
    }, [getDevice])
    
    useMemo(async() => {

        if (getDevice.length) {
            const mergedArray = [].concat(...Object.values(mqttTagData))

            const filteredArray = mergedArray.filter(obj => {
                const device = obj.device__uid;
                const deviceTag = obj.device_tag;
                return getDevice[0].device__uid == device && getDevice[0].device_tag == deviceTag
            });
            console.log("filteredArray", filteredArray);
            const minMaxvalue = await cubejsApi.load({
                "measures": [
                    "DashboardTaghistory.maxValue",
                    "DashboardTaghistory.minValue"
                ],
                "order": {
                    "DashboardTaghistory.createdAt": "asc"
                },
                "filters": [
                    {
                        "member": "DashboardTaghistory.tag",
                        "operator": "equals",
                        "values": [getDevice[0].id.toString()]
                    }
                ]
            });


            const maxMincube = {
                maxValue: parseFloat(minMaxvalue.tablePivot().length ? minMaxvalue.tablePivot()[0]["DashboardTaghistory.maxValue"] : 0),
                minValue: parseFloat(minMaxvalue.tablePivot().length ? minMaxvalue.tablePivot()[0]["DashboardTaghistory.minValue"] : 0)
            };
            
            if (filteredArray.length) {
                handelCubeChartLineData(filteredArray[0], maxMincube)
            }
         
        }

    }, [mqttTagData])

    useEffect(() => {
        if (!getDevice[0]) {
            return true
        }
        
        // Function to execute the cube query
        const executeCubeQuery = async () => {
            try {
                var currentTime;

                if (selectTimeRange == "1min") {
                    currentTime = new Date().toLocaleTimeString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        hour12: false,
                    });
                }

                if (selectTimeRange == "5min") {
                    currentTime = new Date().toLocaleTimeString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        hour12: false,
                    });
                }

                if (selectTimeRange == "15min") {
                    currentTime = new Date().toLocaleTimeString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        hour12: false,
                    });
                }

                const realTimeArray = [];

                const chartGraph = await cubejsApi.load(
                    {
                        "dimensions": [
                            "DeviceConfigurationTagconfiguration.latestValue"
                        ],
                        "limit": 1,
                        "filters": [
                            {
                                "member": "DeviceConfigurationTagconfiguration.tagid",
                                "operator": "equals",
                                "values": [getDevice[0].id.toString()]
                            }
                        ]
                    });
                // values: [getDevice[0].id.toString()]
                const cubeData = {
                    tagValue: parseFloat(chartGraph.tablePivot().length ? chartGraph.tablePivot()[0]["DeviceConfigurationTagconfiguration.latestValue"] ? chartGraph.tablePivot()[0]["DeviceConfigurationTagconfiguration.latestValue"] : 0 : 0),
                    tagTime: currentTime
                };

                const minMaxvalue = await cubejsApi.load({
                    "measures": [
                        "DashboardTaghistory.maxValue",
                        "DashboardTaghistory.minValue"
                    ],
                    "order": {
                        "DashboardTaghistory.createdAt": "asc"
                    },
                    "filters": [
                        {
                            "member": "DashboardTaghistory.tag",
                            "operator": "equals",
                            "values": [getDevice[0].id.toString()]
                        }
                    ]
                });


                const maxMincube = {
                    maxValue: parseFloat(minMaxvalue.tablePivot().length ? minMaxvalue.tablePivot()[0]["DashboardTaghistory.maxValue"] : 0),
                    minValue: parseFloat(minMaxvalue.tablePivot().length ? minMaxvalue.tablePivot()[0]["DashboardTaghistory.minValue"] : 0)
                };
                handelCubeChartLineData(cubeData, maxMincube)
            } catch (error) {
                // Handle any errors that occur during the cube query
                console.error('Cube query error:', error);
            }
        };

        
        // Execute the cube query immediately

        if (selectTimeRange != "live") {
            executeCubeQuery();   
        }
        

        // Set interval to execute the cube query every minute
        var interval = null;

        if (selectTimeRange == "1min") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 60000);
        }

        if (selectTimeRange == "5min") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 300000);
        }

        if (selectTimeRange == "15min") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 900000);
        }


        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(interval);
        };
    }, [getDevice]);


    return (
    <>
            {selectTimeRange == "live" && topics && <Mqqtdata overViewTagData={getDevice} topics={topics} setMqttTagData={setMqttTagData} />}
        </>
    )
};

export default CubeQueryComponent;
