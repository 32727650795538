import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "../Source/Source.css";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import ConfigrationCardSlider from "../Consumption/ConfigrationSlider";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import { useEffect } from "react";
import { getDeviceConfigData, getDeviceSourceAPI, getTagID } from "../Source/services";
import { LineChart } from "./LineChart";
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import { getDeviceConf } from "./service";

const ConfigrationConsumption = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [value, setValue] = React.useState("1");
  const [deviceData, setDeviceData] = useState([]);
  const [deviceConfig, setDeviceConfig] = useState([]);
  const [device, setDevice] = useState("");
  const [tagData, setTagData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [unitSearchQuery, setUnitSearchQuery] = useState("");
  const [deviceId, setDeviceId] = useState([]);
  const [unitName, setUnitName] = useState([]);
  const [getDevice, setGetDevice] = useState([]);
  const [realTimevalue, setRealTimevalue] = useState([]);
  const [minValue, setMinValue] = useState([]);
  const [maxValue, setMaxValue] = useState([]);
  const [chartCubeData, setChartCubeData] = useState([])
  const [selectTimeRange, setSelectTimeRange] = useState("minute")
  const [selectedColors, setSelectedColors] = useState([]);
  const [average, setAverage] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [confData, setConfData] = useState("");

  const switchColors = [
    "#9b64b7",
    "#FEA968",
    "#E579A0",
    "#EEDCEE",
    "#C87171",
    "#E31E24",
    "#45BF6D",
    "#AEBCD2",
    "#EF75A0",
    "#A9B0BD",
  ];

  useEffect(() => {
    const fetchData = async () => {
      const newConfData = await Promise.all(
        deviceData.map(async (device) => {
          const unit = await getDeviceConfData(device.device);
          return unit;
        })
      );
      setConfData(newConfData);
    };

    fetchData();
  }, [deviceData]);
  
  const getDeviceConfData = async (deviceId) => {
    try {
      const param1 = {
        plant_id: param.id,
        company_id: param.companyId,
        device_type: "Consumption",
        device_id: deviceId,
      };
  
      const resp = await getDeviceConf(param1);
      if (resp.status === 200 || resp.status === 201) {
        return resp.data.payload.length ? resp.data.payload[0].tag_assigned : [];
      }
    } catch (error) {
      console.error("Error fetching device configuration:", error);
    }
  };
  const handleUnitSearchChange = (event) => {
    setUnitSearchQuery(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const location = useLocation();

  useEffect(() => {
    handleDevice();
  }, []);

  useEffect(() => {
    handleGetConfigDevice();
  }, []);

  const handleDevice = async () => {
    const params = {
      device_type: "Consumption",
      plant_id: param.id,
    };
    const resp = await getDeviceSourceAPI(params);

    if (resp.data.success === true) {
      setDeviceData(resp.data.payload.device);
      setTagData(resp.data.payload.tag);
      // setDeviceId(resp.data.payload[0].device);
    }
  };

  const handleGetConfigDevice = async () => {
    const params = {
      plant_id: param.id,
      company_id: param.companyId,
      device_type: "Consumption",
    };
    try {
      const resp = await getDeviceConfigData(params);
      if (resp.data.success === true) {
        setDeviceConfig(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  const navigate = useNavigate();
  const handleSearchData = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectDeviceId = (id, index) => {
    const isSelected = selectedDevices.includes(id);
    const deviceColor = switchColors[index % switchColors.length];

    if (isSelected) {
      setSelectedDevices(selectedDevices.filter((deviceId) => deviceId !== id));
      setSelectedColors(
        selectedColors.filter((color) => color !== deviceColor)
      );
    } else {
      setSelectedDevices([...selectedDevices, id]);
      setSelectedColors([...selectedColors, deviceColor]);
    }
    if (!Array.isArray(deviceId)) {
      setDeviceId([]);
    }
    if (deviceId.includes(id)) {
      setDeviceId(deviceId.filter((element) => element !== id));
      setUnitName([]);
      setRealTimevalue([]);
      setMinValue([]);
      setMaxValue([]);
      setGetDevice([]);
    } else {
      setDeviceId([...deviceId, id]);
    }
  };


  const handleSelectTagUnit = (id) => {
    if (!Array.isArray(unitName)) {
      setUnitName([]);
    }
    if (unitName.includes(id)) {
      setUnitName(unitName.filter(element => element !== id));
    } else {
      setUnitName([id]);
    }
  }

  const getTagIDApi = async () => {
    const param = {
      device_id: deviceId,
      tag_unit: unitName[0]
    }

    const resp = await getTagID(param);

    if (resp.status == 200) {
      setGetDevice(resp.data.payload);
    }
  }

  useEffect(() => {
    if (deviceId.length && unitName.length) {
      getTagIDApi();
    }
  }, [deviceId, unitName]);

  useEffect(() => {
    handleCubeData()
  }, [getDevice,selectTimeRange])


  const handleCubeData = async () => {

    if (getDevice.length) {
      const realTimeArray = [];
      const minValueArray = [];
      const maxValueArray = [];


      await Promise.all(getDevice.map(async (e) => {
        const realTimeData = await cubejsApi.load({
          "dimensions": [
            "DashboardTaghistory.tagValue"
          ],
          "order": {
            "DashboardTaghistory.tagValue": "asc"
          },
          "filters": [
            {
              "member": "DashboardTaghistory.tag",
              "operator": "equals",
              "values": [
                e.id.toString()
              ]
            }
          ],
          "timeDimensions": [
            {
              "dimension": "DashboardTaghistory.createdAt",
              "granularity": "second",
              "dateRange": "Today"
            }
          ],
          "limit": 1
        });

        realTimeArray.push(realTimeData.rawData()[0]["DashboardTaghistory.tagValue"]);

        const maxMin = await cubejsApi.load(
          {
            "measures": [
              "DashboardTaghistory.minValue",
              "DashboardTaghistory.maxValue"
            ],
            "timeDimensions": [
              {
                "dimension": "DashboardTaghistory.createdAt",
                "dateRange": "Today"
              }
            ],
            "limit": 1,
            "filters": [
              {
                "member": "DashboardTaghistory.tag",
                "operator": "equals",
                "values": [
                  e.id.toString()
                ]
              }
            ],
            "order": [
              [
                "DashboardTaghistory.createdAt",
                "desc"
              ]
            ]
          }
        );

        minValueArray.push(maxMin.rawData()[0]["DashboardTaghistory.minValue"]);
        maxValueArray.push(maxMin.rawData()[0]["DashboardTaghistory.maxValue"]);



        const chartGraph = await cubejsApi.load({
          "dimensions": [
            "DashboardTaghistory.tagValue"
          ],
          "timeDimensions": [
            {
              "dimension": "DashboardTaghistory.createdAt",
              "granularity": selectTimeRange
            }
          ],
          "order": {
            "DashboardTaghistory.tagValue": "asc"
          },
          "filters": [
            {
              "member": "DashboardTaghistory.tag",
              "operator": "equals",
              "values": [
                e.id.toString()
              ]
            }
          ],
          "limit": 1000
        });

        
        // 1 minutes data chart

        if (selectTimeRange == "minute") {
          const chartValue = chartGraph.tablePivot().map((row) => {
            return ({
              tagValue: parseFloat(row["DashboardTaghistory.tagValue"]),
              tagTime: row['DashboardTaghistory.createdAt.minute'].substr(11)
            })
          });
          setChartCubeData(chartValue)
        }

        if (selectTimeRange == "hour") {
          const chartValue = chartGraph.tablePivot().map((row) => {
            return ({
              tagValue: parseFloat(row["DashboardTaghistory.tagValue"]),
              tagTime: row['DashboardTaghistory.createdAt.hour'].substr(11)
            })
          });
          setChartCubeData(chartValue)
        }  

        if (selectTimeRange == "day") {
          const chartValue = chartGraph.tablePivot().map((row) => {
            return ({
              tagValue: parseFloat(row["DashboardTaghistory.tagValue"]),
              tagTime: row['DashboardTaghistory.createdAt.day'].substr(0, 10)
            })
          });
          setChartCubeData(chartValue)
        }  

        if (selectTimeRange == "week") {
          const chartValue = chartGraph.tablePivot().map((row) => {
            return ({
              tagValue: parseFloat(row["DashboardTaghistory.tagValue"]),
              tagTime: row['DashboardTaghistory.createdAt.week'].substr(0, 10)
            })
          });
          setChartCubeData(chartValue)
        }  


      }));

      setRealTimevalue(realTimeArray);
      setMinValue(minValueArray);
      setMaxValue(maxValueArray);
    }



  }


  const param = useParams();
  return (
    <>
      <div className="page-wraper">
        <div className="page-header">
          <div
            onClick={() =>
              navigate(
                `/consumption/company/${param.companyId}/plant/${param.id}`
              )
            }
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Consumptions</span>
          </div>
        </div>

        <div className="pt-3">
          <ConfigrationCardSlider deviceData={deviceData} confData={confData} />
        </div>
        <div className="pt-2 consumption-grid-card">
        <Grid item xs={12} sm={12} md={3}>
            <Box className="source-box">
              <Box className="consumer-text-disc mt-2">
                <h6 className="Title">Title</h6>
                <h6
                  className="Title-desc"
                  style={{ marginBottom: "0 !important" }}
                >
                   Energy Meter Trends - Real Time
                </h6>
              </Box>

              {/* <Box className="consumer-text-disc mt-2">
                <h6 className="Title">Description</h6>
                <h6 className="Title-desc">Energy Meter Trends - Real Time</h6>
              </Box> */}

              <TextField
                variant="outlined"
                className="search-textfield"
                placeholder="Search Device here"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />
              <div className="furnace-grid">
                <Box className="Grid-text mt-2">
                  {deviceData.length
                    ? deviceData
                      .filter((e) =>
                        e.device__device_name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )
                      .map((e, index) => (
                        <Grid item xs={12} key={e.device__device_name}>
                          <Grid
                            container
                            alignItems="center"
                            style={{
                              background: "#efeffd",
                              width: "100%",
                              marginBottom: "7px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              style={{
                                padding: "7px 12px",
                                borderLeft: `4px solid ${switchColors[index % switchColors.length]
                                  }`,
                              }}
                            >
                              <h6 className="mt-2">
                                {e.device__device_name}
                              </h6>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              textAlign="right"
                              className="grid-switch-unit"
                            >
                              <Switch
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  handleSelectDeviceId(e.device, index, event, e)
                                }
                                disabled
                                name={switchColors[index]}
                                // disabled={isCheckboxDisabled(e.device)}
                                // checked={deviceIdRe.includes(e.device)}
                                sx={{
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#828282",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#D9D9E5 !important",
                                  },
                                  "& .Mui-checked": {
                                    "& .MuiSwitch-thumb": {
                                      bgcolor:
                                        switchColors[
                                        index % switchColors.length
                                        ],
                                    },
                                    "& .MuiSwitch-track": {
                                      bgcolor: "#E2D9F2 !important",
                                    },
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    : []}
                </Box>
              </div>
              <Box className="consumer-text-disc mt-2 ">
                {/* <h6 className="Title">Description</h6>
                <h6 className="Title-desc">Units</h6> */}
                {/* <div class="horizontal-line"></div> */}
              </Box>

              <TextField
                variant="outlined"
                className="search-textfield"
                placeholder="Search Unit here"
                value={unitSearchQuery}
                onChange={handleUnitSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />

              <div className="device-unit">
                <Box className="Grid-text1 mt-2">
                  {tagData
                    .filter(
                      (e) =>
                        e.tag_unit &&
                        e.tag_unit
                          .toLowerCase()
                          .includes(unitSearchQuery.toLowerCase())
                    )
                    .map((e) => (
                      <Grid item xs={12} key={e.tag_unit}>
                        {e.tag_unit && (
                          <Grid
                            container
                            alignItems="center"
                            style={{
                              background: "#efeffd",
                              width: "100%",
                              marginBottom: "7px",
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              className="mt-2"
                              style={{ padding: "7px 12px" }}
                            >
                              <h6>{e.tag_unit}</h6>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              textAlign="right"
                              className="grid-switch-unit"
                            >
                              <Switch
                                checked={unitName.includes(e.tag_unit)}
                                style={{ float: "right" }}
                                onClick={() => handleSelectTagUnit(e.tag_unit)}
                                disabled
                                sx={{
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#828282",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#D9D9E5 !important",
                                  },
                                  "& .Mui-checked": {
                                    "& .MuiSwitch-thumb": {
                                      bgcolor: "#50C878",
                                    },
                                    "& .MuiSwitch-track": {
                                      bgcolor: "#CEE6E1 !important",
                                    },
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                </Box>
              </div>

              <div>
                <button className="export-btn">EXPORT ENERGY DATA</button>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9} className="consumption-table-box">
            <Box className="source-table-box">
              <div style={{ paddingTop: "20px" }}>
                <div className="d-flex justify-content-end">
                  <Box className="source-boxes">
                    <h6 style={{ color: "#50C878" }}>Cum. : 0%</h6>
                  </Box>

                  <Box className="source-boxes" style={{ marginRight: "22px" }}>
                    <h6>
                      Threshold:<b> 0%</b>
                    </h6>
                  </Box>
                  <p className="average-toggle">Average</p>
                  <Switch
                    checked={average}
                    onChange={(e) => setAverage(e.target.checked)}
                    disabled
                    className="average-toggle-switch"
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: "#828282",
                      },
                      "& .MuiSwitch-track": {
                        bgcolor: "#D9D9E5 !important",
                      },
                      "& .Mui-checked": {
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#50C878",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#CEE6E1 !important",
                        },
                      },
                    }}
                  />
                  {/* <FormControlLabel
                    value="start"
                    control={<Switch color="#45BF6D" />}
                    label="Average"
                    labelPlacement="start"
                  /> */}
                  <div className="d-flex ">
                    <h6 className="time-desc">Interval</h6>
                    <Box
                      sx={{ minWidth: 200 }}
                      style={{ float: "right", marginRight: "20px" }}
                    >
                      <select
                        className="form-input-class"
                        style={{ padding: "6px 8px", borderRadius: "4px" }}
                        id="selectedClass"
                        // defaultValue="all"
                        value={selectTimeRange}
                        onChange={(event) =>
                          setSelectTimeRange(event.target.value)
                        }
                        required
                      >
                        <option value="minute" selected>
                          1 Min
                        </option>
                        <option value="15">15 Min</option>
                        <option value="2">30 Min</option>
                        <option value="hour">Hourly</option>
                        <option value="day">Daily</option>
                        <option value="week">Weekly</option>
                      </select>
                    </Box>
                  </div>
                </div>
              </div>
              <div style={{ height: "370px", width: "100%", padding: "20px" }}>
  
                  <LineChart
                   selectedColors={selectedColors}
                    unitName={unitName[0]}
                    chartCubeData={chartCubeData}
                    getDevice={getDevice}
                    average={average}
               // Pass the selected switch color to the LineChart component
                  />
      
              </div>
              <div>
                <Table className="source-table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "600",
                          width: "18%",
                          fontSize: "18px",
                          fontWeight: "800",
                        }}
                      >
                        {unitName[0]}
                      </TableCell>

                      {getDevice.length
                        ? getDevice.map((e) => {
                            return (
                              <TableCell>
                                <div className="d-flex justify-content-between">
                                  <div>{e.device__device_name}</div>
                                  <div>
                                    <NorthIcon style={{ color: "#FEA968" }} />{" "}
                                    0%
                                  </div>
                                </div>
                              </TableCell>
                            );
                          })
                        : []}
                      {/* <TableCell>
                        <div className="d-flex justify-content-between">
                          <div>Furnace - 1</div>
                          <div>
                            <NorthIcon style={{ color: "#FEA968" }} /> 12%
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-between">
                          <div>Furnace - 2</div>
                          <div>
                            <SouthIcon style={{ color: "#9B64B7" }} /> 12%
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-between">
                          <div>Furnace - 3</div>
                          <div>
                            <SouthIcon style={{ color: "#E579A0" }} /> 12%
                          </div>
                        </div>
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ fontWeight: "500" }}>
                        Real Time
                      </TableCell>
                      {realTimevalue.length
                        ? realTimevalue.map((e) => {
                            return <TableCell>{e}</TableCell>;
                          })
                        : []}
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "500" }}>
                        All Time Min.
                      </TableCell>
                      {minValue.length
                        ? minValue.map((e) => {
                            return <TableCell>{e}</TableCell>;
                          })
                        : []}
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "500" }}>
                        All Time Max.
                      </TableCell>
                      {maxValue.length
                        ? maxValue.map((e) => {
                            return <TableCell>{e}</TableCell>;
                          })
                        : []}
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default ConfigrationConsumption;
