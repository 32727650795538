import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
// import { Textarea } from '@material-ui/core';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Grid } from "@mui/material";
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { images } from "../../../config/images";
import {
  getcompanyList,
  addCompany,
  editCompany,
  deleteCompany,
  inviteUserByCompany,
  getSortbycompanyList,
} from "../service";
import ExpandCompany from "./expandCompany";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ClientManagement.css";

const ClientManagement = () => {
  const [openAddcompany, setOpenAddcompany] = useState(false);
  const [openEditcompany, setOpenEditcompany] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [addCompanyData, setAddCompanyData] = useState({
    id: "",
    company_name: "",
    company_description: "",
  });
  const [updateCompanyData, setUpdateCompanyData] = useState({
    company_name: "",
    company_description: "",
  });
  const [inviteUser, setinviteUser] = useState({
    company_name: "",
    user_role: "",
    first_name: "",
    last_name: "",
    email: "",
  });
  const [updateData, setUpdateData] = useState({});
  const [openInviteUserModal, setOpenInviteUserModal] = useState(false);
  const [Arrow, setArrow] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [expanded, setExpanded] = useState({});
  const [comapnySearch, setCompanySearch] = useState("");
  const [sortByName, setSortByName] = useState(false);
  const navigate = useNavigate();

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  //Add plant modal
  const handleOpenaddCompanyModal = (id) => {
    setCompanyId(id);
    setOpenAddcompany(!openAddcompany);
  };

  //Edit Company model
  const handleOpenEditCompanyModal = (e) => {
    setCompanyId(e.id);
    console.log("kp", e);
    setUpdateCompanyData({
      id: e.id,
      company_name: e.company_name,
      company_description: e.company_description,
    });
    setOpenEditcompany(!openEditcompany);
  };

  const handleClose = () => {
    setArrow(!Arrow);
  };
  //Edit and delete modal
  const handleOpenUpdateModal = (event, id) => {
    setUpdateData((prevUpdate) => ({
      ...prevUpdate,
      [id]: !prevUpdate[id],
    }));
    event.stopPropagation();
  };
  // expand
  const toggleExpanded = (e) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [e.id]: !prevExpanded[e.id],
    }));
  };

  //Invite user modal
  const handleOpenInviteModal = (e) => {
    setinviteUser({
      company_name: e.id,
      name: e.company_name,
      user_role: "Admin",
      first_name: "",
      last_name: "",
      email: "",
    });
    setOpenInviteUserModal(true);
  };
  const handleOpenCloseInviteModal = () => {
    setOpenInviteUserModal(false);
  };

  const handelChangeInviteUser = (e) => {
    setinviteUser({ ...inviteUser, [e.target.name]: e.target.value });
  };

  // Delete Confirmation Modal
  const handleConfirmationModal = (id) => {
    setOpenDeleteModal(!openDeleteModal);
    setCompanyId(id);
  };

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //add comapany
  const handleChangeAddCompany = (e) => {
    setAddCompanyData({ ...addCompanyData, [e.target.name]: e.target.value });
  };

  //add comapany
  const handleChangeUpdateCompany = (e) => {
    setUpdateCompanyData({
      ...updateCompanyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCompanySearch = (e) => {
    setCompanySearch(e.target.value);
  };

  //get company list api
  const getcompanyData = async () => {
    try {
      const response = await getcompanyList(comapnySearch);
      console.log("response", response);
      if (response.status == 200) {
        setCompanyData(response.data.payload);
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };

  useEffect(() => {
    if (!sortByName) {
      getcompanyData();
    } else {
      getSortbycompanyListApi();
    }
  }, [comapnySearch, sortByName]);

  const getSortbycompanyListApi = async () => {
    const response = await getSortbycompanyList(comapnySearch);
    if (response.status == 200) {
      setCompanyData(response.data.payload);
    }
  };

  //add company api
  const submitComapny = async () => {
    try {
      const response = await addCompany(addCompanyData);
      console.log("responseresponseresponse", response);
      if (response.data.success == true) {
        setOpenAddcompany(false);
        getcompanyData();
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Please enter company name", {
        theme: "colored",
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: "custom-toast",
      });
    }
  };

  //update company api
  const submitUpdateComapny = async () => {
    const response = await editCompany(updateCompanyData.id, updateCompanyData);
    if (response.status == 200) {
      setOpenEditcompany(false);
      getcompanyData();
    }
  };

  //delete comapny api
  const handleDeleteCompany = async () => {
    const response = await deleteCompany(companyId);
    if (response.status == 200) {
      getcompanyData();
      setOpenDeleteModal(false);
    }
  };

  // invite User api

  const submitInviteUser = async () => {
    if (!inviteUser.first_name) {
      notify("Please Enter FirstName");
      return true;
    }

    if (!inviteUser.last_name) {
      notify("Please Enter LastName");
      return true;
    }

    if (!inviteUser.email) {
      notify("Please Enter Email");
      return true;
    }

    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inviteUser.email)
    ) {
      notify("Please Enter valid Email.");
      return true;
    }

    try {
      const response = await inviteUserByCompany(inviteUser);
      console.log("response", response);
      if (response.status == 201) {
        handleOpenCloseInviteModal();
        notifySuccess("successfully add user");
        getcompanyData();
      }
    } catch (error) {
      notify(error.response.data.email[0]);
    }
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={4000}></ToastContainer>
      </div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div
              className="deviceManagementTitle-wrap"
              onClick={() => navigate("/superadmindashboard")}
            >
              <KeyboardBackspaceIcon
                onClick={() => navigate("/superadmindashboard")}
                className="backArrow"
              />{" "}
              <Typography
                variant=""
                gutterBottom
                style={{
                  margin: "0",
                  paddingLeft: "10px",
                  color: "#222D39",
                  fontSize: "16px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                Client Management
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              <button
                className="addCompany-button"
                onClick={handleOpenaddCompanyModal}
              >
                Add Company
              </button>
            </div>
          </div>
          <div className="clientManagement searchbarWrap">
            <TextField
              type="search"
              fullWidth
              placeholder="Search"
              value={comapnySearch}
              onChange={handleCompanySearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setSortByName(!sortByName)}
                    style={{ cursor: "pointer" }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      height="55px"
                      borderLeft={1}
                      borderColor="#DCE1EA"
                    >
                      {sortByName ? (
                        <ArrowUpwardIcon
                          style={{
                            fontSize: "20px",
                            marginRight: "8px",
                            marginLeft: "10px",
                            color: "#1C407B",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          style={{
                            fontSize: "20px",
                            marginRight: "8px",
                            marginLeft: "10px",
                            color: "#1C407B",
                          }}
                        />
                      )}
                    </Box>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#1C407B",
                      }}
                    >
                      NAME
                    </Typography>
                    <ExpandMoreIcon
                      style={{
                        fontSize: "14px",
                        marginLeft: "8px",
                        color: "#1C407B",
                      }}
                    />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#90A3B9" }} />
                  </InputAdornment>
                ),
              }}
              className="clientManagement searchbar"
              // style={{ backgroundColor: '#fff' }}
            />
          </div>
          {/* =============== Add Company Modal Start =============== */}
          {openAddcompany && (
            <Card className="addPlant-cardModal">
              <Box className="addPlant-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addPlant-title"
                >
                  Add Company
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={handleOpenaddCompanyModal}
                />
              </Box>
              <Box className="addplantMdal-innerWrap">
                <Stack spacing={2} pt="10px">
                  <TextField
                    id="filled-basic"
                    name="company_name"
                    label={
                      <>
                        Company Name <span style={{ color: "#E31E24" }}>*</span>
                      </>
                    }
                    variant="filled"
                    className="addplantInputfield"
                    InputLabelProps={{
                      style: { color: "#011627" },
                    }}
                    onChange={handleChangeAddCompany}
                  />
                  <TextField
                    id="filled-basic"
                    name="company_description"
                    label="Description"
                    variant="filled"
                    className="addplantInputfield"
                    multiline
                    rows={4}
                    InputLabelProps={{
                      style: { color: "#011627" },
                    }}
                    onChange={handleChangeAddCompany}
                  />
                  {/* <div className='texteArea-text'>Write Here..</div> */}
                  {/* <Textarea
                                    aria-label="textarea"
                                    placeholder="Enter your text here"
                                    rows={4}
                                    fullWidth
                                    variant="outlined"
                                /> */}
                </Stack>
                <Box className="addPlant-cardButtons">
                  <button className="addPlantBtn" onClick={submitComapny}>
                    Add Company
                  </button>
                </Box>
              </Box>
            </Card>
          )}
          {/* =============== Add Company Modal End =============== */}
          {/* =============== Edit Company Modal Start =============== */}
          {openEditcompany && (
            <Card className="addPlant-cardModal">
              <Box className="addPlant-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addPlant-title"
                >
                  Update Company
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={handleOpenEditCompanyModal}
                />
              </Box>
              <Box className="addplantMdal-innerWrap">
                <Stack spacing={2} pt="10px">
                  <TextField
                    id="filled-basic"
                    name="company_name"
                    label={
                      <>
                        Company Name <span style={{ color: "#E31E24" }}>*</span>
                      </>
                    }
                    variant="filled"
                    className="addplantInputfield"
                    onChange={handleChangeUpdateCompany}
                    value={updateCompanyData.company_name}
                    InputLabelProps={{
                      style: { color: "#011627" },
                    }}
                  />
                  <TextField
                    id="filled-basic"
                    name="company_description"
                    label="Description"
                    variant="filled"
                    className="addplantInputfield"
                    multiline
                    rows={4}
                    onChange={handleChangeUpdateCompany}
                    value={updateCompanyData.company_description}
                    InputLabelProps={{
                      style: { color: "#011627" },
                    }}
                  />
                  {/* <div className='texteArea-text'>Write Here..</div> */}
                  {/* <Textarea
                                    aria-label="textarea"
                                    placeholder="Enter your text here"
                                    rows={4}
                                    fullWidth
                                    variant="outlined"
                                /> */}
                </Stack>
                <Box className="addPlant-cardButtons">
                  <button className="addPlantBtn" onClick={submitUpdateComapny}>
                    Update Company
                  </button>
                </Box>
              </Box>
            </Card>
          )}
          {/* =============== Edit Company Modal End =============== */}
          {/* =============== Delete Confirmation Modal Start =============== */}
          {openDeleteModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleDeleteCompany}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {/* =============== Delete Confirmation Modal End =============== */}
          {/* =============== Invite User Modal Start =============== */}
          {openInviteUserModal && (
            <Card className="inviteUserModal">
              <Box>
                <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 34 }}>
                  <Typography gutterBottom style={{ display: "none" }}>
                    ..
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{ width: "30px", cursor: "pointer" }}
                    onClick={handleOpenCloseInviteModal}
                  />
                </Stack>
                <Typography
                  gutterBottom
                  style={{
                    color: "#1C407B",
                    fontWeight: "600",
                    fontSize: "19px",
                    textAlign: "center",
                  }}
                >
                  Invite User
                </Typography>
              </Box>
              <Box>
                <div className="useInviteWrap">
                  <label
                    for="exampleInputEmail1"
                    class="form-label userInviteLabel"
                  >
                    Company
                  </label>
                  <input
                    type="text"
                    class="form-control userInviteLabelplaceHolder userInviteLabelplaceHolder"
                    id="exampleInputEmail1"
                    placeholder="Company - 1"
                    value={inviteUser.name}
                  />
                </div>
                <div className="useInviteWrap">
                  <label
                    for="exampleInputEmail1"
                    class="form-label userInviteLabel"
                  >
                    Role
                  </label>
                  <input
                    type="text"
                    class="form-control userInviteLabelplaceHolder"
                    id="exampleInputEmail1"
                    placeholder="Admin"
                    value="Admin"
                  />
                </div>
                <div className="useInviteWrap">
                  <label
                    for="exampleInputEmail1"
                    class="form-label userInviteLabel"
                  >
                    First Name <span style={{ color: "#E31E24" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    class="form-control userInviteLabelplaceHolder"
                    id="exampleInputEmail1"
                    placeholder="Enter First Name"
                    value={inviteUser.first_name}
                    onChange={handelChangeInviteUser}
                  />
                </div>
                <div className="useInviteWrap">
                  <label
                    for="exampleInputEmail1"
                    class="form-label userInviteLabel"
                  >
                    Last Name<span style={{ color: "#E31E24" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    class="form-control userInviteLabelplaceHolder"
                    id="exampleInputEmail1"
                    placeholder="Enter Last Name"
                    value={inviteUser.last_name}
                    onChange={handelChangeInviteUser}
                  />
                </div>
                <div className="useInviteWrap">
                  <label
                    for="exampleInputEmail1"
                    class="form-label userInviteLabel"
                  >
                    Email Id<span style={{ color: "#E31E24" }}>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    class="form-control userInviteLabelplaceHolder"
                    id="exampleInputEmail1"
                    placeholder="Enter Email Id"
                    value={inviteUser.email}
                    onChange={handelChangeInviteUser}
                  />
                </div>
              </Box>
              <Box className="">
                <button
                  className="ClientManagement InviteUser-Button"
                  onClick={submitInviteUser}
                >
                  Invite User
                </button>
              </Box>
            </Card>
          )}
          {/* =============== Invite User Modal End =============== */}
          {/* =============== Company Details Start =============== */}
          {companyData.map((e) => {
            return (
              <ExpandCompany
                row={e}
                handleOpenEditCompanyModal={handleOpenEditCompanyModal}
                handleConfirmationModal={handleConfirmationModal}
                handleOpenInviteModal={handleOpenInviteModal}
              />
            );

            // const isExpanded = expanded[e.id];
            // const openEditDeletemodel = updateData[e.id];

            // return (
            //     <Card className='cardCompany' style={{ marginTop: "20px" }} onClick={() => setUpdateData({})}>
            //         <Grid container>
            //             <Grid item xs={12} sm={12} md={6} lg={7} xl={6}>
            //                 <Box className="companyDetails">
            //                     <Box className="companyTitle">
            //                         <Typography style={{ fontWeight: '500', fontSize: '18px' }}>{e.company_name
            //                         }</Typography>
            //                         <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: '00' }}>
            //                             {e.company_description}
            //                         </Typography>
            //                     </Box>
            //                 </Box>
            //             </Grid>
            //             <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
            //                 <Box className='clientManagement cardContent1'>
            //                     <Typography gutterBottom className='clientManagement - companyText '>
            //                         Device:
            //                     </Typography>
            //                     <div className='clientManagement-discription'>
            //                         <Typography variant="body1" className='clientManagement companyNumber1'>03</Typography>
            //                         <Typography variant="caption" className="clientManagement-companySmalltext">Active</Typography>
            //                     </div>
            //                     <Box style={{ borderRight: '1px solid #C9CFE7' }} paddingRight={2}>
            //                         <div className='clientManagement-discription'>
            //                             <Typography variant="body1" className='clientManagement companyNumber2'>02</Typography>
            //                             <Typography variant="caption" className="clientManagement-companySmalltext">Inactive</Typography>
            //                         </div>
            //                     </Box>
            //                     <Typography gutterBottom className='clientManagement companyText '>
            //                         User:
            //                     </Typography>
            //                     <div className='clientManagement-discription'>
            //                         <Typography variant="body1" className='clientManagement companyNumber1'>03</Typography>
            //                         <Typography variant="caption" className="clientManagement-companySmalltext">Active</Typography>
            //                     </div>
            //                     <Box paddingRight={2}>
            //                         <div className='clientManagement-disc'>
            //                             <div className='clientManagement-discription'>
            //                                 <Typography variant="body1" className='clientManagement companyNumber2'>02</Typography>
            //                                 <Typography variant="caption" className="clientManagement-companySmalltext">Inactive</Typography>
            //                             </div>
            //                             <div>
            //                                 <Switch defaultChecked size="small" color='success' />
            //                             </div>
            //                             <div>
            //                                 <MoreVertIcon className='CoinManagment-detailsIcon' onClick={(event) => handleOpenUpdateModal(event, e.id)} />
            //                             </div>
            //                         </div>
            //                         {/* <MoreVertIcon className='CoinManagment-detailsIcon' onClick={handleOpenUpdateModal} /> */}
            //                         <div className='clienManagementBorder'></div>
            //                     </Box>
            //                 </Box>
            //             </Grid>
            //         </Grid>
            //         {openEditDeletemodel && <Card className='clientManagement-updateModal top'>
            //             <Box className='clientMangement-updatedataWrap'>
            //                 <div className='clientManagement-updateData' onClick={() => handleOpenEditCompanyModal(e)}>
            //                     <img src={images.editImg} alt="" className='clientMangement-editIcon' />
            //                     <Typography variant="caption" display="block" gutterBottom className='clientMangement-editText'>
            //                         Edit
            //                     </Typography>
            //                 </div>
            //                 <div className='clientManagement-deleteData' onClick={handleConfirmationModal}>
            //                     <img src={images.deleteImg} alt="" className='clientMangement-editIcon' />
            //                     <Typography variant="caption" display="block" gutterBottom className='clientMangement-editText'>
            //                         Delete
            //                     </Typography>
            //                 </div>
            //             </Box>
            //         </Card>}
            //         {isExpanded &&
            //             <Box sx={{ width: '100%', typography: 'body1' }}>
            //                 <hr></hr>
            //                 <TabContext value={value}>
            //                     <Box>
            //                         <TabList onChange={handleChange} aria-label="lab API tabs example"
            //                             sx={{
            //                                 bgcolor: '#f2f2f2',
            //                                 border: '1px solid #1C407B',
            //                                 borderRadius: '5px',
            //                                 display: 'inline-flex',
            //                                 gap: '0px',
            //                                 width: 'fit-content',
            //                             }}
            //                         >
            //                             <Tab label="User and Access" value="1"
            //                                 sx={{
            //                                     '&.Mui-selected': {
            //                                         backgroundColor: '#1C407B',
            //                                         color: '#fff',
            //                                         textTransform: 'capitalize',
            //                                         fontSize: '14px',
            //                                         borderLeft: '1px solid #1C407B'
            //                                     },
            //                                     '&:not(.Mui-selected)': {
            //                                         backgroundColor: '#fff',
            //                                         color: '#001323',
            //                                         textTransform: 'capitalize',
            //                                         fontSize: '14px',
            //                                         // borderLeft:'1px solid red'
            //                                     },
            //                                 }} />
            //                             <Tab label="Plants" value="2"
            //                                 sx={{
            //                                     '&.Mui-selected': {
            //                                         backgroundColor: '#1C407B',
            //                                         color: '#fff',
            //                                         textTransform: 'capitalize',
            //                                         fontSize: '14px',
            //                                         borderLeft: '1px solid #1C407B'
            //                                     },
            //                                     '&:not(.Mui-selected)': {
            //                                         backgroundColor: '#fff',
            //                                         color: '#001323',
            //                                         textTransform: 'capitalize',
            //                                         fontSize: '14px',
            //                                         borderLeft: '1px solid #1C407B',
            //                                         borderRight: '1px solid #1C407B',
            //                                     },
            //                                 }} />
            //                             <Tab label="Device" value="3"
            //                                 sx={{
            //                                     '&.Mui-selected': {
            //                                         backgroundColor: '#1C407B',
            //                                         color: '#fff',
            //                                         textTransform: 'capitalize',
            //                                         fontSize: '14px'
            //                                     },
            //                                     '&:not(.Mui-selected)': {
            //                                         backgroundColor: '#fff',
            //                                         color: '#001323',
            //                                         textTransform: 'capitalize',
            //                                         fontSize: '14px'
            //                                     },
            //                                 }} />
            //                         </TabList>
            //                     </Box>
            //                     {/* =============== User and Access tab Start  =============== */}
            //                     <TabPanel value="1" className='userAccessTab'>
            //                         <Typography variant="caption" display="block" gutterBottom className='userAccsessTab-text'>
            //                             All Set to Start Your Digital Factory Journey with "Company - 1"
            //                         </Typography>
            //                          <button className='userAccessTab-inviteTab' onClick={() => handleOpenInviteModal(e)}>Invite</button>

            //                         {/* <Box className="userAccessTabDataList">
            //                             <Box>
            //                                 <Typography variant="caption" display="block" gutterBottom className="userAccessTabDataName">
            //                                     First Name :
            //                                 </Typography>
            //                                 <Typography variant="caption" display="block" gutterBottom className="userAccessTabDataName">
            //                                     Last Name :
            //                                 </Typography>
            //                                 <Typography variant="caption" display="block" gutterBottom className="userAccessTabDataName">
            //                                     Role :
            //                                 </Typography>
            //                                 <Typography variant="caption" display="block" gutterBottom className="userAccessTabDataName">
            //                                     Email Id :
            //                                 </Typography>
            //                             </Box>
            //                             <Box>
            //                                 <Typography variant="caption" display="block" gutterBottom>
            //                                     Shreekar
            //                                 </Typography>
            //                                 <Typography variant="caption" display="block" gutterBottom>
            //                                     Yadav
            //                                 </Typography>
            //                                 <Typography variant="caption" display="block" gutterBottom>
            //                                     Admin
            //                                 </Typography>
            //                                 <Typography variant="caption" display="block" gutterBottom>
            //                                     someone.somewhere@email.com
            //                                 </Typography>
            //                             </Box>
            //                         </Box>
            //                         <button className='userAccessTab-inviteTab'>Manage</button> */}
            //                     </TabPanel>
            //                     {/* =============== User and Access tab End  =============== */}
            //                     {/* =============== Plant tab Start  =============== */}
            //                     <TabPanel value="2" className='plantTab'>
            //                         {/* <Typography variant="caption" display="block" gutterBottom className='plantTab-text'>
            //                             Create Plants for the "Company - 1" <br></br> with the Assigned Devices.
            //                         </Typography>
            //                         <button className='plantTab-btn'>Manage</button> */}
            //                         <Card className='clientManagement plantTabListCard'>
            //                             <Box className="clientManagement plantTabListCardWrap">
            //                                 <Box className="plantTabListWrap">
            //                                     <Box className="clientManagement plantTabListImgWrap">
            //                                         <img src={images.clientManagementPlantS} alt="" />
            //                                         <Typography variant="subtitle2" gutterBottom>
            //                                             Device
            //                                         </Typography>
            //                                     </Box>
            //                                     <Box>
            //                                         <Box className="clientManagement plantTabListTextWrap">
            //                                             <Typography variant="subtitle1" gutterBottom className='clientManagement plantTabList plantName'>
            //                                                 Plant Name - 1
            //                                             </Typography>
            //                                             <Typography variant="caption" display="block" gutterBottom>
            //                                                 Iron and Steel Industry
            //                                             </Typography>
            //                                         </Box>
            //                                         <Box className="clientMangement plantTabList-ButtonWrap">
            //                                             <button className='clientManagemet plantTablist-Button'>Device 1</button>
            //                                             <button className='clientManagemet plantTablist-Button'>Device 2</button>
            //                                             <button className='clientManagemet plantTablist-Button inActive'>Device 3</button>
            //                                             <button className='clientManagemet plantTablist-Button'>Device 4</button>
            //                                         </Box>
            //                                     </Box>
            //                                 </Box>
            //                                 <Box>
            //                                     <button className="clientMangement plantTabList-ManageButton">
            //                                         Manage
            //                                     </button>
            //                                 </Box>
            //                             </Box>
            //                         </Card>
            //                     </TabPanel>
            //                     {/* =============== Plant tab End  =============== */}
            //                     {/* =============== Device tab Start  =============== */}
            //                     <TabPanel value="3" className='deviceTab'>
            //                         {/* <Typography variant="caption" display="block" gutterBottom className='deviceTab-text'>
            //                             Please assign Devices to "Company - 1"
            //                         </Typography>
            //                         <button className='deviceTab-btn'>Assign Device</button> */}
            //                         <Card className='clientManagement deviceTabListCard'>
            //                             <Box className="clientManagement deviceTabListCardWrap">
            //                                 <Box className="deviceTabListWrap">
            //                                     <Box className="clientManagement deviceTabListImgWrap">
            //                                         <img src={images.cloudActiveImg} alt="" style={{ height: '40px', width: '40px' }} />
            //                                         <Typography variant="subtitle2" gutterBottom className="clientManagement deviceTabListText">
            //                                             Last Seen
            //                                         </Typography>
            //                                         <Typography variant="subtitle2" gutterBottom className="clientManagement deviceTabListText small">
            //                                            31/01/2023
            //                                         </Typography>
            //                                         <Typography variant="subtitle2" gutterBottom className="clientManagement deviceTabListText small">
            //                                             09:30
            //                                         </Typography>
            //                                     </Box>
            //                                     <Box>
            //                                         <Box className="clientManagement deviceTabListTextWrap">
            //                                             <Typography variant="subtitle1" gutterBottom className='clientManagement deviceTabList deviceName'>
            //                                                 Device 1
            //                                             </Typography>
            //                                             <Typography variant="caption" display="block" gutterBottom>
            //                                                 UID - 71f73dee-4596-48c8-bdof-7f1234d
            //                                             </Typography>
            //                                         </Box>
            //                                         <Box className="clientMangement deviceTabList-ButtonWrap">
            //                                             <button className='clientManagemet deviceTablist-Button inActive'>Plant 1</button>
            //                                             <button className='clientManagemet deviceTablist-Button inActive'>Plant 2</button>
            //                                             <button className='clientManagemet deviceTablist-Button inActive'>Plant 3</button>
            //                                         </Box>
            //                                     </Box>
            //                                 </Box>
            //                                 <Box>
            //                                     <button className="clientMangement plantTabList-ManageButton">
            //                                         Manage
            //                                     </button>
            //                                 </Box>
            //                             </Box>
            //                         </Card>
            //                         <Card className='clientManagement deviceTabListCard' style={{marginTop:'15px'}}>
            //                             <Box className="clientManagement deviceTabListCardWrap">
            //                                 <Box className="deviceTabListWrap">
            //                                     <Box className="clientManagement deviceTabListImgWrap">
            //                                         <img src={images.cloudImg} alt="" style={{ height: '40px', width: '40px' }} />
            //                                         <Typography variant="subtitle2" gutterBottom className="clientManagement deviceTabListText">
            //                                             Last Seen
            //                                         </Typography>
            //                                         <Typography variant="subtitle2" gutterBottom className="clientManagement deviceTabListText small">
            //                                            31/01/2023
            //                                         </Typography>
            //                                         <Typography variant="subtitle2" gutterBottom className="clientManagement deviceTabListText small">
            //                                             09:30
            //                                         </Typography>
            //                                     </Box>
            //                                     <Box>
            //                                         <Box className="clientManagement deviceTabListTextWrap">
            //                                             <Typography variant="subtitle1" gutterBottom className='clientManagement deviceTabList deviceName'>
            //                                                 Device 2
            //                                             </Typography>
            //                                             <Typography variant="caption" display="block" gutterBottom>
            //                                                 UID - 71f73dee-4596-48c8-bdof-7f1234d
            //                                             </Typography>
            //                                         </Box>
            //                                         <Box className="clientMangement deviceTabList-ButtonWrap">
            //                                             <button className='clientManagemet deviceTablist-Button inActive'>Plant 1</button>
            //                                             <button className='clientManagemet deviceTablist-Button inActive'>Plant 2</button>
            //                                         </Box>
            //                                     </Box>
            //                                 </Box>
            //                                 <Box>
            //                                     <button className="clientMangement plantTabList-ManageButton">
            //                                         Manage
            //                                     </button>
            //                                 </Box>
            //                             </Box>
            //                         </Card>
            //                     </TabPanel>
            //                     {/* =============== Device tab End  =============== */}
            //                 </TabContext>
            //             </Box>
            //         }
            //         {isExpanded ? <img src={images.upArrow} alt="" className='clientMangement-downIcon' onClick={() => toggleExpanded(e)} /> : <img src={images.downIcon} alt="" className='clientMangement-downIcon' onClick={() => toggleExpanded(e)} />}
            //     </Card>
            // )
          })}
          {/* =============== Company Details End =============== */}
        </Container>
      </div>
    </>
  );
};

export default ClientManagement;
