import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Switch } from "@mui/material";
import { images } from "../../../../config/images";
import "./AddAccessManagement.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAccessModule,
  getDepartment,
  editAccessManagement,
  getAccessManagementById,
} from "../../service";
import Loader from "../../../../components/Loader/Loader";

function AddAccesssManagementByID() {
  const navigate = useNavigate();
  const [openAddDepartmentModal, setOpenAddDepartmentModal] =
    React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [department, setDepartment] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState("none");
  const [accessModule, setAccessModule] = useState([]);
  const [accessModuleId, setAccessModuleId] = useState("");
  const [selectAccessManagement, setSelectAccessManagement] = useState([]);
  const [selectRole, setSelectRole] = useState("none");
  const[isLoading,setLoading] = useState(false)

  console.log("selectAccessManagement", selectAccessManagement);

  const { departmentId } = useParams();

  const handleOpenaddDepartmentModal = () => {
    setOpenAddDepartmentModal(!openAddDepartmentModal);
  };

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  // handleSelectDepartment
  const handleSelectDepartment = (e) => {
    setSelectRole("none");
    setSelectDepartment(e.target.value);
  };
  // Delete Confirmation Modal
  const handleConfirmationModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const getDepartmentList = async () => {
    const response = await getDepartment();
    console.log("departmentList", response);
    if (response.status == 200 || response.status == 201) {
      setDepartment(response.data.payload);
    }
  };

  const getAccessmodule = async () => {
    const resp = await getAccessModule();
    if (resp.status == 200 || resp.status == 201) {
      setAccessModule(resp.data.payload[0].access_module);
      // setAccessModuleId(resp.data.payload[0].access_module[0].id);
    }
  };

  const getAccessManagementByIdData = async () => {
    const resp = await getAccessManagementById(departmentId);
    console.log("resp", resp.data);
    if (resp.status == 200) {
      setSelectDepartment(resp.data.department_id);
      setSelectRole(resp.data.role_id);

      const selectedData = resp.data.access_module_management.map((e) => {
        return {
          access_module: e.access_module.id,
          sub_module: e.sub_module.map((e) => e.id),
          is_viewer: e.is_viewer,
          is_editor: e.is_editor,
        };
      });
      setSelectAccessManagement(selectedData);
      setAccessModuleId(resp.data.access_module_management[0].access_module.id);
    }
  };

  useEffect(() => {
    getDepartmentList();
    getAccessmodule();
    getAccessManagementByIdData();
  }, []);

  const handleSelectAccessManagement = (
    accessModuleId,
    subModuleId,
    access,
    e
  ) => {
    const existingIndex = selectAccessManagement.findIndex(
      (item) => item.access_module === accessModuleId
    );

    if (!subModuleId && !access && !e) {
      if (
        selectAccessManagement.find(
          (item) => item.access_module === accessModuleId
        )
          ? true
          : false
      ) {
        const updatedAccessManagement = [...selectAccessManagement];
        updatedAccessManagement.splice(existingIndex, 1);
        setSelectAccessManagement(updatedAccessManagement);
        return true;
      }
    }

    if (existingIndex !== -1) {
      const updatedAccessManagement = [...selectAccessManagement];
      const subModuleIndex =
        updatedAccessManagement[existingIndex].sub_module.indexOf(subModuleId);

      if (subModuleIndex !== -1) {
        updatedAccessManagement[existingIndex].sub_module.splice(
          subModuleIndex,
          1
        );
        if (updatedAccessManagement[existingIndex].sub_module.length === 0) {
          updatedAccessManagement[existingIndex].sub_module = [];
        }
      } else {
        updatedAccessManagement[existingIndex].sub_module = [
          ...(updatedAccessManagement[existingIndex].sub_module || []),
          subModuleId,
        ];
      }

      if (access == "Viewer") {
        updatedAccessManagement[existingIndex].is_viewer = e.target.checked;
      }

      if (access == "Editor") {
        updatedAccessManagement[existingIndex].is_editor = e.target.checked;
      }

      setSelectAccessManagement(updatedAccessManagement);
    } else {
      const newAccessManagement = {
        access_module: accessModuleId,
        sub_module: [],
        is_viewer: false,
        is_editor: false,
      };
      setSelectAccessManagement((prevState) => [
        ...prevState,
        newAccessManagement,
      ]);
    }
  };

  const isViewerChecked = selectAccessManagement.length
    ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
      ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
          .is_viewer
      : false
    : false;
  const isEditorChecked = selectAccessManagement.length
    ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
      ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
          .is_editor
      : false
    : false;

  const submitAddAccessManagement = async () => {
    if (selectDepartment == "none") {
      notify("Please Select Department");
      return true;
    }

    if (selectRole == "none") {
      notify("Please Select Role");
      return true;
    }

    const param = {
      department: selectDepartment,
      role: selectRole,
      access_management: selectAccessManagement,
    };

    try {
      setLoading(true)
      const resp = await editAccessManagement(departmentId, param);

      if (resp.data.success == true) {
        setLoading(false)
        navigate("/accessManagement");
      }
    } catch (error) {
      setLoading(false)
      notify(error.response.data.message);
    }
  };
  return (
    <>
      {/* <div>ManagementDepartment</div> */}
      <div>
        <ToastContainer autoClose={4000}></ToastContainer>
      </div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => navigate("/accessManagement")}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                className="deviceManagementTitle"
                gutterBottom
                style={{ margin: "0" }}
                onClick={() => navigate("/accessManagement")}
              >
                Access Management
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              <button
                className="manageDepartment-button"
                onClick={() => navigate("/managedepartment")}
              >
                Manage Department
              </button>
              <img src={images.excelLogo} alt="" className="cloudActiveImg" />
            </div>
          </div>
          {/* =============== Add Department Modal Start =============== */}
          {openAddDepartmentModal && (
            <Card className="addDepartment-card">
              <Box>
                {/* <Stack direction="row" spacing={{ sm: 14, md: 20, lg: 25 }}>
                    </Stack> */}
                <Box>
                  <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 38 }}>
                    <Typography gutterBottom style={{ display: "none" }}>
                      ..
                    </Typography>
                    <img
                      src={images.closeIcon}
                      alt=""
                      style={{ width: "30px", cursor: "pointer" }}
                      onClick={handleOpenaddDepartmentModal}
                    />
                  </Stack>
                  <Typography
                    gutterBottom
                    style={{
                      color: "#1C407B",
                      fontWeight: "600",
                      fontSize: "19px",
                      textAlign: "center",
                    }}
                  >
                    Add Department
                  </Typography>
                </Box>
                <div className="departmentWrap">
                  <label for="exampleInputEmail1" class="form-label">
                    Department <span>*</span>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Department"
                  ></input>
                </div>
                <button className="addDepartmentButton">Add Department</button>
              </Box>
            </Card>
          )}
          {/* =============== Add Department Modal End =============== */}
          {/* =============== Delete Confirmation Modal Start =============== */}
          {openDeleteModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleConfirmationModal}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {/* =============== Delete Confirmation Modal End =============== */}
          <Grid container style={{ paddingTop: "20px" }}>
            <Grid item xs={12}>
              <Card className="manageDepartment-card">
                <Grid
                  container
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Department</b>{" "}
                      <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      className="addAccessManagement selectDropDown"
                      value={selectDepartment}
                      onChange={(e) => handleSelectDepartment(e)}
                    >
                      <option selected disabled hidden value="none">
                        Select Department
                      </option>
                      {department.map((e, i) => {
                        return (
                          <option value={e.id}>{e.department_name}</option>
                        );
                      })}
                    </select>
                    <Box className="accessDepartment-textWrap">
                      <div>
                        <Typography variant="subtitle2" gutterBottom>
                          <b>Select Access Module</b>{" "}
                          <span style={{ color: "#E31E24" }}>*</span>
                        </Typography>
                      </div>
                      {/* <div>
                                                <img
                                                    src={images.addIcon}
                                                    alt=""
                                                    className="addDeviceImg"
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </div> */}
                    </Box>
                    <div className="manageDepartment-wrap">
                      {accessModule.length
                        ? accessModule.map((e1, i) => {
                            const isChecked = selectAccessManagement.find(
                              (f) => f.access_module == e1.id
                            )
                              ? true
                              : false;
                            return (
                              <div
                                className="addAccessManagement-fieldWrap"
                                onClick={() => setAccessModuleId(e1.id)}
                              >
                                {accessModuleId == e1.id && (
                                  <img
                                    src={images.activeDeviceimg}
                                    alt=""
                                    className="activeAccessDepartmentImg"
                                  />
                                )}
                                <div className="manageDepartment-field">
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ margin: "0" }}
                                  >
                                    <Checkbox
                                      checked={isChecked}
                                      onClick={() =>
                                        handleSelectAccessManagement(e1.id)
                                      }
                                    ></Checkbox>{" "}
                                    {e1.access_module_name}
                                  </Typography>
                                </div>
                              </div>
                            );
                          })
                        : []}
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} lg={8} xl={8}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Role</b> <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      className="addAccessManagement selectDropDown Roles"
                      value={selectRole}
                      onChange={(e) => setSelectRole(e.target.value)}
                    >
                      <option selected disabled hidden value="none">
                        Select Role
                      </option>
                      {department.length
                        ? department.map((e, i) => {
                            if (selectDepartment == e.id) {
                              return e.company_roles.map((e1) => {
                                return (
                                  <option value={e1.id}>{e1.role_name}</option>
                                );
                              });
                            }
                          })
                        : []}
                    </select>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Sub Module</b>{" "}
                      <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <div className="managementDepartment-card">
                      <div
                        className="accessManagement switchWrap"
                        style={{ marginLeft: "20px" }}
                      >
                        <Grid container spacing={2} pt={4}>
                          <Grid item xs={4} sm={6}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{ margin: "0" }}
                            >
                              Viewer
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Switch
                              onClick={(e) =>
                                handleSelectAccessManagement(
                                  accessModuleId,
                                  -1,
                                  "Viewer",
                                  e
                                )
                              }
                              // defaultChecked
                              checked={isViewerChecked}
                              size="small"
                              color="success"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={6}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{ margin: "0" }}
                            >
                              Editor
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Switch
                              onClick={(e) =>
                                handleSelectAccessManagement(
                                  accessModuleId,
                                  -1,
                                  "Editor",
                                  e
                                )
                              }
                              checked={isEditorChecked}
                              size="small"
                              color="success"
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <Grid container className="pt-3">
                        {accessModule.length
                          ? accessModule.map((e, i) => {
                              if (accessModuleId === e.id) {
                                const getAccessManagement =
                                  selectAccessManagement.find(
                                    (f) => f.access_module === accessModuleId
                                  );

                                const subModules = e.sub_module.map((e1) => {
                                  const isChecked = getAccessManagement
                                    ? getAccessManagement.sub_module.includes(
                                        e1.id
                                      )
                                    : false;

                                  return (
                                    <Grid
                                      item
                                      xs={6}
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      key={e1.id}
                                    >
                                      <Box className="managementDepartment roles ">
                                        <Typography
                                          variant="body2"
                                          
                                        >
                                          <Checkbox
                                            onClick={() =>
                                              handleSelectAccessManagement(
                                                e.id,
                                                e1.id
                                              )
                                            }
                                            checked={isChecked}
                                          />
                                          {e1.sub_module_name}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  );
                                });

                                return (
                                  <Grid container spacing={2}>
                                    {subModules}
                                  </Grid>
                                );
                              }
                            })
                          : []}
                      </Grid>
                    </div>
                    <button
                      className="Plantmanagemet submit"
                      onClick={() => submitAddAccessManagement()}
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {isLoading && <Loader/>}
      </div>
    </>
  );
}
export default AddAccesssManagementByID;
