import React from 'react';
import Typography from '@mui/material/Typography';
import './FooterText.css';

function FooterText() {
  return (
    <Typography variant="subtitle2" gutterBottom style={{ backgroundColor:'#F1F1FF',color: '#4D5963', textAlign: 'end', margin: '0', position: 'fixed', bottom: '0px', right: '0', padding:'1px',width:'100%',zIndex:'99',paddingRight:'23px' }}>
      Powered by <b style={{ fontWeight: '900' }}>Hikar®Technomation(p) Ltd</b> © All Rights Reserved
    </Typography>
  )
}

export default FooterText