import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock'; // Import Highstock module
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import { useSelector } from 'react-redux';

HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module

export function PopgroupBarChart({
    analyticsPOPData,
    analyticsPOP1Data,

  }) {

  const chartRef = useRef(null);

  const labels = [];

  if (analyticsPOPData[0]?.data?.length || analyticsPOP1Data[0]?.data?.length) {
    const popDataLength = analyticsPOPData[0]?.data?.length || 0;
    const pop1DataLength = analyticsPOP1Data[0]?.data?.length || 0;
    const maxLength = Math.max(popDataLength, pop1DataLength);
  
    for (let i = 0; i < maxLength; i++) {
      const popData = analyticsPOPData[0]?.data[i] || {};
      const pop1Data = analyticsPOP1Data[0]?.data[i] || {};
      const minKey = popData.MIN_KEY || "";
      const pop1MinKey = pop1Data.MIN_KEY || "";
      labels.push(`${minKey} ${pop1MinKey}`);
    }
  }

  console.log(labels);
  
 const data =  [
          {
            name: analyticsPOPData.length ? analyticsPOPData[0].device_name : "",
            data: analyticsPOPData.length
              ? analyticsPOPData[0] && analyticsPOPData[0].data.length
                ? analyticsPOPData[0].data.map((row) => parseFloat(row.tag_value))
                : []
              : [],
              color: '#C0255F',
            stack: "Stack 0",
            barPercentage: 1,
            barThickness: 30,
          },
    
          {
            name: analyticsPOP1Data.length ? analyticsPOP1Data[0].device_name : "",
            data: analyticsPOP1Data.length
              ? analyticsPOP1Data[0] && analyticsPOP1Data[0].data.length
                ? analyticsPOP1Data[0].data.map((row) => parseFloat(row.tag_value))
                : []
              : [],
              color: "#E66595",
            stack: "Stack 1",
            barPercentage: 1,
            barThickness: 30,
          },
        ]
  const options = {
    chart: {
      type: 'column', // Use 'column' type for vertical bar chart
      events: {
        load: function () {
          const xAxis = this.xAxis[0];
          const visibleCategories = labels.slice(-5); // Show the last 5 categories initially
          xAxis.setExtremes(labels.length - visibleCategories.length, labels.length - 1, false);
          this.redraw();
        },
      },
    },
   
    title: {
      text: null,
    },
    legend: {
      enabled: false, // Disable legend
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'gray',
        },
      },
    },

    series: data,
    scrollbar: {
      enabled: true, // Enable scrollbar
    },
    exporting: {
      enabled: true, // Disable exporting options
    },
    credits: {
      enabled: false, // Disable credits
    },
  };

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      chart.options.xAxis.min = labels.length - 5; // Set the initial visible range for the x-axis
      chart.options.xAxis.max = labels.length - 1;
      chart.redraw();
    }
  }, [analyticsPOPData,analyticsPOP1Data]);

  return (
    // <div style={{ width: '100%', height: '322px' }}>
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
    // </div>
  );
}
