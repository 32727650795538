import * as React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { images } from "../../config/images";
// import FooterText from '../../components/FooterText/FooterText';
import "./Dashboard1.css";
import Typography from "@mui/material/Typography";
import { getCompany, getPlant } from "./services";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderDetails } from "../Header/headerSlice";
import { companyDashboardCountData } from "../SuperAdminDashboard/service";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const Dashboard1 = () => {
  const [companyData, setCompanyData] = useState([]);
  const[companyDataCount,setCompanyDataCount] = useState([])

  console.log("companyDatacompanyData",companyData)

  const { is_admin } = useSelector(state => state.userState);

  console.log("is_admin", is_admin);


  useEffect(() => {
    if (is_admin) {
      if (companyData.length) {
        navigate(`/companyDashboard/plant/${companyData[0].id}`);
        dispatch(setHeaderDetails({ headerName: companyData[0].company_name }))
      }
    }
  }, [companyData])

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    companyDetail();
    dispatch(setHeaderDetails({ headerName: "Company Dashboard" }))
  }, []);

  const companyDetail = async () => {
    try {
      const resp = await getCompany();
      setCompanyData(resp.data.payload);
    } catch (error) {
      // Handle the error here
      console.error('An error occurred:', error);
      // You can also perform any necessary error handling or show an error message to the user
    }
  };


  const handlePlantDashboard = async (row) => {
    navigate(`/companyDashboard/plant/${row.id}`);
    dispatch(setHeaderDetails({ headerName: row.company_name }))
  };

  useEffect(()=>{
    handleCompanyDataCount()
  },[])

  const handleCompanyDataCount = async() =>{
   const resp = await companyDashboardCountData()
   setCompanyDataCount(resp.data.payload)
  }
  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <Card style={{ marginTop: "15px" }} className="dasboardCard">
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{
                    backgroundColor: "#DCE1EA",
                    width: "2px",
                    height: "49px",
                    marginTop: "20px",
                  }}
                />
              }
              // spacing={{ sm: 14, md: 10, lg: 28 }}
              className="dashboardItem"
            >
              <Item className="dashboardCard-item">
                <img
                  src={images.energyMeterImg}
                  alt=""
                  className="dashboardCard-img"
                />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                    ENERGY METER
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                   {companyDataCount.device_count}
                  </Typography>
                </Stack>
              </Item>
              <Item className="dashboardCard-item">
                <img
                  src={images.clientManagementPlantS}
                  alt=""
                  className="dashboardCard-img"
                />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                    COMPANY
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                  {companyDataCount.company_count}
                  </Typography>
                </Stack>
              </Item>
              <Item className="dashboardCard-item">
                <img
                  src={images.activeImg}
                  alt=""
                  className="dashboardCard-img"
                />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                    ACTIVE
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                    01
                  </Typography>
                </Stack>
              </Item>
              <Item className="dashboardCard-item">
                <img
                  src={images.serviceCall}
                  alt=""
                  className="dashboardCard-img"
                />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                    SERVICE CALL
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                    01
                  </Typography>
                </Stack>
              </Item>
            </Stack>
          </Card>
          <Grid container spacing={4} mt={"0px"}>
            {companyData.map((row, i) => {
              return (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <Card
                    className="dashboardCompany-card"
                    onClick={() => handlePlantDashboard(row)}
                  >
                    <Stack direction="column">
                      <Typography
                        gutterBottom
                        className="dashboardCard-plantTitle"
                      >
                        {row.company_name}
                      </Typography>
                      <Typography
                        gutterBottom
                        className="dashboardCard-plantsubTitle"
                      >
                        {row.company_description}
                      </Typography>
                      <Box className="dashboardCard-companyContent">
                        <Typography
                          gutterBottom
                          className="dashboardCard-plantText"
                        >
                          Plant:
                        </Typography>
                        <Stack direction="column" style={{ marginTop: "3px" }}>
                          <Typography
                            variant="body1"
                            className="dashboardCard-plantNumber1"
                          >
                            {row.active_plant_count}
                          </Typography>
                          <Typography
                            variant="caption"
                            className="dashboardCard-plantSmalltext"
                          >
                            Active
                          </Typography>
                        </Stack>
                        <Box
                          style={{
                            borderRight: "2px solid #C9CFE7",
                            height: "30px",
                          }}
                          paddingRight={2}
                        >
                          <Stack
                            direction="column"
                            style={{ marginTop: "-6px" }}
                          >
                            <Typography
                              variant="body1"
                              className="dashboardCard-plantNumber2"
                            >
                             {row.inactive_plant_count}
                            </Typography>
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Inactive
                            </Typography>
                          </Stack>
                        </Box>
                        <Box
                          style={{
                            borderRight: "2px solid #C9CFE7",
                            height: "30px",
                          }}
                          paddingRight={4}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            style={{ marginTop: "-6px" }}
                          >
                            <img
                              src={images.serviceCallImg}
                              alt=""
                              className="dashboardCard-companyImg"
                            />
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Service call
                            </Typography>
                          </Stack>
                        </Box>
                        <Box paddingRight={4}>
                          <Stack direction="column" spacing={1}>
                            <img
                              src={images.alertImage}
                              alt=""
                              className="dashboardCard-companyImg"
                            />
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Subscription
                            </Typography>
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

        </Container>
      </div>
    </>
  );
};
export default Dashboard1;
