import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { images } from "../../../config/images";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BarChart } from "./BarChart";
import { useSelector, useDispatch } from "react-redux";
import { setOverviewData } from "./Slice/OverViewAnalyticsSlice";
import { getOverviewAnalyticsEnergyChartData } from "./services";
import dayjs from "dayjs";

const ZoomInChart = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [zoomChartData, setZoomChartData] = useState([]);
  const [average, setAverage] = useState(false);
  const [showTime, setShowTime] = useState(false);

  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    deviceIdRedux,
    deviceUnitRedux,
    deviceColors,
    startDateRe,
    endDateRe,
  } = useSelector((state) => state.overViewState);

  const startDateReFormat = startDateRe ? dayjs(startDateRe) : null;
  const endDateReFormat = endDateRe ? dayjs(endDateRe) : null;

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";

  const startTimeFormatRep = startDateRe
    ? dayjs(startDateRe).format(timeFormat)
    : "";
  const endTimeFormatRep = endDateRe
    ? dayjs(endDateRe).format(timeFormat)
    : "";
  const startTimeFormat =
    startDateRe != null ? dayjs(startDateRe).format(dateFormat) : "";
  const formatEndDate =
    endDateRe != null ? dayjs(endDateRe).format(dateFormat) : "";

  console.log("startTimeFormat", zoomChartData);

  const getOverviewAnalyticsEnergyChartApi = async () => {
    const paramRequestData = {
      device_id: deviceIdRedux,
      tag_unit: deviceUnitRedux[0],
      start_date: startTimeFormat,
      end_date: formatEndDate,
    };

    if (showTime) {
      paramRequestData["start_time"] = startTimeFormatRep;
      paramRequestData["end_time"] = endTimeFormatRep;
    }

    try {
      const resp = await getOverviewAnalyticsEnergyChartData(paramRequestData);

      if (resp.status == 200) {
        setZoomChartData(resp.data.payload);
      }
      console.log("resp", resp);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getOverviewAnalyticsEnergyChartApi();
  }, [startTimeFormat, formatEndDate, showTime]);


  const handleToggleTime = () => {
    setShowTime((prevShowTime) => !prevShowTime);
  };


  return (
    <>
      <div className="page-wraper">
        <div className="page-header page-header-analytics">
          <div
            className="page-back-btn"
            onClick={() =>
              navigate(
                `/overView-analytics/company/${param.companyId}/plant/${param.id}`
              )
            }
          >
            <ArrowBackIcon />
            <span>Overview - Analytics</span>
          </div>
        </div>
        <div style={{ marginTop: "36px" }}>
          <Box className="overview-zoom-box">
            <Grid container spacing={2}>
              <Grid item md={10}>
                <div className="pt-3" style={{ paddingLeft: "20px" }}>
                  <h6>
                    <b>Energy Bar Chart - Consumer over Consumer</b>
                  </h6>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className="d-flex gap-3">
                  <div
                    style={{ marginTop: "6px" }}
                    onClick={() =>
                      navigate(
                        `/overView-analytics/company/${param.companyId}/plant/${param.id}`
                      )
                    }
                  >
                    <img src={images.zoomOut} />
                  </div>
                  {/* <h6 className="AGG-dropdown">AGG.</h6>
                  <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                    <select
                      className="form-input-class"
                      style={{ padding: "14px 8px", borderRadius: "4px" }}
                      id="selectedClass"
                      required
                    >
                      <option value="none" selected disabled hidden>
                        15 Min
                      </option>
                      <option value="1">15 Min</option>
                      <option value="2">30 Min</option>
                      <option value="3">Hourly</option>
                      <option value="4">Daily</option>
                      <option value="5">Weekly</option>
                    </select>
                  </Box> */}

                  <p className="average-toggle" style={{ marginTop: "8px" }}>
                    Average
                  </p>
                  <Switch
                    className="average-toggle-switch"
                    checked={average}
                    onChange={(e) => setAverage(e.target.checked)}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: "#828282",
                      },
                      "& .MuiSwitch-track": {
                        bgcolor: "#D9D9E5 !important",
                      },
                      "& .Mui-checked": {
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#50C878",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#CEE6E1 !important",
                        },
                      },
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <div className="p-4">
              <BarChart
                average={average}
                zoomChartData={zoomChartData}
                startTimeFormat={startTimeFormat}
                formatEndDate={formatEndDate}
              />
            </div>
            <div style={{ height: "81px", background: "#F5F5F5" }}>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={8}>
                  <h6 style={{ marginLeft: "20px", marginTop: "30px" }}>
                    <b>Energy [{deviceUnitRedux[0]}]</b>
                  </h6>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={<Switch color="primary" />}
                      label="Time"
                      labelPlacement="top"
                      onChange={handleToggleTime}
                    />
                  </FormGroup>
                  {/* <Box
                    className="header-btn-date"
                    style={{ marginRight: "10px", width: "40%" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={startDateReFormat}
                        onChange={(newValue) =>
                          dispatch(setOverviewData({ startDateRe: newValue }))
                        }
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    className="header-btn-month"
                    style={{ width: "40%", marginRight: "20px" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={endDateReFormat}
                        onChange={(newValue) =>
                          dispatch(setOverviewData({ endDateRe: newValue }))
                        }
                      />
                    </LocalizationProvider>
                  </Box> */}
                    {showTime ? (
                    <>
                      <Box style={{ width: "51%" }} className="box-datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="Start Date"
                            value={startDateReFormat}
                            onChange={(newValue) => dispatch(setOverviewData({ startDateRe: newValue }))}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className="header-btn-month box-end-datepicker"
                        style={{ width: "51%" }}

                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="End Date"
                            value={endDateReFormat}
                            onChange={(newValue) => dispatch(setOverviewData({ endDateRe: newValue }))}
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                    className="header-btn-date"
                    style={{ marginRight: "10px", width: "40%" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={startDateReFormat}
                        onChange={(newValue) =>
                          dispatch(setOverviewData({ startDateRe: newValue }))
                        }
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    className="header-btn-month"
                    style={{ width: "40%", marginRight: "20px" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={endDateReFormat}
                        onChange={(newValue) =>
                          dispatch(setOverviewData({ endDateRe: newValue }))
                        }
                      />
                    </LocalizationProvider>
                  </Box> 
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
            <div
              className="pt-3"
              style={{ maxHeight: "239px", overflowY: "auto" }}
            >
              <Table className="overview-table overview-table1">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "22%" }}></TableCell>
                    <TableCell>Min</TableCell>
                    <TableCell>Max</TableCell>
                    <TableCell>Mean</TableCell>
                    <TableCell>Std Dev</TableCell>
                    <TableCell>All Time Min</TableCell>
                    <TableCell>All Time Max</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {zoomChartData.length
                    ? zoomChartData.map((row, index) => {
                        const findColor = deviceColors.find(
                          (f) => f.deviceId == row.device_id
                        );
                        console.log("roww", row);
                        return (
                          <TableRow>
                            <TableCell className="zoomchart-td">
                              <Grid item xs={12} key={row.device_name}>
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    // background: "#efeffd",
                                    width: "100%",
                                    // marginBottom: px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    style={{
                                      padding: "7px 12px",
                                      borderLeft: `4px solid ${
                                        findColor ? findColor.color : ""
                                      }`,
                                    }}
                                  >
                                    <h6 className="mt-2">{row.device_name}</h6>
                                  </Grid>
                                  
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              {typeof row.min_value === "number"
                                ? row.min_value.toFixed(2)
                                : 0}
                            </TableCell>
                            <TableCell>
                              {typeof row.max_value === "number"
                                ? row.max_value.toFixed(2)
                                : 0}
                            </TableCell>
                            <TableCell>
                              {typeof row.mean_value === "number"
                                ? row.mean_value.toFixed(2)
                                : 0}
                            </TableCell>
                            <TableCell>
                              {typeof row.std_dev_value === "number"
                                ? row.std_dev_value.toFixed(2)
                                : 0}
                            </TableCell>
                            <TableCell>
                              {typeof row.all_time_min_value === "number"
                                ? row.all_time_min_value.toFixed(2)
                                : 0}
                            </TableCell>
                            <TableCell>
                              {typeof row.all_time_max_value === "number"
                                ? row.all_time_max_value.toFixed(2)
                                : 0}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : []}
                </TableBody>
              </Table>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};

export default ZoomInChart;
