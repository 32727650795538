import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../OverView/OverView.css";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { fontSize } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getOverViewConfuguration } from "./services";
import Loader from "../../../components/Loader/Loader";
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import { useCubeQuery } from "@cubejs-client/react";
import Mqqtdata from "../Mqqtdata";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    fontSize: 16,
    fontWeight: 500,
  },
  tableWrapper: {
    maxHeight: 600,
    overflow: "auto",
  },
});

const OverView = () => {

  const [overViewTagData, setOverViewTagData] = useState([]);
  const [overViewDeviceData, setOverViewDeviceData] = useState([]);
  const [tableRowData, setTableRowData] = useState([]);
  const [deviceId, setDeviceId] = useState([]);
  const [deviceIndex, setDeviceIndex] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [tagData, setTagData] = useState([])

  console.log("tagDatatagData", tagData);

  const navigate = useNavigate();
  const classes = useStyles();
  const tableRef = useRef(null);

  const param = useParams();
  const handleScroll = (event) => {
    const target = event.target;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    // Check if the user scrolled to the top of the table
    if (scrollTop === 0) {
      // Page up logic goes here
      console.log("Page up");
    }

    // Check if the user scrolled to the bottom of the table
    if (scrollHeight - scrollTop === clientHeight) {
      // Page down logic goes here
      console.log("Page down");
    }

  };

  useEffect(() => {
    GetOverviewConfigurationApi();
  }, [])

  const GetOverviewConfigurationApi = async () => {
    const paramRequset = {
      plant_id: param.id,
      company_id: param.companyId
    }
    const resp = await getOverViewConfuguration(paramRequset);
    if (resp.status == 200) {
      if (resp.data.payload.length) {
        setOverViewTagData(resp.data.payload[0].tag_assigned);
        setOverViewDeviceData(resp.data.payload[0].device)
      }

    }
    console.log("resp", resp);
  }

  // topic array

  useEffect(() => {
    if (overViewDeviceData.length) {
      const topicArray = overViewDeviceData.map((e) => 'Hikar/DeviceUID/' + e.uid);
      // console.log("topicArray", topicArray);
      setTopics(topicArray)
    }
  }, [overViewDeviceData]);

  // const overViewtable = useCubeQuery({
  //   "dimensions": [
  //     "DeviceConfigurationTagconfiguration.latestValue",
  //     "SuperadminDevice.deviceName",
  //     "DeviceConfigurationTagconfiguration.tagUnit"
  //   ],
  //   "order": {
  //     "DeviceConfigurationTagconfiguration.latestValue": "asc"
  //   },
  //   "filters": [
  //     {
  //       "member": "DeviceConfigurationTagconfiguration.tagName",
  //       "operator": "contains",
  //       "values": overViewTagData.length ? overViewTagData.map((row) => row.tag_name) : ["kp"]
  //     }
  //   ]
  // }, {subscribe: true})

  // useEffect(() => {
  //   if (overViewtable.resultSet) {
  //     const tableData = overViewtable.resultSet.tablePivot().map((raw) => {
  //       return ({
  //         tagUnit: raw["DeviceConfigurationTagconfiguration.tagUnit"],
  //         deviceName: raw["SuperadminDevice.deviceName"],
  //         tagValue: raw["DeviceConfigurationTagconfiguration.latestValue"] ? raw["DeviceConfigurationTagconfiguration.latestValue"]  : 0
  //       })
  //     })

  //     setTableRowData(tableData)
  //     console.log("tableData", tableData);
  //   }
  // }, [overViewtable])


  // useEffect(() => {

  //   if (overViewTagData.length) {

  //     // Function to execute the cube query
  //     const executeCubeQuery = async () => {
  //       try {
  //         const realTimeArray = [];

  //         const cubeData = await Promise.all(
  //           overViewTagData.map(async (array, i) => {
  //             const unitData = await cubejsApi.load({
  //               "filters": [
  //                 {
  //                   "member": "DeviceConfigurationTagconfiguration.tagid",
  //                   "operator": "equals",
  //                   "values": [
  //                     array.id.toString()
  //                   ]
  //                 }
  //               ],
  //               "dimensions": [
  //                 "DeviceConfigurationTagconfiguration.latestValue",
  //                 "DeviceConfigurationTagconfiguration.tagUnit",
  //                 "SuperadminDevice.deviceName"
  //               ],
  //             });

  //             const tableRowData = {
  //               tagUnit: unitData.rawData().length ? unitData.rawData()[0]["DeviceConfigurationTagconfiguration.tagUnit"] : null,
  //               deviceName: unitData.rawData().length ? unitData.rawData()[0]["SuperadminDevice.deviceName"] : null,
  //               tagValue: unitData.rawData().length ? parseFloat(unitData.rawData()[0]["DeviceConfigurationTagconfiguration.latestValue"]) : 0
  //             }
  //             // console.log("unitData", unitData.rawData());
  //             return tableRowData;
  //           })
  //         );

  //         setTableRowData(cubeData);
  //         console.log("cubeData", cubeData);

  //       } catch (error) {
  //         // Handle any errors that occur during the cube query
  //         console.error('Cube query error:', error);
  //       }
  //     };


  //     // Execute the cube query immediately
  //     executeCubeQuery();


  //     // Set interval to execute the cube query every minute
  //     setInterval(() => {
  //       executeCubeQuery();
  //     }, 5000);
  //   }

  // }, [overViewTagData]);


  const handleSelectDevice = (id, index) => {
    if (!Array.isArray(deviceId)) {
      setDeviceId([]);
    }
    if (deviceId.includes(id) || deviceIndex.includes(index)) {
      setDeviceId(deviceId.filter((element) => element !== id));
    } else {
      setDeviceId([id]);
    }

    if (!Array.isArray(deviceIndex)) {
      setDeviceIndex([])
    }
    if (deviceIndex.includes(index)) {
      setDeviceIndex(deviceIndex.filter((element) => element !== index));
    } else {
      setDeviceIndex([index]);
    }
  };


  function handleUpMoveRaw(id) {
    const itemIndex = overViewDeviceData.findIndex(item => item.id === id);

    if (!deviceIndex.length) {
      console.log("overViewDeviceData");
      return true;
    }

    if (deviceIndex[0] == -1) {
      console.log("overViewDeviceData");
      return true;
    }

    if (itemIndex !== -1) {
      const item = overViewDeviceData[itemIndex];
      overViewDeviceData.splice(itemIndex, 1); // Remove the item from its current position
      overViewDeviceData.splice(deviceIndex[0], 0, item); // Insert the item at the new position
      setOverViewDeviceData(overViewDeviceData);
      setDeviceIndex([deviceIndex[0] - 1])
    }
  }

  function handleDownMoveRaw(id) {
    const itemIndex = overViewDeviceData.findIndex(item => item.id === id);

    if (!deviceIndex.length) {
      console.log("overViewDeviceData");
      return true;
    }

    if (deviceIndex[0] == overViewDeviceData.length - 1) {
      console.log("overViewDeviceData");
      return true;
    }

    if (itemIndex !== -1) {
      const item = overViewDeviceData[itemIndex];
      overViewDeviceData.splice(itemIndex, 1); // Remove the item from its current position

      // Adjust the target index based on the current deviceIndex
      const targetIndex = deviceIndex[0] === 0 ? 1 : deviceIndex[0] + 1;

      overViewDeviceData.splice(targetIndex, 0, item); // Insert the item at the new position
      setOverViewDeviceData([...overViewDeviceData]); // Make a shallow copy before updating the state
      setDeviceIndex([deviceIndex[0] + 1]); // Update the deviceIndex
    }
  }



  useEffect(() => {

    if (overViewTagData.length) {
      const mergedArray = [].concat(...Object.values(mqttTagData))

      const filteredArray = mergedArray.filter(obj => {
        const device = obj.device__uid;
        const deviceTag = obj.device_tag;
        return overViewTagData.some(item => item.device__uid === device && item.device_tag === deviceTag);
      });
      console.log("filteredArray", filteredArray);
      setTagData(filteredArray)
    }

  }, [mqttTagData])


  const uidTag = [[{ tag_name: "F1MTR_KVARH" }, { tag_name: "F1MTR_KVAR" }], [{ tag_name: "F2MTR_KVAR" }]]

  return (
    <>
      {overViewDeviceData.length && <Mqqtdata setMqttTagData={setMqttTagData} topics={topics} overViewTagData={overViewTagData} />}
      <div className="page-wraper pb-5">
        <div className="page-header d-flex justify-content-between">
          <div
            onClick={() => navigate(`/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`)}
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Overview</span>
          </div>

          <div className="header-btn-group d-flex">
            <button
              className="overviewReport"
              onClick={() => navigate(`/overView-analytics/company/${param.companyId}/plant/${param.id}`)}
            >
              Report & Analysis
            </button>
          </div>
        </div>
        <div className="pt-3">
          <div className={`${classes.tableWrapper} overViewTableData`}>
            <TableContainer
              component={Paper}
            >
              <Table className={classes.table} aria-label="my table">
                <TableHead>
                  <TableRow className={classes.stickyHeader}>
                    <TableCell colSpan={2} style={{ background: "#ffff" }}>
                      <div >
                        <ArrowDropUpIcon style={{ fontSize: "46px" }} onClick={() => handleUpMoveRaw(deviceId[0])} />
                      </div>
                      <div>
                        <ArrowDropDownIcon
                          style={{ fontSize: "46px", marginTop: "-35px" }}
                          onClick={() => handleDownMoveRaw(deviceId[0])}
                        />
                      </div>
                    </TableCell>
                    {
                      overViewTagData.length ? overViewTagData.map((row, index) => {
                        // Check if the current name has already been included
                        const isDuplicate = overViewTagData.slice(0, index).some(
                          (prevRow) => prevRow.tag_unit === row.tag_unit
                        );

                        if (!isDuplicate) {
                          return (
                            <TableCell
                              style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                background: "#FFFF",
                              }}
                              key={row.tag_unit}
                            >
                              {row.tag_unit}
                            </TableCell>
                          );
                        }

                        return null;
                      }) : null
                    }

                  </TableRow>
                </TableHead>
                <TableBody>
                  {overViewDeviceData.length ? overViewDeviceData.map((row, ind) => {
                    return (
                      <TableRow>
                        <TableCell style={{ width: "0%" }}>
                          <Checkbox checked={deviceId.includes(row.id)} onClick={() => handleSelectDevice(row.id, ind)} />
                        </TableCell>
                        <TableCell>{row.device_name}</TableCell>
                        {
                          overViewTagData.length ? overViewTagData.map((row2, index) => {

                            // const findTagUnitValue1 = tableRowData.find((f) => f.tagUnit == row2.tag_unit && f.deviceName == row.device_name)
                          
                            const findTagUnitValue1 = tagData.find((f) => f.tagUnit == row2.tag_unit && f.device__uid == row.uid)
                            // const findTagUnitValue1 = mqttTagData[row.uid]?.find((f) => f[row2.tag_name])?.[row2.tag_name];
                            // console.log("findTagUnitValue1", findTagUnitValue1);
                            // const tagValue = findTagUnitValue1 ? Object.values(findTagUnitValue1)[0] || 0 : 0;

                            // Check if the current name has already been included
                            const isDuplicate = overViewTagData.slice(0, index).some(
                              (prevRow) => prevRow.tag_unit === row2.tag_unit
                            );


                            if (!isDuplicate) {
                              return (
                                <TableCell>
                                  {findTagUnitValue1 ? findTagUnitValue1.tagValue : ""}
                                </TableCell>
                              );
                            }

                            return null;
                          }) : null
                        }

                      </TableRow>
                    )
                  }) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* <div>
        <FooterText/>
        </div> */}
      </div>
    </>
  );
};
export default OverView;
