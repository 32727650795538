import React, { useState } from 'react';
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { images } from "../../../../config/images";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const ReportManagementReportList = () => {
    const navigate = useNavigate()
    // const [openTagListModal, setOpenTagListModal] = useState(false);
    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    //Tag List Modal
    // const handleTagListModal = () => {
    //     setOpenTagListModal(!openTagListModal);
    // }

    return (
        <>
            <Grid container style={{ paddingTop: "20px" }}>
                <Grid item xs={12}>
                    <Card className="manageDepartment-card">
                        <Grid
                            container
                            style={{
                                paddingLeft: "30px",
                                paddingRight: "30px",
                                paddingTop: "30px",
                                paddingBottom: "30px",
                            }}
                        >
                            {/* =============== Tag List Modal Start =============== */}
                            {/* {openTagListModal &&
                                <div className="card-container">
                                    <Card className="reportManagement-tagListModal">
                                        <Box className="addPlant-titleWrap">
                                            <div className='reportManagement-titleWrap'>
                                                <Typography
                                                    variant="h5"
                                                    gutterBottom
                                                    className="reportManagement-taglistModal-title"
                                                >
                                                    Tag List
                                                </Typography>
                                                <img
                                                    src={images.closeIcon}
                                                    alt=""
                                                    style={{
                                                        width: "30px",
                                                        cursor: "pointer",
                                                        backgroundColor: "white",
                                                    }}
                                                    className="closeImg"
                                                />
                                            </div>
                                        </Box>
                                        <div className="table-container">
                                            <Table aria-label="" className="mt-2">
                                                <TableHead className='sticky-header'>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Checkbox
                                                                {...label}
                                                                className="signIncheckBox"
                                                                defaultChecked={false}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            Tag Name
                                                        </TableCell>
                                                        <TableCell>
                                                            Tag Comment
                                                        </TableCell>
                                                        <TableCell>
                                                            Unit
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className='scrollable-body'>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className=''>
                                                        <TableCell>
                                                            <div className=''>
                                                                <Checkbox
                                                                    {...label}
                                                                    className="signIncheckBox"
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            3
                                                        </TableCell>
                                                        <TableCell>
                                                            4
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Card>
                                </div>
                            } */}
                            {/* =============== Tag List Modal End =============== */}
                            <Grid item sm={12} md={12} lg={3} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>Report Type</b>
                                </Typography>
                                <div className="reportManagement-schedule-card">
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked={false}
                                                style={{
                                                    color: "#1C407B",
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Consumption
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked={false}
                                                style={{
                                                    color: "#1C407B",
                                                }} />

                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Source
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked
                                                style={{
                                                    color: "#1C407B",
                                                }} />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Advance Analytics Summary
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked={false}
                                                style={{
                                                    color: "#1C407B",
                                                }} />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Machine Running Hours
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='alarmManagementCard-Roleswrap'>
                                        <div>
                                            <Checkbox
                                                {...label}
                                                defaultChecked={false}
                                                style={{
                                                    color: "#1C407B",
                                                }} />
                                        </div>
                                        <div>
                                            <Typography variant="body2" gutterBottom className='m-0'>
                                                Energy Cost
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={12} md={12} lg={6} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>Devices</b>
                                </Typography>
                                <div className="reportManagement-schedule-card">
                                    <Card className="addDeviceDetailsCard gap">
                                        <Box className="addDeviceCardDetailsWrap">
                                            <Box className="addDeviceDetailsCardetails-wrap">
                                                <Checkbox
                                                    {...label}
                                                    className="signIncheckBox"
                                                    defaultChecked
                                                    style={{
                                                        color: "#1C407B",
                                                    }}
                                                />
                                                <Box className="addDeviceDetailsCardetails">
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        <b>Device -1</b>
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        className="addDeviceDetailsCardetails-text"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        UID - 71f73dee-4596-48c8-bdof-7f1234d
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        className="addDeviceDetailsCardetails-text"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        Lorem Ipsum is simply dummy text
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <button
                                                className="reportManagement-deviceLists-btn"
                                            >
                                                Tag List
                                            </button>
                                        </Box>
                                    </Card>
                                    <Card className="addDeviceDetailsCard gap">
                                        <Box className="addDeviceCardDetailsWrap">
                                            <Box className="addDeviceDetailsCardetails-wrap">
                                                <Checkbox
                                                    {...label}
                                                    className="signIncheckBox"
                                                    defaultChecked={false}
                                                    style={{
                                                        color: "#1C407B",
                                                    }}
                                                />
                                                <Box className="addDeviceDetailsCardetails">
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        <b>Device -3</b>
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        className="addDeviceDetailsCardetails-text"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        UID - 71f73dee-4596-48c8-bdof-7f1234d
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        className="addDeviceDetailsCardetails-text"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        Lorem Ipsum is simply dummy text
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <button
                                                className="reportManagement-deviceLists-btn1"
                                            >
                                                Tag List
                                            </button>
                                        </Box>
                                    </Card>
                                    <Card className="addDeviceDetailsCard gap">
                                        <Box className="addDeviceCardDetailsWrap">
                                            <Box className="addDeviceDetailsCardetails-wrap">
                                                <Checkbox
                                                    {...label}
                                                    className="signIncheckBox"
                                                    defaultChecked={false}
                                                    style={{
                                                        color: "#1C407B",
                                                    }}
                                                />
                                                <Box className="addDeviceDetailsCardetails">
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        <b>Device -3</b>
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        className="addDeviceDetailsCardetails-text"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        UID - 71f73dee-4596-48c8-bdof-7f1234d
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        className="addDeviceDetailsCardetails-text"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        Lorem Ipsum is simply dummy text
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <button
                                                className="reportManagement-deviceLists-btn1"
                                            >
                                                Tag List
                                            </button>
                                        </Box>
                                    </Card>
                                    <Card className="addDeviceDetailsCard gap">
                                        <Box className="addDeviceCardDetailsWrap">
                                            <Box className="addDeviceDetailsCardetails-wrap">
                                                <Checkbox
                                                    {...label}
                                                    className="signIncheckBox"
                                                    defaultChecked={false}
                                                    style={{
                                                        color: "#1C407B",
                                                    }}
                                                />
                                                <Box className="addDeviceDetailsCardetails">
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom style={{ marginBottom: '0px' }}
                                                    >
                                                        <b>Device -3</b>
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        className="addDeviceDetailsCardetails-text"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        UID - 71f73dee-4596-48c8-bdof-7f1234d
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        className="addDeviceDetailsCardetails-text"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        Lorem Ipsum is simply dummy text
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <button
                                                className="reportManagement-deviceLists-btn1"
                                            >
                                                Tag List
                                            </button>
                                        </Box>
                                    </Card>
                                    <Card className="addDeviceDetailsCard gap">
                                        <Box className="addDeviceCardDetailsWrap">
                                            <Box className="addDeviceDetailsCardetails-wrap">
                                                <Checkbox
                                                    {...label}
                                                    className="signIncheckBox"
                                                    defaultChecked={false}
                                                    style={{
                                                        color: "#1C407B",
                                                    }}
                                                />
                                                <Box className="addDeviceDetailsCardetails">
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        <b>Device -3</b>
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        className="addDeviceDetailsCardetails-text"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        UID - 71f73dee-4596-48c8-bdof-7f1234d
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        className="addDeviceDetailsCardetails-text"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        Lorem Ipsum is simply dummy text
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <button
                                                className="reportManagement-deviceLists-btn1"
                                            >
                                                Tag List
                                            </button>
                                        </Box>
                                    </Card>
                                </div>
                            </Grid>
                            <Grid item sm={12} md={12} lg={3} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>Schedule</b>
                                </Typography>
                                <div className="reportManagement-schedule-card">
                                    <Typography variant="body2" gutterBottom className='m-0' style={{ fontWeight: '500' }}>
                                        AGG.
                                    </Typography>
                                    <select class="form-select form-select-lg reportManagement-schedule-dropdown"
                                        aria-label=".form-select-lg example">
                                        <option value="none" selected disabled hidden>15 Min</option>
                                        <option value="1">1 Min</option>
                                        <option value="2">3 Min</option>
                                        <option value="2">5 Min</option>
                                        <option value="2">10 Min</option>
                                    </select>
                                    <Typography variant="body2" gutterBottom className='m-0 pt-4' style={{ fontWeight: '500' }}>
                                        Email Schedule
                                    </Typography>
                                    <select class="form-select form-select-lg reportManagement-schedule-dropdown"
                                        aria-label=".form-select-lg example">
                                        <option value="none" selected disabled hidden>Weekly</option>
                                        <option value="1">Daily</option>
                                        <option value="2">Weekly</option>
                                        <option value="2">Monthly</option>
                                        <option value="2">Years</option>
                                    </select>
                                    <Typography variant="body2" gutterBottom className='m-0 pt-4' style={{ fontWeight: '500' }}>
                                        Time
                                    </Typography>
                                    <select class="form-select form-select-lg reportManagement-schedule-dropdown"
                                        aria-label=".form-select-lg example">
                                        <option value="none" selected disabled hidden>Select Time of The Week</option>
                                        <option value="1">Daily</option>
                                        <option value="2">Weekly</option>
                                        <option value="2">Monthly</option>
                                        <option value="2">Years</option>
                                    </select>
                                </div>
                                <button className="Plantmanagemet submit">Next</button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid></Grid>
            </Grid>
        </>
    )
}
export default ReportManagementReportList;