import React from "react";
import { redirectUserDetailApi } from "./service";
import { clearUserDetails, setUserDetails } from "./slice/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { clearProcessDashboarData } from "../ProcessDashboard/Consumption/slice/processDashboardSlice";
import { clearProcessDashboarDataSet } from "../ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import { clearOverviewData } from "../ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";
// import { clearOverviewPOPData } from "../ProcessDashboard/OverView/Slice/POPslice";



const MainSiteDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const paramRout = useParams()

    const setUserdetail = async () => {
        const param = {
            token: paramRout.token
        }

        const response = await redirectUserDetailApi(param)
        if (response.status == 200) {
            dispatch(setUserDetails({ firstName: response.data.payload.first_name, lastName: response.data.payload.last_name, email: response.data.payload.email, token: response.data.payload.token, id: response.data.payload.id, MFA_UserId: response.data.payload.mfa_user_id, company_logo: response.data.payload.company_logo, companyId: response.data.payload.company_id,is_super_admin: response.data.payload.is_super_admin, is_admin: response.data.payload.is_admin,companyName : response.data.payload.company_name}));
            navigate('/')
        }
    }
    useEffect(() => {
        dispatch(clearUserDetails());
        dispatch(clearProcessDashboarData());
        dispatch(clearProcessDashboarDataSet());
        dispatch(clearOverviewData())
        // dispatch(clearOverviewPOPData())
        setUserdetail();
    },[])

    return <div></div>
}

export default MainSiteDashboard;