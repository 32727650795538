import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";



const initialState = {
    startDateRe: dayjs().subtract(7, 'day'),
    endDateRe: dayjs(),
    startDateRe2: dayjs().subtract(7, 'day'),
    endDateRe2: dayjs(),
    startDateRe3: dayjs().subtract(7, 'day'),
    endDateRe3: dayjs()
};

const dateSliceprocessDashboard = createSlice({
    name: "processDashboardDate",
    initialState,
    reducers: {
        setProcessDashboarDateSet(state, { payload }) {
            return { ...state, ...payload };
        },
        clearProcessDashboarDataSet(state) {
            return initialState;
        },
    },
});

export const {
    setProcessDashboarDateSet,
    clearProcessDashboarDataSet
} = dateSliceprocessDashboard.actions;

export default dateSliceprocessDashboard.reducer;


