import React, { useEffect, useState } from 'react';
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";

const CubeQueryComponent1 = ({ getDevice, handelCubeChartLineData, handelCubeChartLineData1, selectTimeRange }) => {

    useEffect(() => {

        if (!getDevice[1]) {
            return true
        }
        // Function to execute the cube query
        const executeCubeQuery = async () => {
            try {
                var currentTime;

                if (selectTimeRange == "1second") {
                    currentTime = new Date().toLocaleTimeString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        hour12: false,
                    });
                }

                if (selectTimeRange == "3second") {
                    currentTime = new Date().toLocaleTimeString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        hour12: false,
                    });
                }

                if (selectTimeRange == "5second") {
                    currentTime = new Date().toLocaleTimeString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        hour12: false,
                    });
                }

                if (selectTimeRange == "1min") {
                    currentTime = new Date().toLocaleTimeString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        hour12: false,
                    });
                }

                if (selectTimeRange == "15min") {
                    currentTime = new Date().toLocaleTimeString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        hour12: false,
                    });
                }

                if (selectTimeRange == "30min") {
                    currentTime = new Date().toLocaleTimeString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        hour12: false,
                    });
                }

                if (selectTimeRange == "hour") {
                    currentTime = new Date().toLocaleTimeString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        hour12: false,
                    });
                }

                if (selectTimeRange == "day") {
                    currentTime = new Date().toLocaleDateString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                }

                if (selectTimeRange == "week") {
                    currentTime = new Date().toLocaleDateString('en-US', {
                        timeZone: 'Asia/Kolkata',
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                }

                const realTimeArray = [];

                const chartGraph = await cubejsApi.load(
                    {
                        "dimensions": [
                            "DeviceConfigurationTagconfiguration.latestValue"
                        ],
                        "limit": 1,
                        "filters": [
                            {
                                "member": "DeviceConfigurationTagconfiguration.tagid",
                                "operator": "equals",
                                "values": [getDevice[1].id.toString()]
                            }
                        ]
                    });
                // values: [getDevice[0].id.toString()]
                const cubeData = {
                    tagValue: parseFloat(chartGraph.tablePivot().length ? chartGraph.tablePivot()[0]["DeviceConfigurationTagconfiguration.latestValue"] ? chartGraph.tablePivot()[0]["DeviceConfigurationTagconfiguration.latestValue"] : 0 : 0),
                    tagTime: currentTime
                };
                
                const minMaxvalue = await cubejsApi.load({
                    "measures": [
                        "DashboardTaghistory.maxValue",
                        "DashboardTaghistory.minValue"
                    ],
                    "order": {
                        "DashboardTaghistory.createdAt": "asc"
                    },
                    "filters": [
                        {
                            "member": "DashboardTaghistory.tag",
                            "operator": "equals",
                            "values": [getDevice[1].id.toString()]
                        }
                    ]
                });



                const maxMincube = {
                    maxValue: parseFloat(minMaxvalue.tablePivot().length ? minMaxvalue.tablePivot()[0]["DashboardTaghistory.maxValue"] : 0),
                    minValue: parseFloat(minMaxvalue.tablePivot().length ? minMaxvalue.tablePivot()[0]["DashboardTaghistory.minValue"] : 0)
                };
                handelCubeChartLineData1(cubeData, maxMincube)
            } catch (error) {
                // Handle any errors that occur during the cube query
                console.error('Cube query error:', error);
            }
        };

        // Execute the cube query immediately
        executeCubeQuery();

        // Set interval to execute the cube query every minute
        var interval = null;

        if (selectTimeRange == "1second") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 1000);
        }

        if (selectTimeRange == "3second") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 3000);
        }
        

        if (selectTimeRange == "5second") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 5000);
        }

        if (selectTimeRange == "1min") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 60000);
        }

        if (selectTimeRange == "15min") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 900000);
        }

        if (selectTimeRange == "30min") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 1800000);
        }

        if (selectTimeRange == "hours") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 60000 * 60);
        }

        if (selectTimeRange == "day") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 86400000);
        }

        if (selectTimeRange == "week") {
            interval = setInterval(() => {
                executeCubeQuery();
            }, 7 * 24 * 60 * 60 * 1000);
        }

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(interval);
        };
    }, [getDevice]);
};

export default CubeQueryComponent1;
