import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import "../Alarm/Alarm.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";
import { images } from "../../../config/images";
import { Box, MenuItem, Select, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    maxHeight: 400,
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    fontSize: 16,
  },
  tableContainer: {
    maxHeight: 700,
  },
}));


const OverviewAlarm = () => {
  const [alarm, setAlarm] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState("none");
  const classes = useStyles();
  const navigate = useNavigate();

  const handleChangeSelectPriority = (e) => {
    setSelectedPriority(e.target.value);
  };

  const handleSearchData = () => {
  };

  const handleAlarmClick = () => {
    setAlarm(true);
  };

  const handleClick = () => {
    setAlarm(false);
  };
  const param = useParams();

  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      <div className="page-wraper">
        <div className="page-header d-flex justify-content-between">
          <div
            onClick={() =>
              navigate(
                `/overView/company/${param.companyId}/plant/${param.id}`
              )
            }
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Plant 1- Alarm</span>
          </div>
          <div
            className="header-btn-group d-flex analytics-aggregation analytics-datepicker"
            style={{ justifyContent: "flex-end" }}
          >
            <button className="ackbtn" onClick={() => handleAlarmClick()}>
              <img src={images.right} /> Ack. All
            </button>

            <Box
              className="header-btn-date"
              style={{ width: "22%", marginRight: "0px" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="date-picker-production"
                  label="Start Date"
                  value={startDate}
                  inputFormat="DD/MM/YYYY"
                  views={["day"]}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} style={{ background: "#ffff" }} />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box className="header-btn-month" style={{ width: "22%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="date-picker-production"
                  label="End Date"
                  value={endDate}
                  inputFormat="DD/MM/YYYY"
                  views={["day"]}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} style={{ background: "#ffff" }} />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <img src={images.excelLogo} style={{ height: "55px" }} />
          </div>
        </div>

        <div className="pt-4 pb-4">
          <TableContainer className={classes.tableContainer}>
            <Table className="alarm-table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.stickyHeader}>
                    Priority
                  </TableCell>
                  <TableCell className={classes.stickyHeader}>Date</TableCell>
                  <TableCell className={classes.stickyHeader}>Time</TableCell>
                  <TableCell className={classes.stickyHeader}>Tag</TableCell>
                  <TableCell className={classes.stickyHeader}>
                    Alarm Message
                  </TableCell>
                  <TableCell className={classes.stickyHeader}>State</TableCell>
                  <TableCell className={classes.stickyHeader}>Action</TableCell>
                  <TableCell className={classes.stickyHeader}>Note</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="search-table-row search-table-row-select"
                    style={{ background: "#F1F1FF" }}
                  >
                    <Select
                      className="selectDropdown"
                      style={{ width: "90%", border: "none", outline: "none" }}
                      value={selectedPriority}
                      variant="standard"
                      onChange={handleChangeSelectPriority}
                    >
                      <MenuItem value="none">
                        <div className="priority-status1"></div>
                      </MenuItem>
                      <MenuItem value="priority-status2">
                        <div className="priority-status"></div>
                      </MenuItem>
                      <MenuItem value="priority-status3">
                        <div className="priority-statusInprogress"></div>
                      </MenuItem>
                      <MenuItem value="priority-status4">
                        <div className="priority-statusClose"></div>
                      </MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Date"
                      name="Date"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Time"
                      name="Date"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Tag"
                      name="Date"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Alarm Message"
                      name="Date"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="State"
                      name="Date"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                  <TableCell style={{ background: "#F1F1FF", padding: 0 }}>
                    <Switch {...label} onChange={handleSearchData} />
                  </TableCell>
                  <TableCell style={{ background: "#F1F1FF", padding: 0 }}>
                    <Switch
                      onChange={handleSearchData}
                      defaultChecked
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#00AB66",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#B2E6D1 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#00AB66",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#B2E6D1 !important",
                          },
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div className="priority-status"></div>
                  </TableCell>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>HIKEMM1A_WIXV_01</TableCell>
                  <TableCell>HIKEMM1A_WOXV_01WTR OUT LN 1 VLV CMD</TableCell>
                  <TableCell>OFF</TableCell>
                  <TableCell>
                    <Checkbox defaultChecked style={{ color: "#1C407B" }} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                </TableRow>
                {alarm && (
                  <>
                    <div className="alarmData">
                      <Card className="alarmCard">
                        <CardContent>
                          <h4 className="card-content">
                            <h5
                              style={{
                                textAlign: "center",
                                fontWeight: 500,
                                fontSize: "22px",
                              }}
                            >
                              <b>
                                Are You Sure To <br />
                                Acknowledge All The Alarm ?
                              </b>
                            </h5>
                          </h4>
                        </CardContent>
                        <div style={{ textAlign: "center" }}>
                          <button className="alarmYes" onClick={handleClick}>
                            Yes
                          </button>
                          <button className="alarmNo">No</button>
                        </div>
                      </Card>
                    </div>
                    <div className="sending-uid-overlay" on></div>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};
export default OverviewAlarm;
