import { combineReducers } from "redux";

import userSlice from "../../container/Login/slice/userSlice";
import HeaderSlice from "../../container/Header/headerSlice";
import processDashboardSlice from "../../container/ProcessDashboard/Consumption/slice/processDashboardSlice";
import dateSliceprocessDashboard from "../../container/ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import OverViewAnalyticsSlice from "../../container/ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";



const mainReducer = combineReducers({
    userState: userSlice,
    headerState: HeaderSlice,
    processDashboardState: processDashboardSlice,
    overViewState: OverViewAnalyticsSlice,
    dateSliceprocessDashboardState: dateSliceprocessDashboard
});

const rootReducer = (state, action) => {
    return mainReducer(state, action);
};


export default rootReducer