import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "../container/Login/SignIn/SignIn";
// import ForgotPassword from "../container/Login/ForgotPassword/ForgotPassword";
import Otp from "../container/Login/OTP/Otp"
// import NewPassword from "../container/Login/NewPassword/NewPassword";
import TwoStepVerificationOTP from "../container/Login/SignIn/TwoStepVerificationOTP";
import Login from "../container/Login/SignIn/login";

import ForgotPassword from "../container/Login/forgotPassword";
// import CreatePassword from "../container/Login/RedirectCreatePassword/CreatePassword";
import RedirectMainSite from "../container/Login/RedirectCreatePassword/redirectMainSite";
// import GetOtp from "../container/Login/RedirectCreatePassword/GetOtp";
// import ReEnterPassword from "../container/Login/RedirectCreatePassword/ReEnterPassword";
import MainSiteDashboard from "../container/Login/mainSiteDashboard";

const UnAuthenticationRoutes = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/create-password/:uidb64/:token/" element={<RedirectMainSite />} />
                    <Route path="/twoStep-Verification-OTP" element={<TwoStepVerificationOTP />} />
                    <Route path="/redirect/:token" element={<MainSiteDashboard />} />
                </Routes>
            </Router>
        </>
    )
}

export default UnAuthenticationRoutes;