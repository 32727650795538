import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../OverView/OverView.css";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { fontSize } from "@mui/system";
import { Box, Grid, Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  createOverViewList,
  getDeviceOverview,
  getDeviceUnit,
  getOverViewConfuguration,
} from "../OverView/services";
import { useEffect } from "react";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  //   stickyHeader: {
  //     position: "sticky",
  //     top: 0,
  //     // zIndex: 1,
  //     backgroundColor: "#fff",
  //     fontSize: 16,
  //     fontWeight:500,

  //   },
  tableWrapper: {
    maxHeight: 600,
    overflow: "auto",
  },
});

const ConfigrationOverView = () => {
  const [configrationModal, setConfigrationModal] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [deviceUnit, setDeviceUnit] = useState([]);
  const [selectNumberLength, setSelectNumberLength] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectAllUnits, setSelectAllUnits] = useState(false);

  const navigate = useNavigate();
  const classes = useStyles();
  const param = useParams();

  const handleConfigrationClick = (e) => {
    setConfigrationModal(true);
  };
  const handleCloseConfigrationClick = (e) => {
    setConfigrationModal(false);
    e.stopPropagation();
    setSelectedDevices([]);
    setSelectedUnits([]);
  };

  const handelSelectDevice = (row, id) => {
    row.stopPropagation();
    setDeviceId(id)
      ;
  };
  useEffect(() => {
    handlegetDevice();
  }, []);

  useEffect(() => {
    if (configrationModal) {
      GetOverviewConfigurationApi();
    }

  }, [configrationModal])

  const handlegetDevice = async () => {
    const params = {
      plant_id: param.id,
      company_id: param.companyId,
    };
    try {
      const resp = await getDeviceOverview(params);
      if (resp.data.success == true) {
        setDeviceList(resp.data.payload);
        if (resp.data.payload.length) {
          setDeviceId(resp.data.payload[0].device);
        }
        const selected = [];
        const select = resp.data.payload.map((e) => {
          if (e.is_configured) {
            selected.push(e.device);
          }
        });
        // setSelectedDevices(selected);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };


  const GetOverviewConfigurationApi = async () => {
    const paramRequset = {
      plant_id: param.id,
      company_id: param.companyId
    }
    const resp = await getOverViewConfuguration(paramRequset);
    if (resp.status === 200 && resp.data.payload && resp.data.payload.length > 0) {
      const devicePayload = resp.data.payload[0];
      if (devicePayload.device) {
        const deviceId = devicePayload.device.map((e) => e.id);
        setSelectedDevices(deviceId);
      }
      if (devicePayload.tag_assigned) {
        const unitId = devicePayload.tag_assigned.map((e) => e.id);
        setSelectedUnits(unitId);
      }
    }
  }

  useEffect(() => {
    handleGetUnit();
  }, [deviceId]);

  const handleGetUnit = async () => {
    const params = {
      device_id: deviceId,
      plant_id: param.id,
      company_id: param.companyId,
    };
    try {
      const resp = await getDeviceUnit(params);
      if (resp.data.success == true) {
        setDeviceUnit(resp.data.payload);
        // const selected = [];
        // const select = resp.data.payload.map((e) => {
        //   if (e.is_selected) {
        //     selected.push(e.id);
        //   }
        // });

        const getDeviceId = resp.data.payload.map((e) => e.id);

        if(getDeviceId.every(value => selectedUnits.includes(value))){
          setSelectAllUnits(true);
        }else{
          setSelectAllUnits(false); 
          // console.log("selected",selected);
        }
        

        
        // setSelectedUnits(selected); 
      }
    } catch (error) {
      setDeviceUnit([]);
      console.log("handlesubmit", error);
    }
  };

  const handleCheckBox = (id) => {
    if (selectedUnits.includes(id)) {
      setSelectedUnits(selectedUnits.filter((d) => d !== id));
    } else {
      setSelectedUnits([...selectedUnits, id]);
    }
  };

  const handledeviceCheckBox = (device) => {
    if (selectedDevices.includes(device)) {
      setSelectedDevices(selectedDevices.filter((d) => d !== device));
    } else {
      setSelectedDevices([...selectedDevices, device]);
    }
  };

  const handleAllCheckBox = (event) => {
    if (event.target.checked) {
      const allDeviceIds = deviceList.map((e) => e.device);
      setSelectNumberLength(allDeviceIds);
    } else {
      setSelectNumberLength([]);
    }
  };

  const handleSelectAllUnitCheckbox = () => {
    if (selectAllUnits) {
      // setSelectedUnits([]);
      setSelectedUnits(selectedUnits.filter((d) => !deviceUnit.map((e) => e.id).includes(d)));
    } else {
      const allDeviceIds = deviceUnit.map((e) => e.id);
      setSelectedUnits([...selectedUnits].concat(allDeviceIds));
    }
    setSelectAllUnits(!selectAllUnits);
  };

  const handleSelectAllCheckBox = () => {
    if (selectAll) {
      setSelectedDevices([]);
    } else {
      const allDeviceIds = deviceList.map((e) => e.device);
      setSelectedDevices(allDeviceIds);
    }
    setSelectAll(!selectAll);
  };

  const handleSubmitData = async () => {
    const params = {
      plant_id: Number(param.id),
      company_id: Number(param.companyId),
      device: selectedDevices,
      tag_assigned: selectedUnits,
    };
    try {
      const resp = await createOverViewList(params);
      if (resp.data.success == true) {
        setConfigrationModal(false);
        setSelectedDevices([])
        setSelectedUnits([])
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  return (
    <>
      <div className="page-wraper pb-5">
        <div className="page-header d-flex justify-content-between">
          <div
            onClick={() =>
              navigate(`/overView/company/${param.companyId}/plant/${param.id}`)
            }
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Overview</span>
          </div>

          <div className="header-btn-group d-flex">
            <button
              className="overviewReport"
              style={{ pointerEvents: "none" }}
            >
              Report & Analysis
            </button>
          </div>
        </div>
        <div className="pt-3">
          <div
            className={classes.tableWrapper}
            onClick={handleConfigrationClick}
          >
            <TableContainer
              component={Paper}
              style={{
                backgroundColor: "#AEBCD2B3",
                border: "1px dashed #1C407B",
                opacity: 0.8,
              }}
            >
              <Table
                className={`${classes.table} configrationOverviewTable`}
                aria-label="my table"
              >
                <TableHead>
                  <TableRow className={classes.stickyHeader}>
                    <TableCell colSpan={2}></TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "17px" }}>
                      kW
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "17px" }}>
                      kVA
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "17px" }}>
                      PF
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "17px" }}>
                      V1-V2
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "17px" }}>
                      V2-V3
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "17px" }}>
                      V3-V1
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "17px" }}>
                      A1
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "17px" }}>
                      A2
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "17px" }}>
                      A3
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "17px" }}>
                      kWH
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "17px" }}>
                      kVAH
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Furnace - 1</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Furnace - 2</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Furnace - 3</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Furnace - 4</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Furnace - 5</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Furnace - 6</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Grid - 1</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Grid - 2</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Grid - 3</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Grid - 4</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Auxiliary - 1</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Auxiliary - 2</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Auxiliary - 3</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Auxiliary - 4</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "0%" }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Auxiliary - 5</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                    <TableCell>12345.67</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {configrationModal && (
              <>
                <div className="deviceModal-popup">
                  {/* <div className="d-flex devices-text"> */}
                  <div style={{ display: "flex" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={5}>
                        <h6 style={{ marginTop: "7px" }}>
                          <b>Device 1</b>
                        </h6>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <h6 style={{ marginTop: "7px" }}>
                          <b>Unit 1</b>
                        </h6>
                      </Grid>
                    </Grid>
                  </div>

                  <Grid container spacing={2} className="pt-2">
                    <Grid item xs={12} sm={6} md={5} lg={5}>
                      <Box className="devicemodal-box">
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={selectAll}
                                onClick={handleSelectAllCheckBox}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                        <div style={{ maxHeight: "420px", overflow: "auto" }}>
                          {deviceList.length
                            ? deviceList.map((e) => {
                                return (
                                  <Box
                                    className={`Device-caption d-flex ${
                                      deviceId == e.device
                                        ? "Device-caption1"
                                        : ""
                                    }  mb-2`}
                                    onClick={(row) =>
                                      handelSelectDevice(row, e.device)
                                    }
                                  >
                                    <>
                                      <div>
                                        <Checkbox
                                          style={{
                                            color: "#1C407B",
                                            marginTop: "8px",
                                            marginLeft: "-10px",
                                          }}
                                          checked={selectedDevices.includes(
                                            e.device
                                          )}
                                          onClick={() =>
                                            handledeviceCheckBox(e.device)
                                          }
                                        />
                                      </div>
                                      <div>
                                        <h6>{e.device__device_name}</h6>
                                        <p>UID - {e.device__uid}</p>
                                        <p>{e.device__device_description}</p>
                                      </div>
                                    </>
                                  </Box>
                                );
                              })
                            : []}
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={7} lg={7}>
                      <Box className="devicemodal-box">
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={selectAllUnits}
                                onClick={handleSelectAllUnitCheckbox}

                                // onClick={handleSelectAll}
                              />
                            }
                            label={
                              <Typography style={{ fontSize: "15px" }}>
                                All
                              </Typography>
                            }
                          />
                        </FormGroup>
                        <div
                          class="container pt-2"
                          style={{
                            maxHeight: "420px",
                            overflow: "auto",
                            marginLeft: "20px",
                          }}
                        >
                          {deviceUnit.length ? (
                            <div class="row">
                              {deviceUnit.map((e, index) => (
                                <div className="col-3 mt-4" key={index}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          style={{
                                            color: "#1C407B",
                                            padding: "0px",
                                            marginRight: "8px",
                                          }}
                                          checked={selectedUnits.includes(e.id)}
                                          onClick={() => handleCheckBox(e.id)}

                                          // onClick={handleClick}
                                        />
                                      }
                                      label={
                                        <Typography
                                          style={{ fontSize: "15px" }}
                                        >
                                          {e.tag_unit}
                                        </Typography>
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              ))}
                            </div>
                          ) : (
                            []
                          )}
                        </div>
                      </Box>
                    </Grid>
                  </Grid>

                  <div className="text-center pt-5">
                    <button
                      className="canclebtn"
                      onClick={(e) => handleCloseConfigrationClick(e)}
                    >
                      Cancel
                    </button>
                    <button
                      className="submitbtn"
                      onClick={() => handleSubmitData()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <div>
        <FooterText/>
        </div> */}
      </div>
    </>
  );
};
export default ConfigrationOverView;
