import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import { Divider, ListItemIcon } from "@mui/material";
import { images } from "../../config/images";
// import LoginIcon from '@mui/icons-material/Login';
// import PersonIcon from '@mui/icons-material/Person';
// import LockIcon from '@mui/icons-material/Lock';
// import { Navigate, useNavigate } from "react-router-dom";
import headerLogo from "../../assets/hikar.png";
import profileImg from "../../assets/images/user_male_icon.png";
import expandScreen from "../../assets/images/expandScreen.png";
import { clearUserDetails, setUserDetails } from "../Login/slice/userSlice";
import { clearProcessDashboarData } from "../ProcessDashboard/Consumption/slice/processDashboardSlice";
import { clearProcessDashboarDataSet } from "../ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import "./Header.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { setHeaderDetails } from "./headerSlice";
import { clearOverviewData } from "../ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";
import { logoutAPI } from "../Login/service";
// import { clearOverviewPOPData } from "../ProcessDashboard/OverView/Slice/POPslice";

export default function Header() {
  const [fullScreen, setFullScreen] = useState(false);
  const [headerSet, setHeaderSet] = useState();
  const navigate = useNavigate();
  const [confNavigate, setConfnavigate] = useState(false)

  const location = useLocation();

  const { firstName, lastName, email, company_logo, is_super_admin, is_admin } = useSelector(
    (state) => state.userState
  );

  const { headerName, headerImageSrc } = useSelector(
    (state) => state.headerState
  );

  const handleSuperAdminDashboard = () => {
    const newHeaderImageSrc = require("../../assets/images/dashboard.png");
    dispatch(setHeaderDetails({ headerImageSrc: newHeaderImageSrc }));
    navigate("/superadmindashboard");
  };

  const headerName1 = {
    "/superadmindashboard": (
      <img
        src={require("../../assets/images/dashboard.png")}
        onClick={() => navigate("/")}
      />
    ),
    "/userprofile":" ",
    "/deviceManagement": "Super Admin Dashboard",
    "/plantmanagement": "Super Admin Dashboard",
    "/clientmanagement": "Super Admin Dashboard"
  };

  const regex = /\/(\d+)\//;

  const handleConfCompanyNavigate = () => {
    if (!confNavigate) {
      navigate(
        `/company/${location.pathname.match(regex)[1]
        }/plant/configration/${location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        )}`
      )
      setConfnavigate(!confNavigate)
    } else {
      navigate(
        `company/${location.pathname.match(regex)[1]
        }/plant/plantprocessdashboard/${location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        )}`
      )
      setConfnavigate(!confNavigate)
    }

  }

  const handleConfOverViewNavigate = () => {
    if (!confNavigate) {
           navigate(
        `/overview-configration/company/${location.pathname.match(regex)[1]
        }/plant/${location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        )}`
      )
      setConfnavigate(!confNavigate)
    } else {
      navigate(
        `/overView/company/${location.pathname.match(regex)[1]
        }/plant/${location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        )}`
      )
      setConfnavigate(!confNavigate)
    }

  }

  const headerImages = {
    "/company": (
      <>
        <div>
          <button
            className="configration-btn"
            onClick={() => handleConfCompanyNavigate()}
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
            />
            Configuration
          </button>
        </div>
        <div
          onClick={() =>
            navigate(
              `/servicecall/company/${location.pathname.match(regex)[1]
              }/plant/${location.pathname.substring(
                location.pathname.lastIndexOf("/") + 1
              )}`
            )
          }
          style={{ pointerEvents:"none"}}
        >
          <img src={images.callImg} />
        </div>
        <button
          className="alarm-btn"
          style={{ pointerEvents:"none"}}
          onClick={() =>
            navigate(
              `/alarm/company/${location.pathname.match(regex)[1]
              }/plant/${location.pathname.substring(
                location.pathname.lastIndexOf("/") + 1
              )}`
              
            )
          }
        >
          Alarm
        </button>
      </>
    ),
    "/Company": (
      <>
        <div>
          <button
            className="configration-btn"
            style={{ pointerEvents: "none" }}
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
            />
            Configuration
          </button>
        </div>
        <button className="alarm-button" style={{ pointerEvents: "none" }}>
          Alarm
        </button>
      </>
    ),
    "/overView": (
      <>
        <div>
          <button
            className="configration-btn"
            onClick={() =>
              handleConfOverViewNavigate()
            }
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
            />
            Configuration
          </button>
        </div>
        <button
          className="alarm-button"
          onClick={() =>
            navigate(
              `/overview-alarm/company/${location.pathname.match(regex)[1]
              }/plant/${location.pathname.substring(
                location.pathname.lastIndexOf("/") + 1
              )}`
            )
          }
        >
          Alarm
        </button>
      </>
    ),
    "/overview-configration": (
      <>
        <div>
          <button
            className="configration-btn"
            onClick={() => handleConfOverViewNavigate()}
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
            />
            Configuration
          </button>
        </div>

        <button className="alarm-button" style={{ pointerEvents: "none" }}>
          Alarm
        </button>
      </>
    ),
    "/source": (
      <>
        <div>
          <button
            className="configration-btn"
            onClick={() =>
              navigate(
                `/configrationsource/source/company/${location.pathname.match(regex)[1]
                }/plant/${location.pathname.substring(
                  location.pathname.lastIndexOf("/") + 1
                )}`
              )
            }
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
            />
            Configuration
          </button>
        </div>

        <button
          className="alarm-button"
          onClick={() =>
            navigate(
              `/source-alarm/company/${location.pathname.match(regex)[1]
              }/plant/${location.pathname.substring(
                location.pathname.lastIndexOf("/") + 1
              )}`
            )
          }
        >
          Alarm
        </button>
      </>
    ),
    "/configrationsource": (
      <>
        <div>
          <button
            className="configration-btn"
            style={{ pointerEvents: "none" }}
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
            />
            Configuration
          </button>
        </div>

        <button className="alarm-button" style={{ pointerEvents: "none" }}>
          Alarm
        </button>
      </>
    ),
    "/consumption": (
      <>
        <div>
          <button
            className="configration-btn"
            onClick={() =>
              navigate(
                `/consumption-configration/company/${location.pathname.match(regex)[1]
                }/plant/${location.pathname.substring(
                  location.pathname.lastIndexOf("/") + 1
                )}`
              )
            }
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
            />
            Configuration
          </button>
        </div>

        <button
          className="alarm-button"
          onClick={() =>
            navigate(
              `/consumption-alarm/company/${location.pathname.match(regex)[1]
              }/plant/${location.pathname.substring(
                location.pathname.lastIndexOf("/") + 1
              )}`
            )
          }
          style={{pointerEvents:"none"}}
        >
          Alarm
        </button>
      </>
    ),
    "/consumption-configration": (
      <>
        <div>
          <button
            className="configration-btn"
            onClick={()=> navigate(
              `/consumption/company/${location.pathname.match(regex)[1]
              }/plant/${location.pathname.substring(
                location.pathname.lastIndexOf("/") + 1
              )}`
            )}
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
            />
            Configuration
          </button>
        </div>

        <button className="alarm-button" style={{ pointerEvents: "none" }}>
          Alarm
        </button>
      </>
    ),
    "/analytics": (
      <>
        <div>
          <button
            className="configration-btn"
            style={{ marginRight: "-13px" }}
            onClick={() =>
              navigate(
                `/analytics-configration/company/${location.pathname.match(regex)[1]
                }/plant/${location.pathname.substring(
                  location.pathname.lastIndexOf("/") + 1
                )}`
              )
            }
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
            />
            Configuration
          </button>
        </div>

        <button className="alarm-btn" style={{ pointerEvents: "none" }}>
          Alarm
        </button>
      </>
    ),
    "/analytics-configration": (
      <>
        <div>
          <button
            className="configration-btn"
            style={{ marginRight: "-13px" }}
            onClick={() =>
              navigate(
                `/analytics-configration/company/${location.pathname.match(regex)[1]
                }/plant/${location.pathname.substring(
                  location.pathname.lastIndexOf("/") + 1
                )}`
              )
            }
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
            />
            Configuration
          </button>
        </div>

        <button className="alarm-btn" style={{ pointerEvents: "none" }}>
          Alarm
        </button>
      </>
    ),
    "/analyticsproduction/company/:companyId/plant/:id": (
      <>
        {/* <div>
          <button className="configration-btn" style={{ pointerEvents: "none" }}><img src={images.configration} style={{ height: "18px", width: "18px", marginRight: "8px" }} />Configuration</button>
        </div> */}

        <button className="alarm-button" style={{ pointerEvents: "none" }}>
          Alarm
        </button>
      </>
    ),
    "/energycost": (
      <>
        {/* <div>
          <button className="configration-btn" style={{ pointerEvents: "none" }} ><img src={images.configration} style={{ height: "18px", width: "18px", marginRight: "8px" }} />Configuration</button>
        </div> */}

        <button className="alarm-button" style={{ pointerEvents: "none" }}>
          Alarm
        </button>
      </>
    ),
    "/CO2": (
      <>
        {/* <div>
          <button className="configration-btn" style={{ pointerEvents: "none" }}><img src={images.configration} style={{ height: "18px", width: "18px", marginRight: "8px" }} />Configuration</button>
        </div> */}

        <button className="alarm-button" style={{ pointerEvents: "none" }}>
          Alarm
        </button>
      </>
    ),
  };

  useEffect(() => {
    const end = location.pathname.indexOf("/", 1);
    const dynamicPart = location.pathname.substring(0, end);
    setHeaderSet(dynamicPart ? dynamicPart : location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const kp = { name: "khushal" };

    const newKp = Object.keys(kp).reduce((acc, key) => {
      if (key === "name") {
        acc["fullName"] = kp[key];
      } else {
        acc[key] = kp[key];
      }
      return acc;
    }, {});
  }, []);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async() => {
   const resp = await logoutAPI()
   if(resp.status === 200 || resp.status === 201){
    dispatch(clearUserDetails());
    dispatch(clearProcessDashboarData());
    dispatch(clearProcessDashboarDataSet());
    dispatch(clearOverviewData())
    navigate("/");
    window.location.reload(false)
   }
   
  };

  var screen = document.documentElement;

  const openFullscreen = () => {
    if (screen.requestFullscreen) {
      screen.requestFullscreen();
      setFullScreen(!fullScreen);
    } else if (screen.webkitRequestFullscreen) {
      /* Safari */
      screen.webkitRequestFullscreen();
    } else if (screen.msRequestFullscreen) {
      /* IE11 */
      screen.msRequestFullscreen();
    }

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  function updateDateTime() {
    var tempDate = new Date();
    var date1 =
      tempDate.getDate() +
      "/" +
      (tempDate.getMonth() + 1) +
      "/" +
      tempDate.getFullYear() +
      ", " +
      " " +
      new Date().toLocaleTimeString("en-US", {
        hour12: false,
        hour: "numeric",
        minute: "numeric",
      });
    document.getElementById("currentDateTime").textContent = date1;
  }

  setInterval(updateDateTime, 1000);

  return (
    <>
      <AppBar position="static" className="headerWrap">
        <Container maxWidth="none">
          <Toolbar disableGutters className="toolbarWrap">
            <div className="headerTitle-wrap">
              <Typography variant="h6" noWrap component="a" href="/">
                {company_logo ? (
                  <img src={company_logo} alt="logo" className="headerLogo" />
                ) : (
                  <img src={headerLogo} alt="logo" className="headerLogo" />
                )}
                {/* <img src={company_logo} alt="logo" className="headerLogo" /> */}
              </Typography>
              <p className="headerTitle">
                {headerName1[location.pathname] ? (
                  headerName1[location.pathname]
                ) : (
                  <div onClick={() => window.history.back()}>{headerName}</div>
                )}
              </p>
            </div>
            <Box sx={{ flexGrow: 0 }} className="headerToolbar-wrap">
              {headerImages[headerSet]}
              <div className="dateTime-wrap">
              <p id="currentDateTime"></p>
              </div>
              <div
                className="expandScreen-btn"
                onClick={openFullscreen}
                style={{ cursor: "pointer" }}
              >
                {fullScreen ? (
                  <img src={images.minimizeScreen} />
                ) : (
                  <img src={expandScreen} alt="" />
                )}
              </div>

              <div onClick={handleClick} className="profileToggle-menu">
                <Avatar alt="" src={profileImg} />
                <p className="profileName">
                  {firstName} {lastName}
                </p>
                <KeyboardArrowDownIcon className="ToggleIcon" />
              </div>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgb(84, 108, 138))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      // content: '""',
                      // display: "block",
                      // position: "absolute",
                      // top: 0,
                      // right: 46,
                      // width: 29,
                      // height: 28,
                      // bgcolor: "background.paper",
                      // transform: "translateY(-50%) rotate(45deg)",
                      // zIndex: 0,
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: -27,
                      right: 0,
                      width: 0,
                      height: 0,
                      borderLeft: "25px solid transparent",
                      borderRight: "25px solid transparent",
                      borderBottom: "50px solid #fff",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <div className="profile-item">
                    <Avatar alt="" src={profileImg} className="profile-image" />
                    <div className="profile-detail">
                      <p className="userName">
                        {firstName} {lastName}
                      </p>
                      <p className="userEmail">{email}</p>
                    </div>
                  </div>
                </MenuItem>

                <Divider />
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  onClick={() => navigate("/userprofile")}
                >
                  <ListItemIcon>
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  onClick={() => handleSuperAdminDashboard()}
                >
                  <ListItemIcon>
                    <DashboardIcon fontSize="small" />
                  </ListItemIcon>
                  {is_super_admin ? "Super" : ""} Admin Dashboard
                </MenuItem>
                <MenuItem className="menu-item" sx={{ px: 3, py: 2 }}>
                  <ListItemIcon>
                    <img src={images.passwordImg} alt="" class />
                  </ListItemIcon>
                  Change Password
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  onClick={() => handleLogOut()}
                >
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
