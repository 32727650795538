import * as React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import { images } from "../../config/images";
// import FooterText from '../../components/FooterText/FooterText';
import "./Dashboard.css";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getPlantByCompanyApi } from "../Dashboard1/services";
import { setHeaderDetails } from "../Header/headerSlice";
import { plantDashboardCount } from "../SuperAdminDashboard/service";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const Dashboard = () => {

const [plantData,setPlantData] = useState([])
const [plantDashboardCount1,setPlantDashboardCount1] = useState([])

console.log("plantDashboardCount1plantDashboardCount1",plantDashboardCount1)

  const navigate = useNavigate();
  const dispatch = useDispatch()
  
 const params = useParams();


const getPlantByCompany = async () => {
    const param = {  
        company_name: params.id
        }
    const resp = await getPlantByCompanyApi(param);
    setPlantData(resp.data.payload)
 }
 
 useEffect(() => {
    getPlantByCompany();
 },[]);


 const handleProcessDashboard = (row) =>{
  navigate(`/company/${params.id}/plant/plantprocessdashboard/${row.id}`)
  dispatch(setHeaderDetails({headerName: row.plant_name}));
 }

 useEffect(()=>{
  handlePlantDashboardCount()
 },[])

 const handlePlantDashboardCount = async() =>{
  const param = {
    company_name : params.id
  }
 
 try{
  const resp = await plantDashboardCount(param);
  if(resp.data.success == true){
    setPlantDashboardCount1(resp.data.payload)
  }
 }
 catch(error){
  console.log("handleSubmitError",error)
 }
 }

  return (  
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <Card style={{ marginTop: "15px" }} className="dasboardCard">
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{
                    backgroundColor: "#DCE1EA",
                    width: "2px",
                    height: "49px",
                    marginTop: "20px",
                  }}
                />
              }
              // spacing={{ sm: 14, md: 10, lg: 28 }}
              className="dashboardItem"
            >
              <Item className="dashboardCard-item">
                <img
                  src={images.energyMeterImg}
                  alt=""
                  className="dashboardCard-img"
                />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                    ENERGY METER
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                    {plantDashboardCount1.device_count}
                  </Typography>
                </Stack>
              </Item>
              <Item className="dashboardCard-item">
                <img src={images.clientManagementPlantS} alt="" className="dashboardCard-img" />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                    PLANTS
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                  {plantDashboardCount1.plant_count}
                  </Typography>
                </Stack>
              </Item>
              <Item className="dashboardCard-item">
                <img
                  src={images.activeImg}
                  alt=""
                  className="dashboardCard-img"
                />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                    ACTIVE
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                    01
                  </Typography>
                </Stack>
              </Item>
              <Item className="dashboardCard-item">
                <img
                  src={images.serviceCall}
                  alt=""
                  className="dashboardCard-img"
                />
                <Stack direction="column" spacing={{ lg: 0 }}>
                  <Typography gutterBottom className="dashboardCard-title">
                    SERVICE CALL
                  </Typography>
                  <Typography gutterBottom className="dashboardCard-number">
                    01
                  </Typography>
                </Stack>
              </Item>
            </Stack>
          </Card>
          <Grid container spacing={4} mt={"0px"}>
            {plantData.length ? plantData.map((row) => {
              return (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <Card
                    className="dashboardCard-plant"
                    onClick={() => handleProcessDashboard(row)}
                  >
                    <Stack direction="column">
                      <Typography
                        gutterBottom
                        className="dashboardCard-plantTitle"
                      >
                        {row.plant_name}
                      </Typography>
                      <Typography
                        gutterBottom
                        className="dashboardCard-plantsubTitle"
                      >
                        {row.plant_description ? row.plant_description : "-"}
                      </Typography>
                      <Box className="dashboardCard-plantContent">
                        <Typography
                          gutterBottom
                          className="dashboardCard-plantText"
                        >
                          Device:
                        </Typography>
                        <Stack direction="column">
                          
                          <Typography
                            variant="body1"
                            className="dashboardCard-plantNumber1"
                          >
                           {row.device.filter(value => value.is_sub == true).length}
                          </Typography>
                          <Typography
                            variant="caption"
                            className="dashboardCard-plantSmalltext"
                          >
                            Active
                          </Typography>
                        </Stack>
                        <Box
                          style={{
                            borderRight: "2px solid #C9CFE7",
                            height: "30px",
                          }}
                          paddingRight={2}
                        >
                          <Stack
                            direction="column"
                            style={{ marginTop: "-7px" }}
                          >
                            <Typography
                              variant="body1"
                              className="dashboardCard-plantNumber2"
                            >
                              {row.device.filter(value => value.is_sub == false).length}
                            </Typography>
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Inactive
                            </Typography>
                          </Stack>
                        </Box>
                        <Box
                          style={{
                            borderRight: "2px solid #C9CFE7",
                            height: "30px",
                          }}
                          paddingRight={2}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            style={{ marginTop: "-7px" }}
                          >
                            <img
                              src={images.callImg}
                              alt=""
                              className="dashboardCard-plantImg"
                            />
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Service call
                            </Typography>
                          </Stack>
                        </Box>
                        <Box
                          style={{
                            borderRight: "2px solid #C9CFE7",
                            height: "30px",
                          }}
                          paddingRight={2}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            style={{ marginTop: "-7px" }}
                          >
                            <img
                              src={images.alertImage}
                              alt=""
                              className="dashboardCard-plantImg"
                            />
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Subscription
                            </Typography>
                          </Stack>
                        </Box>
                        <Stack direction="column" spacing={1}>
                          <img
                            src={images.notificationactiveImg}
                            alt=""
                            className="dashboardCard-plantImg"
                          />
                          <Typography
                            variant="caption"
                            className="dashboardCard-plantSmalltext"
                          >
                            Notification
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              );
            }) : []}
          </Grid>
        
        </Container>
      </div>
    </>
  );
};
export default Dashboard;
