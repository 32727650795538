import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { Grid } from "@mui/material";
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import { images } from '../../../../config/images';
import Checkbox from '@mui/material/Checkbox';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import Stack from '@mui/material/Stack';
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    FormControlLabel,
    FormGroup,
    TextField,
} from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@mui/material/Switch";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from '@material-ui/core/styles';
import ReportManagementReportList from './ReportManagementReportList';
import ReportManagementUserList from './ReportManagementUserList';
import './ReportMangementConfiguration.css';

const useStyles = makeStyles({
    root: {
        '& .MuiInputBase-input': {
            padding: '8px 10px', // set the desired padding here
        },
    },
});

const ReportManagementConfiguration = () => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [activeClass, setActiveClass] = useState("Report");
    const classes = useStyles();

    const handleSearchData = () => {

    };
    const param = useParams()
    return (
        <>
            <div className='containerWrap'>
                <Container maxWidth="xxl">
                    <div className='www'>
                        <div className="deviceManagementHeader-wrap">
                            <div className='deviceManagementTitle-wrap'>
                                <KeyboardBackspaceIcon onClick={() => navigate('/alarmmanagement')} className='backArrow' /> <Typography variant="h5" gutterBottom style={{ margin: '0', paddingLeft: '10px', color: '#222D39', fontSize: '16px', fontWeight: '500', cursor: 'pointer' }} onClick={() => navigate('/alarmmanagement')}>
                                    Group -1 Configuration
                                </Typography>
                            </div>
                        </div>
                        <div className="d-flex pt-2">
                            <button
                                className={`${activeClass == "Report" ? "device-tab" : "user-tab"
                                    }`}
                                onClick={() => setActiveClass("Report")}
                            >
                                Report
                            </button>
                            <button
                                className={`${activeClass == "User" ? "device-tab" : "user-tab"
                                    }`}
                                onClick={() => setActiveClass("User")}
                            >
                                User
                            </button>
                        </div>
                    </div>
                    {activeClass == "Report" ? (
                        <>
                            <Container maxWidth="xxl pt-2">
                                <ReportManagementReportList/>
                            </Container>
                        </>
                    ) : (
                        <>
                            <Container maxWidth="xxl">
                                <ReportManagementUserList/>
                            </Container>
                        </>
                    )}
                </Container>
            </div>
            {/* =============================================================== */}

        </>
    )
}

export default ReportManagementConfiguration;