import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import InputLabel from "@mui/material/InputLabel";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { images } from '../../../config/images';
import Checkbox from '@mui/material/Checkbox';
import './ReportManagement.css';
const ReportManagement = () => {
    const [openReportModal, setOpenReportModal] = useState(false);
    const [oepnUsersmodal, setOpenUsersmodal] = useState(false);
    const [Arrow, setArrow] = useState(false);

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const navigate = useNavigate();


    //Add Report modal
    const handleOpenaddReportModal = () => {
        setOpenReportModal(!openReportModal);
    }
    const handleClose = () => {
        setArrow(!Arrow)
    }

    //Users modal
    const handleOpenUsersModal = () => {
        setOpenUsersmodal(!oepnUsersmodal);
    }



    return (
        <>
            {/* {/ <div>AlarmManagement</div> /} */}
            <div className='containerWrap'>
                <Container maxWidth="xxl">
                    <div className="deviceManagementHeader-wrap">
                        <div className='deviceManagementTitle-wrap'>
                            <KeyboardBackspaceIcon onClick={() => navigate('/superadmindashboard')} className='backArrow' /> <Typography variant="h5" gutterBottom style={{ margin: '0', paddingLeft: '10px', color: '#222D39', fontSize: '16px', fontWeight: '500', cursor: 'pointer' }} onClick={() => navigate('/superadmindashboard')}>
                                Report Management
                            </Typography>
                        </div>
                    </div>
                    {/* =============== Add Group Modal Start =============== */}
                    {openReportModal && <Card className="addPlant-cardModal">
                        <Box className='addPlant-titleWrap'>
                            <Typography variant="h5" gutterBottom className='addPlant-title'>
                                Report
                            </Typography>
                            <img src={images.closeIcon} alt="" style={{ width: '30px', cursor: 'pointer', backgroundColor: "white" }} className='closeImg' onClick={handleOpenaddReportModal} />
                        </Box>
                        <Box className='addplantMdal-innerWrap'>
                            <Stack spacing={2} pt="10px">
                                <TextField id="filled-basic" label="Group Name" variant="filled" className='addplantInputfield' InputProps={{
                                }} />
                                <TextField id="filled-basic" label="Description" variant="filled" className='addplantInputfield' InputProps={{
                                }} />
                            </Stack>
                            <Box className='addPlant-cardButtons'>
                                <button className='addPlantBtn' onClick={handleOpenaddReportModal}>Submit</button>
                            </Box>
                        </Box>
                    </Card>}
                    {/* =============== Add Group Modal End =============== */}
                    {/* =============== User Modal Start =============== */}
                    {oepnUsersmodal && <Card className="alarmManagement userCardModal">
                        <Box className='addPlant-titleWrap'>
                            <Typography variant="h5" gutterBottom className='addPlant-title'>
                                Users
                            </Typography>
                            <img src={images.closeIcon} alt="" style={{ width: '30px', cursor: 'pointer', backgroundColor: "white" }} className='closeImg' onClick={handleOpenUsersModal} />
                        </Box>
                        <Box className='alarmMangemnt userCardInnerWrap'>
                            <img src={images.settingImage} alt="" className="alarmManagement settingIcon" />
                            <div className="userDetailsWrap">
                                <Checkbox {...label} style={{ color: '#1C407B' }} defaultChecked={true} />
                                <Typography variant="body2" gutterBottom className='userDetails name'>
                                    Shreekar Yadav
                                </Typography>
                            </div>
                            <div className='userDetailsWrap'>
                                <Checkbox {...label} style={{ color: '#1C407B' }} defaultChecked={true} />
                                <Typography variant="body2" gutterBottom className='userDetails name'>
                                    Parth Yadav
                                </Typography>
                            </div>
                            <div className='userDetailsWrap'>
                                <Checkbox {...label} style={{ color: '#1C407B' }} defaultChecked={true} />
                                <Typography variant="body2" gutterBottom className='userDetails name'>
                                    Zeel Vadodariya
                                </Typography>
                            </div>
                            <div className='userDetailsWrap'>
                                <Checkbox {...label} style={{ color: '#1C407B' }} defaultChecked={true} />
                                <Typography variant="body2" gutterBottom className='userDetails name'>
                                    Maulik Gondaliya
                                </Typography>
                            </div>
                        </Box>
                    </Card>}
                    {/* =============== User Modal End =============== */}
                    <Grid container style={{ paddingTop: '20px' }}>
                        <Grid item xs={12}>
                            <Card className="manageDepartment-card">
                                <Grid container style={{ paddingLeft: '30px', paddingRight: '30px', paddingTop: '30px', paddingBottom: '30px' }}>
                                    <Grid item sm={12} md={4} lg={4} xl={4}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            <b>Select Company</b>
                                            <FormControl className='selectCompany-dropDown'>
                                                <Select
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value={10}>Company - 1
                                                        <Typography variant="body2" gutterBottom style={{ marginBottom: '0px' }}>
                                                            Iron and Steel Industry
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem value={20}>Company - 2
                                                        <Typography variant="body2" gutterBottom style={{ marginBottom: '0px' }}>
                                                            Iron and Steel Industry
                                                        </Typography>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Typography>
                                        <Typography variant="subtitle2" gutterBottom>
                                            <b>Select Plant</b>
                                        </Typography>
                                        <div className='manageDepartment-wrap' onClick={() => navigate('/plantmanagement')}>
                                            <div className='plantmanagementDepartment-fieldWrap'>
                                                <img src={images.activeDeviceimg} alt="" className='activeDeviceimg' />
                                                <div className="manageDepartment-field" id='1' >
                                                    <Typography variant="body2" gutterBottom style={{ margin: '0', color: '#001323' }} id='1' >
                                                        <b id='1' >Plant 1</b>
                                                        <Typography variant="body2" gutterBottom id='1'>
                                                            Iron and Steel Industry
                                                        </Typography>
                                                    </Typography>
                                                    <div className='manageDepartment-fieldIcon'>
                                                        <img src={images.editImg} alt="" style={{ width: '25x', cursor: 'pointer' }} />
                                                        <img src={images.deleteImg} alt="" style={{ width: '25px', cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={12} md={8} lg={8} xl={8}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            <b>Reports</b>
                                        </Typography>
                                        <div className='plantmanagementDepartment-card'>
                                            <Box className='plantmanagementDepartment-addDevicewrap'>
                                                <div className='plantmanagementDepartment-assignDeviceTextWrap' onClick={handleOpenaddReportModal}>
                                                    <Typography variant="caption" display="lock" gutterBottom className="plantmanagemetDepartment-assignDeviceText">
                                                        Add Reports
                                                    </Typography>
                                                    <img src={images.addIcon} alt="" className='addDeviceImg' style={{ cursor: 'pointer' }} />
                                                </div>
                                            </Box>
                                            {/* <Card className='addDeviceDetailsCard gap'>
                                                <Box className="addDeviceCardDetailsWrap">
                                                    <Box className='addDeviceDetailsCardetails-wrap'>
                                                        <Checkbox {...label} className='signIncheckBox' defaultChecked={false} />
                                                        <Box className='addDeviceDetailsCardetails'>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                <b>Device - 1</b>
                                                            </Typography>
                                                            <Typography variant="caption" display="block" gutterBottom className='addDeviceDetailsCardetails-text'>
                                                                UID - 71f73dee-4596-48c8-bdof-7f1234d
                                                            </Typography>
                                                            <Typography variant="caption" display="block" gutterBottom className='addDeviceDetailsCardetails-text'>
                                                                Lorem Ipsum is simply dummy text
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <button className='addDeviceConfi-btn' >Configuration</button>
                                                </Box>
                                            </Card> */}
                                            <Card className='alarmManagement groupDetails-wrap'>
                                                <Box className='groupDetails-innerWrap'>
                                                    <Box>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            <b>Report - 1</b>
                                                        </Typography>
                                                        <Typography variant="caption" display="block" gutterBottom className='addDeviceDetailsCardetails-text'>
                                                            Lorem Ipsum is simply dummy text
                                                        </Typography>
                                                    </Box>
                                                    <Box className='alarmManagement groupDetailsBtnWrap'>
                                                        <img src={images.alertImage} alt="" className='alarmManagement groupIcon' />
                                                        <button className='alarmManagementConfiguration-btn' onClick={() => navigate('/reportmanagement/configuration')} >Configuration</button>
                                                        <img src={images.editImg} alt="" className="alarmManagement groupIcon" />
                                                        <img src={images.deleteImg} alt="" className="alarmManagement groupIcon" />
                                                    </Box>
                                                </Box>
                                                {Arrow &&
                                                    <>
                                                        <hr></hr>
                                                        <div className='reportMangement-reportDetailsWrap'>
                                                            <div class="reportManagement-flex-container">
                                                                <div>
                                                                    <Typography variant="subtitle2" gutterBottom style={{ marginBottom: '0px' }}>
                                                                        <b>Report Type</b>
                                                                    </Typography>
                                                                </div>
                                                                <div>
                                                                    <Typography variant="caption" display="block" gutterBottom className='reportmanagement-text'>
                                                                        Consumption
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                            <div class="reportManagement-flex-container">
                                                                <div>
                                                                    <Typography variant="subtitle2" gutterBottom style={{ marginBottom: '0px' }}>
                                                                        <b>Report Trigger</b>
                                                                    </Typography>
                                                                </div>
                                                                <div>
                                                                    <button className='alarmManagement-deviceList-button'>
                                                                        Daily
                                                                    </button>
                                                                </div>
                                                                <div>
                                                                    <button className='alarmManagement-deviceList-button'>
                                                                        06:00 PM
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="reportManagement-flex-container">
                                                                <div className='invisible'>
                                                                    <Typography variant="subtitle2" gutterBottom style={{ marginBottom: '0px' }}>
                                                                        <b>Report Trigger</b>
                                                                    </Typography>
                                                                </div>
                                                                <div>
                                                                    <button className='alarmManagement-deviceList-button'>
                                                                        Weekly
                                                                    </button>
                                                                </div>
                                                                <div>
                                                                    <button className='alarmManagement-deviceList-button'>
                                                                        Monday
                                                                    </button>
                                                                </div>
                                                                <div>
                                                                    <button className='alarmManagement-deviceList-button'>
                                                                        06:00 PM
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="reportManagement-flex-container">
                                                                <div className='invisible'>
                                                                    <Typography variant="subtitle2" gutterBottom style={{ marginBottom: '0px' }}>
                                                                        <b>Report Trigger</b>
                                                                    </Typography>
                                                                </div>
                                                                <div>
                                                                    <button className='alarmManagement-deviceList-button'>
                                                                        Monthly
                                                                    </button>
                                                                </div>
                                                                <div>
                                                                    <button className='alarmManagement-deviceList-button'>
                                                                        2
                                                                    </button>
                                                                </div>
                                                                <div>
                                                                    <button className="alarmManagement-deviceList-button">06:00 PM</button>
                                                                </div>
                                                            </div>
                                                            <div class="reportManagement-flex-container">
                                                                <div>
                                                                    <Typography variant="subtitle2" gutterBottom style={{ marginBottom: '0px' }}>
                                                                        <b>Device</b>
                                                                    </Typography>
                                                                </div>
                                                                <div>
                                                                    <button className='alarmManagement-deviceList-button'>
                                                                        Device 1
                                                                    </button>
                                                                </div>
                                                                <div>
                                                                    <button className='alarmManagement-deviceList-button'>
                                                                        Device 2
                                                                    </button>
                                                                </div>
                                                                <div>
                                                                    <button className="alarmManagement-deviceList-button">Device 3</button>
                                                                </div>
                                                            </div>
                                                            <div class="reportManagement-flex-container">
                                                                <div>
                                                                    <Typography variant="subtitle2" gutterBottom style={{ marginBottom: '0px' }}>
                                                                        <b>Users</b>
                                                                    </Typography>
                                                                </div>
                                                                <div>
                                                                    <img src={images.userImg} alt=""style={{cursor:'pointer'}} onClick={handleOpenUsersModal} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {/* {/ <img src={images.downIconSmall} alt="" className='clientMangement-downIcon' /> /} */}
                                                {Arrow == true ? <img src={images.upArrow} alt="" className='clientMangement-downIcon' onClick={() => handleClose()} /> : <img src={images.downIcon} alt="" className='clientMangement-downIcon' onClick={() => handleClose()} />}
                                            </Card>
                                        </div>
                                        <button className='Plantmanagemet submit'>Submit</button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default ReportManagement;