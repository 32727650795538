import { Chart } from "chart.js";
import { useEffect, useRef } from "react";

const BarChart = ({ dashboard, tagData, positionValue }) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    var barChartData = {
      labels: dashboard ? dashboard.map((e) => "") : [""],
      datasets: [
        {
          data: positionValue ? positionValue : [],
          backgroundColor: [
            "#AEC7A6",
            "#5A8A8A",
            "#DEC591",
            "#E9B9AA",
            "#8CB9C0",
            "#7892B5"
          ]
        }
      ]
    };

    var chartOptions = {
      maintainAspectRatio: false, // set this to false
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              const dataset = barChartData.datasets[context.datasetIndex];
              const total = dataset.data.reduce(
                (previousValue, currentValue) => previousValue + currentValue
              );
              const currentValue = dataset.data[context.dataIndex];
              const percentage =
                ((currentValue / total) * 100).toFixed(2) + "%";
              return `${currentValue} (${percentage})`;
            },
            title: function (tooltipItem) {
              const labelIndex = tooltipItem[0].dataIndex;
              return dashboard[labelIndex].tag_comment;
            }
          }
        },
        legend: {
          display: false
        }
      },
      layout: {},
      scales: {
        y: {
          title: {
            display: true,
            font: {
              size: 17,
              weight: "bold"
            },
            color: "#001323"
          },
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 12
            },
            color: "#001323"
          }
        },
        x: {
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 14
            },
            color: "#001323"
          }
        }
      }
    };

    const ctx = chartContainer.current.getContext("2d");
    const chart = new Chart(ctx, {
      type: "bar",
      data: barChartData,
      options: chartOptions
    });

    return () => {
      chart.destroy();
    };
  }, [tagData, positionValue]);

  return (
    <>
      <div  style={{ width: '100%', height: '250px' }}  className="processdasboard-barChart">
        
        <canvas ref={chartContainer} id="canvas"></canvas>
      </div>
    </>
  );
};

export default BarChart;
