import React, { useState } from 'react';
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import { images } from "../../../../config/images";
import Checkbox from "@mui/material/Checkbox";

const AlarmManagementDeviceList = () => {
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <>
      <Card className='alarmMangement-deviceList-card'>
        <div className='alarmMangement-deviceList-title'>
          <Typography variant="subtitle2" gutterBottom>
            <b>Select Company</b>
          </Typography>
        </div>
        <div className='alarmManagement-deviceLlistWrap'>
          <div className="alarmManagement-deviceListContent">
            <div>
              <Checkbox
                {...label}
                defaultChecked={false}
              />
            </div>
            <div className='alarmManagement-device-innerContent'>
              <Typography
                variant="body2"
                gutterBottom
                className="assignDeviceDetailsTitle"
              >
                Device - 1
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                className="assignDeviceDetailsDisc"
              >
                UID - 71f73dee-4596-48c8-bdof-7f1234d
              </Typography>
              <Typography
                variant="caption"
                gutterBottom
                className="assignDeviceDetailsDisc">
                Lorem Ipsum is simply dummy text
              </Typography>
            </div>
          </div>
        </div>
        <div className='alarmManagement-deviceLlistWrap mt-2'>
          <div className="alarmManagement-deviceListContent">
            <div>
              <Checkbox
                {...label}
                defaultChecked={false}
              />
            </div>
            <div>
              <Typography
                variant="body2"
                gutterBottom
                className="assignDeviceDetailsTitle"
              >
                Device - 3
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                className="assignDeviceDetailsDisc"
              >
                UID - 71f73dee-4596-48c8-bdof-7f1234d
              </Typography>
              <Typography
                variant="caption"
                gutterBottom
                className="assignDeviceDetailsDisc">
                Lorem Ipsum is simply dummy text
              </Typography>
            </div>
          </div>
        </div>
        <div className='alarmManagement-deviceLlistWrap mt-2'>
          <div className="alarmManagement-deviceListContent">
            <div>
              <Checkbox
                {...label}
                defaultChecked={false}
              />
            </div>
            <div>
              <Typography
                variant="body2"
                gutterBottom
                className="assignDeviceDetailsTitle"
              >
                Device - 3
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                className="assignDeviceDetailsDisc"
              >
                UID - 71f73dee-4596-48c8-bdof-7f1234d
              </Typography>
              <Typography
                variant="caption"
                gutterBottom
                className="assignDeviceDetailsDisc">
                Lorem Ipsum is simply dummy text
              </Typography>
            </div>
          </div>
        </div>
        <div className='alarmManagement-deviceLlistWrap mt-2'>
          <div className="alarmManagement-deviceListContent">
            <div>
              <Checkbox
                {...label}
                defaultChecked={false}
              />
            </div>
            <div>
              <Typography
                variant="body2"
                gutterBottom
                className="assignDeviceDetailsTitle"
              >
                Device - 3
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                className="assignDeviceDetailsDisc"
              >
                UID - 71f73dee-4596-48c8-bdof-7f1234d
              </Typography>
              <Typography
                variant="caption"
                gutterBottom
                className="assignDeviceDetailsDisc">
                Lorem Ipsum is simply dummy text
              </Typography>
            </div>
          </div>
        </div>
        <div className='alarmManagement-deviceLlistWrap mt-2'>
          <div className="alarmManagement-deviceListContent">
            <div>
              <Checkbox
                {...label}
                defaultChecked={false}
              />
            </div>
            <div>
              <Typography
                variant="body2"
                gutterBottom
                className="assignDeviceDetailsTitle"
              >
                Device - 3
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                className="assignDeviceDetailsDisc"
              >
                UID - 71f73dee-4596-48c8-bdof-7f1234d
              </Typography>
              <Typography
                variant="caption"
                gutterBottom
                className="assignDeviceDetailsDisc">
                Lorem Ipsum is simply dummy text
              </Typography>
            </div>
          </div>
        </div>
        <button className='alarmManagement-devicList-nextButton'>Next</button>
      </Card>
    </>
  )
}

export default AlarmManagementDeviceList;