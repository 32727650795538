/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { Grid } from "@mui/material";
import { images } from '../../../config/images';
// import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
// import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './SubscriptionManagement.css';


const SubscriptionManagement = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className='containerWrap'>
                <Container maxWidth="xxl">
                    <div className="subscriptionTitle-wrap">
                        <KeyboardBackspaceIcon onClick={() => navigate('/superadmindashboard')} className='backArrow' /> <Typography variant="h5" gutterBottom style={{ margin: '0', paddingLeft: '18px', color: '#001323', fontWeight: '600' }}>
                            Subscription Management
                        </Typography>
                    </div>
                    <Typography variant="subtitle1" gutterBottom style={{ marginBottom: '20px' }}>
                        Hikar Energy Monitoring Management HikEMM Subscription Module helps you to get On-board to the HiCloud.
                    </Typography>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 1, xl: 2, xxl: 2 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Card className='subscriptionCard'>
                                <CardActionArea>
                                    <img src={images.subscriptionbg} alt="avv" className='subscriptionCard-Image' />
                                    <div className="imageWrap"></div>
                                    <img src={images.userManagement} alt="avv" className='subscriptionCard-Icon' />
                                    <Stack className="subscriptionContent">
                                        <Typography gutterBottom variant="h5" className="subscription-title">
                                            User Management
                                        </Typography>
                                        <Typography gutterBottom variant="h5" className="subscription-text">
                                            Free
                                        </Typography>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eos!</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem</Typography>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        {/* ========================================================================= */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Card className='subscriptionCard'>
                                <CardActionArea>
                                    <img src={images.subscriptionbg} alt="avv" className='subscriptionCard-Image' />
                                    <div className="imageWrap"></div>
                                    <img src={images.accessManagement} alt="avv" className='subscriptionCard-Icon' />
                                    <Stack className="subscriptionContent">
                                        <Typography gutterBottom variant="h5" className="subscription-title">
                                            Access Management
                                        </Typography>
                                        <Typography gutterBottom variant="h5" className="subscription-text">
                                            Free
                                        </Typography>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eos!</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem</Typography>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Card className='subscriptionCard'>
                                <CardActionArea>
                                    <img src={images.subscriptionbg} alt="avv" className='subscriptionCard-Image' />
                                    <div className="imageWrap"></div>
                                    <img src={images.deviceManagement} alt="avv" className='subscriptionCard-Icon' />
                                    <Stack className="subscriptionContent">
                                        <Typography gutterBottom variant="h5" className="subscription-title">
                                            Device Management
                                        </Typography>
                                        <Typography gutterBottom variant="h5" className="subscription-text">
                                            Free
                                        </Typography>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eos!</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem</Typography>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Card className='subscriptionCard' onClick={() => navigate('/hikemmservices')}>
                                <CardActionArea>
                                    <img src={images.subscriptionbg} alt="avv" className='subscriptionCard-Image' />
                                    <div className="imageWrap"></div>
                                    <img src={images.energyMeterImg} alt="avv" className='subscriptionCard-Icon' />
                                    <Stack className="subscriptionContent">
                                        <Typography gutterBottom variant="h5" className="subscription-title">
                                            HikEMM Services
                                        </Typography>
                                        <Typography gutterBottom variant="h5" className="subscription-text">
                                            Start at ₹250!
                                        </Typography>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eos!</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" className="subscription-details">Lorem ipsum dolor sit amet, consectetur</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem</Typography>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Card className='subscriptionCard'>
                                <CardActionArea>
                                    <img src={images.subscriptionbg} alt="avv" className='subscriptionCard-Image' />
                                    <div className="imageWrap"></div>
                                    <img src={images.NotificationImg} alt="avv" className='subscriptionCard-Icon' />
                                    <Stack className="subscriptionContent">
                                        <Typography gutterBottom variant="h5" className="subscription-title">
                                            Alarm Management
                                        </Typography>
                                        <Typography gutterBottom variant="h5" className="subscription-text">
                                            Free
                                        </Typography>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eos!</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem</Typography>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Card className='subscriptionCard'>
                                <CardActionArea>
                                    <img src={images.subscriptionbg} alt="avv" className='subscriptionCard-Image' />
                                    <div className="imageWrap"></div>
                                    <img src={images.reportManagement} alt="avv" className='subscriptionCard-Icon' />
                                    <Stack className="subscriptionContent">
                                        <Typography gutterBottom variant="h5" className="subscription-title">
                                            Report Management
                                        </Typography>
                                        <Typography gutterBottom variant="h5" className="subscription-text">
                                            Free
                                        </Typography>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eos!</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem</Typography>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Card className='subscriptionCard'>
                                <CardActionArea>
                                    <img src={images.subscriptionbg} alt="avv" className='subscriptionCard-Image' />
                                    <div className="imageWrap"></div>
                                    <img src={images.plantsManagement} alt="avv" className='subscriptionCard-Icon' />
                                    <Stack className="subscriptionContent">
                                        <Typography gutterBottom variant="h5" className="subscription-title">
                                            Plant Management
                                        </Typography>
                                        <Typography gutterBottom variant="h5" className="subscription-text">
                                            Free
                                        </Typography>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eos!</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem</Typography>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Card className='subscriptionCard'>
                                <CardActionArea>
                                    <img src={images.subscriptionbg} alt="avv" className='subscriptionCard-Image' />
                                    <div className="imageWrap"></div>
                                    <img src={images.managementImg} alt="avv" className='subscriptionCard-Icon' />
                                    <Stack className="subscriptionContent">
                                        <Typography gutterBottom variant="h5" className="subscription-title">
                                            Client Management
                                        </Typography>
                                        <Typography gutterBottom variant="h5" className="subscription-text">
                                            Free
                                        </Typography>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eos!</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur</Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar className="checkIconbg">
                                                        <CheckRoundedIcon className="checkIcon" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography gutterBottom variant="body2" gutterBottom className="subscription-details">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem</Typography>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}
export default SubscriptionManagement;