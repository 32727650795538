import {request, multipartRequest} from "../../api/api"

// Device

//filter
export const getDevice = (params)=>{
    return request.get(`device/getDevice/?search=${params.device_name || params.device_description || params.uid || params.company__company_name}`);
}

// search
export const getDevicePlant = (params) => {
    return request.get(`device/getDevice/?search=${params}`);
}

export const getDeviceCount = (params) => {
    return request.get(`device/getDevice/`);
}

export const getClientDashboardCount = () => {
    return request.get('/superadmindashboard/getcount/');
}

export const getDeviceById = (id) => {
    return request.get(`/device/getDevice/${id}/`);
}

export const deviceTypeApi = ()=>{
    return request.get('device/getDeviceType/');
}

export const licenseApi = ()=>{
    return request.get('device/getLicense/');
}

export const addDeviceSubmit = (data)=>{
    return request.post('/device/addDevice/', data);
}

export const editDevice = (id, data) => {
    return request.put(`/device/updateDevice/${id}/`, data);
}

export const deleteDevice = (id) => {
    return request.put(`/device/deleteDevice/${id}/`);
}


// device_configuration

//Alarm device_configuration
export const  getAlarmConfiguration = (data) => {
    return request.post('/device_configuration/device/getAlarmConfiguration/', data)
}

// tag device_configuration
export const getTagConfiguration = (data) => {
    return request.post('/device_configuration/device/getTagConfiguration/', data)
}

// Export CSV
export const exportCSVConfiguration = (data) => {
    return request.post('/device_configuration/device/exportTagData/', data)
}

// import CSV
export const importCSVConfiguration = (data) => {
    return multipartRequest.post('/device_configuration/device/importTagData/', data)
}

// Add Configuration
export const addConfigurationApi = (data) => {
    return multipartRequest.post('/device_configuration/device/addManualTagconfiguration/', data)
}

// Edit Configuration
export const updateConfigurationApi = (id, data) => {
    return request.put(`/device_configuration/device/editTagconfiguration/${id}/`, data)
}

// Bridge
export const getDeviceAndHicloudTag = (data) => {
    return request.post('/device_configuration/device/getDeviceTag/', data)
}

//create Bridge
export const createBridgeDeviceAndHicloudTag = (data) => {
    return request.post('/device_configuration/device/bridge/createBridge/', data)
}


//Client Management - Company list get api
export const getcompanyList = (search)=>{
    return request.get(`/client_managent/getCompanyList/?search=${search}`)
}

//Client Management - sort by Company list get api
export const getSortbycompanyList = (search) => {
    return request.get(`/client_managent/getCompanyListByName/?search=${search}`)
}

//Client Management - Company add api
export const addCompany = (data) =>{
    return request.post('/client_managent/addCompany/',data)
}

//Client Management - Company edit api
export const editCompany = (id, data) => {
    return request.put(`/client_managent/updateCompany/${id}/`, data)
}

//Client Management - Company delete api
export const deleteCompany = (id) => {
    return request.put(`/client_managent/deleteCompany/${id}/`)
}

//Client Management && Plant management - get plant api
export const getPlantByCompany = (data) => {
    return request.post('/plant_management/getPlant/', data)
}

//Client Management - get device api
export const getDeviceByCompany = (data) => {
    return request.post('/client_managent/getDeviceList/', data)
}

//Client Management - invite User api
export const inviteUserByCompany = (data) => {
    return request.post('/client_managent/inviteUser/', data)
}


export const getCompanyUserManage = (params) =>{
    return request.post(`/client_managent/getCompanyUser/`,params)
}
 
//Company List
export const getcompanyListPlantManegement = () => {
    return request.get('/client_managent/getCompanyList/')
}
// Plant management
// Plant management - Add Plant
export const addPlantData = (data) =>{
    return request.post('/plant_management/addPlant/', data);
}

//Plant management - delete api  
export const deletePlant = (id) => {
    return request.put(`/plant_management/deletePlant/${id}/`)
}

//Plant management - edit api 
export const editPlant = (id, data) =>{
    return request.put(`/plant_management/updatePlant/${id}/`, data);
}

//Plant management - AssignDevice api 
export const assignDeviceApi = (data) => {
    return request.post('/plant_management/assignDevice/', data);
}

//Plant management - DeviceByDevice api 
export const DeviceByPlantApi = (data) => {
    return request.post('/plant_management/deleteAssignDevice/', data);
}

export const getDeviceAssign = (params) =>{
    return request.post(`/plant_management/getDeviceForAssign/`,params)
}

// User management - get api 
export const getUser = (params) =>{
    return request.get(`/user_management/getUserManagement/?search=${params.first_name || params.last_name || params.department__department_name || params.user_role__name || params.email}`);
}
// User management - get department list
export const getUserDepartment = ()=>{
    return request.get('access_management/getDepartment//');
}
//User management - add department
export const adduserDepartment =  (data)=>{
    return request.post('/user_management/addUserManagement/',data)
}

//User management - upadate department
export const updateUserDepartment =  (id, data)=>{
    return request.put(`/user_management/updateUserManagement/${id}/`,data)
}
//Plant management - edit api 
// export const editPlant = (id, data) =>{
//     return request.put(`/plant_management/updatePlant/${id}/`, data);
// }
//User management - delete department
export const deleteUserDepartment =  (id)=>{
    return request.put(`/user_management/deleteUserManagement/${id}/`)
}

//User management - search 
export const searchData = (params)=>{
    return request.get(`/user_management/getUserManagement/?search=${params.first_name || params.last_name || params.department__department_name || params.user_role__name || params.email}`);
}

//Access management - get department list
export const getDepartment = ()=>{
    return request.get('/access_management/getDepartment/');
}
//Access management - add department
export const addDepartment =  (data)=>{
    return request.post('/access_management/addDepartment/',data)
}

//Access Management -- delete department
export const deleteDepartment =  (id)=>{
    return request.put(`/access_management/deleteDepartment/${id}/`)
}

//Access Management -- Edit Department

export const editDepartManage = (id, params)=>{
    return request.put(`/access_management/updateDepartment/${id}/`,params)
}

//Access Management -- Add role

export const addRoleAPI = (params)=>{
    return request.post(`/access_management/addRole/`,params)
}

//Access Management -- delete role

export const deleteRole = (id) =>{
return request.put(`/access_management/deleteRole/${id}/`)
}

//Access Management --- update Role

export const editRoleManage = (id,params) =>{
return request.put(`/access_management/updateRole/${id}/`,params)
}

//Access Management -- accessModule

export const getAccessModule =()=>{
    return request.get(`/access_management/getAccessModule/`)
}

// add Accessmanagement
export const addAccessManagement = (data) => {
    return request.post('/access_management/addAccessManagement/', data)
}

// get access management data
export const getAccessManagement = (param) => {
    return request.get(`/access_management/getAccessManagement/?search=${param.department__department_name || param.role__role_name}`)
}

//  get access managementBy Id data
export const getAccessManagementById = (id) => {
    return request.get(`/access_management/getAccessManagement/${id}/`)
}

// delete access management data
export const deleteAccessManagement = (id) => {
    return request.put(`/access_management/deleteAccessManagement/${id}/`)
}

// edit access management data
export const editAccessManagement = (id, data) => {
    return request.put(`/access_management/updateAccessManagement/${id}/`, data)
}

export const getAccessmanagement = (search) => {
    return request.get(`/device/getDevice/?search=${search}`)
}

export const plantDashboardCount = (param) =>{
    return request.post(`/dashboard/plantDashboardCount/`,param)
}

export const companyDashboardCountData = (params) =>{
 return request.get(`/dashboard/companyDashboardCount/`,params)
}

