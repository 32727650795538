import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { images } from "../../../config/images";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BarChart } from "./BarChart";
import { useDispatch, useSelector } from "react-redux";
import { getOverviewAnalyticsEnergyChartData } from "./services";
import dayjs from "dayjs";
import { BarChartPOP } from "./BarChartPOP";
import { setOverviewData } from "./Slice/OverViewAnalyticsSlice";

const ZoomInPOPChart = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const [endDate1, setEndDate1] = useState(null);
  const [zoomChartData, setZoomChartData] = useState([]);
  const [zoomChartData1, setZoomChartData1] = useState([]);
  const [average, setAverage] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const param = useParams();
  const navigate = useNavigate();

  const {
    startDatePopRe,
    endDatePopRe,
    startDatePopRe1,
    endDatePopRe1,
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    devicePOPColors,
  } = useSelector((state) => state.overViewState);

  const dispatch = useDispatch()

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const startTimeFormat11 = startDatePopRe ? dayjs(startDatePopRe).format(timeFormat) : "";
  const endTimeFormat11 = endDatePopRe ? dayjs(endDatePopRe).format(timeFormat) : "";
  const startTimeFormat12 = startDatePopRe1 ? dayjs(startDatePopRe1).format(timeFormat) : "";
  const endTimeFormat12 = endDatePopRe ? dayjs(endDatePopRe).format(timeFormat) : "";
  
  const startDateReFormatPOP = startDatePopRe ? dayjs(startDatePopRe) : null;
  const endDateReFormatPOP = endDatePopRe ? dayjs(endDatePopRe) : null;
  const startDateReFormatPOP1 = startDatePopRe1 ? dayjs(startDatePopRe1) : null;
  const endDateReFormatPOP1 = endDatePopRe1 ? dayjs(endDatePopRe1) : null;

  const startTimeFormat =
    startDatePopRe != null ? dayjs(startDatePopRe).format(dateFormat) : "";
  const formatEndDate =
    endDatePopRe != null ? dayjs(endDatePopRe).format(dateFormat) : "";
  const startTimeFormat1 =
    startDatePopRe != null ? dayjs(startDatePopRe1).format(dateFormat) : "";
  const formatEndDate1 =
    endDatePopRe != null ? dayjs(endDatePopRe1).format(dateFormat) : "";

  const findColor =
    zoomChartData.length &&
    devicePOPColors.length &&
    devicePOPColors.find((e) => e.deviceId == zoomChartData[0].device_id);

  const getOverviewAnalyticsEnergyChartApi = async () => {
    const paramRequestData = {
      device_id: deviceIdPOPRedux,
      tag_unit: deviceUnitPOPRedux[0],
      start_date: startTimeFormat,
      end_date: formatEndDate,
    };
    if (startTimeFormat11 !== "00:00:00" && endTimeFormat11 !== "00:00:00") {
      paramRequestData["start_time"] = startTimeFormat11;
      paramRequestData["end_time"] = endTimeFormat11;
    }
    try {
      const resp = await getOverviewAnalyticsEnergyChartData(paramRequestData);

      if (resp.status == 200) {
        setZoomChartData(resp.data.payload);
      }
      console.log("resp", resp);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getOverviewAnalyticsEnergyChartApi();
  }, [startTimeFormat, formatEndDate]);

  const getOverviewAnalyticsEnergyChartApi1 = async () => {
    const paramRequestData = {
      device_id: deviceIdPOPRedux,
      tag_unit: deviceUnitPOPRedux[0],
      start_date: startTimeFormat1,
      end_date: formatEndDate1,
    };
    if (startTimeFormat12 !== "00:00:00" && endTimeFormat12 !== "00:00:00") {
      paramRequestData["start_time"] = startTimeFormat12;
      paramRequestData["end_time"] = endTimeFormat12;
    }
    try {
      const resp = await getOverviewAnalyticsEnergyChartData(paramRequestData);

      if (resp.status == 200) {
        setZoomChartData1(resp.data.payload);
      }
      console.log("resp", resp);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getOverviewAnalyticsEnergyChartApi1();
  }, [startTimeFormat1, formatEndDate1]);

  const handleToggleTime = () => {
    setShowTime((prevShowTime) => !prevShowTime);
  };


  return (
    <>
      <div className="page-wraper">
        <div className="page-header page-header-analytics">
          <div
            className="page-back-btn"
            onClick={() =>
              navigate(
                `/overView-analytics/company/${param.companyId}/plant/${param.id}`
              )
            }
          >
            <ArrowBackIcon />
            <span>Overview - Analytics</span>
          </div>
        </div>
        <div style={{ marginTop: "36px" }}>
          <Box className="overview-zoom-box">
            <Grid container spacing={2}>
              <Grid item md={10}>
                <div className="pt-3" style={{ paddingLeft: "20px" }}>
                  <h6>
                    <b>Energy Bar Chart - Period over Period</b>
                  </h6>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className="d-flex gap-3">
                  <div
                    style={{ marginTop: "6px" }}
                    onClick={() =>
                      navigate(
                        `/overView-analytics/company/${param.companyId}/plant/${param.id}`
                      )
                    }
                  >
                    <img src={images.zoomOut} />
                  </div>
                  {/* <h6 className="AGG-dropdown">AGG.</h6>
                  <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                    <select
                      className="form-input-class"
                      style={{ padding: "14px 8px", borderRadius: "4px" }}
                      id="selectedClass"
                      required
                    >
                      <option value="none" selected disabled hidden>
                        15 Min
                      </option>
                      <option value="1">15 Min</option>
                      <option value="2">30 Min</option>
                      <option value="3">Hourly</option>
                      <option value="4">Daily</option>
                      <option value="5">Weekly</option>
                    </select>
                  </Box> */}

                  <p className="average-toggle" style={{ marginTop: "8px" }}>
                    Average
                  </p>
                  <Switch
                    className="average-toggle-switch"
                    checked={average}
                    onChange={(e) => setAverage(e.target.checked)}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: "#828282",
                      },
                      "& .MuiSwitch-track": {
                        bgcolor: "#D9D9E5 !important",
                      },
                      "& .Mui-checked": {
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#50C878",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#CEE6E1 !important",
                        },
                      },
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <div className="p-4">
              <BarChartPOP
                average={average}
                zoomChartData={zoomChartData}
                zoomChartData1={zoomChartData1}
                startTimeFormat1={startTimeFormat1}
                formatEndDate1={formatEndDate1}
                startTimeFormat={startTimeFormat}
                formatEndDate={formatEndDate}
                devicePOPColors={devicePOPColors}
              />
            </div>
            <div style={{ height: "81px", background: "#F5F5F5" }}>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={5}>
                  <h6 style={{ marginLeft: "20px", marginTop: "30px" }}>
                    <b>Energy [{deviceUnitPOPRedux[0]}]</b>
                  </h6>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={7}
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                   <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={<Switch color="primary" />}
                      label="Time"
                      labelPlacement="top"
                      onChange={handleToggleTime}
                    />
                  </FormGroup>
                  {/* <Box
                    className="header-btn-date"
                    style={{ marginRight: "10px", width: "51%" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        className="date-picker-production datepicker-color"
                        value={startTimeDateFormat}
                        onChange={(newValue) => setStartDate(newValue)}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    className="header-btn-month"
                    style={{ width: "51%", marginRight: "20px" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          className="date-picker-production datepicker-color"
                        label="End Date"
                        value={endTimeDateFormat}
                        onChange={(newValue) => setEndDate(newValue)}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    className="header-btn-date"
                    style={{ marginRight: "10px", width: "51%" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        className="date-picker-production second-datepicker-color"
                        value={startTimeDateFormat1}
                        onChange={(newValue) => setStartDate1(newValue)}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    className="header-btn-month"
                    style={{ width: "51%", marginRight: "20px" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        className="date-picker-production second-datepicker-color"
                        value={endTimeDateFormat1}
                        onChange={(newValue) => setEndDate1(newValue)}
                      />
                    </LocalizationProvider>
                  </Box> */}

{showTime ? (
                    <>
                      <Box style={{ width: "51%" }} className="box-datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="Start Date"
                            className="date-picker-production datepicker-color"
                            value={startDateReFormatPOP}
                            onChange={(newValue) => dispatch(setOverviewData({ startDatePopRe: newValue }))}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className="header-btn-month"
                        style={{ width: "51%" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="End Date"
                            className="date-picker-production datepicker-color"
                            value={endDateReFormatPOP}
                            onChange={(newValue) => dispatch(setOverviewData({ endDatePopRe: newValue }))}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box style={{ width: "51%" }} className="box-datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="Start Date"
                            className="date-picker-production second-datepicker-color"
                            value={startDateReFormatPOP1}
                            onChange={(newValue) => dispatch(setOverviewData({ startDatePopRe1: newValue }))}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className="header-btn-month"
                        style={{ width: "51%" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="End Date"
                            className="date-picker-production second-datepicker-color"
                            value={endDateReFormatPOP1}
                            onChange={(newValue) => dispatch(setOverviewData({ endDatePopRe1: newValue }))}
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box style={{ width: "51%" }} className="box-datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="date-picker-production datepicker-color"
                            label="Start Date"
                            value={startDateReFormatPOP}
                            inputFormat="DD/MM/YYYY"
                            views={["day"]}
                            onChange={(newValue) => dispatch(setOverviewData({ startDatePopRe: newValue }))}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                                InputLabelProps={{
                                  style: {
                                    color: "#C0255F",
                                  },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className="header-btn-month"
                        style={{ width: "51%" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="date-picker-production datepicker-color"
                            label="End Date"
                            value={endDateReFormatPOP}
                            inputFormat="DD/MM/YYYY"
                            views={["day"]}
                            onChange={(newValue) => dispatch(setOverviewData({ endDatePopRe: newValue }))}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>

                      <Box style={{ width: "51%" }} className="box-datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="date-picker-production second-datepicker-color"
                            label="Start Date"
                            value={startDateReFormatPOP1}
                            inputFormat="DD/MM/YYYY"
                            views={["day"]}
                            onChange={(newValue) => dispatch(setOverviewData({ startDatePopRe1: newValue }))}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        className="header-btn-month"
                        style={{ width: "51%" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="date-picker-production second-datepicker-color"
                            label="End Date"
                            value={endDateReFormatPOP1}
                            inputFormat="DD/MM/YYYY"
                            views={["day"]}
                            onChange={(newValue) => dispatch(setOverviewData({ endDatePopRe1: newValue }))}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ background: "#ffff" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
            <div
              className="pt-3"
              style={{ maxHeight: "239px", overflowY: "auto" }}
            >
              <Table className="overview-table overview-table1">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "22%" }}></TableCell>
                    <TableCell>Min</TableCell>
                    <TableCell>Max</TableCell>
                    <TableCell>Mean</TableCell>
                    <TableCell>Std Dev</TableCell>
                    <TableCell>All Time Min</TableCell>
                    <TableCell>All Time Max</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="zoomchart-td">
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems="center"
                          style={{
                            // background: "#efeffd",
                            width: "100%",
                            // marginBottom: px",
                            marginBottom: "0px",
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            style={{
                              padding: "7px 12px",
                              borderLeft: `4px solid ${
                                findColor ? findColor.color : ""
                              }`,
                            }}
                          >
                            <h6 className="mt-2">
                              {zoomChartData
                                ? zoomChartData[0]?.device_name
                                : 0}
                            </h6>
                          </Grid>
                        
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell>
                      {zoomChartData
                        ? zoomChartData[0]
                          ? typeof zoomChartData[0].min_value === "number"
                            ? zoomChartData[0].min_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                    </TableCell>

                    <TableCell>
                    {zoomChartData
                        ? zoomChartData[0]
                          ? typeof zoomChartData[0].max_value === "number"
                            ? zoomChartData[0].max_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                    
                    </TableCell>
                    <TableCell>
                    {zoomChartData
                        ? zoomChartData[0]
                          ? typeof zoomChartData[0].mean_value === "number"
                            ? zoomChartData[0].mean_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                     
                    </TableCell>
                    <TableCell>
                    {zoomChartData
                        ? zoomChartData[0]
                          ? typeof zoomChartData[0].std_dev_value === "number"
                            ? zoomChartData[0].std_dev_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                     
                    </TableCell>
                    <TableCell>
                    {zoomChartData
                        ? zoomChartData[0]
                          ? typeof zoomChartData[0].all_time_min_value === "number"
                            ? zoomChartData[0].all_time_min_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                    
                    </TableCell>
                    <TableCell>
                    {zoomChartData
                        ? zoomChartData[0]
                          ? typeof zoomChartData[0].all_time_max_value === "number"
                            ? zoomChartData[0].all_time_max_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                  
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="zoomchart-td">
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems="center"
                          style={{
                            // background: "#efeffd",
                            width: "100%",
                            // marginBottom: px",
                            marginBottom: "0px",
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            style={{
                              padding: "7px 12px",
                              borderLeft: `4px solid ${
                                findColor ? findColor.color : ""
                              }`,
                            }}
                          >
                            <h6 className="mt-2">
                              {zoomChartData1
                                ? zoomChartData1[0]?.device_name
                                : 0}
                            </h6>
                          </Grid>
                          
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell style={{ paddingLeft: "20px !important" }}>
                    {zoomChartData1
                        ? zoomChartData1[0]
                          ? typeof zoomChartData1[0].min_value === "number"
                            ? zoomChartData1[0].min_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                    
                    </TableCell>
                    <TableCell>
                    {zoomChartData1
                        ? zoomChartData1[0]
                          ? typeof zoomChartData1[0].max_value === "number"
                            ? zoomChartData1[0].max_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                     
                    </TableCell>
                    <TableCell>
                    {zoomChartData1
                        ? zoomChartData1[0]
                          ? typeof zoomChartData1[0].mean_value === "number"
                            ? zoomChartData1[0].mean_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                     
                
                    </TableCell>
                    <TableCell>
                    {zoomChartData1
                        ? zoomChartData1[0]
                          ? typeof zoomChartData1[0].std_dev_value === "number"
                            ? zoomChartData1[0].std_dev_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                    
                    </TableCell>
                    <TableCell>
                    {zoomChartData1
                        ? zoomChartData1[0]
                          ? typeof zoomChartData1[0].all_time_min_value === "number"
                            ? zoomChartData1[0].all_time_min_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                     
                    </TableCell>
                    <TableCell>
                    {zoomChartData1
                        ? zoomChartData1[0]
                          ? typeof zoomChartData1[0].all_time_max_value === "number"
                            ? zoomChartData1[0].all_time_max_value.toFixed(2)
                            : 0
                          : 0
                        : 0}
                  
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};

export default ZoomInPOPChart;
