import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "@mui/material";
import "../Source/Source.css";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import {
  createDeviceConfig,
  getDeviceSourceAPI,
  getDeviceTagByTypeAPI,
  getDeviceType,
} from "../Source/services";
import { useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function ConfigrationCardSlider({ deviceData, confData }) {
  const [devicePopup, setDevicePopup] = useState(false);
  const [searchTagName, setSearchTagName] = useState("");
  const [searchTagComment, setSearchTagComment] = useState("");
  const [searchTagUnit, setSearchTagUnit] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [deviceTagType, setDeviceTagType] = useState([]);
  const [selectNumberLength, setSelectNumberLength] = useState([]);
  const [device, setDevice] = useState([]);
  const [deviceConfigData, setDeviceConfigData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const param = useParams();
  const handleOpenPopup = async (id) => {
    setDevicePopup(true);
    setDeviceId(id);
  };

  // useEffect(() => {
  //   handleDevice();
  // }, []);

  // const handleDevice = async () => {
  //   const params = {
  //     device_type: "Consumption",
  //     plant_id: param.id,
  //   };
  //   const resp = await getDeviceSourceAPI(params);
  //   if (resp.data.success === true) {
  //     setDeviceData(resp.data.payload.device);
  //     setDeviceId(resp.data.payload.device.device)
  //   }
  // };

  useEffect(() => {
    handleGetDevice();
  }, [deviceId]);

  const handleGetDevice = async () => {
    const resp = await getDeviceType(deviceId);
    if (resp.status == 200) {
      setDevice(resp.data);
    }
  };
  useEffect(() => {
    if (deviceId) {
      handleDeviceTagName();
    }
  }, [deviceId]);

  const handleDeviceTagName = async () => {
    const params = {
      device_id: deviceId,
      plant_id: param.id,
      company_id: param.companyId,
    };
    try {
      const resp = await getDeviceTagByTypeAPI(params);
      if (resp.data.success === true) {
        setDeviceTagType(resp.data.payload);
        const selected = [];
        const select = resp.data.payload.map((e) => {
          if (e.is_selected) {
            selected.push(e.id);
          }
        });
        setSelectNumberLength(selected);
      }
    } catch (error) {
      // notify(error.response.data.message);
      setDeviceTagType([]);
      console.log("handlesubmit", error);
    }
  };

  const handleCheckBox = (id) => {
    if (selectNumberLength.length === 3 && !selectNumberLength.includes(id)) {
      return;
    }

    if (selectNumberLength.includes(id)) {
      setSelectNumberLength(
        selectNumberLength.filter((element) => element !== id)
      );
    } else {
      setSelectNumberLength([...selectNumberLength, id]);
    }
  };

  const isCheckboxDisabled = (id) => {
    return selectNumberLength.length === 3 && !selectNumberLength.includes(id);
  };

  const handleCancleClick = () => {
    setDevicePopup(false);
    window.location.reload(false)
  };

  const handleLeft = () => {
    const numSelected = selectNumberLength.length;
    const numLeft = 3 - numSelected;
    return numLeft >= 0 ? numLeft : 0;
  };

  const handleSubmitClick = async () => {
    const params = {
      plant_id: param.id,
      company_id: param.companyId,
      device: deviceId,
      tag_assigned: selectNumberLength,
    };
    try {
      const resp = await createDeviceConfig(params);
      if (resp.data.success == true) {
        setDevicePopup(false);
        setDeviceConfigData(resp.data.payload);
        navigate(`/consumption/company/${param.companyId}/plant/${param.id}`);
        // window.location.reload("false");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  return (
    <>
      <Slider {...sliderSettings} className="my-slider-class">
        {deviceData
          ? deviceData.map((card, index) => (
              <div>
                <Card
                  style={{
                    width: "94%",
                    backgroundColor: "#AEBCD2B3",
                    border: "1px dashed #1C407B",
                    marginLeft: "10px",
                    opacity: "0.8",
                  }}
                  onClick={() => handleOpenPopup(card.device)}
                >
                  <h5
                    className="cardTitle"
                    style={{
                      backgroundColor: "#AEBCD2B3",
                      opacity: "0.8",
                    }}
                  >
                    {card.device__device_name}
                  </h5>
                  {/* <p className="cardDesc">Active: {card.active}</p>
            <p className="cardDesc">Apparent: {card.apparent}</p>
            <p className="cardDesc">KVAr :{card.KVAr}</p> */}
                 <Table className="card-slider-table">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                      {confData[index] && confData[index].length > 0
                        ? (confData[index][0] && confData[index][0].tag_unit) || "-"
                        : "-"
                      }
                      </TableCell>
                      <TableCell>:</TableCell>
                    <TableCell>
                      {/* {tagValue[index] && tagValue[index].length > 0
                        ? tagValue[index][0] : 0
                      } */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                      {confData[index] && confData[index].length > 0
                        ? (confData[index][1] && confData[index][1].tag_unit) || "-"
                        : "-"
                      }
                      </TableCell>
                      <TableCell>:</TableCell>
                    <TableCell>
                      {/* {tagValue[index] && tagValue[index].length > 0
                        ? tagValue[index][1] : 0
                      } */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                      {confData[index] && confData[index].length > 0
                        ? (confData[index][2] && confData[index][2].tag_unit) || "-"
                        : "-"
                      }
                      </TableCell>
                      <TableCell>:</TableCell>
                    <TableCell>
                      {/* {tagValue[index] && tagValue[index].length > 0
                      ? tagValue[index][2] : 0
                      } */}
                    </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </Card>
              </div>
            ))
          : []}
      </Slider>

      {devicePopup && (
        <>
          <div className="deviceModal-popup">
            <div className="d-flex devices-text">
              <h6 style={{ marginTop: "7px" }}>Devices</h6>
              <h6 style={{ marginTop: "7px" }} className="device-tag-list">
                Tag List
              </h6>
              <div className="selectionText">
                {" "}
                {handleLeft()} Left For Selection
              </div>
            </div>
            <Grid container spacing={2} className="pt-2">
              <Grid item xs={12} sm={6} md={5} lg={5}>
                <Box className="devicemodal-box">
                  <div style={{ maxHeight: "425px", overflow: "auto" }}>
                    <Box
                      className="Device-caption mb-2"
                      // onClick={() => setDeviceId(device)}
                    >
                      <h6>{device.device_name}</h6>
                      <p>UID - {device.uid}</p>
                      <p>{device.device_description}</p>
                    </Box>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={7} lg={7}>
                <Box className="devicemodal-box">
                  <div style={{ maxHeight: "454px", overflow: "auto" }}>
                    <Table className="device-table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ background: "white" }}
                          ></TableCell>
                          <TableCell style={{ background: "white" }}>
                            Tag Name
                          </TableCell>
                          <TableCell style={{ background: "white" }}>
                            Tag Comment
                          </TableCell>
                          <TableCell
                            style={{ background: "white", width: "0%" }}
                          >
                            Unit
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className="search-table-row"
                            style={{ background: "#F1F1FF" }}
                          ></TableCell>
                          <TableCell
                            className="search-table-row"
                            style={{ background: "#F1F1FF" }}
                          >
                            <SearchIcon className="search-icon" />
                            <input
                              type="search"
                              placeholder="Tag Name"
                              name="Date"
                              value={searchTagName}
                              onChange={(e) => setSearchTagName(e.target.value)}
                            />
                          </TableCell>
                          <TableCell
                            className="search-table-row"
                            style={{ background: "#F1F1FF" }}
                          >
                            <SearchIcon className="search-icon" />
                            <input
                              type="search"
                              placeholder="Tag Comment"
                              name="Date"
                              value={searchTagComment}
                              onChange={(e) =>
                                setSearchTagComment(e.target.value)
                              }
                            />
                          </TableCell>
                          <TableCell
                            className="search-table-row"
                            style={{ background: "#F1F1FF" }}
                          >
                            <SearchIcon className="search-icon" />
                            <input
                              type="search"
                              placeholder="Unit"
                              name="Date"
                              value={searchTagUnit}
                              onChange={(e) => setSearchTagUnit(e.target.value)}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {deviceTagType.length
                          ? deviceTagType
                              .filter(
                                (e) =>
                                  e.tag_name &&
                                  e.tag_name
                                    .toLowerCase()
                                    .includes(searchTagName.toLowerCase())
                              )
                              .filter(
                                (e) =>
                                  e.tag_comment &&
                                  e.tag_comment
                                    .toLowerCase()
                                    .includes(searchTagComment.toLowerCase())
                              )
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(searchTagUnit.toLowerCase())
                              )
                              .map((e) => {
                                return (
                                  <TableRow>
                                    <TableCell style={{ padding: "0px" }}>
                                      <Checkbox
                                        style={{ color: "#1C407B" }}
                                        onClick={() => handleCheckBox(e.id)}
                                        defaultChecked={e.is_selected}
                                        disabled={isCheckboxDisabled(e.id)}
                                      ></Checkbox>
                                    </TableCell>
                                    <TableCell>{e.tag_name} </TableCell>
                                    <TableCell>{e.tag_comment}</TableCell>
                                    <TableCell>{e.tag_unit}</TableCell>
                                  </TableRow>
                                );
                              })
                          : []}
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </Grid>
            </Grid>

            <div className="pt-3 d-flex selectTagDesc">
              <h6>Selected Tag : </h6>
              {deviceTagType
                .filter((tag) => selectNumberLength.includes(tag.id))
                .map((e, index, array) => (
                  <React.Fragment key={e.id}>
                    <p>{e.tag_name}</p>
                    {index < array.length - 1 && <span>,</span>}
                  </React.Fragment>
                ))}
            </div>
            <div className="text-center">
              <button className="canclebtn" onClick={handleCancleClick}>
                Cancel
              </button>
              <button
                className={
                  selectNumberLength.length == 3
                    ? "submitbtn"
                    : "submit-btn-disable"
                }
                onClick={handleSubmitClick}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ConfigrationCardSlider;
