import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { images } from "../../../config/images";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import {
  getcompanyListPlantManegement,
  getPlantByCompany,
  addPlantData,
  editPlant,
  deletePlant,
  getDevicePlant,
  assignDeviceApi,
  DeviceByPlantApi,
  getAccessmanagement,
  getDeviceByCompany,
  getDeviceAssign,
} from "../service";
import "./PlantManagement.css";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

function PlantManagement() {
  const [selectCompany, setSelectCompany] = React.useState("");
  const [plantId, setPlantId] = useState("");
  const [openAddplant, setOpenAddplant] = useState(false);
  const [openAssignDeviceModal, setOpenAssignDeviceModal] = useState(false);
  const [openEditplant, setOpenEditplant] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteDeviceModal, setOpenDeleteDeviceModal] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [searchDevice, setSearcDevice] = useState("");
  const [addPlant, setAddPlant] = useState({
    plant_name: "",
    plant_description: "",
    company: "",
  });
  const [editPlantData, setEditPlantData] = useState({
    plant_name: "",
    plant_description: "",
    company: "",
  });
  const [deviceList, setDeviceList] = useState([]);
  const [assignDeviceIdArray, setAssignDeviceIdArray] = useState([]);
  const [selectDeviceListArray, setSelectDeviceListArray] = useState([]);
  const [assignDevice, setAssignDevice] = useState([]);

  console.log("assignDeviceassignDeviceassignDevice",assignDevice)

  const {companyId, companyName, companyDescription } = useSelector((state) => state.userState);
  useEffect(() => {
    getDeviceByCompanyApi();
  }, [selectCompany]);

  const getDeviceByCompanyApi = async () => {
    const param = {
      company: selectCompany,
    };
    const resp = await getDeviceAssign(param);
    if (resp.status == 200) {
      setAssignDevice(resp.data.payload);
    }
  };

  const kp = [
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
    {
      device_name: "device",
    },
  ];

  useEffect(() => {
    setDeviceList(kp);
  }, []);

  const selectCompanyName = companyList.find((f) => f.id == selectCompany);

  const navigate = useNavigate();

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleChangeCompany = (e) => {
    setSelectCompany(e.target.value);
  };
  const handleTabValue = (e) => {
    console.log(e.target.id);
  };
  //Add plant modal
  const handleOpenaddPlantMdal = () => {
    setOpenDeleteModal(false);
    setOpenDeleteDeviceModal(false);
    setOpenAssignDeviceModal(false);
    setOpenEditplant(false);
    setOpenAddplant(!openAddplant);
  };
  //Assign Device modal
  const handleOPenAssignDeviceModal = () => {
    setOpenAddplant(false);
    if (!plantList.length) {
      notify("Please Add Plant");

      return true;
    }

    setOpenAssignDeviceModal(!openAssignDeviceModal);
    setOpenDeleteModal(false);
    setOpenDeleteDeviceModal(false);
    getDeviceByCompanyApi();
    // getDeviceListApi();
  };
  //Edit plant modal
  const handleEditModal = (e) => {
    setOpenEditplant(!openEditplant);
    setOpenDeleteModal(false);
    setOpenAssignDeviceModal(false);
    setOpenDeleteDeviceModal(false);
    setEditPlantData({
      plant_name: e.plant_name,
      plant_description: e.plant_description,
      company: "",
    });
    setPlantId(e.id);
  };

  //Delete plant modal
  const handleConfirmationModal = () => {
    setOpenEditplant(false);
    setOpenDeleteModal(!openDeleteModal);
    setOpenAssignDeviceModal(false);
    setOpenDeleteDeviceModal(false);
  };

  //Delete device modal
  const handleConfirmationDeleteDeviceModal = () => {
    setOpenDeleteDeviceModal(!openDeleteDeviceModal);
    setOpenEditplant(false);
    setOpenAssignDeviceModal(false);
    setOpenDeleteModal(false);
  };

  // Add plant API
  const handleChangePlantDetails = (e) => {
    setAddPlant({ ...addPlant, [e.target.name]: e.target.value });
  };

  // select DeviceIdArray
  const handleSelectAssingDeviceId = (id) => {
    if (!Array.isArray(assignDeviceIdArray)) {
      setAssignDeviceIdArray([]);
    }
    if (assignDeviceIdArray.includes(id)) {
      setAssignDeviceIdArray(
        assignDeviceIdArray.filter((element) => element !== id)
      );
    } else {
      setAssignDeviceIdArray([...assignDeviceIdArray, id]);
    }
  };

  const handleSelectDeviceId = (id) => {
    if (!Array.isArray(selectDeviceListArray)) {
      setSelectDeviceListArray([]);
    }
    if (selectDeviceListArray.includes(id)) {
      setSelectDeviceListArray(
        selectDeviceListArray.filter((element) => element !== id)
      );
    } else {
      setSelectDeviceListArray([...selectDeviceListArray, id]);
    }
  };

  const submitAddPlant = async () => {
    const param = {
      plant_name: addPlant.plant_name,
      plant_description: addPlant.plant_description,
      company: selectCompany,
    };
    try {
      const response = await addPlantData(param);
      if (response.data.success == true) {
        setOpenAddplant(false);
        // getcompanyListPlantManegementApi();
        getPlatListByComapnyAPI();
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };

  const getcompanyListPlantManegementApi = async () => {
    const resp = await getcompanyListPlantManegement();
    if (resp.status == 200) {
      if (!selectCompany) {
        //temp change by ut
        setSelectCompany(companyId);
      }
      setCompanyList(resp.data.payload);
    }
  };

  // update plant
  const handleChangePlantData = (e) => {
    setEditPlantData({ ...editPlantData, [e.target.name]: e.target.value });
  };

  // Update plant api
  const handleUpdatePlant = async () => {
    const params = {
      id: plantId,
      plant_name: editPlantData.plant_name,
      plant_description: editPlantData.plant_description,
    };
    try {
      const response = await editPlant(plantId, params);
      if (response.status == 200) {
        getPlatListByComapnyAPI();
        setOpenEditplant(false);
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };

  // Delete plant
  const handleDeletePlant = async () => {
    const response = await deletePlant(plantId);
    if (response.status == 200) {
      // getcompanyListPlantManegementApi();
      getPlatListByComapnyAPI();
      setOpenDeleteModal(false);
    }
  };

  useEffect(() => {
    getcompanyListPlantManegementApi();
  }, []);

  useEffect(() => {
    if (selectCompany) {
      getPlatListByComapnyAPIChnageCountry();
    }
  }, [selectCompany]);

  const getPlatListByComapnyAPI = async () => {
    const param = {
      company: selectCompany,
    };
    const resp = await getPlantByCompany(param);
    // console.log("kgkfdg", resp);
    if (resp.status == 200) {
      setPlantList(resp.data.payload);

      if (plantId) {
        setPlantId(plantId);
      } else {
        setPlantId(resp.data.payload[0].id);
      }
    }
  };

  const getPlatListByComapnyAPIChnageCountry = async () => {
    const param = {
      company: selectCompany,
    };
    const resp = await getPlantByCompany(param);
    if (resp.status == 200) {
      setPlantList(resp.data.payload);
      if (resp.data.payload.length) {
        setPlantId(resp.data.payload[0].id);
      }
    }
  };

  // // getDeviceList
  // const getDeviceListApi = async () => {
  //   const resp = await getAccessmanagement(searchDevice);
  //   if (resp.status == 200) {
  //     setDeviceList(resp.data.payload);
  //   }
  // };

  // useEffect(() => {
  //   getDeviceListApi();
  // }, [searchDevice]);

  const deleteDeviceByPlant = async () => {
    const param = {
      plant: plantId,
      device: selectDeviceListArray,
    };

    if (!selectDeviceListArray.length) {
      notify("Please Select Device");
      return true;
    }
    const resp = await DeviceByPlantApi(param);
    if (resp.status == 200) {
      setOpenDeleteDeviceModal(false);
      getPlatListByComapnyAPI();
      setSelectDeviceListArray([]);
    }
  };

  const submitAssignDevice = async () => {
    const param = {
      plant: plantId,
      device: assignDeviceIdArray,
    };
    if (!assignDeviceIdArray.length) {
      notify("Please Select Device");
      return true;
    }

    const resp = await assignDeviceApi(param);

    if (resp.status == 200) {
      getPlatListByComapnyAPI();
      setAssignDeviceIdArray([]);
      setOpenAssignDeviceModal(!openAssignDeviceModal);
    }
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={4000}></ToastContainer>
      </div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => navigate("/superadmindashboard")}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  margin: "0",
                  paddingLeft: "10px",
                  color: "#222D39",
                  fontSize: "16px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/superadmindashboard")}
              >
                Plant Management
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              <button
                className="addDevice-button plant"
                onClick={handleOpenaddPlantMdal}
              >
                Add Plant
              </button>
            </div>
          </div>
          {/* =============== Add Plant Modal Start =============== */}
          {openAddplant && (
            <Card className="addPlant-cardModal">
              <Box className="addPlant-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addPlant-title"
                >
                  Add Plant
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={handleOpenaddPlantMdal}
                />
              </Box>
              <Box className="addplantMdal-innerWrap">
                <Stack spacing={2} pt="10px">
                  <TextField
                    id="filled-basic"
                    label="Plant Name"
                    name="plant_name"
                    variant="filled"
                    className="addplantInputfield"
                    InputProps={{}}
                    onChange={handleChangePlantDetails}
                  />
                  <TextField
                    id="filled-basic"
                    label="Plant Description"
                    name="plant_description"
                    variant="filled"
                    className="addplantInputfield"
                    InputProps={{}}
                    onChange={handleChangePlantDetails}
                  />
                  {/* <TextField id="filled-basic" label="Iron and Steel Industry" variant="filled" className='addplantInputfield' InputProps={{
                                }} /> */}
                </Stack>
                <Box className="addPlant-cardButtons">
                  <button className="addPlantBtn" onClick={submitAddPlant}>
                    Add
                  </button>
                </Box>
              </Box>
            </Card>
          )}
          {/* =============== Add Plant Modal End =============== */}
          {/* =============== Edit Plant Modal Start =============== */}
          {openEditplant && (
            <Card className="addPlant-cardModal">
              <Box className="addPlant-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addPlant-title"
                >
                  Edit Plant
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={handleEditModal}
                />
              </Box>
              <Box className="addplantMdal-innerWrap">
                <Stack spacing={2} pt="10px">
                  <TextField
                    id="filled-basic"
                    name="plant_name"
                    label="Plant Name"
                    variant="filled"
                    className="addplantInputfield"
                    value={editPlantData.plant_name}
                    onChange={handleChangePlantData}
                    InputProps={{}}
                  />
                  <TextField
                    id="filled-basic"
                    name="plant_description"
                    label="Plant Description"
                    variant="filled"
                    className="addplantInputfield"
                    value={editPlantData.plant_description}
                    onChange={handleChangePlantData}
                    InputProps={{}}
                  />
                  {/* <TextField id="filled-basic" label="Iron and Steel Industry" variant="filled" className='addplantInputfield' InputProps={{
                                }} /> */}
                </Stack>
                <Box className="addPlant-cardButtons">
                  <button className="addPlantBtn" onClick={handleUpdatePlant}>
                    Update
                  </button>
                </Box>
              </Box>
            </Card>
          )}
          {/* =============== Edit Plant Modal  =============== */}
          {/* =============== Delete Plant Modal Start =============== */}
          {openDeleteModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleDeletePlant}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {openDeleteDeviceModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={deleteDeviceByPlant}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationDeleteDeviceModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {/* =============== Delete Plant Modal End =============== */}
          {/* =============== Assign Device Modal Start =============== */}
          {openAssignDeviceModal && (
            <Card className="assignDevice-cardModal">
              <div className="assignDevice-cardTitle">
                <Box>
                  <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 34 }}>
                    <Typography gutterBottom style={{ display: "none" }}>
                      ..
                    </Typography>
                    <img
                      src={images.closeIcon}
                      alt=""
                      style={{ width: "30px", cursor: "pointer" }}
                      onClick={handleOPenAssignDeviceModal}
                    />
                  </Stack>
                  <Typography
                    gutterBottom
                    style={{
                      color: "#1C407B",
                      fontWeight: "600",
                      fontSize: "19px",
                      textAlign: "center",
                    }}
                  >
                    Assign Device
                  </Typography>
                </Box>
                <TextField
                  type="search"
                  fullWidth
                  placeholder="Search Device"
                  value={searchDevice}
                  onChange={(e) => setSearcDevice(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className="assignDeviceSearch"
                />
              </div>
              <div className="container__content">
                <Box>
                  {assignDevice
                    ? assignDevice 
                    .filter((e) =>
                    e.device_name.toLowerCase().includes(searchDevice.toLowerCase())
                  )
                    
                    .map((e) => {
                        return (
                          <>
                            <div className="assignDeviceModalWrap">
                              <div className="assignDeviceDetails">
                                <div className="assignDeviceDetailsWrap">
                                  <div>
                                    <Checkbox
                                      {...label}
                                      defaultChecked={false}
                                      onClick={() =>
                                        handleSelectAssingDeviceId(e.id)
                                      }
                                    />
                                  </div>
                                  <div>
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      className="assignDeviceDetailsTitle"
                                    >
                                      {e.device_name}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                      className="assignDeviceDetailsDisc"
                                    >
                                      {e.device_description}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : []}
                </Box>
              </div>
              <div className="assignDevice-cardTitle1">
                <Box>
                  <button
                    className="assignDevice-submitButton"
                    onClick={submitAssignDevice}
                  >
                    Submit
                  </button>
                </Box>
              </div>
            </Card>
          )}
          {/* =============== Assign Device Modal End =============== */}
          <Grid container style={{ paddingTop: "20px" }}>
            <Grid item xs={12}>
              <Card className="manageDepartment-card">
                <Grid
                  container
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Company</b>
                      <FormControl className="selectCompany-dropDown">
                        <Select
                          value={selectCompany}
                          onChange={handleChangeCompany}
                          inputProps={{ "aria-label": "Without label" }}

                        >
                                 <MenuItem value={companyId}>
                                    {companyName}
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      style={{ marginBottom: "0px" }}
                              
                                    >
                                    {companyDescription}
                                    </Typography>
                                  </MenuItem>
                          {companyList.length
                            ? companyList.map((e) => {
                                return (
                                  <MenuItem value={e.id}>
                                    { e.company_name}
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      style={{ marginBottom: "0px" }}
                              
                                    >
                                      {e.company_description}
                                    </Typography>
                                  </MenuItem>
                                );
                              })
                            : []}
                        </Select>
                      </FormControl>
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Plant</b>
                    </Typography>
                    <div className="manageDepartment-wrap">
                      {plantList.length
                        ? plantList.map((e, i) => {
                            return (
                              <div
                                className="plantmanagementDepartment-fieldWrap"
                                onClick={() => setPlantId(e.id)}
                              >
                                {plantId == e.id && (
                                  <img
                                    src={images.activeDeviceimg}
                                    alt=""
                                    className="activeDeviceimg"
                                  />
                                )}
                                <div
                                  className="manageDepartment-field"
                                  id="1"
                                  onClick={(e) => handleTabValue(e)}
                                >
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ margin: "0", color: "#001323" }}
                                    id="1"
                                    onClick={(e) => handleTabValue(e)}
                                  >
                                    <b
                                      id="1"
                                      onClick={(e) => handleTabValue(e)}
                                    >
                                      {e.plant_name}
                                    </b>
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      id="1"
                                      onClick={(e) => handleTabValue(e)}
                                    >
                                      {e.plant_description
                                        ? e.plant_description
                                        : "-"}
                                    </Typography>
                                  </Typography>
                                  <div className="manageDepartment-fieldIcon">
                                    <img
                                      src={images.editImg}
                                      alt=""
                                      style={{
                                        width: "25x",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleEditModal(e)}
                                    />
                                    <img
                                      src={images.deleteImg}
                                      alt=""
                                      style={{
                                        width: "25px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleConfirmationModal}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : []}
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} lg={8} xl={8}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Assign Device</b>
                    </Typography>
                    <div className="plantmanagementDepartment-card">
                      <Box className="plantmanagementDepartment-addDevicewrap">
                        <div
                          onClick={handleOPenAssignDeviceModal}
                          className="plantmanagementDepartment-assignDeviceTextWrap"
                        >
                          <Typography
                            variant="caption"
                            display="lock"
                            gutterBottom
                            className="plantmanagemetDepartment-assignDeviceText"
                          >
                            Assign Device
                          </Typography>
                          <img
                            src={images.addIcon}
                            alt=""
                            className="addDeviceImg"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <img
                          src={images.editImg}
                          alt=""
                          className="addDeviceImg"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/deviceManagement")}
                        />
                        <img
                          src={images.deleteImg}
                          alt=""
                          className="addDeviceImg"
                          style={{ cursor: "pointer" }}
                          onClick={handleConfirmationDeleteDeviceModal}
                        />
                      </Box>
                      {plantList.length
                        ? plantList.map((e, i) => {
                            if (e.id == plantId) {
                              return e.device.map((e1) => {
                                return (
                                  <Card className="addDeviceDetailsCard gap">
                                    <Box className="addDeviceCardDetailsWrap">
                                      <Box className="addDeviceDetailsCardetails-wrap">
                                        <Checkbox
                                          {...label}
                                          className="signIncheckBox"
                                          defaultChecked={false}
                                          onClick={() =>
                                            handleSelectDeviceId(e1.id)
                                          }
                                        />
                                        <Box className="addDeviceDetailsCardetails">
                                          <Typography
                                            variant="subtitle2"
                                            gutterBottom
                                          >
                                            <b>{e1.device_name}</b>
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className="addDeviceDetailsCardetails-text"
                                          >
                                            {e1.uid}
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className="addDeviceDetailsCardetails-text"
                                          >
                                            {e1.device_description}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <button
                                        className="addDeviceConfi-btn"
                                        onClick={() =>
                                          navigate(
                                            `/deviceManagement/configure/device/${e1.id}`
                                          )
                                        }
                                      >
                                        Configuration
                                      </button>
                                      {/* <button className='addDeviceConfi-btn' onClick={() => navigate('/deviceManagement/configure/device/:id')}>Configuration</button> */}
                                    </Box>
                                  </Card>
                                );
                              });
                            }
                          })
                        : []}
                      {/* <Card className='addDeviceDetailsCard gap'>
                                            <Box className="addDeviceCardDetailsWrap">
                                                <Box className='addDeviceDetailsCardetails-wrap'>
                                                    <Checkbox {...label} className='signIncheckBox' />
                                                    <Box className='addDeviceDetailsCardetails'>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            <b>Device - 2</b>
                                                        </Typography>
                                                        <Typography variant="caption" display="block" gutterBottom className='addDeviceDetailsCardetails-text'>
                                                            UID - 71f73dee-4596-48c8-bdof-7f1234d
                                                        </Typography>
                                                        <Typography variant="caption" display="block" gutterBottom className='addDeviceDetailsCardetails-text'>
                                                            Lorem Ipsum is simply dummy text
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <button className='addDeviceConfi-btn'>Configuration</button>
                                            </Box>
                                        </Card> */}
                      {/* <Card className='addDeviceDetailsCard gap'>
                                            <Box className="addDeviceCardDetailsWrap">
                                                <Box className='addDeviceDetailsCardetails-wrap'>
                                                    <Checkbox {...label} className='signIncheckBox' />
                                                    <Box className='addDeviceDetailsCardetails'>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            <b>Device - 3</b>
                                                        </Typography>
                                                        <Typography variant="caption" display="block" gutterBottom className='addDeviceDetailsCardetails-text'>
                                                            UID - 71f73dee-4596-48c8-bdof-7f1234d
                                                        </Typography>
                                                        <Typography variant="caption" display="block" gutterBottom className='addDeviceDetailsCardetails-text'>
                                                            Lorem Ipsum is simply dummy text
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <button className='addDeviceConfi-btn'>Configuration</button>
                                            </Box>
                                        </Card> */}
                    </div>
                    <button
                      className="Plantmanagemet submit"
                      onClick={() => navigate("/clientmanagement")}
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid></Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default PlantManagement;
