import React, { useState } from "react";
// import { ValidatorForm } from "react-material-ui-form-validator";
import logo from "../../../assets/hikar.png";
import { Grid } from "@mui/material";
// import { InputField } from "../../../components/InputField/InputField";
import TextField from '@mui/material/TextField';
import { Button } from "../../../components/Button/button";
import { useNavigate } from "react-router-dom";
import './ForgotPassword.css';
import { SliderAuth } from "../../../components/SliderAuth/SliderAuth";
import { forgotPasswordApi } from "../service";
import { setUserOtpAndEmail } from "../slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Loader from "../../../components/Loader/Loader";

export default function ForgotPassword({ email, setEmail, onNextClick, onCancelClick, setOTP }) {
    const [isLoading, setLoading] = useState(false)

    const userState = useSelector(state => state.userState);
    const dispatch = useDispatch();

    const notify = (message) => toast.error(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    const handleSentOTp = async () => {

        if (!email) {
            notify("Please Enter valid email id.");
            return true;
        }
            const params = {
                email: email
            }
            try {
                setLoading(true)
                const resp = await forgotPasswordApi(params);
                if (resp.data.success == true) {
                    setLoading(false)
                    dispatch(setUserOtpAndEmail({ ...userState, email: email }))
                    onNextClick();
                    setOTP("")
                }

                if (resp.data.success == false) {
                   notify(resp.data.message)  
                   
                }
            } catch (error) {
                setLoading(false)
                console.log("error", error);
                notify(error.response.data.message)  
                // notify(error.response.data.email[0])  
            }
    }

    return (
        <>
            <div>
                <ToastContainer autoClose={8000}></ToastContainer>
            </div>
            <Grid container className="main-container">
                <SliderAuth />
                <Grid item xs={12} md={5} lg={4} className="signIn-form-wrap">
                    <div className="pb-4">
                        <img src={logo} alt="" className="signIn-logo" />
                    </div>
                    {/* <h1 className="text-center p-5 pb-4">Forgot Password?</h1> */}
                    <h4 className="forgotPassword text-center p-5 pb-3">Forgot Password?</h4>
                    <div className="forgotPassword-lines pb-4">
                        <h5 style={{color:"#001323",fontWeight:400,letterSpacing:"0.48px"}}>You will get an OTP to reset your Password</h5>
                    </div>
                    {/* <ValidatorForm> */}
                    {/* <InputField
                        id="standard-basic"
                        label="Email ID"
                        name="email"
                        value=""
                        validators={["required", "isEmail"]}
                        errorMessages={["email is required", "email is not valid"]}
                        variant="standard"
                    /> */}
                <TextField id="filled-basic" label="Email ID" value={email} onChange={(e) => setEmail(e.target.value)} variant="filled" style={{ width: '100%', marginBottom: '20px' }} 
                InputLabelProps={{
                    style: {
                      color: '#001323', // black color
                     },
                  }} />
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='pb-5'>
                        <Grid item xs={6}>
                            <div onClick={onCancelClick}>
                                <Button title='cancel' classname={"border-btn"} />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div onClick={() => handleSentOTp()}>
                                <Button title='Get OTP' classname={"custom-button"} />
                            </div>
                        </Grid>
                    </Grid>
                    {/* <div className="SingIn-unlock d-flex flex-column pb-0 pb-sm-5 pb-md-3 pb-lg-4">
                        <div>Unlock account?</div>
                        <div>Help</div>
                    </div> */}
                    <div className="SingIn-unlock d-flex flex-column">
                        <div onClick={() => window.location.assign("https://hikartech.com/unlock-account/")} style={{cursor: "pointer"}}>Unlock account?</div>
                        <div>Help</div>
                    </div>
                    <div className="signIn-footer d-flex justify-content-between">
                        <div>
                            <h6>Terms & Conditions</h6>
                        </div>
                        <div>
                            <h6>Privacy</h6>
                        </div>
                        <div>
                            <h6>Cookies</h6>
                        </div>
                        <div>
                            <h6>Trademarks</h6>
                        </div>
                    </div>
                    <div className="signIn-footer-lines">
                        <div>
                            <h6>Powered by</h6>
                        </div>
                        <div>
                            <h6><span>Hikar®Technomation</span> Private Limited © All Rights Reserved</h6>
                        </div>
                    </div>
                    {/* </ValidatorForm> */}
                </Grid>
            </Grid>
            {isLoading && <Loader/>}
        </>
    );
}