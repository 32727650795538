import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



export function PopDonutChart({ 
    analyticsPopPieChart,
    analyticsPop1PieChart,
    selectedColors,
    donutPOPChartSum,
    donutPOP1ChartSum,
    donutPOPUnit}) {

      console.log("analyticsPopPieChart",analyticsPop1PieChart);

      const backColors = analyticsPopPieChart.length &&selectedColors.length&&  selectedColors.find((e)=>e.deviceId ==  analyticsPopPieChart[0].device_id
      )
  const data = {
    datasets: [
      {
        label: analyticsPopPieChart.length ? analyticsPopPieChart[0].device_name : '' ,
        data: [analyticsPopPieChart.length ? analyticsPopPieChart[0].tag_value : 0, analyticsPop1PieChart.length ? analyticsPop1PieChart[0].tag_value : 0],
        backgroundColor: [
          '#C0255F',
          '#E66595',
        ],
        borderColor: [
       '#C0255F',
          '#E66595',
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
        plugins: {
          legend: {
            display: false,
            labels: {
              display: false,
            },
          },
          datalabels: {
            color: "#fff",
            anchor: "center",
            align: "center",
            offset: 0,
            font: {
              weight: "bold",
              size: "14",
            },
          },
          formatter: function (value, context) {
            const dataset = data.datasets[context.datasetIndex];
            const total = dataset.data.reduce(
              (previousValue, currentValue) => previousValue + currentValue
            );
            const currentValue = dataset.data[context.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
            return `${value}\n(${percentage})`;
          },
        },
        cutout: "70%", // Adjust this value to set the width of the doughnut chart
      };
  return (

    <div style={{ width: "100%", height: "195px" }} className="doughnut-chart">
    <Doughnut data={data} options={options} style={{ margin: "auto" }}/>
    <p className="doughnut-chartText">
      {typeof donutPOPChartSum === "number" && typeof donutPOP1ChartSum === "number"
        ? (donutPOPChartSum + donutPOP1ChartSum).toFixed(2)
        : ""}
    </p>
    <p className="doughnut-chartText-unit">{donutPOPUnit}</p>
  </div>
  
  )
}

