import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { InputAdornment } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Switch from '@mui/material/Switch';
import OTPInput from "otp-input-react";
import { images } from '../../../config/images';
import { updateUserProfileCommunication, changePasswordApi, generateMFA, verifyMFA, getMobileOTP, verifyMobileOTP, get2stepMobileOTP } from '../service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './SecurityTab.css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { clearUserDetails } from '../../Login/slice/userSlice';
import { clearProcessDashboarData } from '../../ProcessDashboard/Consumption/slice/processDashboardSlice';
import { clearProcessDashboarDataSet } from '../../ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard';
import { clearOverviewData } from '../../ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'space-between',
        maxWidth: 400,
        // margin: 'auto',
    },
}));


const SecurityTab = ({ userProfile, setUserProfile, getUserProfile, setValue, mobielNo, mobileCountryCode, setMobileNo, setMobileCountryCode }) => {
    const [mobilenumberChecked, setMobilenumberChecked] = React.useState(false);
    const [mfaChecked, setMFAChecked] = React.useState(false);
    const [openSecurityModel, setOpenSecurityModel] = React.useState(false);
    const [openMobilenumberModel, setOpenMobilenumberModel] = React.useState(false);
    const [openOtpModel, setOpenOtpModel] = React.useState(false);
    const [openOtpMobileModel, setOpenOtMobilepModel] = React.useState(false);
    const [openSacanModal, setOpenSacanModal] = React.useState(false);
    const [openGoogleauthenicatorModal, setOpenGoogleauthenicatorModal] = React.useState(false);
    const [openMFAModel, setOpenMFAModel] = React.useState(false);
    const initialValue = { old_password: "", new_password: "", conf_password: "" }
    const [password, setPassword] = useState(initialValue);
    const [qrImage, setQrImage] = useState("");
    const [logout, setLogout] = useState(false);
    const [OTP, setOTP] = useState("");
    const [OTPMobile, setOTPMobile] = useState("");
    const [countryCode, setCountryCode] = useState('');
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(60);
    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showReNewPassword, setShowReNewPassword] = React.useState(false);
    const [visibleOTP, setvisibleOTP] = useState(false);
    const [visibleOTP1, setvisibleOTP1] = useState(false);

    const { id, MFA_UserId } = useSelector(state => state.userState);
    console.log("userProfile", qrImage);

    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const notify = (message) => toast.error(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    const notifySuccess = (message) => toast.success(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });


    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);


    // Mobile number
    const handleChangeMobileNumber = async (event) => {

        if (!userProfile.mobile_number) {
            notify("Please Verify Mobile No");
            return true;
        }

        if (!userProfile.mfa) {
            setUserProfile({ ...userProfile, [event.target.name]: event.target.checked });
            setOpenMobilenumberModel(event.target.checked);
            const param = {
                first_name: userProfile.first_name,
                last_name: userProfile.last_name,
                two_step_verification: event.target.checked,
            }

            try {
                const resp = await updateUserProfileCommunication(id, param);
                if (resp) {
                    getUserProfile();
                }
            }
            catch (error) {
                console.log("Error", error);
            }
        } else {
            notify("you can enable only MFA or 2-Step")
        }
    };
    const handleCloseChangeMobileNumber = async () => {
        setUserProfile({ ...userProfile, ["two_step_verification"]: false });
        setOpenMobilenumberModel(false);
        const param = {
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
            two_step_verification: false,
        }

        try {
            const resp = await updateUserProfileCommunication(id, param);
            if (resp) {
                getUserProfile();
            }
        }
        catch (error) {
            console.log("Error", error);
        }
    }

    // MFA Modal
    const handleChangeMFA = async (event) => {
        if (!userProfile.two_step_verification) {
            setUserProfile({ ...userProfile, [event.target.name]: event.target.checked });
            setOpenMFAModel(event.target.checked);
            const param = {
                first_name: userProfile.first_name,
                last_name: userProfile.last_name,
                mfa: event.target.checked,
            }
            try {
                const resp = await updateUserProfileCommunication(id, param);
                if (resp) {
                    getUserProfile();
                }
            }
            catch (error) {
                console.log("Error", error);
            }
        } else {
            notify("you can enable only MFA or 2-Step")
        }
    };
    const handleCloseChangeMFA = async () => {
        setUserProfile({ ...userProfile, ["mfa"]: false });
        setOpenMFAModel(false)
        const param = {
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
            mfa: false,
        }
        try {
            const resp = await updateUserProfileCommunication(id, param);
            if (resp) {
                getUserProfile();
            }
        }
        catch (error) {
            console.log("Error", error);
        }
    }

    // Account security
    const handleopenAccountSecurityModal = () => {
        setOpenSecurityModel(!openSecurityModel)
    }
    // OTP Modal
    const handleopenOtpModal = () => {
        setOpenOtpModel(!openOtpModel)
        setOpenSacanModal(false);
        handleUpdateMFA();
    }

    const handleopenOtpModalNext = () => {
        setOpenOtpModel(!openOtpModel)
        setOpenSacanModal(false)
    }

    // OTP MobileNumber
    const handleOTPMobileNumber = () => {
        setOpenOtMobilepModel(false)
        setUserProfile({ ...userProfile, ["two_step_verification"]: false });
    }
    //SCAN Modal
    const handleopenScanModal = () => {
        setOpenSacanModal(!openSacanModal)
        setOpenMFAModel(false);
        setUserProfile({ ...userProfile, ["mfa"]: false });
        handleUpdateMFA();
    }

    const handleUpdateMFA = async () => {
        const param = {
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
            mfa: false,
        }
        try {
            const resp = await updateUserProfileCommunication(id, param);
            if (resp) {
                getUserProfile();
            }
        }
        catch (error) {
            console.log("Error", error);
        }
    }
    //Google authenicator Modal
    const handleopenGoogleauthenticator = () => {
        setOpenGoogleauthenicatorModal(!openGoogleauthenicatorModal)
    }

    const handleChangePassword = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value })
    }
    //Logout modal
    const handleLogoutmodal = () => {
        setLogout(!logout);
    }

    const handleLogout = () => {
        dispatch(clearUserDetails())
        navigate('/forgot-password')
    }
    const handleChangeMobile = (e) => {
        setMobileNo(e.target.value)
    }
    const handleChangeCountryCode = (e) => {
        setMobileCountryCode(e.target.value)
    }


    // update MFA
    const updateSubmit = async () => {
        const param = {
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
            two_step_verification: userProfile.two_step_verification,
            mfa: userProfile.mfa
        }

        try {
            const resp = await updateUserProfileCommunication(id, param);
            if (resp) {
                notifySuccess("update Successfully");
                getUserProfile();
                setValue("3")
            }
        }
        catch (error) {
            console.log("Error", error);
        }
    }



    const handlegetMobileOTP = async () => {

        const params = {
            mobile_number: userProfile.mobile_number
        }
        const resp = await get2stepMobileOTP(params);
        if (resp.data.success == true) {
            setOpenOtMobilepModel(true)
            setOpenMobilenumberModel(false);
            setSeconds(60)
        }
    }

    const handleResendMobileOTP = async () => {
        const params = {
            mobile_number: userProfile.mobile_number
        }
        const resp = await getMobileOTP(params);
        if (resp.data.success == true) {
            setOpenOtMobilepModel(true)
            setOpenMobilenumberModel(false)
            setSeconds(60);
            setOTPMobile("")
            notifySuccess("Resend OTP Successfully");
        }
    }

    const handleverifyMobileOTP = async () => {
        if (OTPMobile.length != 6) {
            notify("Please fill the OTP");
            return true;
        }
        const params = {
            mobile_number: userProfile.mobile_number,
            otp: OTPMobile
        }
        const resp = await verifyMobileOTP(params);
        if (resp.status == 200 && resp.data.success == true) {
            setOpenOtMobilepModel(false)
            getUserProfile();
            setOTPMobile("");
            notifySuccess("Successfull verify 2-step verification");
            setUserProfile({ ...userProfile, ["two_step_verification"]: true });
        }

        if (resp.data.success == false) {
            notify(resp.data.message);
        }
    }

    const handelGenerateMFA = async () => {
        const payload = {
            mfa_user_id: MFA_UserId,
            email: userProfile.email
        }
        const resp = await generateMFA(payload);
        if (resp.status == 200) {
            setQrImage(resp.data.payload.mfa_qr_code);
            setOpenSacanModal(!openSacanModal)
            setOpenMFAModel(false);
            setUserProfile({ ...userProfile, ["mfa"]: false });
            setOTP("");
        }
    }

    const handelverifyMFA = async () => {
        const payload = {
            mfa_user_id: MFA_UserId,
            email: userProfile.email,
            mfa_token: OTP
        }
        if (OTP.length == 6) {
            setOpenGoogleauthenicatorModal(true)
            try {
                const resp = await verifyMFA(payload);
                if (resp.status == 200) {
                    setOpenGoogleauthenicatorModal(true);
                    setOpenGoogleauthenicatorModal(false)
                    // handleopenOtpModal();
                    setOpenOtpModel(!openOtpModel);
                    setOpenSacanModal(false);
                    getUserProfile();
                    setOTP("");
                    notifySuccess("Successfull verify MFA");
                    setUserProfile({ ...userProfile, ["mfa"]: true });
                }
            }
            catch (error) {
                console.log(error);
                notify(error.response.data.message)
                setOpenGoogleauthenicatorModal(false);
                // handleopenOtpModal();
            }
        } else {
            notify("Please fill the OTP")
        }
    }

    const submitHandlePassword = async () => {
        if (!password.old_password) {
            notify("Please Enter old Password");
            return true;
        }

        if (!password.new_password) {
            notify("Please Enter New Password");
            return true;
        }

        if (password.old_password == password.new_password) {
            notify("Your old password and new password is same. Please enter different password.");
            return true;
        }

        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,16}$/;

        if (!passwordPattern.test(password.new_password)) {
            notify("Password must contain at least one uppercase letter, at least one lowercase, at least one Alphabet, up to 7 to 16 charactor Long password!");
            return true;
        }

        if (!password.conf_password) {
            notify("Please Enter Conform Password");
            return true;
        }

        if (password.new_password != password.conf_password) {
            notify("Please check password is not same.")
            return true;
        }

        const params = {
            old_password: password.old_password,
            new_password: password.new_password
        }
        try {
            const resp = await changePasswordApi(userProfile.user_id, params);
            if (resp.data.success) {
                handleopenAccountSecurityModal();
                setPassword(initialValue);
                notifySuccess("Password change Successfully");
                // getUserProfile();
                dispatch(clearUserDetails())
                // dispatch(clearProcessDashboarData());
                // dispatch(clearProcessDashboarDataSet());
                // dispatch(clearOverviewData())
                // navigate('/')
                window.location.href = '/login'
            }
        } catch (error) {
            notify(error.response.data.old_password[0])
        }
    }

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const options = {
            weekday: "short",
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "Asia/Kolkata",
        };
        const formattedDate = `${date.toLocaleTimeString("en-US", options)} IST`;
        return formattedDate;
    };

    const dateStr = userProfile.last_change_password;
    const formattedDate = formatDate(dateStr);

    useEffect(() => {
        const firstThreeDigits = Math.floor(userProfile.mobile_number / Math.pow(10, Math.floor(Math.log10(userProfile.mobile_number)) - 1));
        setCountryCode(firstThreeDigits)
    }, [])

    return (
        <>
            {/* <div className='container-fluid'> */}
            <div>
                <ToastContainer autoClose={4000}></ToastContainer>
            </div>
            <TabPanel value="2">
                <Grid container spacing={8}>
                    <Grid item xs={4} sm={12} md={6} lg={6}>
                        <Typography variant="subtitle1" gutterBottom pt="20px" style={{ fontWeight: 600, color: '#001323' }}> Account Security </Typography>
                        <Stack spacing={2} pt="10px">
                            <TextField id="" label="Password" variant="standard" className='customeTextField' value={`Last changed ${formattedDate}`} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img src={images.editIcon} alt="" style={{ width: '40px', paddingBottom: '30px', cursor: 'pointer' }} onClick={handleopenAccountSecurityModal} />
                                    </InputAdornment>
                                ),
                            }} />
                            <TextField id="" label="Mobile Number" variant="standard" className='customeTextField securityTab' value="2 Step Verification | Receive a text to reset your password" InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ paddingBottom: '30px' }}>
                                        <Switch
                                            checked={userProfile ? userProfile.two_step_verification : false}
                                            onChange={handleChangeMobileNumber}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            size="small"
                                            name='two_step_verification'
                                            color="success"
                                        />
                                    </InputAdornment>
                                ),
                            }} />
                            <TextField id="" label="Multi-Factor Authentication(MFA)" variant="standard" className='customeTextField securityTab' value="Ensure that only you can access your account" InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ paddingBottom: '30px' }}>
                                        <Switch
                                            checked={userProfile ? userProfile.mfa : false}
                                            onChange={handleChangeMFA}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            size="small"
                                            name='mfa'
                                            color="success"
                                        />
                                    </InputAdornment>
                                ),
                            }} />
                        </Stack>
                    </Grid>
                </Grid>
                {/* =============== Mobile number Modal Start =============== */}
                {openMobilenumberModel && <Card className='mobileNumber-card'>
                    <Box>
                        <Stack direction="row" spacing={{ sm: 14, md: 14, lg: 13 }}>
                            <Typography gutterBottom style={{ color: '#1C407B', fontWeight: '600', fontSize: '19px' }}>
                                Mobile Number
                            </Typography>
                            <img src={images.closeIcon} alt="" style={{ width: '30px', cursor: 'pointer' }} onClick={handleCloseChangeMobileNumber} />
                        </Stack>
                        <Typography className='mfaText'>
                            Last changed {formattedDate}
                        </Typography>
                        {/* <div className="mfaCountrypickerwrap">
                            <div style={{ backgroundColor: '#F4F7FE', display: 'flex', alignItems: 'center', borderBottom: '1px solid #0000006b', minHeight: '56px', paddin: '12px' }}
                                // variant="contained"
                                className={classes.button}
                            >
                                <div className="mobileNumber-DropdownWrap wrap">
                                    <h6 className='mobileNumber-Dropdown'>+{mobileCountryCode}</h6><KeyboardArrowDownIcon />
                                </div>
                            </div>
                            <TextField id="filled-basic" label="Mobile Number" variant="filled" className='customeTextField personal mfa' value={mobielNo} />
                        </div> */}
                        <TextField
                            id="phone-number"
                            label="Mobile No"
                            variant="filled"
                            value={mobielNo}
                            // onChange={handleChangeMobile}
                            InputLabelProps={{
                                style: { marginBottom: '10px' }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <div className="dropdown-container">
                                        <select
                                            value={mobileCountryCode}
                                            style={{
                                                backgroundColor: 'transparent', border: 'none', marginRight: '0px', paddingTop: '0px', cursor: "pointer", fontSize: "14px"
                                            }}
                                            className="PersonalTabn-dropdown"
                                        // onChange={handleChangeCountryCode}
                                        >
                                            <option value="1" style={{ paddingTop: '20px' }}>+1</option>
                                            <option value="44" style={{ paddingTop: '20px' }}>+44</option>
                                            <option value="91" style={{ paddingTop: '20px' }} >+91</option>
                                        </select>
                                    </div>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                    </InputAdornment>
                                ),

                            }}
                        />


                        <button className='otpButton' onClick={handlegetMobileOTP}>Get OTP</button>
                    </Box>
                </Card>
                }
                {/* =============== Mobile number Modal End =============== */}

                {/* =============== OTPMobile Modal Start =============== */}
                {/* openOtpMobileModel */}
                {openOtpMobileModel && <Card className='optCard'>
                    <Box>
                        <Stack direction="row" spacing={{ sm: 32, md: 30, lg: 30 }}>
                            <Typography gutterBottom style={{ color: '#1C407B', fontWeight: '600', fontSize: '19px' }}>
                                Mobile Number
                            </Typography>
                            <img src={images.closeIcon} alt="" style={{ width: '35px', cursor: 'pointer' }} onClick={handleOTPMobileNumber} />
                        </Stack>
                        {/* <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 600, color: '#001323', paddingTop: '20px' }}> Password </Typography> */}
                        <Typography variant="subtitle1" style={{ paddingTop: '20px', paddingBottom: '40px' }}>
                            Please Enter OTP Sent to +91 XXXXX XX{mobielNo.toString().slice(-3)}
                        </Typography>
                    </Box>
                    <div className="otp-input wrap">
                        <OTPInput
                            value={OTPMobile}
                            onChange={setOTPMobile}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                            secure={!visibleOTP}
                            className='mobileNumber-input'
                        />
                        {visibleOTP ? <img src={images.viewIcon} alt="" className="view icon OTP" onClick={() => setvisibleOTP(false)} /> :
                            <img src={images.eyecloseIcon} alt="" className="view icon OTP" onClick={() => setvisibleOTP(true)} />}
                    </div>
                    {/* <ResendOTP className="resend-btn pb-2" /> */}
                    <div className="countdown-text d-flex justify-content-center pt-4">
                        {seconds > 0 ? (
                            <>
                                <p className="d-flex" style={{ marginLeft: "50px" }}>
                                    Please wait for  {seconds < 10 ? `0${seconds}` : seconds} seconds
                                </p>

                            </>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="abc">
                                <div>
                                    Didn't recieve code?
                                </div>
                                <div>
                                    <a style={{ cursor: "pointer", textDecorationLine: "underline", fontWeight: 500 }} onClick={handleResendMobileOTP}>Resend</a>
                                </div>
                            </div>
                        )}

                    </div>
                    <Box className='accountSecurity-cardButtons'>
                        <button className='cancelButton' onClick={() => handleOTPMobileNumber()}>Cancel</button>
                        <button className='nextButtons' onClick={() => handleverifyMobileOTP()}>Verify</button>
                    </Box>
                </Card>}
                {/* =============== OTPMobile Modal End =============== */}

                {/* =============== OTP Modal Start =============== */}
                {/* //openOtpModel */}
                {openOtpModel && <Card className='optCard'>
                    <Box>
                        <Stack direction="row" spacing={{ sm: 32, md: 30, lg: 40 }}>
                            <Typography gutterBottom style={{ color: '#1C407B', fontWeight: '600', fontSize: '19px' }}>
                                MFA
                            </Typography>
                            <img src={images.closeIcon} alt="" style={{ width: '35px', cursor: 'pointer' }} onClick={handleopenOtpModal} />
                        </Stack>
                        {/* <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 600, color: '#001323', paddingTop: '20px' }}> Password </Typography> */}
                        <Typography variant="subtitle1" style={{ paddingTop: '20px', paddingBottom: '40px' }}>
                            Please Enter Google Authenticator Code
                        </Typography>
                    </Box>
                    <div className="otp-input wrap">
                        <OTPInput
                            value={OTP}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                            secure={!visibleOTP1}
                            className='mobileNumber-input'
                        />
                        {visibleOTP1 ? <img src={images.viewIcon} alt="" className="view icon OTP" onClick={() => setvisibleOTP1(false)} /> :
                            <img src={images.eyecloseIcon} alt="" className="view icon OTP" onClick={() => setvisibleOTP1(true)} />}
                    </div>
                    {/* <ResendOTP className="resend-btn pb-2" /> */}
                    <Box className='accountSecurity-cardButtons pt-5'>
                        <button className='cancelButton' onClick={handleopenOtpModal}>Cancel</button>
                        <button className='nextButtons' onClick={() => handelverifyMFA()}>Verify</button>
                    </Box>
                </Card>}
                {/* =============== OTP Modal End =============== */}
                {/* =============== MFA Modal Start =============== */}
                {openMFAModel && <Card className='MFA-card'>
                    <Box>
                        <Stack direction="row" spacing={{ sm: 14, md: 10, lg: 10 }}>
                            <Typography gutterBottom style={{ color: '#1C407B', fontWeight: '600', fontSize: '19px' }}>
                                Enroll in Two-StepAuthentication
                            </Typography>
                            <img src={images.closeIcon} alt="" style={{ width: '35px', cursor: 'pointer' }} className='mfaModal-closeIcon' onClick={handleCloseChangeMFA} />
                        </Stack>
                        <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 600, color: '#001323', padding: '20px 20px', textAlign: 'center' }}> Step 1 of 2 </Typography>
                        <Typography variant="body2" className='MFA-tetx' gutterBottom style={{ textAlign: 'center', color: '#001323' }}>
                            Go to the app store on your mobile device.If necessary,<br class="d-none d-md-block"></br> and install authentication app shown below.<br class="d-none d-md-block"></br> Select the app and click the "Next button"
                        </Typography>
                        {/* <p style={{textAlign : 'center'}}>Go to the app store on your mobile device.If necessary, and install authentication app shown below. Select the app and click the "Next button"</p> */}
                        <img src={images.loaderImage} alt="" className='loaderImage' />
                        <Typography variant="body2" gutterBottom style={{ textAlign: 'center', color: '#001323' }}>
                            Google Authenticator
                        </Typography>
                        <button className='nextButton' onClick={() => handelGenerateMFA()}>Next</button>
                    </Box>
                </Card>}
                {/* =============== MFA Modal End =============== */}

                {/* =============== Account security Modal Start =============== */}
                {openSecurityModel && <Card className='accountSecurity-card'>
                    <Box>
                        <Stack direction="row" spacing={{ sm: 22, md: 28, lg: 28 }}>
                            <Typography gutterBottom style={{ color: '#1C407B', fontWeight: '600', fontSize: '19px' }}>
                                Account Security
                            </Typography>
                            <img src={images.closeIcon} alt="" style={{ width: '30px', cursor: 'pointer' }} onClick={handleopenAccountSecurityModal} />
                        </Stack>
                        <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 600, color: '#001323', paddingTop: '20px' }}> Password </Typography>
                        <Typography variant="subtitle1">
                            Last changed {formattedDate}
                        </Typography>
                    </Box>
                    <Stack spacing={2} pt="10px">
                        <TextField id="filled-basic" type={showOldPassword ? 'text' : 'password'} label="Old Password" variant="filled" className='customeTextField security' name='old_password' value={password.old_password} onChange={handleChangePassword}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={() => setShowOldPassword(!showOldPassword)}>
                                        {showOldPassword ? <img src={images.viewIcon} alt="" /> : <img src={images.eyecloseIcon} alt="" />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField id="filled-basic" type={showNewPassword ? 'text' : 'password'} label="New Password" variant="filled" className='customeTextField security' name='new_password' value={password.new_password} onChange={handleChangePassword}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={() => setShowNewPassword(!showNewPassword)}>
                                        {showNewPassword ? <img src={images.viewIcon} alt="" /> : <img src={images.eyecloseIcon} alt="" />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField id="filled-basic" type={showReNewPassword ? 'text' : 'password'} label="Confirm Password" variant="filled" className='customeTextField security' name='conf_password' value={password.conf_password} onChange={handleChangePassword}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={() => setShowReNewPassword(!showReNewPassword)}>
                                        {showReNewPassword ? <img src={images.viewIcon} alt="" /> : <img src={images.eyecloseIcon} alt="" />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Typography variant="subtitle1">
                            Changing password requires re-login
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom onClick={handleLogoutmodal} style={{ fontWeight: 600, color: '#1C407B', paddingBottom: '20px', cursor: 'pointer' }}> Forgot Password ?</Typography>
                    </Stack>
                    <Box className='accountSecurity-cardButtons'>
                        <button className='cancelButton' onClick={handleopenAccountSecurityModal}>Cancel</button>
                        <button className='nextButtons' onClick={submitHandlePassword}>Next</button>
                    </Box>
                </Card>}
                {/* =============== Account security Modal End =============== */}
                {/* =============== Scan Modal Start =============== */}
                {openSacanModal && <Card className='scanCard'>
                    <Stack direction="row" spacing={{ sm: 12, md: 24, lg: 28 }}>
                        <Typography gutterBottom style={{ color: '#1C407B', fontWeight: '600', fontSize: '19px' }}>
                            Scan QR code
                        </Typography>
                        <img src={images.closeIcon} alt="" style={{ width: '30px', cursor: 'pointer' }} onClick={handleopenScanModal} />
                    </Stack>
                    <Typography variant="body2" className='MFA-tetx mt-4' gutterBottom style={{ textAlign: 'center', color: '#001323' }}>
                        Please scan this QR code in MFA Authenticator Application.
                    </Typography>
                    <img src={qrImage} alt="" className='scanImage' />
                    <button className='nextButton' onClick={handleopenOtpModalNext}>Next</button>
                </Card>}
                {/* =============== Scan Modal End =============== */}
                {/* =============== Google authenicator Modal Start =============== */}
                {openGoogleauthenicatorModal && <Card className='googleAuthenticator'>
                    <Stack direction="row" spacing={{ sm: 30, md: 30, lg: 40 }}>
                        <Typography gutterBottom style={{ color: '#1C407B', fontWeight: '600', fontSize: '19px' }} className='googleAuthenticator-title'>
                            ...
                        </Typography>
                        <img src={images.closeIcon} alt="" style={{ width: '30px', cursor: 'pointer' }} onClick={handleopenGoogleauthenticator} />
                    </Stack>
                    <Typography variant="h6" gutterBottom className='googleAuthenticator-text'>
                        Opening Google Authenticator...
                    </Typography>
                </Card>}
                {/* =============== Logout Modal Start =============== */}
                {
                    logout && <Card className="logoutModal">
                        <Typography variant="h6" gutterBottom className='logoutModal-text'>
                            You have to logout first ,<br></br> Are you sure want to logout??
                        </Typography>
                        <Box className="logoutmodal-btnWrap">
                            <button className='logoutBtn' onClick={() => handleLogout()}>Yes</button>
                            <button className='logoutBtn' onClick={() => handleLogoutmodal()}>No</button>
                        </Box>
                    </Card>
                }
                {/* =============== Logout Modal End =============== */}
                {/* =============== Google authenicator Modal end =============== */}
                <Box className='profileButton-wrap securityTab'>
                    <button className='cancelButton' onClick={() => setValue("1")}>Cancel</button>
                    <button className='updateButton' style={{ marginLeft: '10px' }} onClick={() => updateSubmit()}>Update</button>
                </Box>
            </TabPanel>
            {/* </div> */}
        </>
    )
}

export default SecurityTab;