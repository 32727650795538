import axios from "axios";
import { urls } from "../config/urls";
import store from "../redux/store/store";

export const request = axios.create({
  baseURL: urls.apiBaseUrl + "/api/v1",
  headers: {
    // Authorization: "token faa48f949b6d4ff3dab6c3303a9832a8527a6c94",
    "Accept": "application/json",
    "Content-Type": "application/json",
  },
});

request.interceptors.request.use(
  function (config) {
    const user = store.getState().userState
    if (user?.token) {
      config.headers.Authorization = `token ${user?.token}`
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Interceptor for axiosInstance
request.interceptors.response.use(
  (response) => {
    // TODO: Something if needed
    return Promise.resolve(response);
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export const multipartRequest = axios.create({
  baseURL: urls.apiBaseUrl + "/api/v1",
  headers: {
    "Accept": "application/json",
    "Content-Type": "multipart/form-data"
  }
});

multipartRequest.interceptors.request.use(
  function (config) {
    const user = store.getState().userState
    if (user?.token) {
      config.headers.Authorization = `token ${user?.token}`
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Interceptor for axiosInstance
request.interceptors.response.use(
  (response) => {
    // TODO: Something if needed
    return Promise.resolve(response);
  },
  async (error) => {
    return Promise.reject(error);
  }
);
