import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
// import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import { images } from '../../config/images';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './SuperAdminDashboard.css';
// import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getDeviceCount, getClientDashboardCount } from './service';
// import FooterText from '../../components/FooterText/FooterText';

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
}));

const SuperAdminDashboard = () => {
    const navigate = useNavigate();

    const { is_super_admin, is_admin } = useSelector(state => state.userState);
    
    const [deviceDataCount, setDeviceDataCount] = useState(0);
    const [superAdminClientDashboardCount, setSuperAdminClientDashboardCount] = useState({
        companyCount: 0,
        plantCount: 0
    })

    //get Device
    const getdeviceData = async () => {
        const response = await getDeviceCount();

        if (response.status == 200) {
            setDeviceDataCount(response.data.payload.length)
        }
    }

    const getClientDashboardCountAPI = async () => {
        const response = await getClientDashboardCount();

        if (response.status == 200) {
            setSuperAdminClientDashboardCount({
                companyCount: response.data.payload.company,
                plantCount: response.data.payload.plant
            })
        }
    }

    useEffect(() => {
        getdeviceData();
        getClientDashboardCountAPI();
    },[])

    return (
        <>
            <div className='containerWrap'>
                {/* <SectionTitle title={"Super Admin Dashboard"}></SectionTitle> */}
                <Typography variant="h5" gutterBottom style={{ paddingTop: '30px', paddingLeft: '34px', color: '#001323', fontWeight: '600' }}>
                    {is_super_admin ? "Super" : ""}  Admin Dashboard
                </Typography>
                <Container maxWidth="xxl">
                    <Card style={{ marginTop: '20px' }} className="dasboardCard" >
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem style={{ backgroundColor: '#DCE1EA', width: '2px', height: '49px', marginTop: "32px" }} />}
                            // spacing={{ sm: 14, md: 10, lg: 28 }}
                            className="dashboardItem"
                        >
                            <Item className='dashboardCard-item'>
                                <img src={images.IIOTdeviceImage} alt="" className='superAdmin-dashboardCard-img' />
                                <Stack direction="column" spacing={{ lg: 0 }}>
                                    <Typography gutterBottom className='superAdmin-dashboardCard-title'>
                                        IIOT DEVICES
                                    </Typography>
                                    <Typography gutterBottom className='suparAdmin-dashboardCard-number'>
                                        {deviceDataCount}
                                    </Typography>
                                </Stack>
                            </Item>
                            <Item className='dashboardCard-item'>
                                <img src={images.activeImage} alt="" className='superAdmin-dashboardCard-img' />
                                <Stack direction="column" spacing={{ lg: 0 }}>
                                    <Typography gutterBottom className='superAdmin-dashboardCard-title'>
                                        SUBSCRIPTION<br class="d-none d-md-block"></br> ACTIVE
                                    </Typography>
                                    <Typography gutterBottom className='suparAdmin-dashboardCard-number'>
                                        0
                                    </Typography>
                                </Stack>
                            </Item>
                            <Item className='dashboardCard-item'>
                                <img src={images.expiringSoonImage} alt="" className='superAdmin-dashboardCard-img' />
                                <Stack direction="column" spacing={{ lg: 0 }}>
                                    <Typography gutterBottom className='superAdmin-dashboardCard-title'>
                                        SUBSCRIPTION <br class="d-none d-md-block"></br> EXPIRING SOON
                                    </Typography>
                                    <Typography gutterBottom className='suparAdmin-dashboardCard-number'>
                                        0
                                    </Typography>
                                </Stack>
                            </Item>
                            <Item className='dashboardCard-item'>
                                <img src={images.expiredImage} alt="" className='superAdmin-dashboardCard-img' />
                                <Stack direction="column" spacing={{ lg: 0 }}>
                                    <Typography gutterBottom className='superAdmin-dashboardCard-title'>
                                        SUBSCRIPTION <br class="d-none d-md-block"></br> EXPIRED
                                    </Typography>
                                    <Typography gutterBottom className='suparAdmin-dashboardCard-number'>
                                        0
                                    </Typography>
                                </Stack>
                            </Item>
                        </Stack>
                        <MoreVertIcon className='detailsImage' />
                    </Card>
                    {/* ====================================================================== */}
                    <Grid container spacing={4} mt={'0px'}>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Card className='superAdminDashboard-card'>
                                <Stack direction="row" alignItems="center" spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}>
                                    <img src={images.subscriptionManagement} alt="" className='superAdmin-dashboardCard-image' />
                                    <Typography variant="h5" gutterBottom className='superAdminDashboard-title'>
                                        Subscription Management
                                    </Typography>
                                </Stack>
                                {/* <img src={images.alertImage} alt="" className='alertImage' /> */}
                                <MoreVertIcon className='detailsIcon' />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Card className='superAdminDashboard-card' onClick={() => navigate('/deviceManagement')}>
                                <Stack direction="row" alignItems="center" spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}>
                                    <img src={images.deviceManagement} alt="" className='superAdmin-dashboardCard-image' />
                                    <Typography variant="h5" gutterBottom className='superAdminDashboard-title'>
                                        Device Management
                                    </Typography>
                                </Stack>
                                <img src={images.alertImage} alt="" className='alertImage' />
                                <MoreVertIcon className='detailsIcon' />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}onClick={() => navigate('/accessManagement')}>
                            <Card className='superAdminDashboard-card'>
                                <Stack direction="row" alignItems="center" spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}>
                                    <img src={images.accessManagement} alt="" className='superAdmin-dashboardCard-image' />
                                    <Typography variant="h5" gutterBottom className='superAdminDashboard-title'>
                                        Access Management
                                    </Typography>
                                </Stack>
                                {/* <img src={images.alertImage} alt="" className='alertImage' /> */}
                                <MoreVertIcon className='detailsIcon' />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Card className='superAdminDashboard-card' onClick={() => navigate('/usermanagement')}>
                                <Stack direction="row" alignItems="center" spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}>
                                    <img src={images.userManagement} alt="" className='superAdmin-dashboardCard-image' />
                                    <Typography variant="h5" gutterBottom className='superAdminDashboard-title'>
                                        User Management
                                    </Typography>
                                </Stack>
                                {/* <img src={images.alertImage} alt="" className='alertImage' /> */}
                                <MoreVertIcon className='detailsIcon' />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Card className='superAdminDashboard-card' onClick={() => navigate('/plantmanagement')}>
                                <Stack direction="row" alignItems="center" spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}>
                                    <img src={images.plantsManagement} alt="" className='superAdmin-dashboardCard-image' />
                                    <Typography variant="h5" gutterBottom className='superAdminDashboard-title'>
                                        Plant Management
                                    </Typography>
                                </Stack>
                                <img src={images.alertImage} alt="" className='alertImage' />
                                <MoreVertIcon className='detailsIcon' />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Card className='superAdminDashboard-card'>
                                <Stack direction="row" alignItems="center" spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}>
                                    <img src={images.NotificationImg} alt="" className='superAdmin-dashboardCard-image' />
                                    <Typography variant="h5" gutterBottom className='superAdminDashboard-title'>
                                        Alarm Management
                                    </Typography>
                                </Stack>
                                <img src={images.alertImage} alt="" className='alertImage' />
                                <MoreVertIcon className='detailsIcon' />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Card className='superAdminDashboard-card'>
                                <Stack direction="row" alignItems="center" spacing={{ sm: 6, md: 4, lg: 2, xl: 4 }}>
                                    <img src={images.reportManagement} alt="" className='superAdmin-dashboardCard-image' />
                                    <Typography variant="h5" gutterBottom className='superAdminDashboard-title'>
                                        Report Management
                                    </Typography>
                                </Stack>
                                <img src={images.alertImage} alt="" className='alertImage' />
                                <MoreVertIcon className='detailsIcon' />
                            </Card>
                        </Grid>
                    </Grid>
                    {/* ========================================================================================= */}
                    {is_super_admin == true && is_admin == false && <Card style={{ marginTop: '100px' }} className="dasboardCard superAdmin" >
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem style={{ backgroundColor: '#DCE1EA', width: '2px', height: '49px', marginTop: "20px" }} />}
                            // spacing={{ sm: 14, md: 10, lg: 28 }}
                            className="dashboardItem"
                            onClick={() => navigate('/clientmanagement')}
                        >
                            <Item className='superAdmin-dashboardCard-item'>
                                <Stack direction="row" alignItems="center" spacing={{ sm: 0, md: 2, lg: 2, xl: 2 }} className='item'>
                                    <img src={images.clientManagement} alt="" className='clientManagement-image' />
                                    <Typography variant="h5" gutterBottom className='superAdminDashboardcard-title'>
                                        Client Management
                                    </Typography>
                                </Stack>
                            </Item>
                            <Item className='superAdmin-dashboardCard-item'>
                                <Stack direction="column" spacing={{ lg: 0 }}>
                                    <Typography gutterBottom className='superAdminDashboard-text'>
                                        Company
                                    </Typography>
                                    <Typography gutterBottom className='dashboardCard-number'>
                                        {superAdminClientDashboardCount.companyCount}
                                    </Typography>
                                </Stack>
                            </Item>
                            <Item className='superAdmin-dashboardCard-item'>
                                <Stack direction="column" spacing={{ lg: 0 }}>
                                    <Typography gutterBottom className='superAdminDashboard-text'>
                                        Plants
                                    </Typography>
                                    <Typography gutterBottom className='dashboardCard-number'>
                                        {superAdminClientDashboardCount.plantCount}
                                    </Typography>
                                </Stack>
                            </Item>
                            <Item className='superAdmin-dashboardCard-item'>
                                <Stack direction="column" spacing={{ lg: 0 }}>
                                    <Typography gutterBottom className='superAdminDashboard-text'>
                                        User
                                    </Typography>
                                    <Typography gutterBottom className='dashboardCard-number'>
                                        0
                                    </Typography>
                                </Stack>
                            </Item>
                        </Stack>
                        <MoreVertIcon className='detailsImage' />
                    </Card>}
                    {/* <FooterText /> */}
                </Container>
            </div>
        </>
    )
}

export default SuperAdminDashboard;