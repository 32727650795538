import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { deviceTypeApi, licenseApi, addDeviceSubmit, getDevice, editDevice, deleteDevice } from '../service'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { images } from '../../../config/images';
import './DeviceManagement.css'
import { useSelector } from 'react-redux';
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import { useCubeQuery } from '@cubejs-client/react';

function DeviceManagement() {
    const [openAdddeviceModal, setOpenAdddeviceModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [openUpdatedeviceModal, setOpenUpdatedeviceModal] = React.useState(false);
    const [deviceData, setDeviceData] = useState([]);
    const [deviceTypedata, setdeviceTypeData] = useState([]);
    const [licenseData, setlicenceData] = useState([]);
    const [age, setAge] = useState('');
    const intialData = { company: "", uid: "", device_name: "", device_description: "", device_type: "", license: "" }
    const [addDeviceForm, setAddDeviceForm] = useState(intialData);
    const [updateDeviceForm, setEditDeviceForm] = useState(intialData);
    const [deviceId, setDeviceId] = useState("");
    const [cloudData, setCloudData] = useState([]);
    console.log("cloudData", cloudData);

    const initiallValue = {
        device_name: "",
        device_description: "",
        uid: "",
        company__company_name: ""
    };
    const [searcDevice, setSearchDevice] = useState(initiallValue);

    const { companyId } = useSelector(state => state.userState)
    console.log("addDeviceForm", addDeviceForm);

    const navigate = useNavigate();

    const notify = (message) => toast.success(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    const notifyError = (message) => toast.error(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    //DeviceSearch
    const handleChangeDeviceSearch = (e) => {
        setSearchDevice({...searcDevice, [e.target.name]: e.target.value})
    }

    // Add device Modal
    const handleOpenAdddeviceMOdel = () => {
        setOpenDeleteModal(false)
        setOpenAdddeviceModal(!openAdddeviceModal);
        setAddDeviceForm(initiallValue);
        setOpenDeleteModal(false)
    }
    // Delete Confirmation Modal
    const handleConfirmationModal = (id) => {
        setOpenUpdatedeviceModal(false);
        setOpenAdddeviceModal(false);
        setOpenDeleteModal(!openDeleteModal);
        setDeviceId(id)
    }
    // Update Modal
    const handleUpdateModal = (e) => {
        setOpenUpdatedeviceModal(!openUpdatedeviceModal);
        setOpenDeleteModal(false)
        console.log("updateDeviceForm",e);
        setEditDeviceForm({ id: e.id, uid: e.uid, device_name: e.device_name, device_description: e.device_description, device_type: e.device_type, license: e.license })
    }

    const handleUpdateCloseModal = () => {
        setOpenUpdatedeviceModal(!openUpdatedeviceModal);
        setEditDeviceForm(intialData)
    }

    const handleChange1 = (event) => {
        setAge(event.target.value);
    };

    // add Device Handle Form
    const handleChangeAddDevice = (e) => {
        setAddDeviceForm({ ...addDeviceForm, [e.target.name]: e.target.value })
    }

    // update Device Handle Form
    const handleChangeUpdateDevice = (e) => {
        setEditDeviceForm({ ...updateDeviceForm, [e.target.name]: e.target.value })
    }

    //get Device
    const getdeviceData = async () => {
        try {
            const response = await getDevice(searcDevice);

            if (response.status === 200) {
                setDeviceData(response.data.payload);
            }
        } catch (error) {
            // Handle the error here
            console.error('An error occurred:', error);
            // You can also perform any necessary error handling or show an error message to the user
        }
    };


    //DeviceTypeApi
    const getdeviceTypeApi = async () => {
        const resp = await deviceTypeApi();
        if (resp.status == 200) {
            setdeviceTypeData(resp.data.payload)
        }
        console.log('devicedata', resp);
    }
    //LicenseApi
    const getlicenceApi = async () => {
        const response = await licenseApi();
        console.log('licenecData', response);

        if (response.data.success == true) {
            setlicenceData(response.data.payload);
        }
    }

    // update Device
    const handleUpdateDevice = async () => {
        const params = {
            id: updateDeviceForm.id,
            device_name: updateDeviceForm.device_name,
            device_description: updateDeviceForm.device_description,
            device_type: updateDeviceForm.device_type
        }


        const response = await editDevice(updateDeviceForm.id, params);
        if (response.data.success == true) {
            notify("Successful Update device");
            getdeviceData();
            setOpenUpdatedeviceModal(!openUpdatedeviceModal);
        }

    }

    //deleteDevice
    const handleDeleteDevice = async () => {
        const response = await deleteDevice(deviceId);
        console.log('dfsdf', response);
        if (response.data.success == true) {
            notify("Successful delete device");
            getdeviceData();
            setOpenDeleteModal(!openDeleteModal);
        }

    }

    const submitAddDevice = async () => {
        // const formData = new FormData();
        // formData.append("uid", addDeviceForm.uid);
        // formData.append("device_name", addDeviceForm.device_name);
        // formData.append("device_description", addDeviceForm.device_description);
        // formData.append("device_type", addDeviceForm.device_type);
        // formData.append("license", addDeviceForm.license);

        const param = {
            uid: addDeviceForm.uid,
            device_name: addDeviceForm.device_name,
            device_description: addDeviceForm.device_description,
            device_type: addDeviceForm.device_type,
            license: addDeviceForm.license,
            company: companyId
        }

        if (!addDeviceForm.uid) {
            notifyError("Please Enter UID");
            return true;
        }

        if (!addDeviceForm.device_name) {
            notifyError("Please Enter Device Name");
            return true;
        }

        if (!addDeviceForm.device_type) {
            notifyError("Please Select Device Type");
            return true;
        }

        if (!addDeviceForm.license) {
            notifyError("Please Select License");
            return true;
        }
        try{
        const resp = await addDeviceSubmit(param);
   
        if (resp.data.success == true) {
            notify(resp.data.message);
            setOpenAdddeviceModal(false);
            setAddDeviceForm(intialData);
            getdeviceData();
            getlicenceApi();
        }
    }
    catch(error){
        notifyError(error.response.data.message);
    }
        // console.log("resp", resp);
    }

    useEffect(() => {
        getdeviceData();
    }, [searcDevice]);

    useEffect(() => {
        getdeviceTypeApi();
        getlicenceApi();
    }, []);


   
    const cloudColorData = useCubeQuery({
        "dimensions": [
            "SuperadminDevice.isSub",
            "SuperadminDevice.subTime",
            "SuperadminDevice.uid"
        ],
        "order": {
            "SuperadminDevice.isSub": "asc"
        },
        "filters": [
            {
                "member": "SuperadminDevice.uid",
                "operator": "equals",
                "values": deviceData.length ? deviceData.map((e) => e.uid) : ["0"]
            }
        ]
    }, { subscribe: true })


    useMemo(() => {
        if (cloudColorData.resultSet) {
            const array = cloudColorData.resultSet.tablePivot()
            const colorCloud = array.map((row) => {
                return ({
                    isSub: row["SuperadminDevice.isSub"],
                    uid: row["SuperadminDevice.uid"]
                })
            })
            setCloudData(colorCloud)
        }
    }, [cloudColorData.resultSet])

    // console.log("kp", kp.resultSet ? kp.resultSet.tablePivot() : null);
    // handelCubeJs

    // useEffect(() => {
    //     handelCubeData()
    // }, [deviceData]);

    // const handelCubeData = async() => {
    //     if (deviceData.length) {
    //         // const cloudColerData = []
    //         const kp =  deviceData.map(async (e) => {
    //             const deviceCloud = await cubejsApi.load({
    //                 "dimensions": [
    //                     "SuperadminDevice.isSub",
    //                     "SuperadminDevice.subTime",
    //                     "SuperadminDevice.uid"
    //                 ],
    //                 "order": {
    //                     "SuperadminDevice.isSub": "asc"
    //                 },
    //                 "filters": [
    //                     {
    //                         "member": "SuperadminPlantDevice.device_id",
    //                         "operator": "equals",
    //                         "values": [
    //                             "50", "73", "90"
    //                         ]
    //                     }
    //                 ]
    //             }, {subscribe: true});
    //             console.log("deviceCloud", deviceCloud.rawData());
    //             return deviceCloud.rawData() ? [0]["SuperadminDevice.isSub"] ? true : false : false;
    //             // cloudColerData.push(deviceCloud.rawData() ? true : false);
               
    //         });
    //         const cloudColerData = await Promise.all(kp);
    //         console.log("cloudColerData", cloudColerData);
    //         setCloudData(cloudColerData)
    //     }
    // }



    return (
        <>
            <div>
                <ToastContainer autoClose={4000}></ToastContainer>
            </div>
            <div className='containerWrap'>
                <Container maxWidth="xxl">
                    <div className="deviceManagementHeader-wrap">
                        <div className='deviceManagementTitle-wrap'>
                            <KeyboardBackspaceIcon onClick={() => navigate('/superadmindashboard')} className='backArrow' /> <Typography variant="h5" gutterBottom className='deviceManagementTitle' onClick={() => navigate('/superadmindashboard')}>
                                Device Management
                            </Typography>
                        </div>
                        <div className='addDevice-buttonWrap'>
                            <button className='addDevice-button' onClick={handleOpenAdddeviceMOdel}>Add Device</button>
                            <img src={images.excelLogo} alt="" className='cloudActiveImg' />
                        </div>
                    </div>
                    {/* =============== Add device Modal Start =============== */}
                    {openAdddeviceModal && <Card className='addDeviceModal-card'>
                        <Box className='addDeviceModal-titleWrap'>
                            <Typography variant="h5" gutterBottom className='addDeviceModal-title'>
                                ADD DEVICE
                            </Typography>
                            <img src={images.closeIcon} alt="" style={{ width: '30px', cursor: 'pointer', backgroundColor:"white" }} className='closeImg' onClick={() => handleOpenAdddeviceMOdel()}/>
                        </Box>
                        <Box className='addDeviceModal-headerWrap'>
                            <Typography variant="body2" gutterBottom>
                                Enter the UID of your device and name it individually.<br></br> You can find the UID/OR on the nameplate of the device<br></br> supplied by <b>HIKAR</b>
                            </Typography>
                        </Box>
                        <Box className='addDeviceModal-innerWrap'>
                            <Box className='addDeviceModal-checkBoxWrap'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox />} />
                                </FormGroup>
                                <Typography variant="body2" gutterBottom className='addDeviceModal-text'>
                                    This Device is not supplied by HIKAR, generate a virtual UID.
                                </Typography>
                            </Box>
                            <Stack spacing={2} pt="10px">
                                <TextField id="filled-basic" name='uid' label="UID" variant="filled" className='customeTextField deviceManagement' onChange={handleChangeAddDevice} value={addDeviceForm.uid} InputProps={{
                                    inputProps: { maxLength: 21 }, 
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <img src={images.scanImage} alt="" />
                                        </InputAdornment>
                                    ),
                                }} />
                                <Typography variant="caption" display="block" gutterBottom className='addDeviceModal-text'>
                                    e.g. 71f73dee-4596-48c8-bdof-7f1234d
                                </Typography>
                                <TextField id="filled-basic" name='device_name' label="Device Name" variant="filled" className='customeTextField deviceManagement' onChange={handleChangeAddDevice} value={addDeviceForm.device_name} />
                                <Typography variant="caption" display="block" gutterBottom>
                                    e.g. Furnace 01
                                </Typography>
                                <TextField id="filled-basic" name='device_description' label="Device Description" variant="filled" className='customeTextField deviceManagement' onChange={handleChangeAddDevice} value={addDeviceForm.device_description} />
                                <Typography variant="caption" display="block" gutterBottom className='addDeviceModal-text'>
                                    e.g. Furnace 01 of Plant 1
                                </Typography>
                                <FormControl variant="filled" sx={{ minWidth: 120 }} className='customeTextField deviceManagement'>
                                    <InputLabel id="demo-simple-select-filled-label">Select Device Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        name='device_type'
                                        value={addDeviceForm.device_type}
                                        onChange={handleChangeAddDevice}
                                    >
                                        {deviceTypedata.map((e) => <MenuItem value={e.device_type_name}>{e.device_type_name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                <FormControl variant="filled" sx={{ minWidth: 120 }} className='customeTextField deviceManagement'>
                                    <InputLabel id="demo-simple-select-filled-label">License</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        name='license'
                                        value={addDeviceForm.license}
                                        onChange={handleChangeAddDevice}
                                    >

                                        {licenseData.map((e) => <MenuItem value={e.license
                                        }>{e.license
                                            }</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Box className='accountSecurity-cardButtons deviceManagement'>
                                <button className='addDeviceBtn' onClick={submitAddDevice}>ADD DEVICE TO HIKAR.CLOUD</button>
                            </Box>
                        </Box>
                    </Card>}
                    {/* =============== Add device Modal End =============== */}
                    {/* =============== Update Modal Start =============== */}
                    {openUpdatedeviceModal && <Card className='addDeviceModal-card'>
                        <Box className='addDeviceModal-titleWrap'>
                            <Typography variant="h5" gutterBottom className='addDeviceModal-title'>
                                UPDATE DEVICE
                            </Typography>
                            <img src={images.closeIcon} alt="" style={{ width: '30px', cursor: 'pointer', backgroundColor: "white" }} className='closeImg' onClick={() => handleUpdateCloseModal()}/>
                        </Box>
                        <Box className='addDeviceModal-headerWrap'>
                            <Typography variant="body2" gutterBottom>
                                Enter the UID of your device and name it individually.<br></br> You can find the UID/OR on the nameplate of the device<br></br> supplied by <b>HIKAR</b>
                            </Typography>
                        </Box>
                        <Box className='addDeviceModal-innerWrap'>
                            <Box className='addDeviceModal-checkBoxWrap'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox />} />
                                </FormGroup>
                                <Typography variant="body2" gutterBottom className='addDeviceModal-text'>
                                    This Device is not supplied by HIKAR, generate a virtual UID.
                                </Typography>
                            </Box>
                            <Stack spacing={2} pt="10px">
                                <TextField id="filled-basic" name='uid' label="UID" variant="filled" className='customeTextField' value={updateDeviceForm.uid} InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <img src={images.scanImage} alt="" />
                                        </InputAdornment>
                                    ),
                                    disabled: true
                                }} />
                                <Typography variant="caption" display="block" gutterBottom className='addDeviceModal-text'>
                                    e.g. 71f73dee-4596-48c8-bdof-7f1234d
                                </Typography>
                                <TextField id="filled-basic" name='device_name' label="Device Name" variant="filled" className='customeTextField' value={updateDeviceForm.device_name} onChange={handleChangeUpdateDevice} />
                                <Typography variant="caption" display="block" gutterBottom>
                                    e.g. Furnace 01
                                </Typography>
                                <TextField id="filled-basic" name='device_description' label="Device Description" variant="filled" className='customeTextField' value={updateDeviceForm.device_description} onChange={handleChangeUpdateDevice} />
                                <Typography variant="caption" display="block" gutterBottom className='addDeviceModal-text'>
                                    e.g. Furnace 01 of Plant 1
                                </Typography>
                                <FormControl variant="filled" sx={{ minWidth: 120 }} className='customeTextField'>
                                    <InputLabel id="demo-simple-select-filled-label">Select Device Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        name='device_type'
                                        value={updateDeviceForm.device_type}
                                        onChange={handleChangeUpdateDevice}
                                    >
                                        {deviceTypedata.map((e) => <MenuItem value={e.device_type_name}>{e.device_type_name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                <TextField id="filled-basic" name='device_description' label="License" variant="filled" className='customeTextField' value={updateDeviceForm.license} InputProps={{
                                    disabled: true
                                }} />
                            </Stack>
                            <Box className='accountSecurity-cardButtons'>
                                <button className='addDeviceBtn' onClick={() => handleUpdateDevice()}>UPDATE DEVICE TO HIKAR.CLOUD</button>
                            </Box>
                        </Box>
                    </Card>}
                {/* =============== Update Modal End =============== */}
                {/* =============== Delete Confirmation Modal Start =============== */}
                {openDeleteModal && <Card className='deleteConfirmation-Modal'>
                    <Typography variant="h6" gutterBottom className='deleteConfirmation-title'>
                        Are You Sure<br></br> You Want To Delete?
                    </Typography>
                    <Box className='deleteConfirmation-btnWrap'>
                        <button className='deleteConfirmation-Yesbtn' onClick={() => handleDeleteDevice()}>Yes</button>
                        <button className='deleteConfirmation-Nobtn' onClick={handleConfirmationModal}>No</button>
                    </Box>
                </Card>}
                {/* =============== Delete Confirmation Modal End =============== */}
                {/* =============== Device Management Table Start =============== */}
                <div className="tableResponsive">
                    <Table aria-label="" className={`deviceManagementTable ${openUpdatedeviceModal || openDeleteModal ? 'disabledRow' : ''}`}>
                        <TableHead>
                            <TableRow className="deviceManagementTable-title">
                                <TableCell colSpan={2} >
                                    Device Name
                                </TableCell>
                                <TableCell>
                                    Device Description
                                </TableCell>
                                <TableCell>
                                    UID
                                </TableCell>
                                <TableCell>
                                    Company
                                </TableCell>
                                <TableCell>
                                    License
                                </TableCell>
                                <TableCell>
                                    Action
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} className="search-table-row">
                                    <SearchIcon className="search-icon" />
                                    <input type="search" placeholder="Device Name" name="device_name" value={searcDevice.device_name} onChange={handleChangeDeviceSearch}/>
                                </TableCell>
                                <TableCell className="search-table-row">
                                    <SearchIcon className="search-icon" />
                                    <input type="search" placeholder="Device Description" name="device_description" value={searcDevice.device_description} onChange={handleChangeDeviceSearch} />
                                </TableCell>
                                <TableCell className="search-table-row">
                                    <SearchIcon className="search-icon" />
                                    <input type="search" placeholder="UID" name="uid" value={searcDevice.uid} onChange={handleChangeDeviceSearch} />
                                </TableCell>
                                <TableCell className="search-table-row">
                                    <SearchIcon className="search-icon" />
                                        <input type="search" placeholder="Company" name="company__company_name" value={searcDevice.company__company_name} onChange={handleChangeDeviceSearch}  />
                                </TableCell>
                                <TableCell className="search-table-row">
                                    {/* <SearchIcon className="search-icon" />
                                    <input type="search" placeholder="Class" name="Class" /> */}
                                </TableCell>
                                <TableCell className="search-table-row">
                                    {/* <SearchIcon className="search-icon" />
                                    <input type="search" placeholder="Size" name="Size" /> */}
                                </TableCell>
                            </TableRow>
                            {/* {deviceTypedata.map((e) => <MenuItem value=>{e.device_type_name}</MenuItem>)} */}
                                {deviceData.length ? deviceData.map((e, i) => {
                                    const findCloud = cloudData.find((f) => f.uid == e.uid)
                                    return (<TableRow className='deviceManagementTable-data'>
                                        <TableCell >
                                            {findCloud ? findCloud.isSub ? <img src={images.cloudActiveImg} alt="" className='cloudActiveImg' /> : <img src={images.cloudImg} alt="" className='cloudActiveImg' /> : <img src={images.cloudImg} alt="" className='cloudActiveImg' />}
                                        </TableCell>
                                        <TableCell>
                                            {e.device_name}
                                        </TableCell>
                                        <TableCell>
                                            {e.device_description}
                                        </TableCell>
                                        <TableCell>
                                            {e.uid}
                                        </TableCell>
                                        <TableCell>
                                            {e.company_name}
                                            {/* Company - 1 */}
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" className='deviceManagementTable-license'>
                                                {e.license_status == "Active" ? <img src={images.activeKey} alt="" className='cloudActiveImg' /> : e.license_status == "Expire" ? <img src={images.expiresImg} alt="" className='cloudActiveImg' /> : <img src={images.expiresSoonImg} alt="" className='cloudActiveImg' />}
                                                {e.license_status}
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" className='deviceManagementTable-icon'>
                                                <img src={images.editImg} alt="" className='cloudActiveImg' onClick={() => handleUpdateModal(e)} />
                                                <img src={images.settingImg} alt="" className='cloudActiveImg' onClick={() => navigate(`/deviceManagement/configure/device/${e.id}`)} />
                                                <img src={images.deleteImg} alt="" className='cloudActiveImg' onClick={() => handleConfirmationModal(e.id)} />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>)
                                }
                                   
                            )
                                    :
                                    []
                                }
                            </TableHead>
                            <TableBody>
                            </TableBody>
                        </Table>
                    </div>
                    {/* =============== Device Management Table End =============== */}
                </Container>
            </div>
        </>
    )
}

export default DeviceManagement;