// import React from 'react';
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';
// import { useSelector } from 'react-redux';
// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend
// );

// export const options = {
//     maintainAspectRatio: false,
//     responsive: true,
//     interaction: {
//         mode: 'index',
//         intersect: false,
//     },
//     stacked: false,
//     plugins: {
//         legend: {
//            display:false
//        }
//     },
//     scales: {
//         y: {
//             type: 'linear',
//             display: true,
//             position: 'left',
//         },
//         y1: {
//             type: 'linear',
//             display: true,
//             position: 'right',
//             grid: {
//                 drawOnChartArea: false,
//             },
//         },
//     },
// };



// export function MultiAxisLineChart({ fisrtLineChartReportData, secondeLineChartReportData }) {

//     const {  deviceColorsReportRedux } = useSelector(
//         (state) => state.overViewState
//     );


//     const labels = fisrtLineChartReportData.length ? fisrtLineChartReportData[0] && fisrtLineChartReportData[0].data.map((row) => row.MIN_KEY) : [];

//     // color Set
//     const findColorFirstLine = deviceColorsReportRedux && fisrtLineChartReportData.length ? deviceColorsReportRedux.find((f) => f.deviceId == fisrtLineChartReportData[0].device_id) : "";
//     const findColorSecondLine = deviceColorsReportRedux && secondeLineChartReportData.length ? deviceColorsReportRedux.find((f) => f.deviceId == secondeLineChartReportData[0].device_id) : "";

//  const data = {
//     labels,
//     datasets: [
//         {
//             label: fisrtLineChartReportData.length ? fisrtLineChartReportData[0].device_name : "",
//             data: fisrtLineChartReportData.length ? fisrtLineChartReportData[0] && fisrtLineChartReportData[0].data.map((row) => parseFloat(row.tag_value)) : [],
//             borderColor: findColorFirstLine ? findColorFirstLine.color : "",
//             backgroundColor: findColorFirstLine ? findColorFirstLine.color : "",
//             yAxisID: 'y',
//         },
//         {
//             label: secondeLineChartReportData.length ? secondeLineChartReportData[0].device_name : "",
//             data: secondeLineChartReportData.length ? secondeLineChartReportData[0] && secondeLineChartReportData[0].data.map((row) => parseFloat(row.tag_value)) : [],
//             borderColor: findColorFirstLine ? findColorFirstLine.color : "",
//             backgroundColor: findColorSecondLine ? findColorFirstLine.color : "",
//             yAxisID: 'y1',
//         },
//     ],
// };
//     console.log("MultiAxisLineChart", fisrtLineChartReportData);
//     return (
//         <div style={{width:"90%",height:"337px",margin:"auto",paddingTop:"30px"}}>

//             <Line options={options} data={data} />
//         </div>
       
//     )
    
// }

import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import { useSelector } from 'react-redux';

HC_more(Highcharts);
exporting(Highcharts);






export function MultiAxisLineChart({ fisrtLineChartReportData, secondeLineChartReportData, selectFirstUnit, selectSecondUnit  }) { 

    const {
        deviceIdReportRedux, deviceUnitReportRedux, deviceColorsReportRedux, unitColorsReportRedux,
    } = useSelector((state) => state.overViewState);

    console.log("fisrtLineChartReportData", fisrtLineChartReportData);
    // const categories = fisrtLineChartReportData.length ? fisrtLineChartReportData[0] && fisrtLineChartReportData[0].data.length ? fisrtLineChartReportData[0] && fisrtLineChartReportData[0].data.map((row) => row.MIN_KEY) : secondeLineChartReportData.length ? secondeLineChartReportData[0] && secondeLineChartReportData[0].data.map((row) => row.MIN_KEY) : [] : []

    const categories = [];
    
    if (fisrtLineChartReportData.length || secondeLineChartReportData.length) {
        const popDataLength = fisrtLineChartReportData[0]?.data?.length || 0;
        const pop1DataLength = secondeLineChartReportData[0]?.data?.length || 0;
        const maxLength = Math.max(popDataLength, pop1DataLength);

        for (let i = 0; i < maxLength; i++) {
            const popData = fisrtLineChartReportData[0]?.data[i] || {};
            const pop1Data = secondeLineChartReportData[0]?.data[i] || {};
            const minKey = popData?.MIN_KEY || "";
            const pop1MinKey = pop1Data?.MIN_KEY || "";
            categories.push(`${minKey} ${pop1MinKey}`);
        }
    }




    // const categories = fisrtLineChartReportData.length ? fisrtLineChartReportData[0] && fisrtLineChartReportData[0].data.map((row) => row.MIN_KEY) : [] 

    const findUnitColor1 = unitColorsReportRedux.find((f) => f.tagUnit == selectFirstUnit);
    const findUnitColor2 = unitColorsReportRedux.find((f) => f.tagUnit == selectSecondUnit);

    const options = {
        chart: {
            type: "spline",
            scrollablePlotArea: {
                minWidth: 700,
                scrollPositionX: categories.length - 1, // Set scroll position to the rightmost end
            },
        },
        title: {
            text: null
        },
        legend: {
            enabled: false // Disable legend
        },
        xAxis: {
            categories: categories,
            scrollbar: {
                enabled: true,
            },
        },
        series: [
            {
                name: fisrtLineChartReportData.length ? fisrtLineChartReportData[0] && fisrtLineChartReportData[0].device_name : "",
                data: fisrtLineChartReportData.length ? fisrtLineChartReportData[0] && fisrtLineChartReportData[0].data.map((row) => parseFloat(row.tag_value)) : [],
                color: findUnitColor1 ? findUnitColor1.color : "",
                yAxis: 0 // Set the series to use the first y-axis
            },
            {
                name: secondeLineChartReportData.length ? secondeLineChartReportData[0] && secondeLineChartReportData[0].device_name : "",
                data: secondeLineChartReportData.length ? secondeLineChartReportData[0] && secondeLineChartReportData[0].data.map((row) => parseFloat(row.tag_value)) : [],
                color: findUnitColor2 ? findUnitColor2.color : "",
                yAxis: 1 // Set the series to use the second y-axis
            }
        ],
        yAxis: [
            {
                title: {
                    text: selectFirstUnit == "none" ? "" : selectFirstUnit // First y-axis title
                }
            },
            {
                title: {
                    text: selectSecondUnit == "none" ? "" : selectSecondUnit// Second y-axis title
                },
                opposite: true // Display the second y-axis on the opposite side
            }
        ],
        // colors: unitColorsReportRedux.length ? unitColorsReportRedux.map((e) => e.color) : [],
        exporting: {
            enabled: true, // Disable exporting options
        },
        credits: {
            enabled: false, // Disable credits
        },
    };

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    )
}