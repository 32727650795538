// import React from "react";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { Doughnut } from "react-chartjs-2";

// ChartJS.register(ArcElement, Tooltip, Legend);

// const DonutChart = ({
//   analyticsPieChartData,
//   selectedColors,
//   donutChartSum,
//   donutUnit,
// }) => {
//   const data = {
//     labels: analyticsPieChartData.length
//       ? analyticsPieChartData.map((e) => e.device_name)
//       : [],
//     datasets: analyticsPieChartData.length
//       ? analyticsPieChartData.map((e) => {
//           const findDeviceId = selectedColors.find(
//             (f) => f.deviceId == e.device_id
//           );
//           return ({
//             data: [e.tag_value],
//             backgroundColor: findDeviceId ? findDeviceId.color : "" + "80",
//             borderColor: findDeviceId ? findDeviceId.color : "",
//             borderWidth: 1,
//           });
//         })
//       : [],
//   };
//   const options = {
//     plugins: {
//       tooltip: {
//         callbacks: {
//           label: function (context) {
//             const dataset = data.datasets[context.datasetIndex];
//             const total = dataset.data.reduce(
//               (previousValue, currentValue) => previousValue + currentValue
//             );
//             const currentValue = dataset.data[context.dataIndex];
//             const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
//             return `${currentValue} (${percentage})`;
//           },
//         },
//       },
//       legend: {
//         display: false,
//         labels: {
//           display: false,
//         },
//       },
//       datalabels: {
//         color: "#fff",
//         anchor: "center",
//         align: "center",
//         offset: 0,
//         font: {
//           weight: "bold",
//           size: "14",
//         },
//       },
//       formatter: function (value, context) {
//         const dataset = data.datasets[context.datasetIndex];
//         const total = dataset.data.reduce(
//           (previousValue, currentValue) => previousValue + currentValue
//         );
//         const currentValue = dataset.data[context.dataIndex];
//         const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
//         return `${value}\n(${percentage})`;
//       },
//     },
//     cutout: "70%", // Adjust this value to set the width of the doughnut chart
//   };
//   return (
//     <div style={{ width: "100%", height: "195px" }} className="doughnut-chart">
//       <Doughnut
//         data={data}
//         id="canvas"
//         style={{ margin: "auto" }}
//         options={options}
//       />
//       <p className="doughnut-chartText">{donutChartSum}</p>
//       <p className="doughnut-chartText-unit">{donutUnit}</p>
//     </div>
//   );
// };

// export default DonutChart;


import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend);



export function DonutChart({
  analyticsPieChartData,
  selectedColors,
  donutChartSum,
  donutUnit,
}) {
  console.log("analyticsPieChartData", analyticsPieChartData);

  const {
    deviceColors
  } = useSelector((state) => state.overViewState);

  const switchColors = [
    "#FEBB22",
    "#075571",
    "#002B2A",
    "#C99C00",
    "#69927A",
    "#872B81",
    "#F06292",
    "#29445A",
    "#CD4D4A",
    "#0C4562",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#001A30",
    "#0B648D",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#123782",
    "#C60BC8",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#EB9E79",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#7D2264",
    "#0EB520",
    "#0E3504",
    "#1D733F",
    "#6E02B1",
  ];
  const data = {
    labels: deviceColors.length ? deviceColors.map((row) => row.dvName) : [],
    datasets: [
      {
        label: '# of Votes',
        data: analyticsPieChartData.length ? analyticsPieChartData.map((row) => row.tag_value) : [],
        backgroundColor: deviceColors.length ? deviceColors.map((row) => row.color) : [],
        borderColor: deviceColors.length ? deviceColors.map((row) => row.color) : [],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataset = data.datasets[context.datasetIndex];
            const total = dataset.data.reduce(
              (previousValue, currentValue) => previousValue + currentValue
            );
            const currentValue = dataset.data[context.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
            return `${currentValue} (${percentage})`;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          display: false,
        },
      },
      datalabels: {
        color: "#fff",
        anchor: "center",
        align: "center",
        offset: 0,
        font: {
          weight: "bold",
          size: "14",
        },
      },
      formatter: function (value, context) {
        const dataset = data.datasets[context.datasetIndex];
        const total = dataset.data.reduce(
          (previousValue, currentValue) => previousValue + currentValue
        );
        const currentValue = dataset.data[context.dataIndex];
        const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
        return `${value}\n(${percentage})`;
      },
    },
    cutout: "70%", // Adjust this value to set the width of the doughnut chart
  };
  return (
    <div style={{ width: "100%", height: "195px" }} className="doughnut-chart">
    <Doughnut
      data={data}
      id="canvas"
      style={{ margin: "auto" }}
      options={options}
    />
    <p className="doughnut-chartText">
      {typeof donutChartSum === "number" ? donutChartSum.toFixed(2) : donutChartSum}
    </p>
    <p className="doughnut-chartText-unit">{donutUnit}</p>
  </div>
  
  );
}
