import React, { useState } from "react";
// import { ValidatorForm } from "react-material-ui-form-validator";
import logo from "../../../assets/hikar.png";
// import viewIcon from "../../../assets/images/viewIcon.png";
import { Grid } from "@mui/material";
// import { InputField } from "../../../components/InputField/InputField";
import TextField from '@mui/material/TextField';
import { Button } from "../../../components/Button/button";
import { InputAdornment } from '@material-ui/core';
import './NewPassword.css';
import { SliderAuth } from "../../../components/SliderAuth/SliderAuth";
import { newPasswordApi } from "../service";
import { ToastContainer, toast } from 'react-toastify';
import { images } from '../../../config/images';
import 'react-toastify/dist/ReactToastify.css'
import { useEffect } from "react";
import Loader from "../../../components/Loader/Loader";

export default function NewPassword({ OTP, email, onNextClick, onCancelClick, setEmail, setOTP }) {

    const [passwordValue, setpasswordValue] = useState({ password: '', rePassword: "" });
    const [showPassword, setShowPassword] = React.useState(false);
    const [showReEnterPassword, setShowReEnterPassword] = React.useState(false);
    const[isLoading,setLoading]= useState(false)

    const notify = (message) => toast.error(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    const handleChange = (e) => {
        setpasswordValue({ ...passwordValue, [e.target.name]: e.target.value })
    }

    const handlePasswordToggle = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleRePasswordToggle = () => {
        setShowReEnterPassword((prevShowPassword) => !prevShowPassword);
    };


    const [lengthValid, setLengthValid] = useState(false);
    const [numberValid, setNumberValid] = useState(false);
    const [specialCharValid, setSpecialCharValid] = useState(false);
    const [lowercaseValid, setLowercaseValid] = useState(false);
    const [uppercaseValid, setUppercaseValid] = useState(false);
    const [usernameValid, setUsernameValid] = useState(false);

    useEffect(() => {
        // Check password length


        if (passwordValue.password.length >= 8 && passwordValue.password.length <= 16) {
            setLengthValid(true);
        } else {
            setLengthValid(false);
        }

        // Check for number
        const numberRegex = /\d/;
        if (numberRegex.test(passwordValue.password)) {
            setNumberValid(true);
        } else {
            setNumberValid(false);
        }

        // Check for special character
        const specialCharRegex = /[^\w\s]/;
        if (specialCharRegex.test(passwordValue.password)) {
            setSpecialCharValid(true);
        } else {
            setSpecialCharValid(false);
        }

        // Check for lowercase letter
        const lowercaseRegex = /[a-z]/;
        if (lowercaseRegex.test(passwordValue.password)) {
            setLowercaseValid(true);
        } else {
            setLowercaseValid(false);
        }

        // Check for uppercase letter
        const uppercaseRegex = /[A-Z]/;
        if (uppercaseRegex.test(passwordValue.password)) {
            setUppercaseValid(true);
        } else {
            setUppercaseValid(false);
        }

        // Check that passwordValue.password does not contain username
        // const username = userDetail.username; // replace with actual username
        // if (username != passwordValue.password) {
        //     setUsernameValid(true);
        // } else {
        //     setUsernameValid(false);
        // }
    }, [passwordValue.password]);



    const handleSubmit = async () => {

        // const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,16}$/;
        if (!passwordValue.password) {
            notify("Please enter password in both field.")
            return true;
        }

        // if (!passwordPattern.test(passwordValue.password)) {
        //     notify("Password must contain at least one uppercase letter, at least one lowercase, at least one Alphabet, up to 7 to 16 charactor Long password!");
        //     return true;
        // }

        if (!lengthValid || !numberValid || !specialCharValid || !lowercaseValid || !uppercaseValid) {
            return true;
        }

        if (passwordValue.password != passwordValue.rePassword) {
            notify("Please check password is not same.");
            return true;
        }


        const params = {
            otp: OTP,
            email: email,
            password: passwordValue.password
        }
        try{
            setLoading(true)
            const resp = await newPasswordApi(params);
            if (resp.data.success == true) {
                setLoading(false)
                onNextClick();
                setEmail("");
                setOTP("")
            }
        }
        catch(error){
            setLoading(false)
            console.log("handlesubmit",error)
        }
     
    }

    return (
        <>
            <div>
                <ToastContainer autoClose={8000}></ToastContainer>
            </div>
            <Grid container className="main-container">
                <SliderAuth />
                <Grid item xs={12} md={5} lg={4} className="signIn-form-wrap">
                    <div className="pb-4">
                        <img src={logo} alt="" className="signIn-logo" />
                    </div>
                    <h1 className="newPassword text-center pt-4 pb-4">Create New Password</h1>
                    {/* <ValidatorForm> */}
                    {/* <InputField
                        id="standard-basic"
                        label="Password"
                        name="password"
                        value=""
                        validators={["required", "isEmail"]}
                        errorMessages={["email is required", "email is not valid"]}
                        variant="standard"
                    /> */}
                    <TextField id="filled-basic" type={showPassword ? 'text' : 'password'} label="Password" name="password" variant="filled" style={{ width: '100%', marginBottom: '20px' }} value={passwordValue.password} onChange={handleChange} InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" onClick={handlePasswordToggle} style={{ cursor: "pointer" }}>
                                {showPassword ? <img src={images.viewIcon} alt="" /> : <img src={images.eyecloseIcon} alt="" />}
                            </InputAdornment>
                        ),
                    }}
                        InputLabelProps={{
                            style: {
                                color: '#001323', // black color
                                background: '#F4F7FE'
                            },
                        }}
                    />
                    <TextField id="filled-basic" type={showReEnterPassword ? 'text' : 'password'} label="Re-enter Password" name="rePassword" variant="filled" style={{ width: '100%', marginBottom: '20px' }} value={passwordValue.rePassword} onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" onClick={handleRePasswordToggle} style={{ cursor: "pointer" }}>
                                    {showReEnterPassword ? <img src={images.viewIcon} alt="" /> : <img src={images.eyecloseIcon} alt="" />}
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#001323', // black color
                                background: '#F4F7FE'
                            },
                        }}
                    />
                    <div className="reEnterPassword-TextWrap">
                        <h6><b>New password must include at least one:</b></h6>
                        <h6 style={{ color: passwordValue.password ? lengthValid ? "green" : "red" : "" }}>Includes 8-16 characters</h6>
                        <h6 style={{ color: passwordValue.password ? numberValid ? "green" : "red" : "" }}>Number</h6>
                        <h6 style={{ color: passwordValue.password ? specialCharValid ? "green" : "red" : "" }}>Special character</h6>
                        <h6 style={{ color: passwordValue.password ? lowercaseValid ? "green" : "red" : "" }}>Lowercase letter</h6>
                        <h6 style={{ color: passwordValue.password ? uppercaseValid ? "green" : "red" : "" }}>Uppercase letter</h6>
                        {/* <h6 style={{ color: password.newPassword ? usernameValid ? "green" : "red" : "" }}>Not your username</h6> */}
                    </div>
                    {/* <InputField
                        type="password"
                        id="standard-basic"
                        label="Re-enter Password"
                        name="re-enter password"
                        value=""
                        validators={["required"]}
                        errorMessages={["password is required"]}
                        variant="standard"
                        style={{ position: "relative" }}
                    /> */}
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='pb-0 pb-md-3 pb-lg-5'>
                        <Grid item xs={6}>
                            <div>
                                <Button title='cancel' classname={"border-btn"} onClick={onCancelClick} />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <Button title='Submit' classname={"custom-button"} onClick={handleSubmit} />
                            </div>
                        </Grid>
                    </Grid>
                    {/* <div className='viewIcon'>
                        <img src={viewIcon} alt=""  />
                    </div> */}
                    {/* <div className="SingIn-unlock d-flex flex-column pb-0 pb-sm-5 pb-md-3 pb-lg-4">
                        <div>Unlock account?</div>
                        <div>Help</div>
                    </div> */}
                    <div className="SingIn-unlock d-flex flex-column">
                        <div onClick={() => window.location.assign("https://hikartech.com/unlock-account/")} style={{ cursor: "pointer" }}>Unlock account?</div>
                        <div>Help</div>
                    </div>
                    <div className="signIn-footer d-flex justify-content-between">
                        <div>
                            <h6>Terms & Conditions</h6>
                        </div>
                        <div>
                            <h6>Privacy</h6>
                        </div>
                        <div>
                            <h6>Cookies</h6>
                        </div>
                        <div>
                            <h6>Trademarks</h6>
                        </div>
                    </div>
                    <div className="signIn-footer-lines">
                        <div>
                            <h6>Powered by</h6>
                        </div>
                        <div>
                            <h6><span>Hikar®Technomation</span> Private Limited © All Rights Reserved</h6>
                        </div>
                    </div>
                    {/* </ValidatorForm> */}
                </Grid>
            </Grid>
            {isLoading && <Loader/>}
        </>
    );
}