import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { images } from '../../../config/images';
// import FooterText from '../../../components/FooterText/FooterText';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAccessManagement, deleteAccessManagement } from '../service';
import './AccessManagement.css'
import { useSelector } from 'react-redux';
import Loader from '../../../components/Loader/Loader';

function AccessManagement() {
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [accessManagementData, setAccessManagementData] = React.useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [search, setSearch] = useState({ department__department_name: "", role__role_name: "" })
    const[isLoading,setLoading] = useState(false)

    const navigate = useNavigate();

    const {companyName } = useSelector(state => state.userState)
    const notify = (message) => toast.error(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });


    const notifySuccess = (message) => toast.success(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    // Delete Confirmation Modal
    const handleConfirmationModal = () => {
        setOpenDeleteModal(!openDeleteModal)
    }


    const getAccessManagementdata = async () => {
        const resp = await getAccessManagement(search);
        if (resp.data.success == true) {
            setAccessManagementData(resp.data.payload)
        }
    }


    useEffect(() => {
        getAccessManagementdata()
    }, [search]);

    const handleSelectAll = (checked) => {
        if (checked) {
            const allRowIds = accessManagementData.map((e) => e.id);
            setSelectedRows(allRowIds);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(checked);
    };


    const handleSelectRow = (rowId) => {
        const selectedIndex = selectedRows.indexOf(rowId);
        let newSelectedRows = [];

        if (selectedIndex === -1) {
            newSelectedRows = [...selectedRows, rowId];
        } else {
            newSelectedRows = selectedRows.filter((id) => id !== rowId);
        }

        setSelectedRows(newSelectedRows);
    };


    // delete Access Management


    const deleteAccessManagementdata = async () => {

        if (!selectedRows.length) {
            notify("Please Select Device");
            return true;
        }

        if (selectedRows.length > 1) {
            notify("Please Select only one Device");
            return true;
        }
        try{
            setLoading(true)
            const resp = await deleteAccessManagement(selectedRows[0]);
            if (resp.data.success == true) {
                setLoading(false)
                getAccessManagementdata();
                handleConfirmationModal();
                notifySuccess(resp.data.message)
    
            }
        }
        catch(error){
            setLoading(false)
            console.log("handleSubmit",error)
        }
       
    }

    // handle Search
    const handleChangeSearch = (e) => {
        setSearch({ ...search, [e.target.name]: e.target.value })
    }


    return (
        <>
            <div>
                <ToastContainer autoClose={4000}></ToastContainer>
            </div>
            <div className='containerWrap'>
                <Container maxWidth="xxl">
                    <div className="deviceManagementHeader-wrap">
                        <div className='deviceManagementTitle-wrap'>
                            <KeyboardBackspaceIcon onClick={() => navigate('/superadmindashboard')} className='backArrow' /> <Typography variant="h5" className="deviceManagementTitle" gutterBottom style={{ margin: '0' }} onClick={() => navigate('/superadmindashboard')}>
                                {companyName} - Access Management
                            </Typography>
                        </div>
                        <div className='addDevice-buttonWrap'>
                            <button className='manageDepartment-button' onClick={() => navigate('/managedepartment')} >Manage Department</button>
                            <img src={images.addImage} alt="" className='cloudActiveImg' onClick={() => navigate('/addaccessManagement')} />
                            <img src={images.deleteIcon} alt="" className='cloudActiveImg' onClick={handleConfirmationModal} />
                            <img src={images.excelLogo} alt="" className='cloudActiveImg' />
                        </div>
                    </div>
                    {/* =============== Delete Confirmation Modal Start =============== */}
                    {openDeleteModal && <Card className='deleteConfirmation-Modal'>
                        <Typography variant="h6" gutterBottom className='deleteConfirmation-title'>
                            Are You Sure<br></br> You Want To Delete?
                        </Typography>
                        <Box className='deleteConfirmation-btnWrap'>
                            <button className='deleteConfirmation-Yesbtn' onClick={deleteAccessManagementdata}>Yes</button>
                            <button className='deleteConfirmation-Nobtn' onClick={handleConfirmationModal}>No</button>
                        </Box>
                    </Card>}
                    {/* =============== Delete Confirmation Modal End =============== */}
                    <div className="tableResponsive accessManagement">
                        <Table aria-label="" className="deviceManagementTable">
                            <TableHead>
                                <TableRow className="deviceManagementTable-title">
                                    <TableCell colSpan={2} >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox checked={selectAll}
                                                    onChange={(e) => handleSelectAll(e.target.checked)} />} />
                                            </FormGroup> Department
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        Role
                                    </TableCell>
                                    <TableCell className='accessManagement-text'>
                                        Access
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input type="search" placeholder="Department" name="department__department_name" onChange={handleChangeSearch} value={search.department__department_name} />
                                    </TableCell>
                                    <TableCell className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input type="search" placeholder="Role" name="role__role_name" onChange={handleChangeSearch} value={search.role__role_name} />
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>

                                {accessManagementData.length ? accessManagementData.map((e) => {
                                    return (
                                        <TableRow className='deviceManagementTable-data'>
                                            <TableCell className='accessManagement-checkbox'>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox checked={selectedRows.includes(e.id)}
                                                        onChange={() => handleSelectRow(e.id)} />} />
                                                </FormGroup>
                                            </TableCell>
                                            <TableCell>
                                                {e.department}
                                            </TableCell>
                                            <TableCell>
                                                {e.role}
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center" }}>
                                                <img src={images.editImg} alt="" className='cloudActiveImg' onClick={() => navigate(`/accessManagement/${e.id}`)} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) : []}

                            </TableHead>

                        </Table>
                    </div>
                    {/* <FooterText className='accessManagement-footer' /> */}
                </Container>
                {isLoading && <Loader />}
            </div>
        </>
    )
}

export default AccessManagement