import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deviceIdRedux: [],
    deviceUnitRedux: [],
    deviceColors: [],
    deviceIdReportRedux: [],
    deviceUnitReportRedux: [],
    deviceColorsReportRedux: [],
    unitColorsReportRedux: [],
    deviceIdPOPRedux: [],
    deviceUnitPOPRedux: [],
    devicePOPColors:[],
    startDateRe: null,
    endDateRe: null,
    startDateTimeRe : null,
    endDateTimeRe : null,
    startDatePopRe : null,
    endDatePopRe : null,
    startDatePopRe1 : null,
    endDatePopRe1 : null,

}

const overViewSlice = createSlice({
    name: "processDashboard",
    initialState,
    reducers: {
        setOverviewData(state, { payload }) {
            return { ...state, ...payload };
        },
        clearOverviewData(state) {
            return initialState;
        },
    },
});

export const {
    setOverviewData,
    clearOverviewData,
} = overViewSlice.actions;

export default overViewSlice.reducer;


