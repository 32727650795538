import { Card, Container, Divider, Paper, Stack, Typography, styled } from "@mui/material";
import React from "react";
import { images } from "../../../../config/images";

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));
const VmsMachine = () => {

  return (
    <div className="containerWrap">
    <Container maxWidth="xxl">
      <Card style={{ marginTop: "15px" }} className="dasboardCard">
        <Stack
          direction="row"
          divider={
            <Divider
              orientation="vertical"
              flexItem
              style={{
                backgroundColor: "#DCE1EA",
                width: "2px",
                height: "49px",
                marginTop: "20px",
              }}
            />
          }
          // spacing={{ sm: 14, md: 10, lg: 28 }}
          className="dashboardItem"
        >
          <Item className="dashboardCard-item">
            <img
              src={images.energyMeterImg}
              alt=""
              className="dashboardCard-img"
            />
            <Stack direction="column" spacing={{ lg: 0 }}>
              <Typography gutterBottom className="dashboardCard-title">
              MACHINES
              </Typography>
              <Typography gutterBottom className="dashboardCard-number">
              108
              </Typography>
            </Stack>
          </Item>
          <Item className="dashboardCard-item">
            <img src={images.clientManagementPlantS} alt="" className="dashboardCard-img" />
            <Stack direction="column" spacing={{ lg: 0 }}>
              <Typography gutterBottom className="dashboardCard-title">
              ACTIVE
              </Typography>
              <Typography gutterBottom className="dashboardCard-number">
              08
              </Typography>
            </Stack>
          </Item>
          <Item className="dashboardCard-item">
            <img
              src={images.activeImg}
              alt=""
              className="dashboardCard-img"
            />
            <Stack direction="column" spacing={{ lg: 0 }}>
              <Typography gutterBottom className="dashboardCard-title">
              INACTIVE
              </Typography>
              <Typography gutterBottom className="dashboardCard-number">
              06
              </Typography>
            </Stack>
          </Item>
          <Item className="dashboardCard-item">
            <img
              src={images.serviceCall}
              alt=""
              className="dashboardCard-img"
            />
            <Stack direction="column" spacing={{ lg: 0 }}>
              <Typography gutterBottom className="dashboardCard-title">
              WORK ORDER
              </Typography>
              <Typography gutterBottom className="dashboardCard-number">
              01
              </Typography>
            </Stack>
          </Item>
        </Stack>
      </Card>
      </Container>
      </div>
  )
};
export default VmsMachine;
