import React, { useState } from "react";
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import "../../ProcessDashboard/ProcessDashboard.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { images } from "../../../config/images";
import "../Configration/Configration.css";
import { TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
  getCreatePlantDahboard,
  getDeviceList,
  getDeviceTagList,
  getPlantDashboard,
} from "./services";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCubeQuery } from "@cubejs-client/react";
import dayjs from "dayjs";
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";

const Configration = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [devicepopup, setDevicePopup] = useState(false);
  const [deviceListData, setDeviceListData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [deviceTagList, setDeviceTagList] = useState([]);
  const [selectNumberLength, setSelectNumberLength] = useState([]);
  const [cardID, setCard] = useState("");
  const [position, setPosition] = useState("");
  const [result, setResult] = useState("");
  const [searchTagName, setSearchTagName] = useState("");
  const [searchTagComment, setSearchTagComment] = useState("");
  const [searchTagUnit, setSearchTagUnit] = useState("");
  const [dashboard, setDashboard] = useState("")
  const [selectTagName, setSelectTagName] = useState([])
  // const [positionValue1, setPositionValue1] = useState("");
  // const [positionValue2, setPositionValue2] = useState(0);
  // const [positionValue3, setPositionValue3] = useState("");
  // const [positionValue4, setPositionValue4] = useState("");
  // const [tagData1, setTagData1] = useState("");
  // const [tagData2, setTagData2] = useState("");
  // const [tagData3, setTagData3] = useState("");
  // const [tagData4, setTagData4] = useState("");

  const location = useLocation();
  const param = useParams();

  const { id } = useSelector((state) => state.userState);




  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handelSelectDevice = (row, id) => {
    row.stopPropagation()
    setDeviceId(id)
  }

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleGridClick = async (cardId, event) => {
    event.stopPropagation();

    if (devicepopup) {
      return true;
    }

    setDevicePopup(true);
    const params = {
      plant_id: param.id,
    };
    try {
      const resp = await getDeviceList(params);
      if (resp.data.success == true) {
        setDeviceListData(resp.data.payload);
        if (resp.data.payload.length) {
          setDeviceId(resp.data.payload[0].id);
        }
        
        setPosition(cardId);
        setCard(cardId);
        // setSelectNumberLength([]);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const getTagListByDevice = async (i) => {
    const params = {
      device_id: deviceId,
      plant_id: param.id,
      company_id: param.companyId,
      position: position,
    };
    try {
      const resp = await getDeviceTagList(params);
      if (resp.data.success == true) {
        setDeviceTagList(resp.data.payload);
        // const selected = [];
        // const select = resp.data.payload.map((e) => {
        //   if (e.is_selected) {
        //     selected.push(e.id);
        //   }
        // });
        // setSelectNumberLength(selected);
      }
    } catch (error) {
      notify(error.response.data.message);
      setDeviceTagList([]);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    getTagListByDevice();
  }, [deviceId, position]);

  // useEffect(() => {
  //   let isMounted = true; // Add a flag to check if the component is still mounted

  //   if (!position) {
  //     return () => {
  //       isMounted = false; // Update the flag when the component unmounts
  //     };
  //   }

  //   const deviceIdList = deviceListData.map((e) => e.id);

  //   deviceIdList.forEach(async (e) => {
  //     const params = {
  //       device_id: e,
  //       plant_id: param.id,
  //       company_id: param.companyId,
  //       position: position,
  //     };

  //     try {
  //       const resp = await getDeviceTagList(params);

  //       if (isMounted && resp.data.success === true) { // Check if the component is still mounted before updating state
  //         const select = resp.data.payload
  //           .filter((e) => e.is_selected)
  //           .map((e) => e.id);

  //         setSelectNumberLength((prevSelectNumberLength) => [
  //           ...prevSelectNumberLength,
  //           ...select,
  //         ]);
  //       }
  //     } catch (error) {
  //       if (isMounted) { // Check if the component is still mounted before updating state
  //         notify(error.response.data.message);
  //         setDeviceTagList([]);
  //         console.log("handlesubmit", error);
  //       }
  //     }
  //   });

  //   return () => {
  //     isMounted = false; // Cleanup function to update the flag when the component unmounts
  //   };
  // }, [position, deviceListData, param.id, param.companyId]);


  // useEffect(() => {
  //   let isMounted = true; // Add a flag to check if the component is still mounted

  //   if (!position) {
  //     return () => {
  //       isMounted = false; // Update the flag when the component unmounts
  //     };
  //   }

  //   const deviceIdList = [];
  //   if (deviceListData.length) {
  //     deviceListData.forEach((e) => {
  //       deviceIdList.push(e.id);
  //     });

  //     deviceIdList.forEach(async (e) => {
  //       const params = {
  //         device_id: e,
  //         plant_id: param.id,
  //         company_id: param.companyId,
  //         position: position,
  //       };

  //       try {
  //         const resp = await getDeviceTagList(params);

  //         if (isMounted && resp.data.success === true) { // Check if the component is still mounted before updating state
  //           const select = resp.data.payload
  //             .filter((e) => e.is_selected)
  //             .map((e) => e.tag_name);

  //           setSelectTagName((prevSelectTagName) => [...prevSelectTagName, ...select]);
  //         }
  //       } catch (error) {
  //         if (isMounted) { // Check if the component is still mounted before updating state
  //           notify(error.response.data.message);
  //           setDeviceTagList([]);
  //           console.log("handlesubmit", error);
  //         }
  //       }
  //     });
  //   }

  //   return () => {
  //     isMounted = false; // Cleanup function to update the flag when the component unmounts
  //   };
  // }, [deviceListData, devicepopup, position]);

  // const position_1_cube = useCubeQuery(
  //   {
  //     limit: 1,
  //     dimensions: [
  //       "DashboardTaghistory.latestTagName",
  //       "DashboardTaghistory.tagValue",
  //       "DashboardTaghistory.createdAt",
  //     ],
  //     order: [
  //       ["DashboardTaghistory.latestValue", "desc"],
  //       ["DashboardTaghistory.createdAt", "desc"],
  //     ],
  //     filters: [
  //       {
  //         member: "DashboardTaghistory.tag",
  //         operator: "equals",
  //         values: dashboard.length ? [`${dashboard[0].position_1}`] : ["0"],
  //       },
  //     ],
  //   },
  //   { subscribe: true }
  // );

  // const position_2_cube = useCubeQuery(
  //   {
  //     limit: 1,
  //     dimensions: [
  //       "DashboardTaghistory.latestTagName",
  //       "DashboardTaghistory.tagValue",
  //       "DashboardTaghistory.createdAt",
  //     ],
  //     order: [
  //       ["DashboardTaghistory.latestValue", "desc"],
  //       ["DashboardTaghistory.createdAt", "desc"],
  //     ],
  //     filters: [
  //       {
  //         member: "DashboardTaghistory.tag",
  //         operator: "equals",
  //         values: dashboard.length ? [`${dashboard[0].position_2}`] : ["0"],
  //       },
  //     ],
  //   },
  //   { subscribe: true }
  // );

  // const position_3_cube = useCubeQuery(
  //   {
  //     limit: 1,
  //     dimensions: [
  //       "DashboardTaghistory.latestTagName",
  //       "DashboardTaghistory.tagValue",
  //       "DashboardTaghistory.createdAt",
  //     ],
  //     order: [
  //       ["DashboardTaghistory.latestValue", "desc"],
  //       ["DashboardTaghistory.createdAt", "desc"],
  //     ],
  //     filters: [
  //       {
  //         member: "DashboardTaghistory.tag",
  //         operator: "equals",
  //         values: dashboard.length ? [`${dashboard[0].position_3}`] : ["0"],
  //       },
  //     ],
  //   },
  //   { subscribe: true }
  // );

  // const position_4_cube = useCubeQuery(
  //   {
  //     limit: 1,
  //     dimensions: [
  //       "DashboardTaghistory.latestTagName",
  //       "DashboardTaghistory.tagValue",
  //       "DashboardTaghistory.createdAt",
  //     ],
  //     order: [
  //       ["DashboardTaghistory.latestValue", "desc"],
  //       ["DashboardTaghistory.createdAt", "desc"],
  //     ],
  //     filters: [
  //       {
  //         member: "DashboardTaghistory.tag",
  //         operator: "equals",
  //         values: dashboard.length ? [`${dashboard[0].position_4}`] : ["0"],
  //       },
  //     ],
  //   },
  //   { subscribe: true }
  // );

  // useEffect(() => {
  //   if (
  //     !position_1_cube.isLoading &&
  //     !position_2_cube.isLoading &&
  //     !position_3_cube.isLoading &&
  //     !position_4_cube.isLoading
  //   ) {
  //     if (
  //       position_1_cube.resultSet ||
  //       position_2_cube.resultSet ||
  //       position_4_cube.resultSet
  //     ) {
  //       const pos1 = position_1_cube.resultSet.tablePivot().length
  //         ? position_1_cube.resultSet.tablePivot()[0][
  //             "DashboardTaghistory.tagValue"
  //           ]
  //         : 0;
  //       const pos2 = position_2_cube.resultSet.tablePivot().length
  //         ? position_2_cube.resultSet.tablePivot()[0][
  //             "DashboardTaghistory.tagValue"
  //           ]
  //         : 0;
  //       const pos3 = position_3_cube.resultSet.tablePivot().length
  //         ? position_3_cube.resultSet.tablePivot()[0][
  //             "DashboardTaghistory.tagValue"
  //           ]
  //         : 0;
  //       const pos4 = position_4_cube.resultSet.tablePivot().length
  //         ? position_4_cube.resultSet.tablePivot()[0][
  //             "DashboardTaghistory.tagValue"
  //           ]
  //         : 0;

  //       // console.log("pos1", position_4_cube);
  //       setPositionValue1(pos1);
  //       setPositionValue2(pos2);
  //       setPositionValue3(pos3);
  //       setPositionValue4(pos4);
  //     }
  //   }
  // }, [position_1_cube, position_2_cube, position_3_cube, position_4_cube]);

  // const handleCube = async () => {
  //   if (!dashboard.length) {
  //     return true;
  //   }

  //   if (dashboard[0].position_1) {
  //     const t1 = await cubejsApi.load(
  //       {
  //         dimensions: [
  //           "DeviceConfigurationTagconfiguration.tagComment",
  //           "DeviceConfigurationTagconfiguration.tagUnit",
  //         ],
  //         order: {
  //           "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //         },
  //         filters: [
  //           {
  //             member: "DeviceConfigurationTagconfiguration.tagName",
  //             operator: "equals",
  //             values: [`${dashboard[0].position_1_name}`],
  //           },
  //         ],
  //       },
  //       { subscribe: true }
  //     );

  //     setTagData1({
  //       comment:
  //         t1.loadResponses[0].data[0][
  //           "DeviceConfigurationTagconfiguration.tagComment"
  //         ],
  //       unit: t1.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagUnit"
  //       ],
  //     });
  //   }
  //   if (dashboard[0].position_2) {
  //     const t2 = await cubejsApi.load(
  //       {
  //         dimensions: [
  //           "DeviceConfigurationTagconfiguration.tagComment",
  //           "DeviceConfigurationTagconfiguration.tagUnit",
  //         ],
  //         order: {
  //           "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //         },
  //         filters: [
  //           {
  //             member: "DeviceConfigurationTagconfiguration.tagName",
  //             operator: "equals",
  //             values: [`${dashboard[0].position_2_name}`],
  //           },
  //         ],
  //       },
  //       { subscribe: true }
  //     );
  //     setTagData2({
  //       comment:
  //         t2.loadResponses[0].data[0][
  //           "DeviceConfigurationTagconfiguration.tagComment"
  //         ],
  //       unit: t2.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagUnit"
  //       ],
  //     });
  //   }
  //   if (dashboard[0].position_3) {
  //     const t3 = await cubejsApi.load(
  //       {
  //         dimensions: [
  //           "DeviceConfigurationTagconfiguration.tagComment",
  //           "DeviceConfigurationTagconfiguration.tagUnit",
  //         ],
  //         order: {
  //           "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //         },
  //         filters: [
  //           {
  //             member: "DeviceConfigurationTagconfiguration.tagName",
  //             operator: "equals",
  //             values: [`${dashboard[0].position_3_name}`],
  //           },
  //         ],
  //       },
  //       { subscribe: true }
  //     );
  //     setTagData3({
  //       comment:
  //         t3.loadResponses[0].data[0][
  //           "DeviceConfigurationTagconfiguration.tagComment"
  //         ],
  //       unit: t3.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagUnit"
  //       ],
  //     });
  //   }
  //   if (dashboard[0].position_4) {
  //     const t4 = await cubejsApi.load(
  //       {
  //         dimensions: [
  //           "DeviceConfigurationTagconfiguration.tagComment",
  //           "DeviceConfigurationTagconfiguration.tagUnit",
  //         ],
  //         order: {
  //           "DeviceConfigurationTagconfiguration.tagComment": "asc",
  //         },
  //         filters: [
  //           {
  //             member: "DeviceConfigurationTagconfiguration.tagName",
  //             operator: "equals",
  //             values: [`${dashboard[0].position_4_name}`],
  //           },
  //         ],
  //       },
  //       { subscribe: true }
  //     );
  //     setTagData4({
  //       comment:
  //         t4.loadResponses[0].data[0][
  //           "DeviceConfigurationTagconfiguration.tagComment"
  //         ],
  //       unit: t4.loadResponses[0].data[0][
  //         "DeviceConfigurationTagconfiguration.tagUnit"
  //       ],
  //     });
  //   }
  // }


  // useEffect(() => {
  //   if (dashboard.length) {
  //     handleCube();
  //   }
  // }, [
  //   dashboard,
  
  // ]);

 

  const handleCancleClick = () => {
    setDevicePopup(false);
    setSelectNumberLength([])
    setSelectTagName([])
    setPosition("")
    // window.location.reload(false);
  };
  const navigate = useNavigate();

  const handleSubmitClick = async () => {
    // const index = selectNumberLength.indexOf(id);
    // if (index > -1) {
    //   // if checkbox is already selected, remove it from selectNumberLength
    //   selectNumberLength.splice(index, 1);
    // } else {
    //   // if checkbox is not selected, add it to selectNumberLength
    //   selectNumberLength.push(id);
    // }

    const params = {
      plant_id: param.id,
      company_id: param.companyId,
      position_1: position == 1 ? selectNumberLength[0] : dashboard.length ? dashboard[0].position_1 : "",
      position_2: position == 2 ? selectNumberLength[0] : dashboard.length ? dashboard[0].position_2 : "",
      position_3: position == 3 ? selectNumberLength[0] : dashboard.length ? dashboard[0].position_3 : "",
      position_4: position == 4 ? selectNumberLength[0] : dashboard.length ? dashboard[0].position_4 : "",
      position_5: position == 5 ? selectNumberLength.slice(0, 6) : dashboard.length ?  dashboard[0].position_5 : [],
      position_6: position == 6 ? selectNumberLength.slice(0, 6) : dashboard.length ? dashboard[0].position_6 : [],
      position_7: position == 7 ? selectNumberLength.slice(0, 6) : dashboard.length ?  dashboard[0].position_7 : [],
      user: id,
    };

    try {
      const resp = await getCreatePlantDahboard(params);
    
      if (resp.data.success == true) {
        setDevicePopup(false);
        setSelectNumberLength([]);
        setSelectTagName([]);
        handleGetPlantDashboard();
        setPosition("")
        // window.location.reload(false);
        // navigate(`/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`)
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  // useEffect(() => {
  //   handleSubmitClick();
  // },[selectNumberLength])

  const handleClickCheckbox = (id, row, name) => {
    row.stopPropagation()
    if (!Array.isArray(selectNumberLength)) {
      setSelectNumberLength([]);
    }
    if (selectNumberLength.includes(id)) {
      setSelectNumberLength(
        selectNumberLength.filter((element) => element !== id)
      );
    } else {
      setSelectNumberLength([...selectNumberLength, id]);
    }
    if (position === 5 || position === 6 || position === 7) {
      // Allow selecting checkboxes only for positions 5, 6, and 7
      if (selectNumberLength.includes(id)) {
        setSelectNumberLength(
          selectNumberLength.filter((checkboxId) => checkboxId !== id)
        );
      } else {
        setSelectNumberLength([...selectNumberLength, id]);
      }
    }

    if (!Array.isArray(selectTagName)) {
      setSelectTagName([]);
    }
    if (selectTagName.includes(name)) {
      setSelectTagName(
        selectTagName.filter((element) => element !== name)
      );
    } else {
      setSelectTagName([...selectTagName, name]);
    }
    if (position === 5 || position === 6 || position === 7) {
      // Allow selecting checkboxes only for positions 5, 6, and 7
      if (selectTagName.includes(name)) {
        setSelectTagName(
          selectTagName.filter((checkboxId) => checkboxId !== name)
        );
      } else {
        setSelectTagName([...selectTagName, name]);
      }
    }
  };

  const handleLeftSelect = () => {
    if (position === 1 || position === 2 || position === 3 || position === 4) {
      return selectNumberLength.length ? 0 : 1;
    } else if (position === 5 || position === 6 || position === 7) {
      const numSelected = selectNumberLength.length;
      const numLeft = 6 - numSelected;
      return numLeft >= 0 ? numLeft : 0;
    }
    return 0;
  };
  const currentDate = dayjs().format("YYYY-MM-DD HH:mm:ss");

  const handleCubejs = useCubeQuery({
    dimensions: ["DashboardTaghistory.tagValue"],
    order: {
      "DashboardTaghistory.tagValue": "desc",
    },
    filters: [
      {
        member: "DashboardTaghistory.tag",
        operator: "equals",
        values: ["218"],
      },
    ],
  });

  const isCheckboxDisabled = (id) => {
    if (position === 1 || position === 2 || position === 3 || position === 4) {
      return selectNumberLength.length > 0 && !selectNumberLength.includes(id);
    } else if (position === 5 || position === 6 || position === 7) {
      return selectNumberLength.length >= 6 && !selectNumberLength.includes(id);
    }
    return false;
  };

  useEffect(() => {
    handleGetPlantDashboard();
  }, []);

  const handleGetPlantDashboard = async () => {
    const params = {
      plant_id: param.id,
    };
    try {
      const resp = await getPlantDashboard(params);
      if (resp.data.success == true && resp.data.payload.length) {
        setDashboard(resp.data.payload);
        setSelectNumberLength([]);
        setSelectTagName([])

        // if (resp.data.payload.length) {
        //   if (resp.data.payload[0]) {
        //     setSelectNumberLength([...selectNumberLength, resp.data.payload[0].position_1, resp.data.payload[0].position_2, resp.data.payload[0].position_3, resp.data.payload[0].position_4])
        //   }
        // }
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (dashboard && position) {
      if (position == 1) {
        setSelectNumberLength([dashboard[0].position_1]);
        setSelectTagName([dashboard[0].position_1_name.tag_name])
      }
      else if (position == 2) {
        setSelectNumberLength([dashboard[0].position_2]);
        setSelectTagName([dashboard[0].position_2_name.tag_name])
      }
      else if (position == 3) {
        setSelectNumberLength([dashboard[0].position_3]);
        setSelectTagName([dashboard[0].position_3_name.tag_name])
      }
      else if (position == 4) {
        setSelectNumberLength([dashboard[0].position_4]);
        setSelectTagName([dashboard[0].position_4_name.tag_name])
      }
      else if (position == 5) {
        setSelectNumberLength(dashboard[0].position_5);
        setSelectTagName(dashboard[0].position_5_names.length ? dashboard[0].position_5_names.map((e) => e.tag_name) : [])
      }
      else if (position == 6) {
        setSelectNumberLength(dashboard[0].position_6);
        setSelectTagName(dashboard[0].position_6_names.length ? dashboard[0].position_6_names.map((e) => e.tag_name) : [])
      }
      else if (position == 7) {
        setSelectNumberLength(dashboard[0].position_7);
        setSelectTagName(dashboard[0].position_7_names.length ? dashboard[0].position_7_names.map((e) => e.tag_name) : [])
      }
    }
  }, [dashboard, position])

  return (
    <>
      <div className="p-4 pb-5">
        <ToastContainer />
        <div className="d-flex gap-3">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                className="configration-card-overlay"
                style={{
                  padding: "11px 19px",
                  borderRadius: "6px",
                  position: "relative",
                  border: "1px dashed #1C407B",
                }}
              >
                <div className="d-flex ">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.speedometer} />
                    </div>
                  </Box>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography className="device-desc">
                    Grid Set Demand
                    </Typography>
                    <Typography className="device-number">00003486</Typography>
                    <Typography>KVA</Typography>
                  </CardContent>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#AEBCD2B3",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                    onClick={(event) => handleGridClick(1, event)}
                  ></div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                className="configration-card-overlay"
                style={{
                  padding: "11px 19px",
                  borderRadius: "6px",
                  position: "relative",
                  border: "1px dashed #1C407B",
                }}
              >
                <div className="d-flex ">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.speedometer} />
                    </div>
                  </Box>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography className="device-desc">
                    Grid Set Demand
                    </Typography>
                    <Typography className="device-number">00003486</Typography>
                    <Typography>KVA</Typography>
                  </CardContent>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#AEBCD2B3",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                    onClick={(event) => handleGridClick(2, event)}
                  ></div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                className="configration-card-overlay"
                style={{
                  padding: "11px 19px",
                  borderRadius: "6px",
                  position: "relative",
                  border: "1px dashed #1C407B",
                }}
              >
                <div className="d-flex ">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.speedometer} />
                    </div>
                  </Box>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography className="device-desc">
                    Grid Set Demand
                    </Typography>
                    <Typography className="device-number">00003486</Typography>
                    <Typography>KVA</Typography>
                  </CardContent>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#AEBCD2B3",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                    onClick={(event) => handleGridClick(3, event)}
                  ></div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                className="configration-card-overlay"
                style={{
                  padding: "11px 19px",
                  borderRadius: "6px",
                  position: "relative",
                  border: "1px dashed #1C407B",
                }}
              >
                <div className="d-flex ">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.speedometer} />
                    </div>
                  </Box>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography className="device-desc">
                    Grid Set Demand
                    </Typography>
                    <Typography className="device-number">00003486</Typography>
                    <Typography>KVA</Typography>
                  </CardContent>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#AEBCD2B3",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                    onClick={(event) => handleGridClick(4, event)}
                  ></div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div className="pt-3 process-card-chart">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card
                style={{
                  height: "550px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "11px 19px",
                  borderRadius: "6px",
                  position: "relative",
                  border: "1px dashed #1C407B",
                }}
              >
                <div className="graph-box">
                  <div className="d-flex p-3">
                    <img
                      src={images.pieChart}
                      height="50px"
                      width="50px"
                      style={{ marginRight: "20px" }}
                    />
                    <img src={images.barChart} height="50px" width="50px" />
                  </div>
                  <div
                    className="graph-date pt-3"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Box className="process-header-btn-date">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="Start Date"
                          value={startDate}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box
                      className="process-header-btn-month"
                      style={{ marginRight: "11px" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="End Date"
                          value={endDate}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            setEndDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#AEBCD2B3",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }}
                      onClick={(event) => handleGridClick(5, event)}
                    ></div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card
                style={{
                  height: "550px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "11px 19px",
                  borderRadius: "6px",
                  position: "relative",
                  border: "1px dashed #1C407B",
                }}
              >
                <div className="graph-box">
                  <div className="d-flex p-3">
                    <img
                      src={images.pieChart}
                      height="50px"
                      width="50px"
                      style={{ marginRight: "20px" }}
                    />
                    <img src={images.barChart} height="50px" width="50px" />
                  </div>
                  <div
                    className="graph-date pt-3"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Box className="process-header-btn-date">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="Start Date"
                          value={startDate}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box
                      className="process-header-btn-month"
                      style={{ marginRight: "11px" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="End Date"
                          value={endDate}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            setEndDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#AEBCD2B3",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }}
                      onClick={(event) => handleGridClick(6, event)}
                    ></div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card
                style={{
                  height: "550px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "11px 19px",
                  borderRadius: "6px",
                  position: "relative",
                  border: "1px dashed #1C407B",
                }}
              >
                <div className="graph-box">
                  <div className="d-flex p-3">
                    <img
                      src={images.pieChart}
                      height="50px"
                      width="50px"
                      style={{ marginRight: "20px" }}
                    />
                    <img src={images.barChart} height="50px" width="50px" />
                  </div>
                  <div
                    className="graph-date pt-3"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Box className="process-header-btn-date">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="Start Date"
                          value={startDate}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box
                      className="process-header-btn-month"
                      style={{ marginRight: "11px" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date-picker-production"
                          label="End Date"
                          value={endDate}
                          inputFormat="DD/MM/YYYY"
                          views={["day"]}
                          onChange={(newValue) => {
                            setEndDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#AEBCD2B3",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }}
                      onClick={(event) => handleGridClick(7, event)}
                    ></div>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={1} className="pt-4">
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <button
              // className="Process-dashboard-btn"
              className={`${
                activeClass == "overview"
                  ? "active-process-dashboard"
                  : "Process-dashboard-btn"
              }`}
              style={{ pointerEvents: "none" }}
            >
              OVERVIEW
            </button>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <button
              className={`${
                activeClass == "source"
                  ? "active-process-dashboard"
                  : "Process-dashboard-btn"
              }`}
              style={{ pointerEvents: "none" }}
            >
              SOURCE
            </button>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <button
              className={`${
                activeClass == "consumption"
                  ? "active-process-dashboard"
                  : "Process-dashboard-btn"
              }`}
              style={{ pointerEvents: "none" }}
            >
              CONSUMPTIONS
            </button>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <button
              className={`${
                activeClass == "Analytics"
                  ? "active-process-dashboard"
                  : "Process-dashboard-btn"
              }`}
              style={{ pointerEvents: "none" }}
            >
              ADVANCED ANALYTICS
            </button>
          </Grid>
        </Grid>

        {devicepopup && (
          <>
            <div className="deviceModal-popup">
              <div className="d-flex devices-text">
                <h6 style={{ marginTop: "7px" }}>Devices</h6>
                <h6 style={{ marginTop: "7px" }} className="device-tag-list">
                  Tag List
                </h6>
                <div className="selectionText">
                  {handleLeftSelect()} Left For Selection
                </div>
              </div>
              <Grid container spacing={2} className="pt-2">
                <Grid item xs={12} sm={6} md={5} lg={5}>
                  <Box className="devicemodal-box">
                    <div style={{ maxHeight: "425px", overflow: "auto" }}>
                      {deviceListData.length
                        ? deviceListData.map((e) => {
                            return (
                              <Box
                                className={`Device-caption ${deviceId == e.id ? "Device-caption1" : ""}  mb-2`}
                                onClick={(row) => handelSelectDevice(row, e.id)}
                              >
                                <h6>{e.device_name}</h6>
                                <p>UID - {e.uid}</p>
                                <p>{e.device_description}</p>
                              </Box>
                            );
                          })
                        : []}
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={7}>
                  <Box className="devicemodal-box">
                    <div style={{ maxHeight: "454px", overflow: "auto" }}>
                      <Table className="device-table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{ background: "white" }}
                            ></TableCell>
                            <TableCell style={{ background: "white" }}>
                              Tag Name
                            </TableCell>
                            <TableCell style={{ background: "white" }}>
                              Tag Comment
                            </TableCell>
                            <TableCell
                              style={{ background: "white", width: "0%" }}
                            >
                              Unit
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className="search-table-row"
                              style={{ background: "#F1F1FF" }}
                            ></TableCell>
                            <TableCell
                              className="search-table-row"
                              style={{ background: "#F1F1FF" }}
                            >
                              <SearchIcon className="search-icon" />
                              <input
                                type="search"
                                placeholder="Tag Name"
                                style={{fontSize:'13px'}}
                                name="Date"
                                value={searchTagName}
                                onChange={(e) =>
                                  setSearchTagName(e.target.value)
                                }
                              />
                            </TableCell>

                            <TableCell
                              className="search-table-row"
                              style={{ background: "#F1F1FF" }}
                            >
                              <SearchIcon className="search-icon" />
                              <input
                                type="search"
                                placeholder="Tag Comment"
                                style={{fontSize:'13px'}}
                                name="Date"
                                value={searchTagComment}
                                onChange={(e) =>
                                  setSearchTagComment(e.target.value)
                                }
                              />
                            </TableCell>

                            <TableCell
                              className="search-table-row"
                              style={{ background: "#F1F1FF" }}
                            >
                              <SearchIcon className="search-icon" />
                              <input
                                type="search"
                                placeholder="Unit"
                                style={{fontSize:'13px'}}
                                name="Date"
                                value={searchTagUnit}
                                onChange={(e) =>
                                  setSearchTagUnit(e.target.value)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {deviceTagList
                            .filter((e) =>
                            e.tag_name && e.tag_name.toLowerCase().includes(searchTagName.toLowerCase())
                          )
                          .filter((e) =>
                            e.tag_comment && e.tag_comment.toLowerCase().includes(searchTagComment.toLowerCase())
                          )
                          .filter((e) =>
                            e.tag_unit && e.tag_unit.toLowerCase().includes(searchTagUnit.toLowerCase())
                          )
                            .map((e) => {
                              return (
                                <TableRow>
                                  <TableCell style={{ padding: "0px" }}>
                                    <Checkbox
                                      style={{ color: "#1C407B" }}
                                      onClick={(row) => handleClickCheckbox(e.id, row, e.tag_name)}
                                      checked={selectNumberLength.includes(e.id)}
                                      disabled={isCheckboxDisabled(e.id)}
                                    >
                                      {e.tag_name}
                                    </Checkbox>
                                  </TableCell>
                                  <TableCell>{e.tag_name}</TableCell>
                                  <TableCell>{e.tag_comment}</TableCell>
                                  <TableCell>{e.tag_unit}</TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </div>
                  </Box>
                </Grid>
              </Grid>

              <div className="pt-3 d-flex selectTagDesc">
                <h6>Selected Tag: </h6>
                {selectTagName.length ? [...new Set(selectTagName)]
                  .map((tag, index, array) => (
                    <React.Fragment key={tag.id}>
                      <p>{tag}</p>
                      {index < array.length - 1 && <p>,</p>}
                    </React.Fragment>
                  )):""}
              </div>
              <div className="text-center">
                <button className="canclebtn" onClick={handleCancleClick}>
                  Cancel
                </button>
                <button className={selectNumberLength.length ?"submitbtn" : "submit-btn-disable" } onClick={handleSubmitClick}>
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Configration;
